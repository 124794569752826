import Service, { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { isPresent } from '@ember/utils';
import config from 'eflex/config/environment';
import { waitFor } from '@ember/test-waiters';

const authConfig = config['ember-simple-auth-token'];
const TOKEN_PROPERTY_NAME = authConfig.tokenPropertyName ?? 'token';
const AUTHORIZATION_HEADER_NAME = authConfig.authorizationHeaderName ?? 'Authorization';
const AUTHORIZATION_PREFIX = authConfig.authorizationPrefix ?? 'Bearer ';

export default class EflexAjaxService extends Service {
  @service session;

  @task
  @waitFor
  *get(route, params, accept) {
    return yield this._sendJsonRequest.perform('GET', this.getUrl(route, params), null, accept);
  }

  @task
  @waitFor
  *post(route, data, accept) {
    return yield this._sendJsonRequest.perform('POST', this.getUrl(route), data, accept);
  }

  @task
  @waitFor
  *put(route, data, accept) {
    return yield this._sendJsonRequest.perform('PUT', this.getUrl(route), data, accept);
  }

  @task
  @waitFor
  *delete(route) {
    return yield this._sendJsonRequest.perform('DELETE', this.getUrl(route));
  }

  @task
  @waitFor
  *sendRawRequest(url, type, data, accept) {
    const options = this.getOptions(type, data, accept);
    const response = yield fetch(url, options);

    if (this.session.isAuthenticated && response.status === 401) {
      yield this.session.invalidate();
      return;
    }

    return response;
  }

  @task
  @waitFor
  *_sendJsonRequest(type, url, data, accept) {
    const response = yield this.sendRawRequest.perform(url, type, data, accept);

    if (response.status === 204 || response == null) {
      return;
    }

    const jsonResponse = yield response.json();

    if (response.ok) {
      return jsonResponse;
    }

    const errorJson = jsonResponse.error;

    const error = new Error(errorJson?.message);
    error.contextVariables = errorJson?.contextVariables;
    error.isValidationError = errorJson?.isValidationError;
    throw error;
  }

  getOptions(method, data, accept) {
    const options = {
      method,
      mode: 'cors',
      credentials: 'include',
      headers: {},
    };

    if (this.session.isOidc) {
      options.headers = { ...this.session.headers };
    } else {
      const token = this.session.data?.authenticated?.[TOKEN_PROPERTY_NAME];

      if (this.session.isAuthenticated && isPresent(token)) {
        options.headers[AUTHORIZATION_HEADER_NAME] = `${AUTHORIZATION_PREFIX}${token}`;
      }
    }

    if (method !== 'DELETE') {
      options.headers.Accept = accept ?? 'application/json';

      if (method !== 'GET') {
        if (data instanceof FormData) {
          options.body = data;
        } else if (data != null) {
          options.headers['content-type'] = 'application/json';
          options.body = JSON.stringify(data);
        }
      }
    }

    return options;
  }

  getUrl(url, params = null) {
    url = `${config.servicePrefix}/${url}`;

    if (params != null) {
      Object.keys(params).forEach(key => {
        if (params[key] === undefined) {
          delete params[key];
        }
      });

      url = `${url}?${new URLSearchParams(params).toString()}`;
    }

    return url;
  }
}
