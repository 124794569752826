import Hardware from 'eflex/models/hardware';
import { inject as service } from '@ember/service';

export default class WorkInstructionHardware extends Hardware {
  @service intl;

  get triggerDisplayName() {
    return this.intl.t('workInstruction');
  }

  get isConfigured() {
    return true;
  }
}
