import { isBlank } from '@ember/utils';

export default function (array, val, allowBlank = false) {
  const counts = {};
  const nonUnique = [];
  const toCheck = array ?? [];
  toCheck.forEach(model => {
    const field = model[val];
    counts[field] ??= 0;
    counts[field] += 1;

    if (counts[field] > 1 && (!isBlank(field) || allowBlank)) {
      nonUnique.push(field);
    }
  });

  return nonUnique;
}
