import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { task as trackedTask } from 'ember-resources/util/ember-concurrency';
import OEE_STATES from 'eflex/constants/oee-states';
import { waitFor } from '@ember/test-waiters';
export default class BiPanelStates extends Component {
  @service intl;
  @service queryRunner;

  get params() {
    return {
      startDate: this.args.startDate,
      endDate: this.args.endDate,
    };
  }

  oeeStateNames = trackedTask(this, this.getOeeButtonNames, () => [
    this.params,
  ]);

  @task({ drop: true })
  @waitFor
  *getOeeButtonNames(params) {
    const defaultOeeStateNames = OEE_STATES.BUTTONS.map((button) => {
      return this.intl.t(button.label).toUpperCase();
    });

    const customOeeStateQuery = yield this.queryRunner.queryWithParams.perform(
      params,
      [
        {
          $match: {
            customButtonColor: {
              $ne: null,
            },
          },
        },
        {
          $group: {
            _id: '$state',
          },
        },
        {
          $project: {
            _id: 0,
            state: '$_id',
          },
        },
      ],
      true,
    );

    const customOeeStates = yield this.queryRunner.runQuery.perform(
      'OeeStates',
      customOeeStateQuery,
    );
    return customOeeStates
      .map((result) => result.state.toUpperCase())
      .concat(defaultOeeStateNames)
      .toSorted((a, b) => a.localeCompare(b));
  }

  get selectedStates() {
    return this.args.states;
  }
}
