import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { copyable } from 'eflex/decorators';
import { anyInvalid } from 'eflex/util/getter-helpers';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { cached } from '@glimmer/tracking';
import { rollbackHasMany, isHasManyRefDirty } from 'eflex/util/relationship-helpers';
import { rangeCorrectness } from 'eflex/util/validators';
import { taskTypes } from 'eflex/constants/tasks/task-types';
import { getOwner } from '@ember/application';

const basicLimitValidator = validator('number', {
  gte: 0,
  allowString: true,
  allowBlank: true,
});

// if adding a new config relationship that is related back
// to tasks. Make sure to update the following locations
// * copyTaskConfig in services/task-config-repo
// * isDirty && copyableOptions in this file
// * copyModel in services/model-repo
@copyable
@buildValidations({
  enabled: [
    validator('inline', {
      validate(enabled, options, taskConfig) {
        if (taskConfig.strings.every(item => item.enabled === false)) {
          const intl = getOwner(taskConfig).lookup('service:intl');
          if (taskConfig.usesJobs) {
            return intl.t('errors.mustEnableOneString');
          }

          const context = taskConfig.usesComponents ? intl.t('option') : intl.t('model');

          return intl.t('errors.mustEnableString', { context });
        }
        return true;
      },
      get disabled() {
        return this.model.taskType !== taskTypes.barcode || !this.model.enabled;
      },
    }),
  ],

  programNumber: {
    validators: [
      validator('number', {
        integer: true,
        gte: 0,
        allowString: true,
        allowNone: false,
      }),
      validator('number', {
        allowString: true,
        lte: 127,
        get disabled() {
          return this.model.taskType !== taskTypes.plc;
        },
      }),
    ],
  },

  programName: [
    validator('presence', {
      presence: true,
      get disabled() {
        return this.model.taskType !== 'lightGuide';
      },
    }),
  ],

  targetCycleTime: [validator('number', { gte: 0, allowString: true })],

  // torque specific
  boltCount: [
    validator('number', {
      integer: true,
      gte: 0,
      lte: 127,
      allowString: true,
      allowNone: false,
    }),
  ],

  finalAngleLowerLimit: {
    validators: [
      basicLimitValidator,
      validator('inline', {
        validate(finalAngleLowerLimit, options, taskConfig) {
          return rangeCorrectness(
            taskConfig,
            finalAngleLowerLimit,
            taskConfig.finalAngleUpperLimit,
            'plant.taskConfig.limitOverlap',
          );
        },
      }),
    ],
  },

  finalAngleUpperLimit: {
    validators: [
      basicLimitValidator,
      validator('inline', {
        validate(finalAngleUpperLimit, options, taskConfig) {
          return rangeCorrectness(
            taskConfig,
            taskConfig.finalAngleLowerLimit,
            finalAngleUpperLimit,
            'plant.taskConfig.limitOverlapUpper',
          );
        },
      }),
    ],
  },

  finalTorqueLowerLimit: {
    validators: [
      basicLimitValidator,
      validator('inline', {
        validate(finalTorqueLowerLimit, options, taskConfig) {
          return rangeCorrectness(
            taskConfig,
            finalTorqueLowerLimit,
            taskConfig.finalTorqueUpperLimit,
            'plant.taskConfig.limitOverlap',
          );
        },
      }),
    ],
  },

  finalTorqueUpperLimit: {
    validators: [
      basicLimitValidator,
      validator('inline', {
        validate(finalTorqueUpperLimit, options, taskConfig) {
          return rangeCorrectness(
            taskConfig,
            taskConfig.finalTorqueLowerLimit,
            finalTorqueUpperLimit,
            'plant.taskConfig.limitOverlapUpper',
          );
        },
      }),
    ],
  },

  interlockedTime: [
    validator('number', {
      get disabled() {
        if (this.model.task?.isNonInterlocked && this.model.isTimer) {
          return false;
        }

        if (this.model.task?.isPick) {
          return this.model.pickTimeDisabled;
        }

        return true;
      },
      gt: 0,
      allowString: true,
      allowNone: false,
    }),
  ],

  startPosition: [
    validator('number', {
      integer: true,
      gt: 0,
      allowString: true,
    }),
  ],

  lightGuideWatchVariable: [
    validator('presence', {
      presence: true,
      get disabled() {
        return this.model.taskType !== 'lightGuide';
      },
    }),
  ],

  lightGuideWatchVariableExpected: [
    validator('presence', {
      presence: true,
      get disabled() {
        return this.model.taskType !== 'lightGuide';
      },
    }),
  ],

})
class TaskConfig extends Model {
  @attr('string', { defaultValue: 'taskConfig' }) type;
  @attr('string') path;
  @attr('string') programName;
  @attr('number', { defaultValue: 1 }) programNumber;
  @attr('boolean', { defaultValue: true }) enabled;

  // barcode reader
  @hasMany('barcodeString', { inverse: 'taskConfig', async: false, embedded: true }) strings;

  // picksensor
  @attr('boolean', { defaultValue: true }) pickLightEnabled;
  @attr('boolean', { defaultValue: true }) pickSensorEnabled;

  // serialNumberTransfer
  @attr('number', { defaultValue: 1 }) startPosition;
  @attr('string', { defaultValue: '' }) expectedString;

  // torque
  @attr('boolean') finalTorqueLimitsEnabled;
  @attr('number') finalTorqueUpperLimit;
  @attr('number') finalTorqueLowerLimit;
  @attr('boolean') finalAngleLimitsEnabled;
  @attr('number') finalAngleUpperLimit;
  @attr('number') finalAngleLowerLimit;
  @attr('number', { defaultValue: 1 }) boltCount;

  // light guide
  @attr('string') lightGuideWatchVariable;
  @attr('string') lightGuideWatchVariableExpected;

  @attr('number', { defaultValue: 1 }) interlockedTime;

  // pushToSchedule
  @hasMany('pushToScheduleConfig', { async: false, inverse: 'taskConfig', embedded: true }) pushToScheduleConfigs;

  // component configs
  @attr('boolean', { defaultValue: false }) ignored;

  @hasMany('jemProcessDataDefConfig', { async: false, inverse: 'taskConfig', embedded: true }) jemProcessDataDefConfigs;
  @hasMany('variableDefConfig', { async: false, inverse: 'taskConfig', embedded: true }) variableDefConfigs;
  @hasMany('hardwareInputDefConfig', { async: false, inverse: 'taskConfig', embedded: true }) hardwareInputDefConfigs;
  @hasMany('triggerConfig', { async: false, inverse: 'taskConfig', polymorphic: true, embedded: true }) triggerConfigs;
  @hasMany('decisionDefConfig', { async: false, inverse: 'taskConfig', embedded: true }) decisionDefConfigs;
  @hasMany('spindleConfig', { async: false, inverse: 'taskConfig', embedded: true }) spindleConfigs;

  @belongsTo('model', { async: false, inverse: 'taskConfigs' }) configModel;
  @belongsTo('componentOption', { async: false, polymorphic: true, inverse: 'taskConfigs' }) configOption;
  @belongsTo('task', { inverse: 'taskConfigs', async: false }) parent;

  @cached
  get stringsInvalid() {
    return anyInvalid(this.strings);
  }

  @cached
  get jemProcessDataDefConfigsInvalid() {
    return anyInvalid(this.jemProcessDataDefConfigs);
  }

  @cached
  get triggerConfigsInvalid() {
    return anyInvalid(this.triggerConfigs);
  }

  @cached
  get pushToScheduleConfigsInvalid() {
    return anyInvalid(this.pushToScheduleConfigs);
  }

  @cached
  get isSelfOrChildInvalid() {
    return this.isInvalid ||
      this.stringsInvalid ||
      this.jemProcessDataDefConfigsInvalid ||
      this.triggerConfigsInvalid ||
      this.pushToScheduleConfigsInvalid;
  }

  get isSelfOrChildDirty() {
    return this.isDirty;
  }

  // eslint-disable-next-line complexity
  get isDirty() {
    return super.isDirty ||
      isHasManyRefDirty(this, 'strings') ||
      isHasManyRefDirty(this, 'jemProcessDataDefConfigs') ||
      isHasManyRefDirty(this, 'variableDefConfigs') ||
      isHasManyRefDirty(this, 'hardwareInputDefConfigs') ||
      isHasManyRefDirty(this, 'triggerConfigs') ||
      isHasManyRefDirty(this, 'decisionDefConfigs') ||
      isHasManyRefDirty(this, 'spindleConfigs') ||
      isHasManyRefDirty(this, 'pushToScheduleConfigs') ||
      this.strings.some(item => item.isDirty) ||
      this.jemProcessDataDefConfigs.some(item => item.isDirty) ||
      this.variableDefConfigs.some(item => item.isDirty) ||
      this.hardwareInputDefConfigs.some(item => item.isDirty) ||
      this.triggerConfigs.some(item => item.isDirty) ||
      this.decisionDefConfigs.some(item => item.isDirty) ||
      this.spindleConfigs.some(item => item.isDirty) ||
      this.pushToScheduleConfigs.some(item => item.isDirty);
  }

  get task() {
    return this.parent;
  }

  set task(val) {
    this.parent = val;
  }

  get station() {
    return this.parent?.station;
  }

  set station(val) {
    this.parent.station = val;
  }

  get area() {
    return this.parent?.area;
  }

  get model() {
    return this.configModel;
  }

  set model(val) {
    this.configModel = val;
  }

  get disabled() {
    return !this.enabled;
  }

  get taskType() {
    return this.task?.taskType;
  }

  get isTimer() {
    return this.task?.isTimer || this.pickUsesTime;
  }

  get usesComponents() {
    return this.station?.usesComponents ?? false;
  }

  get isIgnored() {
    return this.usesComponents && this.ignored;
  }

  // ValidationErrorNoticeCreator
  get errorIdentifier() {
    return this.task?.name;
  }

  get validatedRelationships() {
    return [
      'strings',
      'jemProcessDataDefConfigs',
      'triggerConfigs',
      'pushToScheduleConfigs',
    ];
  }

  @cached
  get configName() {
    return this.configModel?.displayName ?? this.configOption?.name;
  }

  get config() {
    if (this.usesComponents) {
      return this.configOption;
    } else {
      return this.configModel;
    }
  }

  get pickTimeDisabled() {
    return this.pickSensorEnabled || !this.pickLightEnabled;
  }

  get pickUsesTime() {
    return this.task?.isPick && !this.pickTimeDisabled;
  }

  get copyableOptions() {
    return {
      ignoreAttributes: new Set([
        'parent',
        'jemProcessDataDefConfigs',
        'variableDefConfigs',
        'decisionDefConfigs',
        'hardwareInputDefConfigs',
        'triggerConfigs',
        'spindleConfigs',
        'strings',
      ]),

      copyByReference: new Set(['configModel', 'configOption']),
    };
  }

  rollbackAttributes() {
    rollbackHasMany(this, 'strings');
    rollbackHasMany(this, 'jemProcessDataDefConfigs');
    rollbackHasMany(this, 'variableDefConfigs');
    rollbackHasMany(this, 'hardwareInputDefConfigs');
    rollbackHasMany(this, 'triggerConfigs');
    rollbackHasMany(this, 'decisionDefConfigs');
    rollbackHasMany(this, 'spindleConfigs');
    rollbackHasMany(this, 'pushToScheduleConfigs');
    super.rollbackAttributes();
  }
}

export default TaskConfig;
