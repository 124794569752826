import { inject as service } from '@ember/service';
import AuthenticatedRoute from 'eflex/util/authenticated-route';

export default class EvisionCameraStatusRoute extends AuthenticatedRoute {
  @service store;

  model() {
    return this.store.findAll('cameraStatus');
  }

  setupController(controller, cameraStatuses) {
    controller.cameraStatuses = cameraStatuses;
  }
}
