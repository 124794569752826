import { validator } from '@eflexsystems/ember-tracked-validations';
import WieSettings from 'eflex/constants/work-instructions/wie-settings';

export default {
  username: validator('presence', {
    presence: true,
    ignoreBlank: true,
  }),

  state: [validator('inclusion', { in: WieSettings.approvalStates })],
};
