// values come from https://github.com/zxing-js/library/blob/master/src/core/BarcodeFormat.ts
export default [
  { name: 'symbology.any', value: null },
  { name: 'symbology.aztec', value: 0 },
  { name: 'symbology.codabar', value: 1 },
  { name: 'symbology.code39', value: 2 },
  { name: 'symbology.code93', value: 3 },
  { name: 'symbology.code128', value: 4 },
  { name: 'symbology.dataMatrix', value: 5 },
  { name: 'symbology.ean8', value: 6 },
  { name: 'symbology.ean13', value: 7 },
  { name: 'symbology.itf', value: 8 },
  { name: 'symbology.pdf417', value: 10 },
  { name: 'symbology.qrCode', value: 11 },
  { name: 'symbology.rss14', value: 12 },
  { name: 'symbology.upca', value: 14 },
  { name: 'symbology.upce', value: 15 },
];
