import { maxOf } from '@eflexsystems/ramda-helpers';
import { isEmpty } from '@ember/utils';
import { prop } from 'ramda';

export default function getNextTreeOrder(siblings) {
  if (isEmpty(siblings)) {
    return 0;
  }

  return maxOf(prop('order'), siblings) + 1;
}
