import { validator } from '@eflexsystems/ember-tracked-validations';
import EdhrMethodTypes from 'eflex/constants/tasks/edhr-method-types';

export default {
  processDataType: [
    validator('presence', {
      presence: true,
      get disabled() {
        return this.model.task != null && this.model.task.edhrMethod !== EdhrMethodTypes.sendData;
      },
    }),
  ],

  fieldName: [
    validator('presence', {
      presence: true,
      get disabled() {
        if (this.model.isSendImage) {
          return true;
        }

        return this.model.task != null && this.model.task.edhrMethod !== EdhrMethodTypes.sendData;
      },
    }),
  ],
};
