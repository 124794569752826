import Service, { inject as service } from '@ember/service';
import { task, all } from 'ember-concurrency';
import config from 'eflex/config/environment';
import WieSettings from 'eflex/constants/work-instructions/wie-settings';
import { waitFor } from '@ember/test-waiters';

export default class WorkInstructionImageRepoService extends Service {
  @service eflexAjax;
  @service fileUploader;
  @service notifier;

  @task
  @waitFor
  *saveWieImage(dataUrl, options) {
    const { dataURLToBlob } = yield import('blob-util');
    const displayImageBlob = dataURLToBlob(dataUrl);
    return yield this.fileUploader.post.perform('workInstructionImages', displayImageBlob, options);
  }

  @task
  @waitFor
  *duplicateWieImage(id) {
    return yield this.eflexAjax.post.perform('workInstructionImages', { id });
  }

  @task
  @waitFor
  *deleteWorkInstructionPreviews(workInstruction) {
    yield all([
      this.delete.perform(workInstruction.displayImage),
      this.delete.perform(workInstruction.displayImageThumb),
    ]);

    Object.assign(workInstruction, {
      displayImage: null,
      displayImageThumb: null,
    });
  }

  @task
  @waitFor
  *deleteWorkInstructionDeployedPreviews(workInstruction) {
    yield all([
      this.delete.perform(workInstruction.deployedImage),
      this.delete.perform(workInstruction.deployedImageThumb),
    ]);

    Object.assign(workInstruction, {
      deployedImage: null,
      deployedImageThumb: null,
    });
  }

  @task
  @waitFor
  *delete(id) {
    if (id == null) {
      return;
    }

    yield this.eflexAjax.delete.perform(`workInstructionImages/${id}`);
  }

  fileAllowed(file) {
    if (file == null || !WieSettings.supportedFileTypes.includes(file.type)) {
      this.notifier.sendError('plant.station.jem.wrongImageFormat');
      return false;
    }

    if (file.size > WieSettings.maxFileSize) {
      this.notifier.sendError('plant.station.jem.fileTooLarge');
      return false;
    }

    return true;
  }

  getWieImageUrl(id) {
    if (id == null) {
      return null;
    }

    return `${this._getBaseUrl()}/${id}`;
  }

  createVersionedImageUrl(id) {
    if (id == null) {
      return null;
    }

    const version = Date.now();

    return `${this._getBaseUrl()}/${id}?version=${version}`;
  }

  _getBaseUrl() {
    return `${config.servicePrefix}/workInstructionImages`;
  }
}
