import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { task as trackedTask } from 'ember-resources/util/ember-concurrency';
import { tracked } from '@glimmer/tracking';
import TaskAndFastenerMatcher from 'eflex/util/bi-task-fastener-matcher';
import { action } from '@ember/object';
import EchartsTheme from 'eflex/echarts-theme';
import BiFormatTaskAndBoltAxis from 'eflex/util/bi-task-anb-bolt-axis-formatter';
import { waitFor } from '@ember/test-waiters';
import { clone } from 'ramda';

export default class AverageTorque extends Component {
  // jscpd:ignore-start
  @service router;
  @service queryRunner;
  @service intl;

  @tracked boltStatus = this.intl.t('bi.chartLabel.allBolts');
  @tracked boltStatuses = [
    this.intl.t('bi.chartLabel.passedBolts'),
    this.intl.t('bi.chartLabel.failedBolts'),
    this.intl.t('bi.chartLabel.allBolts'),
  ];

  get params() {
    return {
      stationIds: this.args.stationIds,
      fasteners: this.args.fasteners,
      modelIds: this.args.modelIds,
      userIds: this.args.userIds,
      tags: this.args.tags,
      startDate: this.args.startDate,
      endDate: this.args.endDate,
      shiftNames: this.args.shiftNames,
    };
  }

  torqueData = trackedTask(this, this.getTorqueData, () => [this.params, this.boltStatus]);

  @task({ drop: true })
  @waitFor
  *getTorqueData(params) {
    const torqueDataChartQuery = yield this.queryRunner.queryWithParams.perform(params, [
      {
        $limit: 100_000,
      },
      {
        $unwind: {
          path: '$children',
        },
      },
      {
        $match: {
          'children.boltProcessData.0': {
            $exists: true,
          },
        },
      },
      {
        $unwind: {
          path: '$children',
        },
      },
      {
        $unwind: {
          path: '$children.boltProcessData',
        },
      },
      this.getBoltStatusMatch(this.boltStatus),
      {
        $group: {
          _id: {
            taskId: '$children.location._id',
            taskName: {
              $first: '$children.location.captions.text',
            },
            boltNumber: '$children.boltProcessData.boltNumber',
          },
          avgTorque: {
            $avg: '$children.boltProcessData.torque',
          },
          avgAngle: {
            $avg: '$children.boltProcessData.angle',
          },
        },
      },
      {
        $project: {
          _id: 0,
          taskId: '$_id.taskId',
          taskAndBolt: {
            $concat: [
              '$_id.taskName',
              ' ',
              this.intl.t('fastener'),
              ' ',
              {
                $toString: '$_id.boltNumber',
              },
            ],
          },
          idAndFastener: {
            $concat: [
              {
                $toString: '$_id.taskId',
              },
              {
                $toString: '$_id.boltNumber',
              },
            ],
          },
          avgTorque: 1,
          avgAngle: 1,
        },
      },
      TaskAndFastenerMatcher(params.fasteners),
      {
        $sort: {
          taskAndBolt: -1,
        },
      },
    ]);

    const torqueData = yield this.queryRunner.runQuery.perform('BuildStatuses', torqueDataChartQuery);

    return {
      chartData: this.getEchartOptions(torqueData),
      exportData: () => clone(torqueData).map(({ idAndFastener, ...relevantData }) => relevantData),
    };

  }

  getEchartOptions(torqueData) {
    return {
      title: {
        text: this.intl.t('bi.chartLabel.boltAngleAvg'),
      },

      tooltip: {
        trigger: 'axis',
      },

      legend: {
        show: true,
        bottom: 5,
      },

      yAxis: BiFormatTaskAndBoltAxis(torqueData),

      xAxis: [
        {
          type: 'value',
          data: torqueData.map(item => item.avgTorque),
          name: this.intl.t('averageTorque'),
          nameTextStyle: {
            padding: 10,
          },
          nameLocation: 'center',
        },
        {
          type: 'value',
          data: torqueData.map(item => item.avgAnlge),
          name: this.intl.t('averageAngle'),
          nameTextStyle: {
            padding: 10,
          },
          nameLocation: 'center',
          axisLabel: {
            formatter: '{value}°',
          },
        },
      ],

      series: [
        {
          name: this.intl.t('averageTorque'),
          type: 'bar',
          xAxisIndex: 0,
          data: torqueData.map((m) => {
            return m.avgTorque?.toFixed(2).replace(/\.00/, '') ?? 0;
          }),
          color: EchartsTheme.colorPalette.warning,
        },
        {
          name: this.intl.t('averageAngle'),
          type: 'line',
          xAxisIndex: 1,
          data: torqueData.map((m) => {
            return m.avgAngle?.toFixed(2).replace(/\.00/, '') ?? 0;
          }),
          color: EchartsTheme.colorPalette.success,
        },
      ],
      taskIds: torqueData.map(item => item.taskId),
      names: torqueData.map(item => item.taskAndBolt),
      idAndFasteners: torqueData.map(item => item.idAndFastener),
    };
  }
  // jscpd:ignore-end

  getBoltStatusMatch(boltStatus) {
    if (boltStatus === this.intl.t('bi.chartLabel.allBolts')) {
      return {
        $match: {},
      };
    } else if (boltStatus === this.intl.t('bi.chartLabel.passedBolts')) {
      return {
        $match: {
          'children.boltProcessData.torqueStatus': 1,
          'children.boltProcessData.angleStatus': 1,
          'children.boltProcessData.errorCode': null,
        },
      };
    } else {
      return {
        $match: {
          $expr: {
            $or: [
              { $ne: ['$children.boltProcessData.torqueStatus', 1] },
              { $ne: ['$children.boltProcessData.angleStatus', 1] },
              { $ne: ['$children.boltProcessData.errorCode', null] },
            ],
          },
        },
      };
    }
  }

  @action
  fastenerDrillDown(individualTorqueData, dataIndex) {
    this.router.transitionTo('bi.torqueData.singleBoltsChart', {
      queryParams: {
        stationIds: this.args.stationIds,
        fasteners: [{
          _id: individualTorqueData.taskIds[dataIndex],
          name: individualTorqueData.names[dataIndex],
          idAndFastener: individualTorqueData.idAndFasteners[dataIndex],
        }],
        modelIds: this.args.modelIds,
        userIds: this.args.userIds,
        tags: this.args.tags,
        beginDate: this.args.startDate,
        endDate: this.args.endDate,
        shiftNames: this.args.shiftNames,
      },
    });
  }
}
