const oeeReports = {
  oeeStatesHistory: {
    label: 'bi.chartLabel.oeeStatesHistoryPieChart',
    route: 'bi.oee',
  },

  faultCodeSummary: {
    label: 'bi.chartLabel.faultCodeSumary',
    route: 'bi.oee.faults',
  },
};

const qualityReports = {
  qualitySummary: {
    label: 'bi.chartLegend.qualitySummary',
    route: 'bi.quality',
  },

  rejects: {
    label: 'bi.chartLabel.rejectCodeOccurrencesStation',
    route: 'bi.quality.rejects',
  },

  stationQualityPchart: {
    label: 'bi.chartLegend.stationQualityPChart',
    route: 'bi.quality.stations',
  },
};

const andonReports = {
  partCounts: {
    label: 'bi.chartLabel.partCounts',
    route: 'bi.andon',
  },

  stationSummary: {
    label: 'bi.chartLabel.andonStationSummary',
    route: 'bi.andon.stations',
  },
};

const cycleTimeReports = {
  cycleTimeSummary: {
    label: 'bi.chartLabel.cycleTimeSummary',
    route: 'bi.cycleTime',
  },
  cycleTimeStations: {
    label: 'bi.chartLabel.cycleTimeStations',
    route: 'bi.cycleTime.stations',
  },
  cycleTimeModels: {
    label: 'bi.chartLabel.cycleTimeModels',
    route: 'bi.cycleTime.models',
  },
  cycleTimeBySerial: {
    label: 'bi.chartLabel.cycleTimeBySerial',
    route: 'bi.cycleTime.serialNumbers',
  },
};

const processDataReports = {
  processDataAverage: {
    label: 'bi.chartLabel.processDataAvgBarChart',
    route: 'bi.processData',
  },
  processDatum: {
    label: 'bi.chartLabel.processDatumControlChart',
    route: 'bi.processData.processDatum',
  },
};

const torqueDataReports = {
  torqueSummary: {
    label: 'bi.chartLabel.torqueSummary',
    route: 'bi.torqueData',
  },
  boltData: {
    label: 'bi.chartLabel.boltData',
    route: 'bi.torqueData.bolts',
  },
  individualBoltDataChart: {
    label: 'bi.chartLabel.individualAngleTorque',
    route: 'bi.torqueData.singleBoltsChart',
  },
};

export {
  oeeReports,
  qualityReports,
  andonReports,
  cycleTimeReports,
  processDataReports,
  torqueDataReports,
};
