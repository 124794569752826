import Model, { attr, belongsTo } from '@ember-data/model';

export default class SystemConfiguration extends Model {
  @attr('string') systemLicense;
  @attr('string') timezone;
  @attr('string') biUrl;
  @attr('boolean', { default: true }) enableWieApprovals;

  @belongsTo('evisionConfig', { inverse: null, async: false, embedded: true }) evision;
  @belongsTo('jemSystemConfig', { inverse: null, async: false, embedded: true }) jem;
  @belongsTo('wieConfig/wie', { inverse: null, async: false, embedded: true }) wie;
  @belongsTo('singleSignOnConfig', { inverse: null, async: false, embedded: true }) singleSignOnConfig;
  @belongsTo('kineticConfig', { inverse: null, async: false, embedded: true }) kinetic;

  get isDirty() {
    return super.isDirty ||
      this.evision.isDirty ||
      this.jem.isDirty ||
      this.wie.isDirty ||
      this.singleSignOnConfig.isDirty;
  }
}
