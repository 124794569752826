var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
import Controller from '@ember/controller';
/* eslint-disable @typescript-eslint/no-unused-vars */
import { inject as service } from '@ember/service';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { alias } from '@ember/object/computed';
/* eslint-enable @typescript-eslint/no-unused-vars */
let FreestyleController = (_dec = service('ember-freestyle'), _dec2 = alias('emberFreestyle.showLabels'), _dec3 = alias('emberFreestyle.showNotes'), _dec4 = alias('emberFreestyle.showCode'), _dec5 = alias('emberFreestyle.showApi'), _dec6 = alias('emberFreestyle.showMenu'), _dec7 = alias('emberFreestyle.focus'), _dec8 = alias('emberFreestyle.section'), _dec9 = alias('emberFreestyle.subsection'), (_class = class FreestyleController extends Controller {
  constructor(...args) {
    super(...args);
    _initializerDefineProperty(this, "emberFreestyle", _descriptor, this);
    this.queryParams = ['l', 'n', 'c', 'a', 'm', 'f', 's', 'ss'];
    _initializerDefineProperty(this, "l", _descriptor2, this);
    _initializerDefineProperty(this, "n", _descriptor3, this);
    _initializerDefineProperty(this, "c", _descriptor4, this);
    _initializerDefineProperty(this, "a", _descriptor5, this);
    _initializerDefineProperty(this, "m", _descriptor6, this);
    _initializerDefineProperty(this, "f", _descriptor7, this);
    _initializerDefineProperty(this, "s", _descriptor8, this);
    _initializerDefineProperty(this, "ss", _descriptor9, this);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "emberFreestyle", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "l", [_dec2], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "n", [_dec3], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "c", [_dec4], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "a", [_dec5], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "m", [_dec6], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "f", [_dec7], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "s", [_dec8], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "ss", [_dec9], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class));
export { FreestyleController as default };