/* eslint-disable complexity */
import Service, { inject as service } from '@ember/service';
import { EJSON, ObjectId } from 'bson';
import { isBlank } from '@ember/utils';
import { task } from 'ember-concurrency';
import { includes } from 'ramda';
import { waitFor } from '@ember/test-waiters';

export default class QueryRunnerService extends Service {
  @service eflexAjax;
  @service store;

  @task
  @waitFor
  *runQuery(collection, query) {
    return yield this.eflexAjax.post.perform('queries', {
      collection,
      query: EJSON.stringify(query),
    });
  }

  @task
  @waitFor
  *queryWithParams(params = {}, query = [], isOee = false) {
    const matches = [];
    if (!isBlank(params.startDate) && !isOee) {
      matches.push({
        $match: {
          timestamp: { $gte: new Date(params.startDate) },
        },
      });
    }

    if (!isBlank(params.endDate) && !isOee) {
      matches.push({
        $match: {
          timestamp: { $lte: new Date(params.endDate) },
        },
      });
    }

    if (!isBlank(params.shiftNames)) {
      const scheduleHistories = yield this.runQuery.perform(
        'ScheduleHistories',
        this._queryScheduleHistories(params),
      );

      if (!isBlank(scheduleHistories)) {
        if (isOee) {
          matches.push({
            $match: {
              $or: scheduleHistories.map((shift) => {
                return {
                  $and: [
                    { startDate: { $gte: new Date(shift.startDate) } },
                    { endDate: { $lte: new Date(shift.endDate) } },
                    { 'station._id': new ObjectId(shift.station) },
                  ],
                };
              }),
            },
          });
        } else {
          matches.push({
            $match: {
              $or: scheduleHistories.map((shift) => {
                return {
                  $and: [
                    { timestamp: { $gte: new Date(shift.startDate) } },
                    { timestamp: { $lte: new Date(shift.endDate) } },
                    { 'location._id': new ObjectId(shift.station) },
                  ],
                };
              }),
            },
          });
        }
      }
    }

    if (!isBlank(params.modelIds)) {
      matches.push({
        $match: {
          'model._id': {
            $in: params.modelIds.map((modelId) => new ObjectId(modelId)),
          },
        },
      });
    }

    if (!isBlank(params.stationIds) && !isOee) {
      matches.push({
        $match: {
          'location._id': {
            $in: params.stationIds.map((stationId) => new ObjectId(stationId)),
          },
        },
      });
    }

    if (!isBlank(params.stationId)) {
      matches.push({
        $match: {
          'location._id': new ObjectId(params.stationId),
        },
      });
    }

    if (!isBlank(params.taskId)) {
      matches.push({
        $match: {
          'children.location._id': new ObjectId(params.taskId),
        },
      });
    }

    if (!isBlank(params.tags)) {
      matches.push({
        $match: {
          'children.location.tags': {
            $in: params.tags,
          },
        },
      });
    }

    if (!isBlank(params.userIds)) {
      matches.push({
        $match: {
          userId: {
            $in: params.userIds.map((userId) => new ObjectId(userId)),
          },
        },
      });
    }

    return isOee
      ? [...this._oeeQueryWithParams(params), ...matches, ...query]
      : [...matches, ...query];
  }

  _queryScheduleHistories(params = {}) {
    const shiftIds = [];
    const shiftNames = [];

    this.store.peekAll('schedule').forEach((shift) => {
      if (includes(shift?.text, params?.shiftNames)) {
        shiftIds.push(new ObjectId(shift._id));
        shiftNames.push(shift.text);
      }
    });

    return [
      {
        $match: {
          startDate: { $gte: new Date(params.startDate) },
          endDate: { $lte: new Date(params.endDate) },
          $or: [{ text: { $in: shiftNames } }, { schedule: { $in: shiftIds } }],
        },
      },
    ];
  }

  _oeeQueryWithParams(params = {}) {
    const matches = [];

    if (!isBlank(params.startDate)) {
      matches.push({
        $match: {
          startDate: { $gte: new Date(params.startDate) },
        },
      });
    }

    if (!isBlank(params.endDate)) {
      matches.push({
        $match: {
          endDate: { $lte: new Date(params.endDate) },
        },
      });
    }

    if (!isBlank(params.stationIds)) {
      matches.push({
        $match: {
          'station._id': {
            $in: params.stationIds.map((stationId) => new ObjectId(stationId)),
          },
        },
      });
    }

    if (!isBlank(params.states)) {
      const states = params.states.map((state) => {
        return state.toLowerCase();
      });
      matches.push({
        $match: {
          state: { $in: states },
        },
      });
    }
    return matches;
  }
}
