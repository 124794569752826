import Service, { inject as service } from '@ember/service';
import { all, task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import { NotificationLogTypes } from 'eflex/constants/notification-log';

export default class LogRepoService extends Service {
  @service eflexAjax;
  @service store;
  @service currentUser;

  logs = this.store.peekAll('log');

  @task
  @waitFor
  *acknowledgeLogs(logs) {
    logs.forEach(log => { log.acknowledge(this.currentUser.user); });
    yield all(logs.map(log => log.save()));
  }

  @task
  @waitFor
  *acknowledgeAll(logType) {
    const params = { acknowledgedBy: this.currentUser.user.toFragment() };

    if (logType != null) {
      params.logType = logType;
    }

    yield this.eflexAjax.post.linked().perform('logs/acknowledgeAll', params);
  }

  @task
  @waitFor
  *deployBySource(source) {
    yield this.eflexAjax.post.linked().perform('logs/deploy', { source });
  }

  @task
  @waitFor
  *fetchLogsForJem(params = {}) {
    return yield this.store.query('log', {
      locationId: params?.stationId,
      logTypes: [NotificationLogTypes.JEM, NotificationLogTypes.WIE],
      afterDate: this.currentUser.user.createdAt,
      notAcknowledgedByUser: this.currentUser.user.id,
      sortField: 'timestamp',
      skip: params.skip ?? 0,
      take: params.take ?? 30,
    });
  }
}
