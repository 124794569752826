import Base from 'ember-simple-auth-token/authenticators/jwt';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import moment from 'moment-timezone';
import { task, timeout } from 'ember-concurrency';
import getDelayTime from 'eflex/util/get-delay-time';
import { getOwner } from '@ember/application';
import { waitFor } from '@ember/test-waiters';

const ONE_MINUTE = 60_000;

export default class JwtAuthenticator extends Base {
  @service session;
  @service currentUser;

  refreshAccessToken() {
    if (window.isTesting) {
      return Promise.resolve();
    }

    return super.refreshAccessToken(...arguments);
  }

  async restore(data = {}) {
    const token = data[this.tokenPropertyName];

    if (isEmpty(token)) {
      throw new Error('empty token');
    }

    const wait = this._getWaitTime(token);

    if (wait > 0) {
      this.scheduleAccessTokenRefresh(data[this.tokenExpireName], token);
      return data;
    } else {
      await this.session.invalidate();
      throw new Error('token is expired');
    }
  }

  scheduleAccessTokenRefresh(expiresAt, token) {
    if (isEmpty(token) || isEmpty(expiresAt)) {
      return;
    }

    this._handleAutoLogout.perform(token);
  }

  @task({ restartable: true })
  @waitFor
  *_handleAutoLogout(token) {
    const wait = this._getWaitTime(token);

    if (wait <= 0) {
      return;
    }

    const applicationController = getOwner(this).lookup('controller:application');
    applicationController.onCloseLogoutWarningModal();

    yield timeout(getDelayTime(wait));

    const showWarning = this.currentUser.user?.timeoutWarning;

    if (showWarning) {
      applicationController.onShowLogoutWarningModal(token);
    }

    if (window.isTesting) {
      return;
    }

    const timeToLogout = showWarning ? ONE_MINUTE : (this.refreshLeeway * 1000);
    yield timeout(timeToLogout);
    this.session.invalidate(token);
  }

  _getWaitTime(token) {
    const tokenData = this.getTokenData(token);
    const expireTime = moment(tokenData.clientExpiration);
    const timeDiff = expireTime?.diff(moment(), 's');

    return (timeDiff - this.refreshLeeway) * 1000;
  }
}
