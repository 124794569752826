import Service, { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';

export default class LabelPrinterService extends Service {
  @service notifier;
  @service eflexAjax;

  @task({ drop: true })
  @waitFor
  *printLabel(printJob) {
    try {
      yield this.eflexAjax.post.perform('labelPrintJobs', printJob);
    } catch (error) {
      this.notifier.sendError(error);
    }
  }
}
