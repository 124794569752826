import { task, waitForQueue } from 'ember-concurrency';
import { taskTypes } from 'eflex/constants/tasks/task-types';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import { TrackedArray } from 'tracked-built-ins';
import { removeObject, removeObjects } from 'eflex/util/array-helpers';

export default class SerialNumberMonitorBomSourceController extends Controller {
  @service buildDataRepo;
  @service validationErrorNotifier;
  @service labelPrinter;
  @service currentUser;

  @tracked params = {};
  @tracked searchTerm;
  @tracked buildData = new TrackedArray();
  @tracked selectedBomSource;
  @tracked selectedSerialNumber;
  @tracked panelBuildDatum;
  @tracked datumToCopy;
  @tracked selectAll = false;
  @tracked showBomCopy = false;
  @tracked showBomBuilder = false;
  @tracked showPrintLabelModal = false;
  @tracked statusCategory = 'queued';
  @tracked currentSerialNumberOptionsTab = 'comp-opt-data';

  get printTasks() {
    return this.selectedBomSource.areas
      .flatMap(area => area.tasks)
      .filter(item => item.taskType === taskTypes.print);
  }

  get isDirty() {
    return this.buildData.some(item => item.isDirty);
  }

  get isEditDisabled() {
    return this.currentUser.user.isNotAdmin || !this.selectedSerialNumber;
  }

  get uniqComponents() {
    const selectedSerialNumber = this.selectedSerialNumber;
    const components = selectedSerialNumber.bomSource.components.filter(item => !item.isDeleted);

    return selectedSerialNumber.components
      ?.filter(item => !item.isAlwaysRun)
      .filter(component =>
        components.some(item => item.id === component.id),
      );
  }

  @action
  deleteSerial() {
    if (this.selectedSerialNumber?.isNew) {
      removeObject(this.buildData, this.selectedSerialNumber);
      this.selectedSerialNumber.rollbackAttributes();
    } else {
      this.selectedSerialNumber.deleteRecord();
    }

    this.selectedSerialNumber = null;
    this.closeBomBuilder();
  }

  @task
  @waitFor
  *save() {
    const toSave = this.buildData.filter(item => item.isDirty);

    if (toSave.some(item => item.isInvalid)) {
      this.validationErrorNotifier.sendErrors(toSave);
      return;
    }

    removeObjects(this.buildData, toSave.filter(saved => saved.isDeleted));

    yield this.buildDataRepo.save.perform(toSave);
  }

  @task
  @waitFor
  *addNewSerial() {
    const newDatum = this.buildDataRepo.create(this.selectedBomSource);

    this.buildData.push(newDatum);

    Object.assign(this, {
      selectedSerialNumber: newDatum,
      panelBuildDatum: newDatum,
      showBomBuilder: true,
    });

    const grid = document.querySelector('.serial-number-status-grid .ember-grid-component');

    if (grid == null) {
      return;
    }

    yield waitForQueue('afterRender');

    grid.scrollTo({
      top: grid.scrollHeight,
      behavior: 'smooth',
    });
  }

  @task({ drop: true })
  @waitFor
  *printLabel(printJob) {
    this.showPrintLabelModal = false;
    yield this.labelPrinter.printLabel.perform(printJob);
  }

  @action
  editSerial() {
    Object.assign(this, {
      panelBuildDatum: this.selectedSerialNumber,
      showBomBuilder: true,
    });
  }

  @action
  copySerial() {
    Object.assign(this, {
      datumToCopy: this.selectedSerialNumber,
      showBomBuilder: false,
      showBomCopy: true,
    });
  }

  @action
  closeBomBuilder() {
    Object.assign(this, {
      panelBuildDatum: null,
      showBomBuilder: false,
    });
  }

  @action
  closeBomCopy() {
    Object.assign(this, {
      datumToCopy: null,
      showBomCopy: false,
    });
  }

  @action
  updateBuildData(newBuildData) {
    this.buildData.push(...newBuildData);
  }

  @action
  rollback() {
    if (this.panelBuildDatum?.isNew) {
      this.closeBomBuilder();
    }

    this.buildData.filter(item => item.isDirty).forEach(buildData => { buildData.rollbackAttributes(); });
    this.buildData = new TrackedArray(this.buildData.filter(item => !item.isDeleted));

    if (this.selectedSerialNumber?.isDeleted && !this.buildData[0]?.isDeleted) {
      this.selectedSerialNumber = this.buildData[0];
    } else {
      this.selectedSerialNumber = null;
    }
  }

  @action
  swapSelected(selectedDatum) {
    this.selectedSerialNumber = selectedDatum;
    if (this.showBomBuilder && selectedDatum.userCreated) {
      this.panelBuildDatum = selectedDatum;
    } else if (this.showBomBuilder) {
      this.closeBomBuilder();
    } else if (this.showBomCopy) {
      this.closeBomCopy();
    }
  }
}
