import ApplicationSerializer from 'eflex/serializers/application';
import toFastProperties from 'to-fast-properties';
import { isEmpty } from '@ember/utils';

export default class UserSerializer extends ApplicationSerializer {
  normalize(type, hash) {
    if (hash.password) {
      delete hash.password;
      toFastProperties(hash);
    }

    hash.hasBadgeId = !isEmpty(hash.badgeId);
    hash.badgeId = null;

    return super.normalize(...arguments);
  }
}
