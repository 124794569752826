import { guidFor } from '@ember/object/internals';
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class RadioButton extends Component {
  uid = `radio-${guidFor(this)}`;

  @action
  onClick(value) {
    if (value === 'true' || value === 'false') {
      value = value === 'true';
    }

    this.args.didSelect?.(value);
  }
}
