import ApplicationSerializer from 'eflex/serializers/application';

export default class BoltProcessDatumSerializer extends ApplicationSerializer {
  attrs = {
    buildStatusChild: {
      serialize: false,
      deserialize: false,
    },
  };
}
