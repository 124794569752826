import Model, { attr, belongsTo } from '@ember-data/model';
import { copyable } from 'eflex/decorators';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { rangeCorrectness } from 'eflex/util/validators';

@copyable
@buildValidations({
  lowerLimit: [
    validator('number', {
      allowString: true,
      allowBlank: true,
    }),
  ],

  upperLimit: [
    validator('number', {
      allowString: true,
      allowBlank: true,
    }),
    validator('inline', {
      validate(upperLimit, options, jemProcessDataDefConfig) {
        return rangeCorrectness(
          jemProcessDataDefConfig,
          jemProcessDataDefConfig.lowerLimit,
          upperLimit,
          'plant.taskConfig.limitOverlapUpper',
        );
      },
    }),
  ],

  decimalLength: [
    validator('number', {
      allowString: true,
      allowBlank: false,
      integer: true,
      gte: 0,
      lte: 10,
    }),
  ],
})
class JemProcessDataDefConfig extends Model {
  @attr('boolean', { defaultValue: true }) required;
  @attr('number') upperLimit;
  @attr('number') lowerLimit;
  @attr('number', { defaultValue: 2 }) decimalLength;

  @belongsTo('taskConfig', { async: false, inverse: 'jemProcessDataDefConfigs' }) taskConfig;
  @belongsTo('jemProcessDataDef', { async: false, inverse: 'jemProcessDataDefConfigs' }) jemProcessDataDef;

  get errorIdentifier() {
    return this.taskConfig?.errorIdentifier;
  }

  get copyableOptions() {
    return {
      ignoreAttributes: new Set(['jemProcessDataDef', 'taskConfig']),
    };
  }
}

export default JemProcessDataDefConfig;
