import Modifier from 'ember-modifier';
import { task, waitForEvent } from 'ember-concurrency';

export default class OnIframeMessageFocusTaskModifier extends Modifier {
  didSetup = false;

  modify(element) {
    if (this.didSetup) {
      return;
    }

    this.didSetup = true;
    this._messageListener.perform(element);
  }

  @task({ onState: null })
  *_messageListener(element) {
    while (true) {
      let event = yield waitForEvent(window, 'message');

      if (event.data?.focusChildSelector) {
        element.querySelector(event.data?.focusChildSelector)?.focus();
      } else if (event.data?.focusElement) {
        element.focus();
      }
    }
  }
}
