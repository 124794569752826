import { modifier } from 'ember-modifier';

// workaround for Chrome issues with displaying pdf pages
export default modifier(function displayPdf(element, _, { targetUrl }) {
  if (element.children[0]?.data === targetUrl) {
    return;
  }

  element.innerHTML = `<object data=${targetUrl} type="application/pdf"></object>`;
}, { eager: false });
