import Model, { attr, belongsTo } from '@ember-data/model';

export default class JemNote extends Model {
  @attr('string') content;
  @attr('string') type;
  @attr('date', { defaultValue() { return new Date(); } }) timestamp;

  @belongsTo('user', { async: false, inverse: null }) user;
  @belongsTo('station', { async: false, inverse: 'jemNotes' }) station;
}
