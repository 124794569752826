import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class FileInput extends Component {
  @action
  onChange({ target }) {
    this.args.onChange(target.files, this._resetInput.bind(this, target));
  }

  _resetInput(elem) {
    if (elem) {
      elem.value = '';
    }
  }
}
