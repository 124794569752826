import ApplicationSerializer from 'eflex/serializers/application';

export default class DecisionDefSerializer extends ApplicationSerializer {
  attrs = {
    task: {
      serialize: false,
      deserialize: false,
    },
    decisionDefConfigs: {
      serialize: false,
      deserialize: false,
    },
  };
}
