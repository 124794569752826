import Controller, { inject as controller } from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class PartsBuildHistoryIndexController extends Controller {
  @controller('parts/build-history') buildHistoryController;

  @tracked selectedChildStatus;
  @tracked selectedModalChildStatus;

  get selectedBuildStatus() {
    return this.buildHistoryController.selectedBuildStatus;
  }

  @action
  setModalChildStatus(childStatus) {
    if (childStatus && !childStatus.hasCodes && !childStatus.hasAuthorization) {
      return;
    }

    this.selectedModalChildStatus = childStatus;
  }
}
