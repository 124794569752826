import GridRoute from 'eflex/util/grid-route';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import { TrackedArray } from 'tracked-built-ins';

export default class DebugLiveBuildStatusLogsRoute extends GridRoute {
  @service store;

  @task
  @waitFor
  *getData(searchFields = {}) {
    const params = Object.assign(searchFields, this.controller?.params);
    return new TrackedArray(yield this.store.query('liveBuildStatusLog', params));
  }
}
