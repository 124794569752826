import BsFormElement from 'ember-bootstrap/components/bs-form/element';
import { isEmpty } from '@ember/utils';
import DatePicker from 'eflex/components/bs-form/element/control/date-picker';
import EflexCheckbox from 'eflex/components/bs-form/element/control/eflex-checkbox';
import NumberInput from 'eflex/components/bs-form/element/control/number';
import RadioGroup from 'eflex/components/bs-form/element/control/radio-group';
import TextInput from 'eflex/components/bs-form/element/control/text';
import PowerSelectMultiple from 'ember-bootstrap-power-select/components/bs-form/element/control/power-select-multiple';
import PowerSelect from 'ember-bootstrap-power-select/components/bs-form/element/control/power-select';

export default class ValidatedBsFormElement extends BsFormElement {
  '__ember-bootstrap_subclass' = true;

  get hasValidator() {
    return !isEmpty(this._attrValidations);
  }

  get hasErrors() {
    return this._attrValidations?.isInvalid;
  }

  get errors() {
    return this._attrValidations?.messages;
  }

  get warnings() {
    return this._attrValidations?.warningMessages;
  }

  get _attrValidations() {
    return this.args.model?.validations?.attrs[this.args.property];
  }

  get controlComponent() {
    switch (this.controlType) {
      case 'text': {
        return TextInput;
      }
      case 'eflex-checkbox': {
        return EflexCheckbox;
      }
      case 'date-picker': {
        return DatePicker;
      }
      case 'number': {
        return NumberInput;
      }
      case 'power-select': {
        return PowerSelect;
      }
      case 'power-select-multiple': {
        return PowerSelectMultiple;
      }
      case 'radio-group': {
        return RadioGroup;
      }
    }

    return super.controlComponent;
  }
}
