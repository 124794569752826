import Model, { attr } from '@ember-data/model';
import NamingConventionValidations from 'eflex/validations/naming-convention';
import { buildValidations } from '@eflexsystems/ember-tracked-validations';
import { arrayAttr } from 'eflex/decorators';

@buildValidations(NamingConventionValidations)
class NamingConvention extends Model {
  @arrayAttr selectedOptions;
  @arrayAttr defaultOptions;
  @attr('string') fieldSeparator;
}

export default NamingConvention;
