import EmberDataCache from 'eflex/util/ember-data-cache';
import BaseStore, { CacheHandler } from '@ember-data/store';
import { LegacyNetworkHandler } from '@ember-data/legacy-compat';
import RequestManager from '@ember-data/request';
import Fetch from '@ember-data/request/fetch';

export default class Store extends BaseStore {
  constructor(args) {
    super(args);
    this.requestManager = new RequestManager();
    this.requestManager.use([LegacyNetworkHandler, Fetch]);
    this.requestManager.useCache(CacheHandler);
  }

  createCache(storeWrapper) {
    return new EmberDataCache(storeWrapper);
  }
}
