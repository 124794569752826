import Model, { attr } from '@ember-data/model';

export default class SystemLog extends Model {
  @attr('string') path;
  @attr('string') host;
  @attr('string') message;
  @attr('string') type;
  @attr('date') timestamp;
  @attr('number') version;
}
