import Model, { attr, hasMany } from '@ember-data/model';
import { anyInvalid, anyTreeItemDirty } from 'eflex/util/getter-helpers';
import { cached } from '@glimmer/tracking';
import { sortByProps } from 'ramda-adjunct';
import { caption, arrayAttr } from 'eflex/decorators';

class TaskParent extends Model {
  @attr('string') path;

  @arrayAttr captions;

  @caption name;

  @hasMany('task', {
    inverse: 'parent',
    async: false,
    cascadeDelete: true,
    as: 'task-parent',
  }) tasks;

  get polymorphic() {
    return true;
  }

  get childType() {
    return 'task';
  }

  @cached
  get children() {
    return this.tasks.filter(item => !item.isDeleted);
  }

  get allChildren() {
    return this.tasks;
  }

  @cached
  get isSelfOrChildDirty() {
    return this.isDirty || anyTreeItemDirty(this.tasks);
  }

  @cached
  get isSelfOrChildInvalid() {
    return this.isInvalid || this.isChildInvalid;
  }

  @cached
  get isChildInvalid() {
    return anyInvalid(this.children);
  }

  get validatedRelationships() {
    return ['tasks'];
  }

  @cached
  get sortedChildren() {
    return sortByProps(['row', 'column'], this.children);
  }

  rollbackAttributes() {
    this.tasks.forEach((task) => { task.rollbackAttributes(); });
    super.rollbackAttributes();
  }
}

export default TaskParent;
