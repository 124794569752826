import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class TagsSelect extends Component {
  @service intl;

  get fieldName() {
    return this.args.fieldName ?? 'tags';
  }

  get options() {
    let options = this.args.siblings.filter(item => !item.isDeleted).map(item => item[this.fieldName]);

    options.forEach((element, index, array) => {
      if (element?.content) {
        array[index] = element.content;
      }
    });

    options = options.flat();

    if (this.args.additionalOptions) {
      options = options.concat(this.args.additionalOptions);
    }

    return [...new Set(options)].toSorted();
  }

  @action
  createTag(tag) {
    const taggedModel = this.args.taggedModel;
    const fieldName = this.fieldName;
    const tags = taggedModel[fieldName].map(_tag => _tag);
    tags.push(tag);
    taggedModel[fieldName] = tags;
  }

  @action
  showCreate(tag) {
    return !this.options.includes(tag);
  }

  @action
  suggestAddTag(tag) {
    return this.intl.t('plant.task.addNewTag', { tag });
  }

  @action
  updateTags(tags) {
    this.args.taggedModel[this.fieldName] = tags;
  }
}
