import { getCaptionText, getUpdatedCaptions } from 'eflex/util/translation-helper';
import { getOwner } from '@ember/application';

export default function caption(captionsName, opts = {}) {
  let isWrapped = false;
  let formatter;

  if (typeof captionsName === 'string') {
    isWrapped = true;
    formatter = opts.formatter;
  } else if (typeof captionsName?.formatter === 'function') {
    isWrapped = true;
    formatter = captionsName.formatter;
  } else {
    captionsName = 'captions';
  }

  const desc = {
    get() {
      const owner = getOwner(this);
      const currentUser = owner.lookup('service:currentUser');
      const text = getCaptionText(this[captionsName], currentUser);

      if (formatter) {
        const intl = owner.lookup('service:intl');
        return formatter(this, text, intl);
      }

      return text;
    },

    set(val) {
      const currentUser = getOwner(this).lookup('service:currentUser');
      this[captionsName] = getUpdatedCaptions(val, this[captionsName], currentUser);
    },
  };

  if (isWrapped) {
    return function captionWrapper() {
      return desc;
    };
  }

  return desc;
}
