const TaskAndFastenerMatcher = (tasksAndBolts) => {
  if (tasksAndBolts?.length === 0) {
    return {
      $match: {},
    };
  } else {
    return {
      $match: {
        idAndFastener: {
          $in: tasksAndBolts.map((taskAndBolt) => taskAndBolt.idAndFastener),
        },
      },
    };
  }
};

export default TaskAndFastenerMatcher;
