var _class, _descriptor;
function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
import { assert } from '@ember/debug';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { get, action } from '@ember/object';
import RSVP, { resolve } from 'rsvp';
import PowerSelectComponent from 'ember-power-select/components/power-select';
import { filterOptions, defaultMatcher } from 'ember-power-select/utils/group-utils';
import { ensureSafeComponent } from '@embroider/util';
import SuggestedOptionComponent from './power-select-with-create/suggested-option';
let PowerSelectWithCreateComponent = (_class = class PowerSelectWithCreateComponent extends Component {
  // Lifecycle hooks
  constructor() {
    super(...arguments);
    this.matcher = defaultMatcher;
    _initializerDefineProperty(this, "searchEnabled", _descriptor, this);
    assert('<PowerSelectWithCreate> requires an `onCreate` function', this.args.onCreate && typeof this.args.onCreate === 'function');
  }
  get powerSelectComponent() {
    return ensureSafeComponent(this.args.powerSelectComponent || PowerSelectComponent, this);
  }
  get suggestedOptionComponent() {
    return ensureSafeComponent(this.args.suggestedOptionComponent || SuggestedOptionComponent, this);
  }
  shouldShowCreateOption(term, options) {
    return this.args.showCreateWhen ? this.args.showCreateWhen(term, options) : true;
  }
  addCreateOption(term, results) {
    if (this.shouldShowCreateOption(term, results)) {
      if (this.args.showCreatePosition === 'bottom') {
        results.push(this.buildSuggestionForTerm(term));
      } else {
        results.unshift(this.buildSuggestionForTerm(term));
      }
    }
  }
  searchAndSuggest(term, select) {
    return RSVP.resolve(this.args.options).then(newOptions => {
      if (term.length === 0) {
        return newOptions;
      }
      let searchAction = this.args.search;
      if (searchAction) {
        return resolve(searchAction(term, select)).then(results => {
          if (results.toArray) {
            results = results.toArray();
          }
          this.addCreateOption(term, results);
          return results;
        });
      }
      newOptions = this.filter(newOptions, term);
      this.addCreateOption(term, newOptions);
      return newOptions;
    });
  }
  selectOrCreate(selection, select, e) {
    if (selection && selection.__isSuggestion__) {
      this.args.onCreate(selection.__value__, select, e);
    } else {
      this.args.onChange(selection, select, e);
    }
  }

  // Methods
  filter(options, searchText) {
    let matcher;
    if (this.args.searchField) {
      matcher = (option, text) => this.matcher(get(option, this.args.searchField), text);
    } else {
      matcher = (option, text) => this.matcher(option, text);
    }
    return filterOptions(options || [], searchText, matcher);
  }
  buildSuggestionForTerm(term) {
    return {
      __isSuggestion__: true,
      __value__: term,
      text: this.buildSuggestionLabel(term)
    };
  }
  buildSuggestionLabel(term) {
    if (this.args.buildSuggestion) {
      return this.args.buildSuggestion(term);
    }
    return `Add "${term}"...`;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "searchEnabled", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return true;
  }
}), _applyDecoratedDescriptor(_class.prototype, "searchAndSuggest", [action], Object.getOwnPropertyDescriptor(_class.prototype, "searchAndSuggest"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectOrCreate", [action], Object.getOwnPropertyDescriptor(_class.prototype, "selectOrCreate"), _class.prototype)), _class);
export { PowerSelectWithCreateComponent as default };