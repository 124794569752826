import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { isBlank, isPresent } from '@ember/utils';
import StationOptions from 'eflex/constants/stations/station-options';
import { action } from '@ember/object';

const CAN_GENERATE_NEW_PART_OPTIONS = new Set([
  StationOptions.loadOptions.auto,
  StationOptions.loadOptions.bomSourceLookupAuto,
]);

class JemInfoBar extends Component {
  @service currentUser;

  get canGenerateNewPart() {
    return CAN_GENERATE_NEW_PART_OPTIONS.has(this.args.station.loadOption);
  }

  get showSerialInput() {
    const showSerialInput = !this.args.stationIsRunning && !this.args.waitingForData;

    if (this.showModelDropdown) {
      return showSerialInput && isPresent(this.args.model);
    } else {
      return showSerialInput;
    }
  }

  get showModelDropdown() {
    if (this.args.stationIsRunning && this.args.isOnline) {
      return false;
    }

    return (
      !this.args.isOnline ||
      this.args.station?.loadOption === StationOptions.loadOptions.auto ||
      this.args.station?.loadOption === StationOptions.loadOptions.selectModel
    );
  }

  get newPartButtonDisabled() {
    if (this.args.stationIsRunning || this.args.notAuthorized) {
      return true;
    }

    if (this.args.station.usesModels && !this.args.model) {
      return true;
    }

    if (
      this.args.station.loadOption === StationOptions.loadOptions.bomSourceLookupAuto &&
      isBlank(this.args.bomSourceLookupValue)
    ) {
      return true;
    }

    return false;
  }

  @action
  onLoadNewPart() {
    if (this.newPartButtonDisabled || !this.canGenerateNewPart) {
      return;
    }

    let partValue;
    if (this.args.station.loadOption === StationOptions.loadOptions.bomSourceLookupAuto) {
      partValue = this.args.bomSourceLookupValue;
    } else {
      partValue = this.args.model?.code;
    }

    return this.args.onSubmitSerialNumber(partValue);
  }
}

export default JemInfoBar;
