const NotificationLogTypes = {
  GLOBAL: 'global',
  JEM: 'jem',
  WIE: 'wie',
};

const NotificationLogLevels = {
  ALARM: 'alarm',
  ERROR: 'error',
  WARN: 'warn',
  INFO: 'info',
};

export { NotificationLogTypes, NotificationLogLevels };
export default {
  types: NotificationLogTypes,
  levels: NotificationLogLevels,
};
