import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class CarouselModal extends Component {
  @tracked index = this.args.images.indexOf(this.args.currentImage);

  get currentImage() {
    return this.args.images[this.index];
  }

  @action
  nextImage() {
    if (this.index === this.args.images?.length - 1) {
      this.index = 0;
    } else {
      this.index += 1;
    }
  }

  @action
  previousImage() {
    if (this.index === 0) {
      this.index = this.args.images?.length - 1;
    } else {
      this.index -= 1;
    }
  }
}
