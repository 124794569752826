import Controller from '@ember/controller';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { waitFor } from '@ember/test-waiters';

export default class SettingsSsoController extends Controller {
  @service currentUser;
  @service systemConfig;

  get systemConfiguration() {
    return this.systemConfig.config;
  }

  get singleSignOnConfig() {
    return this.systemConfiguration.singleSignOnConfig;
  }

  @task
  @waitFor
  *save() {
    if (this.singleSignOnConfig.isInvalid) {
      return;
    }

    yield this.systemConfiguration.save();
  }

  @action
  rollback() {
    this.systemConfiguration.rollbackAttributes();
  }
}
