import { inject as service } from '@ember/service';
import { TextOptions, TextTypes, FABRIC_FILL_HEX_LENGTH } from 'eflex/constants/work-instructions/tool-props';
import { hexOpacityToDec, decOpacityToHex } from 'eflex/util/opacity-helper';
import { isPresent } from '@ember/utils';
import { all, task, waitForProperty, waitForQueue } from 'ember-concurrency';
import { fabric } from 'fabric';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import { pluck, map, uniq } from 'ramda';
import { intoArray } from '@eflexsystems/ramda-helpers';
import { updateObjectProperty } from 'eflex/util/fabric-helpers';

const UPDATE_ON_EVENTS = new Set(['Backspace', 'Enter']);

const TEXT_TYPES = new Set([
  TextTypes.TEXT,
  TextTypes.ITEXT,
  TextTypes.TEXTBOX,
]);

export default class WorkInstructionEditorToolPropertiesText extends Component {
  @service imageEditor;
  @service systemConfig;
  @service store;

  fontFamilies = TextOptions.fontFamilies;
  fontWeights = TextOptions.fontWeights;
  fontFamilyDefault = TextOptions.defaultFont;
  fontSizeDefault = 36;
  fontStyleDefault = 'normal';
  fontWeightDefault = 'normal';
  fillDefault = '#8C8C8C';
  underlineDefault = false;
  linethroughDefault = false;
  textBackgroundColorDefault = '#FFFFFFFF';
  strokeDefault = '#00000000';
  strokeWidthDefault = 2;
  textBackgroundColorOpacityDefault = 100;
  strokeOpacityDefault = 0;

  @tracked selectedTextBackgroundColor = '#FFFFFF';
  @tracked selectedTextBackgroundOpacity = 100;
  @tracked selectedStrokeColor = '#000000';
  @tracked selectedStrokeOpacity = 0;
  @tracked selectedBackgroundColor = '#FFFFFF';
  @tracked selectedBackgroundOpacity = 100;
  @tracked currentTextBox;
  @tracked eventTextBox;
  @tracked textSelection;
  @tracked link = '';
  @tracked startHidden = false;
  @tracked dynamicObjectName;
  @tracked calculatedBackgroundColor = '#FFFFFFFF';
  @tracked calculatedTextBackgroundColor = '#FFFFFFFF';
  @tracked calculatedStrokeColor = '#00000000';
  @tracked selectedStrokeWidth = 2;
  @tracked isUnderline = false;
  @tracked selectedFontStyle = 'normal';
  @tracked isStrikethrough = false;
  @tracked selectedFontWeight = 'normal';
  @tracked selectedFontFamily = TextOptions.defaultFont;
  @tracked selectedTextAlign = 'left';
  @tracked selectedFontSize = 36;
  @tracked selectedTextColor = '#8C8C8C';

  // jscpd:ignore-start
  @task
  @waitFor
  *onDidInsert() {
    yield all([
      waitForProperty(this.imageEditor, 'canvas'),
      waitForQueue('afterRender'),
    ]);

    if (this._activeObject() != null) {
      this.updateSelectedProperties();
    } else {
      this.#updateFromSystemConfig();
    }

    this.imageEditor
      .on('selection:updated', this.updateSelectedProperties)
      .on('selection:cleared', this.#updateFromSystemConfig);

    this.imageEditor.canvas
      .on('mouse:down', this.#startDraw)
      .on('mouse:move', this.#updateDraw)
      .on('mouse:up', this.#endDraw);
  }
  // jscpd:ignore-end

  _isText(obj) {
    return TEXT_TYPES.has(obj?.type);
  }

  _activeObject() {
    const obj = this.imageEditor.canvas?.getActiveObject();
    if (this._isText(obj)) {
      return obj;
    } else {
      return null;
    }
  }

  addTextBoxListener() {
    this.eventTextBox
      .on('selection:changed', this.#updateSelection)
      .on('editing:entered', this.#resetTextSelection)
      .on('editing:exited', this.#resetTextSelection);

    this.eventTextBox.onKeyUp = this.#handleInput;
  }

  removeTextBoxListener() {
    this.eventTextBox
      .off('selection:changed')
      .off('editing:entered')
      .off('editing:exited');

    this.eventTextBox.onKeyUp = null;
  }

  #handleInput = ({ key }) => {
    if (UPDATE_ON_EVENTS.has(key)) {
      this.#updateSelection();
    }
  };

  #updateSelection = () => {
    const { selectionStart, selectionEnd } = this.eventTextBox;
    const leftBoundary = this.eventTextBox.findLineBoundaryLeft(selectionStart);
    const rightBoundary = this.eventTextBox.findLineBoundaryRight(selectionEnd);

    if (selectionEnd - selectionStart > 0) {
      this.textSelection = [selectionStart, selectionEnd];
    } else if (selectionStart === leftBoundary) {
      this.textSelection = [selectionStart, selectionEnd + 1];
    } else if (selectionEnd === rightBoundary) {
      this.textSelection = [selectionEnd - 1, selectionEnd];
    } else {
      this.textSelection = [selectionStart - 1, selectionEnd];
    }

    this.setPropertiesPanel(this.eventTextBox, this.textSelection);
  };

  resetProperties() {
    Object.assign(this, {
      dynamicObjectName: null,
      link: '',
    });
  }

  #updateFromSystemConfig = () => {
    this.resetProperties();
    const config = this.systemConfig.getWieDefaults('text');

    const calculatedBackground = config.background?.color + decOpacityToHex(config.background?.opacity);

    const calculatedTextBackground =
      config.textBackground?.color + decOpacityToHex(config.textBackground?.opacity);

    const calculatedStrokeColor = config.stroke?.color + decOpacityToHex(config.stroke?.opacity);

    Object.assign(this, {
      selectedFontFamily: config.font?.family,
      selectedFontSize: config.font?.size,
      selectedFontStyle: config.font?.style,
      selectedFontWeight: config.font?.weight,
      selectedTextAlign: config.alignment,
      selectedTextColor: config.font?.color,
      selectedBackgroundColor: config.background?.color,
      selectedBackgroundOpacity: config.background?.opacity,
      selectedTextBackgroundColor: config.textBackground?.color,
      selectedTextBackgroundOpacity: config.textBackground?.opacity,
      selectedStrokeColor: config.stroke?.color,
      selectedStrokeOpacity: config.stroke?.opacity,
      selectedStrokeWidth: config.stroke?.width,
      isUnderline: config.font?.underline,
      isStrikethrough: config.font?.strikethrough,
      calculatedBackgroundColor: calculatedBackground,
      calculatedTextBackgroundColor: calculatedTextBackground,
      calculatedStrokeColor,
    });
  };

  @action
  updateSelectedProperties() {
    const obj = this._activeObject();
    if (obj == null) {
      return;
    }

    const { eventTextBox } = this;

    if (eventTextBox != null && eventTextBox !== obj) {
      this.removeTextBoxListener();
    }

    this.eventTextBox = obj;
    this.setPropertiesPanel(obj, null);

    if (eventTextBox !== obj) {
      this.addTextBoxListener();
    }
  }

  setPropertiesPanel(obj, range = null) {
    if (this.currentTextBox != null) {
      return;
    }

    if (range == null) {
      range = [0, obj.text.length];
    }

    if (range[1] === 0) {
      range[1] = 1;
    }

    const stroke = this.calculateStyleValue(obj, 'stroke', range) || this.strokeDefault;

    const textBackground =
      this.calculateStyleValue(obj, 'textBackgroundColor', range) || this.textBackgroundColorDefault;

    const selectedTextColor = this.calculateStyleValue(obj, 'fill', range) || this.fillDefault;

    const selectedStrokeColor = stroke?.slice(0, FABRIC_FILL_HEX_LENGTH);
    const selectedTextBackgroundColor = textBackground?.slice(0, FABRIC_FILL_HEX_LENGTH);
    const selectedBackgroundColor = obj.backgroundColor?.slice(0, FABRIC_FILL_HEX_LENGTH);

    const selectedStrokeOpacity = this.calculateOpacityValue(obj, 'stroke', range);

    const selectedTextBackgroundOpacity = this.calculateOpacityValue(obj, 'textBackgroundColor', range);

    const selectedBackgroundOpacity = hexOpacityToDec(obj.backgroundColor?.slice(FABRIC_FILL_HEX_LENGTH));

    Object.assign(this, {
      selectedFontFamily: this.calculateStyleValue(obj, 'fontFamily', range),
      selectedFontSize: this.calculateStyleValue(obj, 'fontSize', range),
      selectedFontStyle: this.calculateStyleValue(obj, 'fontStyle', range),
      selectedFontWeight: this.calculateStyleValue(obj, 'fontWeight', range),
      isUnderline: this.calculateStyleValue(obj, 'underline', range),
      isStrikethrough: this.calculateStyleValue(obj, 'linethrough', range),
      selectedStrokeWidth: this.calculateStyleValue(obj, 'strokeWidth', range),

      calculatedStrokeColor: stroke,
      calculatedTextBackgroundColor: textBackground,
      selectedTextColor,
      selectedTextBackgroundColor,
      selectedTextBackgroundOpacity,
      selectedStrokeColor,
      selectedStrokeOpacity,
      selectedBackgroundOpacity,
      selectedBackgroundColor,
      selectedTextAlign: obj.textAlign,
      dynamicObjectName: obj.eflex?.dynamicObjectName,
      startHidden: obj.eflex?.startHidden,
      link: obj.eflex?.link,
      calculatedBackgroundColor: obj.backgroundColor,
    });
  }

  _value(collection, defaultValue) {
    if (collection.length > 1) {
      return null;
    }

    if (isPresent(collection[0])) {
      return collection[0];
    }

    return defaultValue;
  }

  calculateOpacityValue(obj, style, range) {
    const styles = obj.getSelectionStyles(range[0], range[1], true);
    const collection = intoArray(
      map(elem => hexOpacityToDec(elem[style]?.slice(FABRIC_FILL_HEX_LENGTH))),
      uniq,
    )(styles);

    return this._value(collection, this[`${style}OpacityDefault`]);
  }

  calculateStyleValue(obj, style, range) {
    const styles = obj.getSelectionStyles(range[0], range[1], true);
    const collection = intoArray(
      pluck(style),
      uniq,
    )(styles);

    return this._value(collection, this[`${style}Default`]);
  }

  #resetTextSelection = () => {
    this.textSelection = null;
  };

  #startDraw = ({ e, target }) => {
    const { canvas } = this.imageEditor;

    if (target?.isEditing || target?.type === 'textbox' || !canvas.isTextMode) {
      return;
    }

    this.imageEditor.disableHistory();

    const pointer = canvas.getPointer(e);
    const textBox = new fabric.Textbox('', {
      left: pointer.x,
      top: pointer.y,
      paintFirst: 'stroke',
      lockScalingY: true,
      backgroundColor: this.calculatedBackgroundColor,
      textAlign: this.selectedTextAlign,
      fill: this.selectedTextColor,
      fontFamily: this.selectedFontFamily,
      fontSize: this.selectedFontSize,
      fontStyle: this.selectedFontStyle,
      fontWeight: this.selectedFontWeight,
      underline: this.isUnderline,
      linethrough: this.isStrikethrough,
      textBackgroundColor: this.calculatedTextBackgroundColor,
      stroke: this.calculatedStrokeColor,
      strokeWidth: this.selectedStrokeWidth,
      eflex: {
        dynamicObjectName: this.dynamicObjectName,
        startHidden: this.startHidden,
        link: this.link,
      },
    });

    textBox.setControlsVisibility({
      bl: false,
      br: false,
      mb: false,
      mt: false,
      tl: false,
      tr: false,
    });

    canvas.add(textBox);
    textBox.setCoords();
    canvas.setActiveObject(textBox);
    this.currentTextBox = textBox;
    canvas.renderAll();
  };

  #updateDraw = ({ e }) => {
    if (this.currentTextBox == null) {
      return;
    }
    const { canvas } = this.imageEditor;
    const pointer = canvas.getPointer(e);
    this.currentTextBox.set({ width: pointer.x - this.currentTextBox.left });
    this.currentTextBox.setCoords();
    canvas.renderAll();
  };

  #endDraw = () => {
    if (this.currentTextBox == null) {
      return;
    }
    const { canvas } = this.imageEditor;

    this.currentTextBox.set({ padding: 8 });
    this.currentTextBox.insertChars(TextOptions.defaultText);
    this.imageEditor.enableHistory();
    canvas.fire('object:modified', { target: this.currentTextBox });

    const styles = {
      fill: this.selectedTextColor,
      fontFamily: this.selectedFontFamily,
      fontSize: this.selectedFontSize,
      fontStyle: this.selectedFontStyle,
      fontWeight: this.selectedFontWeight,
      underline: this.isUnderline,
      linethrough: this.isStrikethrough,
      textBackgroundColor: this.calculatedTextBackgroundColor,
      stroke: this.calculatedStrokeColor,
      strokeWidth: this.selectedStrokeWidth,
    };

    this.currentTextBox.setSelectionStyles(styles, 0, TextOptions.defaultText.length);

    canvas.renderAll();
    this.args.objectCreated?.(this.currentTextBox);
    this.currentTextBox = null;

    this.updateSelectedProperties();
    this.eventTextBox.enterEditing();
    this.eventTextBox.selectAll();
  };

  willDestroy() {
    super.willDestroy(...arguments);
    if (this.eventTextBox != null) {
      this.removeTextBoxListener();
    }

    this.imageEditor
      .off('selection:updated', this.updateSelectedProperties)
      .off('selection:cleared', this.#updateFromSystemConfig);

    this.imageEditor.canvas
      ?.off('mouse:down')
      .off('mouse:move')
      .off('mouse:up');
  }

  updateTextRange(prop, val) {
    const obj = this._activeObject();
    if (obj == null) {
      return;
    }

    const styles = { [prop]: val };
    const range = this.textSelection ?? [0, obj.text.length];
    this.imageEditor.updateTextRange(obj, styles, range);
  }

  // jscpd:ignore-start
  @action
  updateLink(val) {
    this.link = val;
    const obj = this._activeObject();
    if (!obj) {
      return;
    }
    obj.eflex ??= {};
    updateObjectProperty(obj.eflex, 'link', val);
    this.imageEditor.notifyModified(obj);
  }

  @action
  updateDynamicObjectName(val) {
    this.dynamicObjectName = val;
    const obj = this._activeObject();
    if (!obj) {
      return;
    }
    obj.eflex ??= {};
    updateObjectProperty(obj.eflex, 'dynamicObjectName', val);
    this.imageEditor.notifyModified(obj);
  }

  @action
  updateStartHidden(val) {
    this.startHidden = val;
    const obj = this._activeObject();
    if (!obj) {
      return;
    }
    obj.eflex ??= {};
    updateObjectProperty(obj.eflex, 'startHidden', val);
    this.imageEditor.notifyModified(obj);
  }

  @action
  updateStrokeColor(color, opacity, combinedColor) {
    Object.assign(this, {
      selectedStrokeColor: color,
      calculatedStrokeColor: combinedColor,
      selectedStrokeOpacity: opacity,
    });

    this.updateTextRange('stroke', combinedColor);
  }
  // jscpd:ignore-end

  @action
  updateTextBackground(color, opacity, combinedColor) {
    Object.assign(this, {
      selectedTextBackgroundColor: color,
      calculatedTextBackgroundColor: combinedColor,
      selectedTextBackgroundOpacity: opacity,
    });

    this.updateTextRange('textBackgroundColor', combinedColor);
  }

  @action
  updateBackgroundColor(color, opacity, combinedColor) {
    Object.assign(this, {
      selectedBackgroundColor: color,
      selectedBackgroundOpacity: opacity,
      calculatedBackgroundColor: combinedColor,
    });

    const obj = this._activeObject();
    updateObjectProperty(obj, 'backgroundColor', combinedColor);
    this.imageEditor.notifyModified(obj);
  }

  @action
  updateStrokeWidth(val) {
    this.selectedStrokeWidth = val;
    this.updateTextRange('strokeWidth', val);
  }

  @action
  updateIsUnderline() {
    this.isUnderline = !this.isUnderline;
    this.updateTextRange('underline', this.isUnderline);
  }

  @action
  updateFontStyle() {
    if (this.selectedFontStyle === 'italic') {
      this.selectedFontStyle = 'normal';
    } else {
      this.selectedFontStyle = 'italic';
    }

    this.updateTextRange('fontStyle', this.selectedFontStyle);
  }

  @action
  updateIsStrikethrough() {
    this.isStrikethrough = !this.isStrikethrough;
    this.updateTextRange('linethrough', this.isStrikethrough);
  }

  @action
  updateFontWeight(val) {
    this.selectedFontWeight = val;
    this.updateTextRange('fontWeight', val);
  }

  @action
  updateFontFamily(val) {
    this.selectedFontFamily = val;
    this.updateTextRange('fontFamily', val);
  }

  @action
  updateTextAlign(val) {
    this.selectedTextAlign = val;
    const obj = this._activeObject();
    updateObjectProperty(obj, 'textAlign', val);
    this.imageEditor.notifyModified(obj);
  }

  @action
  updateFontSize(val) {
    this.selectedFontSize = val;
    this.updateTextRange('fontSize', val);
  }

  @action
  updateTextColor(val) {
    this.selectedTextColor = val;
    this.updateTextRange('fill', val);
  }

  @action
  onMakeDefault() {
    const textDefault = this.store.createRecord('wieConfig/text', {
      alignment: this.selectedTextAlign,
      font: this.store.createRecord('wieConfig/font', {
        family: this.selectedFontFamily,
        weight: this.selectedFontWeight,
        size: this.selectedFontSize,
        style: this.selectedFontStyle,
        color: this.selectedTextColor,
        underline: this.isUnderline,
        strikethrough: this.isStrikethrough,
      }),
      stroke: this.store.createRecord('wieConfig/stroke', {
        color: this.selectedStrokeColor,
        opacity: this.selectedStrokeOpacity,
        width: this.selectedStrokeWidth,
      }),
      background: this.store.createRecord('wieConfig/fill', {
        color: this.selectedBackgroundColor,
        opacity: this.selectedBackgroundOpacity,
      }),
      textBackground: this.store.createRecord('wieConfig/fill', {
        color: this.selectedTextBackgroundColor,
        opacity: this.selectedTextBackgroundOpacity,
      }),
    });

    this.systemConfig.config.wie.editorDefaults.text = textDefault;
    return this.args.saveDefault();
  }
}
