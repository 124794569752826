const ErrorProofingTriggerValues = {
  taskActive: 'taskActive',
  jemButton: 'jemButton',
};

const CameraTriggerValues = {
  taskActive: 'taskActive',
  captureButton: 'captureButton',
  captureAdvance: 'captureAdvance',
};

const ErrorProofingTriggerTypes = [
  {
    value: ErrorProofingTriggerValues.taskActive,
    label: 'plant.task.jemerrorproofing.trigger.active',
  },
  {
    value: ErrorProofingTriggerValues.jemButton,
    label: 'plant.task.jemerrorproofing.trigger.jemButton',
  },
];

const CameraTriggerTypes = [
  {
    value: CameraTriggerValues.taskActive,
    label: 'plant.task.triggerConfig.taskActive',
  },
  {
    value: CameraTriggerValues.captureButton,
    label: 'plant.task.triggerConfig.captureButton',
  },
  {
    value: CameraTriggerValues.captureAdvance,
    label: 'plant.task.triggerConfig.captureAdvance',
  },
];

const types = {
  errorProofing: ErrorProofingTriggerTypes,
  vision: CameraTriggerTypes.filter(item => item.value !== 'captureAdvance'),
  imageCapture: CameraTriggerTypes,
};

export { ErrorProofingTriggerValues, CameraTriggerValues };
export default types;
