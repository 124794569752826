var _class;
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
import Component from '@glimmer/component';
import { action } from '@ember/object';
const isTouchDevice = !!window && 'ontouchstart' in window;
if (typeof FastBoot === 'undefined') {
  (function (ElementProto) {
    if (typeof ElementProto.matches !== 'function') {
      ElementProto.matches = ElementProto.msMatchesSelector || ElementProto.mozMatchesSelector || ElementProto.webkitMatchesSelector;
    }
    if (typeof ElementProto.closest !== 'function') {
      ElementProto.closest = function closest(selector) {
        let element = this;
        while (element !== null && element.nodeType === 1) {
          if (element.matches(selector)) {
            return element;
          }
          element = element.parentNode;
        }
        return null;
      };
    }
  })(window.Element.prototype);
}
let Options = (_class = class Options extends Component {
  constructor(...args) {
    super(...args);
    this.isTouchDevice = this.args.extra?._isTouchDevice || isTouchDevice;
    this.touchMoveEvent = void 0;
    this.mouseOverHandler = _ => {};
    this.mouseUpHandler = _ => {};
    this.touchEndHandler = _ => {};
    this.touchMoveHandler = _ => {};
    this.touchStartHandler = _ => {};
  }
  addHandlers(element) {
    let role = element.getAttribute('role');
    if (role === 'group') {
      return;
    }
    let findOptionAndPerform = (action, e) => {
      if (e.target === null) return;
      let optionItem = e.target.closest('[data-option-index]');
      if (!optionItem) {
        return;
      }
      if (optionItem.closest('[aria-disabled=true]')) {
        return; // Abort if the item or an ancestor is disabled
      }

      let optionIndex = optionItem.getAttribute('data-option-index');
      if (optionIndex === null) return;
      action(this._optionFromIndex(optionIndex), e);
    };
    this.mouseUpHandler = e => findOptionAndPerform(this.args.select.actions.choose, e);
    element.addEventListener('mouseup', this.mouseUpHandler);
    if (this.args.highlightOnHover) {
      this.mouseOverHandler = e => findOptionAndPerform(this.args.select.actions.highlight, e);
      element.addEventListener('mouseover', this.mouseOverHandler);
    }
    if (this.isTouchDevice) {
      this.touchMoveHandler = e => {
        this.touchMoveEvent = e;
        if (element) {
          element.removeEventListener('touchmove', this.touchMoveHandler);
        }
      };
      // Add touch event handlers to detect taps
      this.touchStartHandler = _ => {
        element.addEventListener('touchmove', this.touchMoveHandler);
      };
      this.touchEndHandler = e => {
        if (e.target === null) return;
        let optionItem = e.target.closest('[data-option-index]');
        if (optionItem === null) return;
        e.preventDefault();
        if (this._hasMoved(e)) {
          this.touchMoveEvent = undefined;
          return;
        }
        if (optionItem.closest('[aria-disabled=true]')) {
          return; // Abort if the item or an ancestor is disabled
        }

        let optionIndex = optionItem.getAttribute('data-option-index');
        if (optionIndex === null) return;
        this.args.select.actions.choose(this._optionFromIndex(optionIndex), e);
      };
      element.addEventListener('touchstart', this.touchStartHandler);
      element.addEventListener('touchend', this.touchEndHandler);
    }
    if (role !== 'group') {
      this.args.select.actions.scrollTo(this.args.select.highlighted);
    }
  }
  removeHandlers(element) {
    element.removeEventListener('mouseup', this.mouseUpHandler);
    element.removeEventListener('mouseover', this.mouseOverHandler);
    element.removeEventListener('touchstart', this.touchStartHandler);
    element.removeEventListener('touchmove', this.touchMoveHandler);
    element.removeEventListener('touchend', this.touchEndHandler);
  }
  _optionFromIndex(index) {
    let parts = index.split('.');
    let option = this.args.options[parseInt(parts[0], 10)];
    for (let i = 1; i < parts.length; i++) {
      option = option.options[parseInt(parts[i], 10)];
    }
    return option;
  }
  _hasMoved(endEvent) {
    let moveEvent = this.touchMoveEvent;
    if (!moveEvent) {
      return false;
    }
    let changedTouch = moveEvent.changedTouches[0];
    if (!endEvent.changedTouches?.[0] || changedTouch.touchType !== 'stylus') {
      return true;
    }

    // Distinguish stylus scroll and tap: if touch "distance" < 5px, we consider it a tap
    let horizontalDistance = Math.abs(changedTouch.pageX - endEvent.changedTouches[0].pageX);
    let verticalDistance = Math.abs(changedTouch.pageY - endEvent.changedTouches[0].pageY);
    return horizontalDistance >= 5 || verticalDistance >= 5;
  }
}, (_applyDecoratedDescriptor(_class.prototype, "addHandlers", [action], Object.getOwnPropertyDescriptor(_class.prototype, "addHandlers"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeHandlers", [action], Object.getOwnPropertyDescriptor(_class.prototype, "removeHandlers"), _class.prototype)), _class);
export { Options as default };