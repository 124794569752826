import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import { task as trackedTask } from 'ember-resources/util/ember-concurrency';
import TaskStatuses from 'eflex/constants/task-statuses';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';

const REFRESH_INTERVAL = 60_000;

export default class AndonProductionStation extends Component {
  // jscpd:ignore
  @service queryRunner;
  @service intl;

  @tracked timerToken;

  @task({ drop: true })
  *startAutoRefresh() {
    if (window.isTesting || window.isIntegrationTest) {
      return;
    }

    while (true) {
      yield timeout(REFRESH_INTERVAL);
      this.timerToken = true;
    }
  }

  get params() {
    return {
      stationIds: this.args.stationIds,
      modelIds: this.args.modelIds,
      userIds: this.args.userIds,
      tags: this.args.tags,
      startDate: this.args.startDate,
      endDate: this.args.endDate,
      shiftNames: this.args.shiftNames,
      useUniqueSerialNumbers: this.args.useUniqueSerialNumbers,
    };
  }
  // jscpd:ignore-end

  get tableConfiguration() {
    return {
      columns: [
        {
          title: this.intl.t('station'),
          field: 'station',
          tooltip: false,
          headerSort: false,
          formatter: 'textarea',
        },

        {
          title: this.intl.t('bi.chartLabel.partCounts'),
          field: 'partCounts',
          tooltip: false,
          headerSort: false,
          formatter: (cell) => this.formatPartsColumnData(cell),
          hozAlign: 'right',
        },
      ],
    };
  }

  partCountData = trackedTask(this, this.getData, () => [this.params, this.timerToken]);

  @task({ restartable: true })
  @waitFor
  *getData(params) {
    const totalCyclesTableQuery = yield this.queryRunner.queryWithParams.perform(params, [
      {
        $group: {
          _id: '$location._id',
          station: {
            $first: '$location.captions',
          },
          totalParts: {
            $sum: 1,
          },
          goodParts: {
            $sum: {
              $cond: {
                if: {
                  $and: [
                    {
                      $gte: ['$status', TaskStatuses.GOOD_RANGE_START],
                    },
                    {
                      $lte: ['$status', TaskStatuses.GOOD_RANGE_END],
                    },
                  ],
                },
                then: 1,
                else: 0,
              },
            },
          },
        },
      },
      {
        $project: {
          _id: false,
          station: {
            $arrayElemAt: ['$station', 0],
          },
          totalParts: true,
          goodParts: true,
        },
      },
      {
        $sort: {
          totalParts: -1,
        },
      },
    ]);

    const totalPartsTableQuery = yield this.queryRunner.queryWithParams.perform(params, [
      {
        $sort: {
          timestamp: -1,
        },
      },
      {
        $group: {
          _id: {
            serialNumber: '$serialNumber',
            stationId: '$location._id',
          },
          buildStatus: {
            $first: {
              station: '$location',
              timestamp: '$timestamp',
              status: '$status',
            },
          },
        },
      },
      {
        $group: {
          _id: '$_id.stationId',
          station: {
            $first: '$buildStatus.station.captions',
          },
          totalParts: {
            $sum: 1,
          },
          goodParts: {
            $sum: {
              $cond: {
                if: {
                  $and: [
                    {
                      $gte: ['$buildStatus.status', TaskStatuses.GOOD_RANGE_START],
                    },
                    {
                      $lte: ['$buildStatus.status', TaskStatuses.GOOD_RANGE_END],
                    },
                  ],
                },
                then: 1,
                else: 0,
              },
            },
          },
        },
      },
      {
        $project: {
          _id: 0,
          station: {
            $arrayElemAt: ['$station', 0],
          },
          totalParts: true,
          goodParts: true,
        },
      },
      {
        $sort: {
          totalParts: -1,
        },
      },
    ]);

    const andonProductionQuery = params.useUniqueSerialNumbers ? totalPartsTableQuery : totalCyclesTableQuery;
    const partCountTableData = yield this.queryRunner.runQuery.perform('BuildStatuses', andonProductionQuery);
    let goodParts = 0;
    let badParts = 0;

    const rows = partCountTableData.map((row) => {
      goodParts += row.goodParts;
      badParts += row.totalParts - row.goodParts;
      return {
        station: row.station?.text.toUpperCase(),
        goodParts: row.goodParts,
        badParts: row.totalParts - row.goodParts,
      };
    });

    return {
      rows,
      goodParts,
      badParts,
      exportData: () => rows.map(({ partCounts, ...row }) => row),
    };
  }

  formatPartsColumnData(cell) {
    const { goodParts, badParts } = cell.getData();

    return `<div>
      <span class="part-count-good">${goodParts} </span>
      <span class="part-count-bad">${badParts}</span>
    </div>`;
  }
}
