import ApplicationSerializer from 'eflex/serializers/application';

export default class ComplexComponentOptionSerializer extends ApplicationSerializer {
  attrs = {
    component: {
      serialize: false,
      deserialize: false,
    },
    jemPrerequisites: {
      serialize: false,
      deserialize: false,
    },
    taskConfigs: {
      serialize: false,
      deserialize: false,
    },
  };
}
