import config from 'eflex/config/environment';

const VAGRANT_ADDRESS = '192.168.50.10';

export default {
  name: 'ember-cli-raygun',
  async initialize(applicationInstance) {
    const raygunConfig = config.raygun;

    if (!raygunConfig.enabled || window.location.hostname === VAGRANT_ADDRESS) {
      return;
    }

    const rg4js = (await import('raygun4js')).default;

    rg4js('apiKey', raygunConfig.apiKey);
    rg4js('enableCrashReporting', true);
    rg4js('setVersion', config.APP.version);
    rg4js('logContentsOfXhrCalls', true);
    rg4js('enablePulse', true);

    rg4js('withCustomData', {
      environment: config.environment,
      appName: config.APP.name,
    });

    const router = applicationInstance.lookup('service:router');

    router.on('routeDidChange', transition => {
      if (!transition.to?.name) {
        return;
      }

      rg4js('trackEvent', {
        type: 'pageView',
        path: transition.to.name,
      });
    });
  },
};
