import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { trackedFunction } from 'ember-resources/util/function';

export default class HardwareWebCam extends Component {
  @service mediaDevices;

  availableDevices = trackedFunction(this, async () => {
    await this.mediaDevices.load.perform('videoinput');
    return this.mediaDevices.devices.filter(item => item.kind === 'videoinput');
  });

  @action
  setDeviceOnHardwareModel(device) {
    Object.assign(this.args.hardware, {
      cameraName: device.label,
      cameraId: device.deviceId,
    });
  }
}
