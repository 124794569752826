import { taskTypes } from 'eflex/constants/tasks/task-types';
import { ErrorProofingTriggerValues } from 'eflex/constants/tasks/trigger-types';
import { action } from '@ember/object';
import TaskStatuses from 'eflex/constants/task-statuses';
import Component from '@glimmer/component';
import { keyResponder, onKey } from 'ember-keyboard';

const SHOW_OK_TASK_TYPES = new Set([
  taskTypes.barcode,
  taskTypes.imageCapture,
  taskTypes.button,
  taskTypes.timer,
  taskTypes.serialNumberTransfer,
  taskTypes.decision,
]);

@keyResponder
class JemTaskButtonOkButton extends Component {
  get keyboardActivated() {
    return !this.okButtonDisabled && !this.args.alwaysShow;
  }

  get task() {
    return this.args.childStatus?.task;
  }

  get buildStatus() {
    return this.args.childStatus?.parent;
  }

  get showButton() {
    return this.task &&
      this.args.childStatus &&
      !this.buildStatus?.isFinished &&
      (
        this.showOkButton ||
        this.showCheckButton ||
        this.showRestartButton ||
        this.showRecheckButton ||
        this.showRetryButton ||
        this.args.alwaysShow
      );
  }

  get showRestartButton() {
    return this.args.selected &&
      this.args.childStatus?.isGood &&
      this.task?.retryStrategy === 0 &&
      this.buildStatus?.isStarted;
  }

  get showOkButton() {
    if (!this.task?.showOkButton || this.task?.hideBarcodeInput || !this.args.childStatus) {
      return false;
    }

    return (
      (this.args.childStatus.isStarted || this.args.childStatus.isRestart) &&
      (SHOW_OK_TASK_TYPES.has(this.args.childStatus?.taskType) || this.args.taskConfig?.pickUsesTime)
    );
  }

  get isDecisionTaskAndRequiresDecision() {
    return this.task.decisionRequired &&
      this.args.childStatus.decisionProcessData.filter(item => item.isSelected).length === 0;
  }

  get showCheckButton() {
    if (!this.args.childStatus?.isStarted) {
      return false;
    }

    return this.task?.isErrorProofing && this.task?.triggerType === ErrorProofingTriggerValues.jemButton;
  }

  get showRecheckButton() {
    if (!this.task?.canRestartRejected || !this.args.selected) {
      return false;
    }

    return (
      this.task?.isErrorProofing &&
      this.task?.triggerType === ErrorProofingTriggerValues.jemButton &&
      this.args.childStatus?.isRejected
    );
  }

  get showRetryButton() {
    if (!this.task?.canRestartRejected || !this.args.selected) {
      return false;
    }

    return this.args.childStatus?.isRejected;
  }

  get buttonAttributes() {
    if (this.showOkButton) {
      return {
        taskStatus: TaskStatuses.GOOD,
        label: 'ok',
        class: 'complete-jem-task ok-button',
      };
    } else if (this.showCheckButton) {
      return {
        taskStatus: TaskStatuses.NOT_RECEIVED,
        label: 'check',
        class: 'complete-jem-task check-button',
      };
    } else if (this.showRestartButton) {
      return {
        taskStatus: TaskStatuses.RESTART,
        label: 'status.restart',
        class: 'restart-jem-task restart-button',
      };
    } else if (this.showRecheckButton) {
      return {
        taskStatus: TaskStatuses.NOT_RECEIVED,
        label: 'recheck',
        class: 'restart-jem-task recheck-button',
      };
    } else if (this.showRetryButton) {
      return {
        taskStatus: TaskStatuses.RESTART,
        label: 'retry',
        class: 'reject-jem-task retry-button',
      };
    }

    return {
      taskStatus: TaskStatuses.GOOD,
      label: 'ok',
      class: 'complete-jem-task ok-button',
    };
  }

  get buttonDisabled() {
    if (!this.args.taskConfig) {
      return true;
    }
    if (this.args.disabled || this.args.notAuthorized || !this.buildStatus?.isRunning) {
      return true;
    } else if (this.showOkButton) {
      return this.okButtonDisabled;
    } else if (
      this.showCheckButton ||
      this.showRestartButton ||
      this.showRecheckButton ||
      this.showRetryButton
    ) {
      return false;
    } else {
      return this.args.alwaysShow;
    }
  }

  get okButtonDisabled() {
    return !this.args.childStatus?.isStarted ||
      this.args.childStatus?.isGood ||
      !this.showOkButton ||
      this.args.childStatus?.processData?.some(item => item.isInvalid) ||
      this.isDecisionTaskAndRequiresDecision;
  }

  @onKey('Enter')
  @onKey('ctrl+alt+KeyO')
  keyboardOk() {
    if (this.args.childStatus.isHolding) {
      return;
    }

    if (this.okButtonDisabled) {
      return;
    }

    this.sendStatus(TaskStatuses.GOOD);
  }

  @action
  sendStatus(status) {
    return this.args.sendStatus?.(status, this.args.childStatus);
  }
}

export default JemTaskButtonOkButton;
