import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { task, all } from 'ember-concurrency';
import { ALLOWED_EXTENSIONS, MAX_UPLOAD_SIZE, MAX_UPLOAD_SIZE_MB } from 'eflex/constants/allowed-file-upload-types';
import { range } from 'ramda';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';

export default class WorkInstructionEditorFiles extends Component {
  @service store;
  @service fileUploader;
  @service notifier;
  @service intl;
  @service hostedFileRepo;
  @service workInstructionRepo;
  @service currentUser;

  @tracked showConfirmModal = false;
  @tracked confirmModalText;
  @tracked confirmAction;
  @tracked replacementFile;

  get uploadDisabled() {
    return !this.workInstructionRepo.userCanUploadFiles(this.currentUser);
  }

  get selectedFiles() {
    return this.hostedFileRepo.hostedFiles.filter(item => item.isSelected);
  }

  get isLoading() {
    return this.remove.isRunning || this.import.isRunning || this.replace.isRunning;
  }

  get notOneSelected() {
    return this.selectedFiles.length !== 1;
  }

  get supportedFileTypes() {
    return ALLOWED_EXTENSIONS.map(ext => `.${ext}`);
  }

  @task
  @waitFor
  *preRemove() {
    let text;
    const instructions = yield this.store.query('workInstruction', {
      backgroundIds: this.selectedFiles.map(item => item.id),
    });

    if (instructions.length > 0) {
      text = this.intl.t('workInstructions.instructionsAffected', { count: instructions.length });
    } else {
      text = this.intl.t('workInstructions.deleteFiles', { count: this.selectedFiles.length });
    }

    Object.assign(this, {
      confirmModalText: text,
      showConfirmModal: true,
      confirmAction: 'remove',
    });
  }

  @task({ drop: true })
  @waitFor
  *remove() {
    yield all(this.selectedFiles.map(selectedFile => selectedFile.destroyRecord()));
  }

  @task
  @waitFor
  *import(fileList) {
    yield all(
      range(0, fileList.length).map(i =>
        this._uploadFile.perform(fileList.item(i)),
      ),
    );
  }

  @task
  @waitFor
  *preReplace(fileList) {
    const instructions = yield this.store.query('workInstruction', {
      backgroundIds: this.selectedFiles[0].id,
    });

    this.replacementFile = fileList.item(0);

    if (instructions.length > 0) {
      Object.assign(this, {
        confirmModalText: this.intl.t('workInstructions.instructionsAffected', { count: instructions.length }),
        showConfirmModal: true,
        confirmAction: 'replace',
      });
    } else {
      yield this.replace.perform(this.replacementFile);
    }
  }

  @task({ enqueue: true })
  @waitFor
  *replace(replacementFile) {
    yield this._uploadFile.perform(replacementFile, this.selectedFiles[0]);
    this.replacementFile = null;
  }

  @task
  @waitFor
  *removeReplace() {
    switch (this.confirmAction) {
      case 'replace': {
        yield this.replace.perform(this.replacementFile);
        break;
      }
      case 'remove': {
        yield this.remove.perform();
        break;
      }
    }

    this.showConfirmModal = false;

    this.args.afterRemoveReplace?.();
  }

  @task
  @waitFor
  *_uploadFile(file, replacedFile = null) {
    if (file.size > MAX_UPLOAD_SIZE) {
      this.notifier.sendError('fileTooLarge', {
        filename: file.name,
        size: MAX_UPLOAD_SIZE_MB,
      });
      return;
    }

    const { hostedFile } =
      replacedFile != null
        ? (yield this.fileUploader.put.perform(`hostedFiles/${replacedFile.id}`, file))
        : (yield this.fileUploader.post.perform('hostedFiles', file));

    this.store.push(this.store.normalize('hostedFile', hostedFile));
  }

  @action
  toggleFile(file) {
    file.isSelected = !file.isSelected;
  }

  @action
  hideConfirmModal() {
    Object.assign(this, {
      showConfirmModal: false,
      confirmAction: null,
    });
  }
}
