import Component from '@glimmer/component';
import { action } from '@ember/object';
import { Modal } from 'bootstrap';
import { registerDestructor } from '@ember/destroyable';

// DO NOT USE DIRECTLY
export default class StandardModalBase extends Component {
  get focus() {
    return this.args.focus ?? 'true';
  }

  get keyboard() {
    return this.args.keyboard ?? 'true';
  }

  @action
  onDidInsert(element) {
    const modal = new Modal(element);
    registerDestructor(this, () => {
      modal.dispose();
    });
    modal.show();
  }
}
