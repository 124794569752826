var _class;
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { get } from '@ember/object';
import { scheduleOnce } from '@ember/runloop';
const isIndexAccesible = target => {
  return typeof target.objectAt === 'function';
};
let Trigger = (_class = class Trigger extends Component {
  constructor(...args) {
    super(...args);
    this._lastIsOpen = this.args.select.isOpen;
  }
  // Actions
  openChanged(_el, [isOpen]) {
    if (isOpen === false && this._lastIsOpen === true) {
      scheduleOnce('actions', null, this.args.select.actions.search, '');
    }
    this._lastIsOpen = isOpen;
  }
  chooseOption(e) {
    if (e.target === null) return;
    let selectedIndex = e.target.getAttribute('data-selected-index');
    if (selectedIndex) {
      let numericIndex = parseInt(selectedIndex, 10);
      e.stopPropagation();
      e.preventDefault();
      let object = this.selectedObject(this.args.select.selected, numericIndex);
      this.args.select.actions.choose(object);
    }
  }
  selectedObject(list, index) {
    if (isIndexAccesible(list)) {
      return list.objectAt(index);
    } else {
      return get(list, index);
    }
  }
}, (_applyDecoratedDescriptor(_class.prototype, "openChanged", [action], Object.getOwnPropertyDescriptor(_class.prototype, "openChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "chooseOption", [action], Object.getOwnPropertyDescriptor(_class.prototype, "chooseOption"), _class.prototype)), _class);
export { Trigger as default };