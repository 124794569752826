/**
 * Copyright 2015, Yahoo! Inc.
 * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
 */

import Formatter from './-base';

/**
 * @private
 * @hide
 */
export default class FormatNumber extends Formatter {
  format(intl, ...[value, opts]) {
    return intl.formatNumber(value, opts);
  }
}
FormatNumber.type = 'number';