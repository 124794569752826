import Model, { attr, belongsTo } from '@ember-data/model';
import numbro from 'numbro';
import { arrayAttr, caption } from 'eflex/decorators';
import { pipe, filter, propEq, unnest } from 'ramda';
import { sortByProp } from 'ramda-adjunct';

export default class SpindleProcessDatum extends Model {
  @attr('number') spindleNumber;
  @attr('number', { defaultValue: -1 }) overallStatus;
  @attr('number') torqueStatus;
  @attr('number') torque;
  @attr('number') angleStatus;
  @attr('number') angle;
  @arrayAttr captions;
  @caption name;

  @belongsTo('multispindleProcessDatum', { async: false, inverse: 'spindles' }) multispindleProcessDatum;

  get formattedTorque() {
    if (this.torque == null) {
      return '-';
    }

    return `${numbro(this.torque).format({ mantissa: 3 })} ${this.multispindleProcessDatum.selectedTorqueFormat}`;
  }

  get formattedAngle() {
    return (this.angle != null) ? `${this.angle}°` : '-';
  }

  get isPassed() {
    return this.overallStatus === 1;
  }

  get isRejected() {
    return this.overallStatus === 0 ||
      (this.isNotReceived && this.multispindleProcessDatum.buildStatusChild.isRejected);
  }

  get isNotReceived() {
    return this.overallStatus === -1;
  }

  get isActiveResult() {
    return this.multispindleProcessDatum === this.multispindleProcessDatum.buildStatusChild.latestMultispindleDatum;
  }

  get groupedSpindles() {
    return pipe(
      unnest,
      filter(propEq(this.spindleNumber, 'spindleNumber')),
      sortByProp('timestamp'),
    )(this.multispindleProcessDatum.buildStatusChild.groupedSpindleData);
  }

  get isCollapsedResult() {
    if (
      (this.multispindleProcessDatum.buildStatusChild.isFinished &&
      this.groupedSpindles.at(-1) !== this) || (!this.isActiveResult && this.isPassed)
    ) {
      return true;
    }

    return false;
  }
}
