import Trigger from 'eflex/models/trigger';
import EdhrTriggerValidations from 'eflex/validations/edhr-trigger';
import { hasMany, attr } from '@ember-data/model';
import { anyInvalid } from 'eflex/util/getter-helpers';
import { buildValidations } from '@eflexsystems/ember-tracked-validations';

@buildValidations(EdhrTriggerValidations)
class EdhrTrigger extends Trigger {
  @attr('string') reasonCode;
  @attr('string') operation;

  @hasMany('edhrMapping', { async: false, inverse: 'edhrTrigger', embedded: true }) edhrMappings;

  get isDirty() {
    return super.isDirty || this.edhrMappings.some(item => item.isDirty);
  }

  get edhrMappingsInvalid() {
    return anyInvalid(this.edhrMappings);
  }

  get isSelfOrChildInvalid() {
    return this.isInvalid || this.edhrMappingsInvalid;
  }

  get validatedRelationships() {
    return ['edhrMappings'];
  }

  get copyableOptions() {
    const options = super.copyableOptions;
    options.ignoreAttributes.add('edhrMappings');
    return options;
  }
}

export default EdhrTrigger;
