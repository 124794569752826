import TriggerConfig from 'eflex/models/trigger-config';
import { attr } from '@ember-data/model';
import LightGuideTriggerConfigValidations from 'eflex/validations/light-guide-trigger-config';
import { buildValidations } from '@eflexsystems/ember-tracked-validations';

@buildValidations(LightGuideTriggerConfigValidations)
class LightGuideTriggerConfig extends TriggerConfig {
  @attr('string') programName;
  @attr('number') x;
  @attr('number') y;
}

export default LightGuideTriggerConfig;
