import TriggerConfig from 'eflex/models/trigger-config';
import { attr } from '@ember-data/model';

export default class AudioTriggerConfig extends TriggerConfig {
  @attr('string', { defaultValue: 'sound' }) audioType;
  @attr('string', { defaultValue: 'pass' }) sound;
  @attr('string') text;

  get isText() {
    return this.audioType === 'text';
  }

  get isSound() {
    return this.audioType === 'sound';
  }
}
