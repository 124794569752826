import AuthenticatedRoute from 'eflex/util/authenticated-route';
import { inject as service } from '@ember/service';

export default class PartsGenealogyRoute extends AuthenticatedRoute {
  @service eflexAjax;

  model(queryParams) {
    if (!queryParams.serialNumber) {
      return [];
    }

    return this.eflexAjax.get.perform('marriages', {
      searchTerm: queryParams.serialNumber,
      beginDate: queryParams.beginDate,
      endDate: queryParams.endDate,
      searchMode: queryParams.searchMode,
    });
  }

  setupController(controller, searchResult) {
    controller.searchResult = searchResult;
  }
}
