import { inject as service } from '@ember/service';
import AuthenticatedRoute from 'eflex/util/authenticated-route';
import pProps from 'p-props';
import { task } from 'ember-concurrency';
import StationOptions from 'eflex/constants/stations/station-options';
import { waitFor } from '@ember/test-waiters';

const USES_JEM_MODEL_SELECT = new Set([
  StationOptions.loadOptions.auto,
  StationOptions.loadOptions.selectModel,
]);

class JemStationsRoute extends AuthenticatedRoute {
  @service store;
  @service mediaDevices;
  @service intl;
  @service notifier;
  @service router;
  @service logRepo;
  @service jemRepo;

  @task({ drop: true })
  @waitFor
  *_loadMediaDevices() {
    yield this.mediaDevices.load.linked().perform();
  }

  @task
  @waitFor
  *_getModel(station) {
    if (
      station.usesModels &&
      (!this.controllerFor('jem/stations').isOnline || USES_JEM_MODEL_SELECT.has(station.loadOption))
    ) {
      const model = station.models[0];
      yield this.jemRepo.loadTaskConfigs.perform(station, model);
      return model;
    }
  }

  model({ id: stationId }) {
    const station = stationId != null ? this.store.peekRecord('station', stationId) : null;

    if (!station) {
      if (stationId != null) {
        const error = this.intl.t('errors.locationNotFound', { id: stationId });
        this.notifier.sendError(error);
      }
      this.router.transitionTo('jem');
      return;
    }

    return pProps({
      mediaDevices: this._loadMediaDevices.perform(),
      station,
      jemNotes: this.store.query('jemNote', { stationId: station.id }),
      logs: this.logRepo.fetchLogsForJem.perform({ stationId: station.id }),
      liveBuildStatuses: this.store.query('liveBuildStatus', { station: station.id, limit: 1 }),
      model: this._getModel.perform(station),
    });
  }

  setupController(controller, { liveBuildStatuses, station, logs, model }) {
    Object.assign(controller, {
      station,
      model,
      totalLogs: logs.meta?.count ?? 0,
    });

    this.controller.onBuildStatusReceived.perform(liveBuildStatuses[0]);
  }

  resetController(controller, isExiting, transition) {
    Object.assign(controller, {
      partContained: false,
      prereqsNotMet: false,
      tempSerialNumber: '',
      bomSourceLookupValue: null,
    });

    if (isExiting) {
      if (transition.to.name !== 'oee.station') {
        this.store.unloadAll('oeeState');
      }
      controller.clearBuildStatus();
    }
  }
}

export default JemStationsRoute;
