import { compact } from 'ramda-adjunct';

export default function cleanHelperArgs(args) {
  args = compact(args);
  const [maybeEvent] = args;
  if (
    maybeEvent !== undefined &&
    typeof maybeEvent.preventDefault === 'function'
  ) {
    maybeEvent.preventDefault();
    args = args.slice(1);
  }

  return args;
}
