import EmberValidator from '@eflexsystems/ember-tracked-validations/-private/ember-validator';

/**
 *  <i class="fa fa-hand-o-right" aria-hidden="true"></i> [See All Options](#method_validate)
 *
 *  Validates that the attributes’ values are not included in a given list. All comparisons are done using strict equality so type matters! For range, the value type is checked against both lower and upper bounds for type equality.
 *
 *  ## Examples:
 *
 *  ```javascript
 *  validator('exclusion', {
 *    in: ['Admin', 'Super Admin']
 *  })
 *  validator('exclusion', {
 *    range: [0, 5] // Cannot be between 0 (inclusive) to 5 (inclusive)
 *  })
 *  ```
 *
 *  @class Exclusion
 *  @module Validators
 *  @extends Base
 */
export default class ValidatorsExclusion extends EmberValidator {
  constructor(...args) {
    super(...args);
    this._evType = 'exclusion';
  }
  static create(props) {
    return new ValidatorsExclusion(props);
  }
}