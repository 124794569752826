var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
import { tracked } from '@glimmer/tracking';

/**
 * @module Validations
 * @class Error
 */
let ValidationsError = (_class = class ValidationsError {
  static create(props) {
    return new ValidationsError(props);
  }
  constructor(props = {}) {
    /**
     * The error validator type
     * @property type
     * @type {String}
     */
    _initializerDefineProperty(this, "type", _descriptor, this);
    /**
     * The error message
     * @property message
     * @type {String}
     */
    _initializerDefineProperty(this, "message", _descriptor2, this);
    /**
     * The attribute that the error belongs to
     * @property attribute
     * @type {String}
     */
    _initializerDefineProperty(this, "attribute", _descriptor3, this);
    /**
     * The parent attribute that the error belongs to
     * @property parentAttribute
     * @type {String}
     */
    _initializerDefineProperty(this, "parentAttribute", _descriptor4, this);
    Object.assign(this, props);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "type", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "message", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "attribute", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "parentAttribute", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { ValidationsError as default };