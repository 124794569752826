import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';

export default class WorkInstructionEditorLibraryApprovalView extends Component {
  @service currentUser;
  @service workInstructionRepo;

  @tracked enlargedImageSrc;
  @tracked approveRejectMode;
  @tracked showApproveRejectModal = false;
  @tracked showDeploymentModal = false;

  get isEditorOnly() {
    return this.workInstructionRepo.userIsEditorOnly(this.currentUser);
  }

  get approvableWorkInstructions() {
    const { user } = this.currentUser;

    if (user.isAdmin) {
      return this.args.selectableWorkInstructions;
    } else if (!isEmpty(user.wieApproverTags) || !isEmpty(user.wieDeployerTags)) {
      return this.args.selectableWorkInstructions.filter((instruction) => {
        return this.workInstructionRepo.userCanApprove(this.currentUser, instruction) ||
          this.workInstructionRepo.userCanDeploy(this.currentUser, instruction);
      });
    } else if (this.isEditorOnly) {
      return this.args.selectableWorkInstructions.filter((instruction) => {
        return this.workInstructionRepo.userCanEdit(this.currentUser, instruction);
      });
    }

    return null;
  }

  get approveRejectModalText() {
    const key = `wieList.approveModal.${this.approveRejectMode}`;

    if (this.args.selectedCards.length === 1) {
      return `${key}.single`;
    } else {
      return `${key}.multiple`;
    }
  }

  get approvalsModified() {
    return this.args.selectedCards.some(item => item.isDirty);
  }

  get selectedAreDeployable() {
    return this.args.oneOrMoreSelected && this.args.selectedCards?.every(item => item.isApproved);
  }

  get canApprove() {
    return this.workInstructionRepo.userCanApproveSelected(this.currentUser, this.args.selectedCards);
  }

  get canDeploy() {
    return this.workInstructionRepo.userCanDeploySelected(this.currentUser, this.args.selectedCards);
  }

  @task
  @waitFor
  *saveApprovals() {
    yield this.workInstructionRepo.saveWithApprovals.linked().perform(this.args.selectedCards);
    this._closeReviewModal();
  }

  @task
  @waitFor
  *approveSelected() {
    yield this.workInstructionRepo.approveAndSave.linked().perform(this.args.selectedCards);
    this._closeReviewModal();
  }

  @task
  @waitFor
  *disapproveSelected() {
    yield this.workInstructionRepo.rejectAndSave.linked().perform(this.args.selectedCards);
    this._closeReviewModal();
  }

  @task
  @waitFor
  *deploy() {
    yield this.workInstructionRepo.deployMany.linked().perform(this.args.selectedCards);
    yield this.args.delistInstructions(this.args.selectedCards);
    this.showDeploymentModal = false;
  }

  @task
  @waitFor
  *openReviewModal(mode) {
    yield this.workInstructionRepo.setPendingApprovalAndSave.perform(
      this.args.selectedCards,
      this.currentUser.user.userName,
    );

    Object.assign(this, {
      approveRejectMode: mode,
      showApproveRejectModal: true,
    });
  }

  @action
  rollbackApprovals() {
    this.args.selectedCards.forEach(selectedCard => { selectedCard.rollbackAttributes(); });
    this._closeReviewModal();
  }

  @action
  enlargeImage(src) {
    if (src != null) {
      this.enlargedImageSrc = src;
    }
  }

  @action
  setApproved(workInstruction) {
    workInstruction.currentApproveReject.approve();
  }

  @action
  setRejected(workInstruction) {
    workInstruction.currentApproveReject.reject();
  }

  @action
  instructionClicked(instruction) {
    if (this.isEditorOnly) {
      return;
    }
    this.args.cardSelected(instruction);
  }

  @action
  instructionDoubleClicked(instruction) {
    if (!this.workInstructionRepo.userCanEdit(this.currentUser, instruction)) {
      return;
    }
    this.args.cardDoubleClick(instruction);
  }

  _closeReviewModal() {
    Object.assign(this, {
      approveRejectMode: null,
      showApproveRejectModal: false,
    });

    this.args.clearSelections();
  }
}
