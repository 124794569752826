import AuthenticatedRoute from 'eflex/util/authenticated-route';
import { inject as service } from '@ember/service';

export default class EvisionCameraRoute extends AuthenticatedRoute {
  @service router;

  beforeModel(transition) {
    if (transition.to.name === 'evision.camera') {
      this.router.replaceWith('evision.camera.status');
    }
  }
}
