import Button from 'ember-bootstrap/components/bs-button';

/**
 Button component with that can act as a dropdown toggler.

 See [Components.Dropdown](Components.Dropdown.html) for examples.

 @class DropdownButton
 @namespace Components
 @extends Components.Button
 @public
 */
export default class DropdownButton extends Button {
  constructor(...args) {
    super(...args);
    this['__ember-bootstrap_subclass'] = true;
  }
}