import OidcAuthenticationRoute from '@eflexsystems/ember-simple-auth-oidc/routes/oidc-authentication';
import OidcSession from 'eflex/services/oidc-session';
import { inject as service } from '@ember/service';
import { getOwner } from '@ember/application';

export default class LoginOidcRoute extends OidcAuthenticationRoute {
  @service session;

  async beforeModel() {
    if (!this.session.isOidc) {
      const owner = getOwner(this);
      owner.unregister('service:session');
      owner.register('service:session', OidcSession);
      this.session = owner.lookup('service:session');
      await this.session.setup();
    }

    return await super.beforeModel(...arguments);
  }
}
