import Model, { attr, belongsTo } from '@ember-data/model';
import { inject as service } from '@ember/service';
import EdhrProcessDataTypes from 'eflex/constants/edhr-process-data-types';
import { arrayAttr, caption } from 'eflex/decorators';

const NO_REPLY = 'NO REPLY';
const OK_RESULTS = new Set(['PASS', 'ACCEPT', NO_REPLY]);

export default class EdhrProcessDatum extends Model {
  @attr('string') processDataType;
  @attr('string') value;
  @attr('string') result;
  @attr('number') index;

  @arrayAttr captions;
  @arrayAttr taskCaptions;

  @belongsTo('buildStatusChild', { async: false, inverse: 'edhrProcessData' }) buildStatusChild;

  @service intl;

  @caption name;
  @caption('taskCaptions') taskName;

  get isOk() {
    return OK_RESULTS.has(this.result);
  }

  get isNoReply() {
    return this.result === NO_REPLY;
  }

  get formattedData() {
    return {
      timestamp: this.buildStatusChild.timestamp,
      displayName: `${this.buildStatusChild.taskName} - ${this.formattedName}`,
      displayValue: this.result,
      displayClass: this.displayClass,
    };
  }

  get formattedName() {
    const boltTranslation = this.intl.t('bolt');
    const torqueTranslation = this.intl.t('torque');
    const angleTranslation = this.intl.t('angle');

    let displayName = [];

    if (this.taskName) {
      displayName.push(this.taskName);
    } else {
      displayName.push(this.intl.t(this.processDataType));
    }

    if (this.name) {
      displayName.push(this.name);
    } else if (this.processDataType === EdhrProcessDataTypes.boltTorque) {
      displayName.push(`${boltTranslation} ${this.index} ${torqueTranslation}`);
    } else if (this.processDataType === EdhrProcessDataTypes.boltAngle) {
      displayName.push(`${boltTranslation} ${this.index} ${angleTranslation}`);
    } else if (this.taskName) {
      displayName.push(this.intl.t(this.processDataType));
    }

    return displayName.join(' - ');
  }

  get displayClass() {
    if (this.isNoReply) {
      return 'not-required';
    } else if (this.isOk) {
      return 'good';
    } else {
      return 'bad';
    }
  }
}
