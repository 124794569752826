import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import TaskStatuses from 'eflex/constants/task-statuses';

export default class JemAuthPanel extends Component {
  @service eflexAjax;
  @service notifier;
  @service intl;

  @dropTask
  *authorize(badgeId) {
    let authorizingUser;

    if (this.args.childStatus.parent.isHolding) {
      return;
    }

    try {
      ({ authorizingUser } = yield this.eflexAjax.post.perform('jemAuthorize', {
        jemAuthorize: {
          username: this.username,
          password: this.password,
          badgeId,
          tags: [...this.args.jemAuthorizedTags],
        },
      }));
    } catch (e) {
      const message = this.intl.t(e.message);
      this.notifier.sendError('taskAuthorizationFailed', { error: message });
      return;
    }

    this.args.childStatus.authorizedAt = new Date();
    this.args.childStatus.authorizedBy = authorizingUser;
    this.notifier.sendSuccess('taskAuthorized');
    this.args.completeTask(TaskStatuses.AUTHORIZED, this.args.childStatus);
    this.args.onAuthSuccess();
  }
}
