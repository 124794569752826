import Model, { attr, belongsTo } from '@ember-data/model';
import { copyable } from 'eflex/decorators';
import VariableMappingValidations from 'eflex/validations/variable-mapping';
import { buildValidations } from '@eflexsystems/ember-tracked-validations';
import { rollbackBelongsTo, isBelongsToRefDirty } from 'eflex/util/relationship-helpers';

@copyable
@buildValidations(VariableMappingValidations)
class VariableMapping extends Model {
  @attr('string') name;
  @attr value;

  @belongsTo('variableDef', { async: false, inverse: 'variableMappings' }) variableDefForValue;
  @belongsTo('jemProcessDataDef', { async: false, inverse: 'variableMappings' }) jemProcessDataDefForValue;

  get isDirty() {
    return super.isDirty ||
      isBelongsToRefDirty(this, 'variableDefForValue') ||
      isBelongsToRefDirty(this, 'jemProcessDataDefForValue');
  }

  get selectedValue() {
    return this.variableDefForValue ?? this.jemProcessDataDefForValue ?? this.valueForOptions;
  }

  get valueForOptions() {
    if (this.value != null) {
      return { name: this.value };
    } else {
      return '';
    }
  }

  get copyableOptions() {
    return {
      copyByReference: new Set(['variableDefForValue', 'jemProcessDataDefForValue']),
    };
  }

  rollbackAttributes() {
    rollbackBelongsTo(this, 'variableDefForValue');
    rollbackBelongsTo(this, 'jemProcessDataDefForValue');
    super.rollbackAttributes();
  }
}

export default VariableMapping;
