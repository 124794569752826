import { VIDEO_EXTENSIONS } from 'eflex/constants/allowed-file-upload-types';
import getUrlType from 'eflex/util/get-url-type';
import IMAGE_EXTENSIONS from 'eflex/constants/image-extensions';
import THREED_EXTENSIONS from 'eflex/constants/threed-extensions';
import Component from '@glimmer/component';
import { trackedFunction } from 'ember-resources/util/function';

export default class UrlViewer extends Component {
  type = trackedFunction(this, async () => {
    const url = this.args.url;

    if (url == null) {
      return;
    }

    return await getUrlType(url);
  });

  get isImage() {
    return Object.values(IMAGE_EXTENSIONS).some(extension =>
      this.type.value.includes(extension),
    );
  }

  get isPdf() {
    return this.type.value.includes('pdf');
  }

  get is3d() {
    return Object.values(THREED_EXTENSIONS).some(extension =>
      this.type.value.includes(extension),
    );
  }

  get isSvg() {
    return this.type.value.includes('svg');
  }

  get isVideo() {
    return VIDEO_EXTENSIONS.some(videoType =>
      this.type.value.includes(videoType),
    );
  }
}
