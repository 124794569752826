import { checkUniqueField } from 'eflex/util/validators';
import { isBlank } from '@ember/utils';
import { getOwner } from '@ember/application';

export default function (model, siblings) {
  if (isBlank(model.fullAddress)) {
    return true;
  }

  const hardware = siblings.filter(item => !item.isDeleted);
  const conflicting = checkUniqueField(model, hardware, 'fullAddress');

  if (conflicting.duplicate) {
    return getOwner(model).lookup('service:intl').t('station.ipAndPortAreInUse', { item: conflicting.name });
  }

  return true;
}
