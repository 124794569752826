import Model, { attr, hasMany } from '@ember-data/model';
import CodeValidations from 'eflex/validations/code';
import { isEmpty } from '@ember/utils';
import { buildValidations } from '@eflexsystems/ember-tracked-validations';
import { isHasManyRefDirty, rollbackHasMany } from 'eflex/util/relationship-helpers';

@buildValidations(CodeValidations)
class Code extends Model {
  @attr('string') code;
  @attr('string') description;

  @hasMany('station', {
    async: false,
    inverse: 'codes',
    as: 'code',
  }) stations;

  get polymorphic() {
    return true;
  }

  get isDirty() {
    return super.isDirty || isHasManyRefDirty(this, 'stations');
  }

  get isGlobal() {
    return isEmpty(this.stations);
  }

  get stationIds() {
    return this.stations?.map(item => item.id) ?? [];
  }

  get displayName() {
    if (this.code == null || this.description == null) {
      return '';
    } else {
      return `${this.code} - ${this.description}`;
    }
  }

  rollbackAttributes() {
    rollbackHasMany(this, 'stations');
    super.rollbackAttributes();
  }
}

export default Code;
