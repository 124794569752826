import ComponentOption from 'eflex/models/component-option';
import SimpleOptionValidations from 'eflex/validations/simple-component-option';
import { attr } from '@ember-data/model';
import { buildValidations } from '@eflexsystems/ember-tracked-validations';

@buildValidations(SimpleOptionValidations)
class SimpleComponentOption extends ComponentOption {
  @attr('string', { defaultValue: '' }) value;

  get isSimple() {
    return true;
  }
}

export default SimpleComponentOption;
