import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import moment from 'moment-timezone';
import { tracked } from '@glimmer/tracking';
import JwtSession from 'eflex/services/session';
import { getOwner } from '@ember/application';
import { waitFor } from '@ember/test-waiters';

export default class LoginController extends Controller {
  @service session;
  @service intl;
  @service systemConfig;

  @tracked password;
  @tracked errorMessage;
  @tracked username;

  @task
  @waitFor
  *_sendLoginRequest(credentials) {
    credentials.timezone = moment.tz.guess();
    credentials.clientTime = moment().toDate();

    try {
      yield this.session.authenticate('authenticator:jwt', credentials);
    } catch (e) {
      if (!window.isTesting) {
        console.error(e);
      }
      let errorMessage;

      if (!e.json?.error?.message) {
        errorMessage = e.message;
      } else {
        errorMessage = e.json.error.message;
        if (this.intl.exists(errorMessage)) {
          errorMessage = this.intl.t(errorMessage);
        }
      }

      Object.assign(this, {
        password: null,
        errorMessage,
      });
    }
  }

  @task
  @waitFor
  *badgeScan(badgeId) {
    yield this._sendLoginRequest.perform({ badgeId });
  }

  @task
  @waitFor
  *login() {
    if (this.session.isOidc) {
      const owner = getOwner(this);
      owner.unregister('service:session');
      owner.register('service:session', JwtSession);
      this.session = owner.lookup('service:session');
      yield this.session.setup();
    }

    yield this._sendLoginRequest.perform({
      username: this.username,
      password: this.password,
    });
  }
}
