import { anyInvalid, anyTreeItemDirty } from 'eflex/util/getter-helpers';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { cached, tracked } from '@glimmer/tracking';
import { rollbackBelongsTo } from 'eflex/util/relationship-helpers';
import { arrayAttr, caption } from 'eflex/decorators';

@buildValidations({
  modelCodeLength: [
    validator('number', {
      integer: true,
      gte: 1,
      allowString: true,
      allowNone: false,
    }),
  ],

  modelCodeStart: [
    validator('number', {
      integer: true,
      gte: 1,
      allowString: true,
      allowNone: false,
    }),
  ],
})
class Area extends Model {
  @attr('string') path;
  @attr('string') parent;
  @attr('string', { defaultValue: 'area' }) type;
  @attr('boolean', { defaultValue: true }) oeeEnabled;
  @attr('boolean', { defaultValue: true }) evisionEnabled;
  @attr('boolean', { defaultValue: false }) usesComponents;
  @attr('boolean', { defaultValue: false }) advancedModelCodeEnabled;
  @attr('boolean', { defaultValue: false }) usesJobs;
  @attr('number', { defaultValue: 1 }) modelCodeStart;
  @attr('number', { defaultValue: 1 }) modelCodeLength;
  @attr('number', { defaultValue: 0 }) order;

  @arrayAttr captions;
  @caption name;

  @belongsTo('bomSource', { async: false, polymorphic: true, inverse: 'areas' }) bomSource;
  @belongsTo('bomVariable', { async: false, inverse: null }) jemDisplayVariable;

  @hasMany('model', { async: false, inverse: 'area' }) models;
  @hasMany('oeeButton', { async: false, inverse: 'area' }) oeeButtons;
  @hasMany('group', { inverse: 'parent', async: false, cascadeDelete: true }) groups;
  @hasMany('customIdentifier', { inverse: 'area', async: false, embedded: true }) customIdentifiers;

  @tracked isChecked = false;

  get isDirty() {
    return super.isDirty || this.customIdentifiers.some(item => item.isDirty);
  }

  get area() {
    return this;
  }

  get childType() {
    return 'group';
  }

  get treeIconClass() {
    return 'icon-area';
  }

  get stations() {
    return this.children.flatMap(group => group.children);
  }

  get tasks() {
    return this.stations.flatMap(station => station.children);
  }

  @cached
  get children() {
    return this.groups.filter(item => !item.isDeleted);
  }

  get usesModels() {
    return !this.usesComponents && !this.usesJobs;
  }

  get allChildren() {
    return this.groups;
  }

  @cached
  get isSelfOrChildInvalid() {
    return this.isInvalid ||
      this.isChildInvalid ||
      (this.usesComponents && this.bomSource?.isSelfOrChildInvalid) ||
      anyInvalid(this.customIdentifiers);
  }

  @cached
  get isChildInvalid() {
    return anyInvalid(this.children);
  }

  @cached
  get isSelfOrChildDirty() {
    return this.isDirty || anyTreeItemDirty(this.groups);
  }

  get validatedRelationships() {
    return ['groups'];
  }

  get alwaysRunComponent() {
    return this.bomSource?.components?.find(item => item.isAlwaysRun);
  }

  rollbackAttributes() {
    rollbackBelongsTo(this, 'bomSource');
    this.groups.forEach(group => { group.rollbackAttributes(); });
    super.rollbackAttributes();
  }
}

export default Area;
