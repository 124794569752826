import Trigger from 'eflex/models/trigger';
import { anyInvalid } from 'eflex/util/getter-helpers';
import { hasMany, attr } from '@ember-data/model';
import NodeRedTriggerValidations from 'eflex/validations/node-red-trigger';
import { buildValidations } from '@eflexsystems/ember-tracked-validations';

@buildValidations(NodeRedTriggerValidations)
class NodeRedTrigger extends Trigger {
  @attr('string') mqttTopic;

  @hasMany('variableMapping', { async: false, inverse: null, embedded: true }) variableMappings;

  get isDirty() {
    return super.isDirty || this.variableMappings.some(item => item.isDirty);
  }

  get variableMappingsInvalid() {
    return anyInvalid(this.variableMappings);
  }

  get isSelfOrChildInvalid() {
    return this.isInvalid || this.variableMappingsInvalid;
  }

  get validatedRelationships() {
    return ['variableMappings'];
  }

  get copyableOptions() {
    const options = super.copyableOptions;
    options.ignoreAttributes.add('variableMappings');
    return options;
  }
}

export default NodeRedTrigger;
