var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26;
function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
import { tagName } from '@ember-decorators/component';
import { observes } from '@ember-decorators/object';
import { action, computed } from '@ember/object';
import { equal, filter, gt, lte, readOnly } from '@ember/object/computed';
import CarouselSlide from 'ember-bootstrap/components/bs-carousel/slide';
import Component from '@ember/component';
import ComponentParent from 'ember-bootstrap/mixins/component-parent';
import { schedule, scheduleOnce } from '@ember/runloop';
import { task, timeout } from 'ember-concurrency';
import RSVP from 'rsvp';
import defaultValue from 'ember-bootstrap/utils/default-decorator';
import deprecateSubclassing from 'ember-bootstrap/utils/deprecate-subclassing';

/**
  Ember implementation of Bootstrap's Carousel. Supports all original features but API is partially different:

  | Description | Original | Component |
  | ------ | ------ | ------ |
  | Autoplays after first user event or on page load. | ride='carousel'\|false | autoPlay=false\|true |
  | Disable automatic cycle. | interval=false | interval=0 |
  | If first slide should follow last slide on "previous" event, the opposite will also be true for "next" event. | wrap=false\|true | wrap=false\|true |
  | Jumps into specific slide index | data-slide-to=n | index=n |
  | Keyboard events. | keyboard=false\|true | keyboard=false\|true |
  | Left-to-right or right-to-left sliding. | N/A |  ltr=false\|true |
  | Pause current cycle on mouse enter. | pause='hover'\|null | pauseOnMouseEnter=false\|true |
  | Show or hide controls  | Tag manipulation. | showControls=false\|true |
  | Show or hide indicators  | Tag manipulation. | showIndicators=false\|true |
  | Waiting time of slides in an automatic cycle. | interval=n | interval=n |

  Default settings are the same as the original, so you don't have to worry about changing parameters.

  ```hbs
  <BsCarousel as |car|>
    <car.slide>
      <img alt="First slide" src="slide1.jpg">
    </car.slide>
    <car.slide>
      <img alt="Second slide" src="slide2.jpg">
    </car.slide>
    <car.slide>
      <img alt="Third slide" src="slide3.jpg">
    </car.slide>
  </BsCarousel>
  ```

  To better understand the whole documentation, you should be aware of the following operations:

  | Operation | Description |
  | ------ | ------ |
  | Transition | Swaps two slides. |
  | Interval | Waiting time after a transition. |
  | Presentation | Represents a single transition, or a single interval, or the union of both. |
  | Cycle | Presents all slides until it reaches first or last slide. |
  | Wrap | wrap slides, cycles without stopping at first or last slide. |

  *Note that only invoking the component in a template as shown above is considered part of its public API. Extending from it (subclassing) is generally not supported, and may break at any time.*

  @class Carousel
  @namespace Components
  @extends Ember.Component
  @public
*/
let Carousel = (_dec = tagName(''), _dec2 = computed('wrap', 'currentIndex'), _dec3 = computed('childSlides.length', 'wrap', 'currentIndex'), _dec4 = filter('children', function (view) {
  return view instanceof CarouselSlide;
}).readOnly(), _dec5 = observes('childSlides.[]', 'autoPlay'), _dec6 = computed('childSlides', 'currentIndex').readOnly(), _dec7 = computed('childSlides', 'followingIndex').readOnly(), _dec8 = gt('interval', 0).readOnly(), _dec9 = observes('index'), _dec10 = computed('childSlides.length'), _dec11 = lte('childSlides.length', 1), _dec12 = readOnly('hasInterval'), _dec13 = equal('transition', 'fade').readOnly(), _dec14 = task(function* () {
  yield this.transitioner.perform();
  yield timeout(this.interval);
  this.toAppropriateSlide();
}).restartable(), _dec15 = task(function* () {
  this.set('presentationState', 'willTransit');
  yield timeout(this.transitionDuration);
  this.set('presentationState', 'didTransition');
  // Must change current index after execution of 'presentationStateObserver' method
  // from child components.
  yield new RSVP.Promise(resolve => {
    schedule('afterRender', this, function () {
      this.set('currentIndex', this.followingIndex);
      resolve();
    });
  });
}).drop(), _dec16 = task(function* () {
  if (this.shouldRunAutomatically === false) {
    return;
  }
  yield timeout(this.interval);
  this.toAppropriateSlide();
}).restartable(), _dec(_class = deprecateSubclassing(_class = (_class2 = class Carousel extends Component.extend(ComponentParent) {
  constructor(...args) {
    super(...args);
    this.tabindex = '1';
    /**
     * All `CarouselSlide` child components.
     *
     * @private
     * @property childSlides
     * @readonly
     * @type array
     */
    _initializerDefineProperty(this, "childSlides", _descriptor, this);
    /**
     * Indicates the current index of the current slide.
     *
     * @property currentIndex
     * @private
     */
    _initializerDefineProperty(this, "currentIndex", _descriptor2, this);
    /**
     * Bootstrap style to indicate that a given slide should be moving to left/right.
     *
     * @property directionalClassName
     * @private
     * @type string
     */
    _initializerDefineProperty(this, "directionalClassName", _descriptor3, this);
    /**
     * Indicates the next slide index to move into.
     *
     * @property followingIndex
     * @private
     * @type number
     */
    _initializerDefineProperty(this, "followingIndex", _descriptor4, this);
    /**
     * @private
     * @property hasInterval
     * @type boolean
     */
    _initializerDefineProperty(this, "hasInterval", _descriptor5, this);
    /**
     * If user is hovering its cursor on component.
     * This property is only manipulated when 'pauseOnMouseEnter' is true.
     *
     * @property isMouseHovering
     * @private
     * @type boolean
     */
    _initializerDefineProperty(this, "isMouseHovering", _descriptor6, this);
    /**
     * The class name to append to the next control link element.
     *
     * @property nextControlClassName
     * @type string
     * @private
     */
    /**
     * Bootstrap style to indicate the next/previous slide.
     *
     * @property orderClassName
     * @private
     * @type string
     */
    /**
     * The current state of the current presentation, can be either "didTransition"
     * or "willTransit".
     *
     * @private
     * @property presentationState
     * @type string
     */
    _initializerDefineProperty(this, "presentationState", _descriptor7, this);
    /**
     * The class name to append to the previous control link element.
     *
     * @property prevControlClassName
     * @type string
     * @private
     */
    /**
     * @private
     * @property shouldNotDoPresentation
     * @type boolean
     */
    _initializerDefineProperty(this, "shouldNotDoPresentation", _descriptor8, this);
    /**
     * @private
     * @property shouldRunAutomatically
     * @type boolean
     */
    _initializerDefineProperty(this, "shouldRunAutomatically", _descriptor9, this);
    /**
     * Starts automatic sliding on page load.
     * This parameter has no effect if interval is less than or equal to zero.
     *
     * @default false
     * @property autoPlay
     * @public
     * @type boolean
     */
    _initializerDefineProperty(this, "autoPlay", _descriptor10, this);
    /**
     * If false will hard stop on corners, i.e., first slide won't make a transition to the
     * last slide and vice versa.
     *
     * @default true
     * @property wrap
     * @public
     * @type boolean
     */
    _initializerDefineProperty(this, "wrap", _descriptor11, this);
    /**
     * Index of starting slide.
     *
     * @default 0
     * @property index
     * @public
     * @type number
     */
    _initializerDefineProperty(this, "index", _descriptor12, this);
    /**
     * Waiting time before automatically show another slide.
     * Automatic sliding is canceled if interval is less than or equal to zero.
     *
     * @default 5000
     * @property interval
     * @public
     * @type number
     */
    _initializerDefineProperty(this, "interval", _descriptor13, this);
    /**
     * Should bind keyboard events into sliding.
     *
     * @default true
     * @property keyboard
     * @public
     * @type boolean
     */
    _initializerDefineProperty(this, "keyboard", _descriptor14, this);
    /**
     * If automatic sliding should be left-to-right or right-to-left.
     * This parameter has no effect if interval is less than or equal to zero.
     *
     * @default true
     * @property ltr
     * @public
     * @type boolean
     */
    _initializerDefineProperty(this, "ltr", _descriptor15, this);
    /**
     * The next control icon to be displayed.
     *
     * @default null
     * @property nextControlIcon
     * @type string
     * @public
     */
    /**
     * Label for screen readers, defaults to 'Next'.
     *
     * @default 'Next'
     * @property nextControlLabel
     * @type string
     * @public
     */
    _initializerDefineProperty(this, "nextControlLabel", _descriptor16, this);
    /**
     * Pauses automatic sliding if mouse cursor is hovering the component.
     * This parameter has no effect if interval is less than or equal to zero.
     *
     * @default true
     * @property pauseOnMouseEnter
     * @public
     * @type boolean
     */
    _initializerDefineProperty(this, "pauseOnMouseEnter", _descriptor17, this);
    /**
     * The previous control icon to be displayed.
     *
     * @default null
     * @property prevControlIcon
     * @type string
     * @public
     */
    /**
     * Label for screen readers, defaults to 'Previous'.
     *
     * @default 'Previous'
     * @property prevControlLabel
     * @type string
     * @public
     */
    _initializerDefineProperty(this, "prevControlLabel", _descriptor18, this);
    /**
     * Show or hide controls.
     *
     * @default true
     * @property showControls
     * @public
     * @type boolean
     */
    _initializerDefineProperty(this, "showControls", _descriptor19, this);
    /**
     * Show or hide indicators.
     *
     * @default true
     * @property showIndicators
     * @public
     * @type boolean
     */
    _initializerDefineProperty(this, "showIndicators", _descriptor20, this);
    /**
     * The duration of the slide transition.
     * You should also change this parameter in Bootstrap CSS file.
     *
     * @default 600
     * @property transitionDuration
     * @public
     * @type number
     */
    _initializerDefineProperty(this, "transitionDuration", _descriptor21, this);
    /**
     * The type slide transition to perform.
     * Options are 'fade' or 'slide'. Note: BS4 only
     *
     * @default 'slide'
     * @property transition
     * @public
     * @type string
     */
    _initializerDefineProperty(this, "transition", _descriptor22, this);
    _initializerDefineProperty(this, "carouselFade", _descriptor23, this);
    // eslint-disable-line no-unused-vars
    /**
     * Do a presentation and calls itself to perform a cycle.
     *
     * @method cycle
     * @private
     */
    _initializerDefineProperty(this, "cycle", _descriptor24, this);
    /**
     * @method transitioner
     * @private
     */
    _initializerDefineProperty(this, "transitioner", _descriptor25, this);
    /**
     * Waits an interval time to start a cycle.
     *
     * @method waitIntervalToInitCycle
     * @private
     */
    _initializerDefineProperty(this, "waitIntervalToInitCycle", _descriptor26, this);
  }
  /**
   * @property slideComponent
   * @type {String}
   * @private
   */
  /**
   * If a slide can turn to left, including corners.
   *
   * @private
   * @property canTurnToLeft
   */
  get canTurnToLeft() {
    return this.wrap || this.currentIndex > 0;
  }

  /**
   * If a slide can turn to right, including corners.
   *
   * @private
   * @property canTurnToRight
   */
  get canTurnToRight() {
    return this.wrap || this.currentIndex < this.childSlides.length - 1;
  }
  /**
   * This observer is the entry point for real time insertion and removing of slides.
   *
   * @private
   * @property childSlidesObserver
   */
  childSlidesObserver() {
    scheduleOnce('actions', this, this._childSlidesObserver);
  }
  _childSlidesObserver() {
    let childSlides = this.childSlides;
    if (childSlides.length === 0) {
      return;
    }
    // Sets new current index
    let currentIndex = this.currentIndex;
    if (currentIndex >= childSlides.length) {
      currentIndex = childSlides.length - 1;
      this.set('currentIndex', currentIndex);
    }
    // Automatic sliding
    if (this.autoPlay) {
      this.waitIntervalToInitCycle.perform();
    }
    // Initial slide state
    this.set('presentationState', null);
  }
  /**
   * The current slide object that is going to be used by the nested slides components.
   *
   * @property currentSlide
   * @private
   *
   */
  get currentSlide() {
    return this.childSlides.objectAt(this.currentIndex);
  }
  /**
   * The following slide object that is going to be used by the nested slides components.
   *
   * @property followingIndex
   * @private
   */
  get followingSlide() {
    return this.childSlides.objectAt(this.followingIndex);
  }
  /**
   * This observer is the entry point for programmatically slide changing.
   *
   * @property indexObserver
   * @private
   */
  indexObserver() {
    this.send('toSlide', this.index);
  }

  /**
   * @property indicators
   * @private
   */
  get indicators() {
    return [...Array(this.childSlides.length)];
  }
  /**
   * Action called after the slide has changed.
   *
   * @event onSlideChanged
   * @param toIndex
   * @public
   */
  onSlideChanged(toIndex) {}
  toSlide(toIndex) {
    if (this.currentIndex === toIndex || this.shouldNotDoPresentation) {
      return;
    }
    this.assignClassNameControls(toIndex);
    this.setFollowingIndex(toIndex);
    if (this.shouldRunAutomatically === false || this.isMouseHovering) {
      this.transitioner.perform();
    } else {
      this.cycle.perform();
    }
    this.onSlideChanged(toIndex);
  }
  toNextSlide() {
    if (this.canTurnToRight) {
      this.send('toSlide', this.currentIndex + 1);
    }
  }
  toPrevSlide() {
    if (this.canTurnToLeft) {
      this.send('toSlide', this.currentIndex - 1);
    }
  }

  /**
   * Indicates what class names should be applicable to the current transition slides.
   *
   * @method assignClassNameControls
   * @private
   */
  assignClassNameControls(toIndex) {
    if (toIndex < this.currentIndex) {
      this.set('directionalClassName', 'right');
      this.set('orderClassName', 'prev');
    } else {
      this.set('directionalClassName', 'left');
      this.set('orderClassName', 'next');
    }
  }

  /**
   * Initial page loading configuration.
   */
  // eslint-disable-next-line ember/no-component-lifecycle-hooks
  didInsertElement() {
    super.didInsertElement(...arguments);
    this.triggerChildSlidesObserver();
  }
  handleMouseEnter() {
    if (this.pauseOnMouseEnter) {
      this.set('isMouseHovering', true);
      this.cycle.cancelAll();
      this.waitIntervalToInitCycle.cancelAll();
    }
  }
  handleMouseLeave() {
    if (this.pauseOnMouseEnter && (this.transitioner.last !== null || this.waitIntervalToInitCycle.last !== null)) {
      this.set('isMouseHovering', false);
      this.waitIntervalToInitCycle.perform();
    }
  }
  handleKeyDown(e) {
    let code = e.keyCode || e.which;
    if (this.keyboard === false || /input|textarea/i.test(e.target.tagName)) {
      return;
    }
    switch (code) {
      case 37:
        this.send('toPrevSlide');
        break;
      case 39:
        this.send('toNextSlide');
        break;
      default:
        break;
    }
  }

  /**
   * Sets the following slide index within the lower and upper bounds.
   *
   * @method setFollowingIndex
   * @private
   */
  setFollowingIndex(toIndex) {
    let slidesLengthMinusOne = this.childSlides.length - 1;
    if (toIndex > slidesLengthMinusOne) {
      this.set('followingIndex', 0);
    } else if (toIndex < 0) {
      this.set('followingIndex', slidesLengthMinusOne);
    } else {
      this.set('followingIndex', toIndex);
    }
  }

  /**
   * Coordinates the correct slide movement direction.
   *
   * @method toAppropriateSlide
   * @private
   */
  toAppropriateSlide() {
    if (this.ltr) {
      this.send('toNextSlide');
    } else {
      this.send('toPrevSlide');
    }
  }

  /**
   * @method triggerChildSlidesObserver
   * @private
   */
  triggerChildSlidesObserver() {
    this.childSlides;
  }
}, (_applyDecoratedDescriptor(_class2.prototype, "canTurnToLeft", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "canTurnToLeft"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "canTurnToRight", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "canTurnToRight"), _class2.prototype), _descriptor = _applyDecoratedDescriptor(_class2.prototype, "childSlides", [_dec4], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class2.prototype, "childSlidesObserver", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "childSlidesObserver"), _class2.prototype), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "currentIndex", [defaultValue], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return this.index;
  }
}), _applyDecoratedDescriptor(_class2.prototype, "currentSlide", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "currentSlide"), _class2.prototype), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "directionalClassName", [defaultValue], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return null;
  }
}), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "followingIndex", [defaultValue], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return null;
  }
}), _applyDecoratedDescriptor(_class2.prototype, "followingSlide", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "followingSlide"), _class2.prototype), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "hasInterval", [_dec8], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class2.prototype, "indexObserver", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "indexObserver"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "indicators", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "indicators"), _class2.prototype), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "isMouseHovering", [defaultValue], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "presentationState", [defaultValue], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return null;
  }
}), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "shouldNotDoPresentation", [_dec11], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "shouldRunAutomatically", [_dec12], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "autoPlay", [defaultValue], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor11 = _applyDecoratedDescriptor(_class2.prototype, "wrap", [defaultValue], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return true;
  }
}), _descriptor12 = _applyDecoratedDescriptor(_class2.prototype, "index", [defaultValue], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return 0;
  }
}), _descriptor13 = _applyDecoratedDescriptor(_class2.prototype, "interval", [defaultValue], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return 5000;
  }
}), _descriptor14 = _applyDecoratedDescriptor(_class2.prototype, "keyboard", [defaultValue], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return true;
  }
}), _descriptor15 = _applyDecoratedDescriptor(_class2.prototype, "ltr", [defaultValue], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return true;
  }
}), _descriptor16 = _applyDecoratedDescriptor(_class2.prototype, "nextControlLabel", [defaultValue], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return 'Next';
  }
}), _descriptor17 = _applyDecoratedDescriptor(_class2.prototype, "pauseOnMouseEnter", [defaultValue], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return true;
  }
}), _descriptor18 = _applyDecoratedDescriptor(_class2.prototype, "prevControlLabel", [defaultValue], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return 'Previous';
  }
}), _descriptor19 = _applyDecoratedDescriptor(_class2.prototype, "showControls", [defaultValue], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return true;
  }
}), _descriptor20 = _applyDecoratedDescriptor(_class2.prototype, "showIndicators", [defaultValue], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return true;
  }
}), _descriptor21 = _applyDecoratedDescriptor(_class2.prototype, "transitionDuration", [defaultValue], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return 600;
  }
}), _descriptor22 = _applyDecoratedDescriptor(_class2.prototype, "transition", [defaultValue], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return 'slide';
  }
}), _descriptor23 = _applyDecoratedDescriptor(_class2.prototype, "carouselFade", [_dec13], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor24 = _applyDecoratedDescriptor(_class2.prototype, "cycle", [_dec14], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor25 = _applyDecoratedDescriptor(_class2.prototype, "transitioner", [_dec15], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor26 = _applyDecoratedDescriptor(_class2.prototype, "waitIntervalToInitCycle", [_dec16], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class2.prototype, "toSlide", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "toSlide"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toNextSlide", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "toNextSlide"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toPrevSlide", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "toPrevSlide"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleMouseEnter", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleMouseEnter"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleMouseLeave", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleMouseLeave"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleKeyDown", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleKeyDown"), _class2.prototype)), _class2)) || _class) || _class);
export { Carousel as default };