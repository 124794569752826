import moment from 'moment-timezone';
import { helper } from '@ember/component/helper';

function formatCycleTime([value]) {
  if (value == null) {
    return;
  }

  value = parseInt(value);
  return moment.duration(value).format('m[m] s[s]', 1);
}

export { formatCycleTime };
export default helper(formatCycleTime);
