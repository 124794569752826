import { defaultModules, defaults, defaultStack } from '@pnotify/core';
import * as PNotifyBootstrap from '@pnotify/bootstrap4';
import * as PNotifyFontAwesome5Fix from '@pnotify/font-awesome5-fix';
import * as PNotifyFontAwesome5 from '@pnotify/font-awesome5';

defaultModules.set(PNotifyBootstrap, {});
defaultModules.set(PNotifyFontAwesome5Fix, {});
defaultModules.set(PNotifyFontAwesome5, {});

defaults.delay = 2000;

defaultStack.maxOpen = Infinity;
defaultStack.modal = false;

window.disablePnotify = function() {
  defaults.autoOpen = false;
};
