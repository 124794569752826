import { inject as service } from '@ember/service';
import { AnimationTypeValues } from 'eflex/constants/jem/instruction-animation-types';
import { AnimationEventValues } from 'eflex/constants/jem/instruction-animation-events';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { reject } from 'ramda';

export default class JemInstructionsAnimation extends Component {
  @service store;
  @service intl;

  boltEvents = AnimationEventValues;
  animations = AnimationTypeValues;

  get currentAnimations() {
    return reject(animation =>
      (animation.bolt !== 'all' && parseInt(animation.bolt) > parseInt(this.args.boltCount)) || animation.isDeleted,
    )(this.args.triggerConfig.animations);
  }

  get bolts() {
    const bolts = [
      {
        label: this.intl.t('jem.animations.allBolts'),
        value: 'all',
      },
    ];
    for (let i = 1; i <= this.args.boltCount; i++) {
      bolts.push({
        label: `${this.intl.t('bolt')} ${i}`,
        value: `${i}`,
      });
    }

    return bolts;
  }

  get instructionObjects() {
    return this.args.triggerConfig.workInstruction.getDynamicObjectNames();
  }

  @action
  addAnimation() {
    const animation = this.store.createRecord('workInstructionAnimation', {
      object: this.instructionObjects[0].id,
      event: this.boltEvents[0],
      animation: this.animations[0],
      bolt: this.bolts[0].value,
    });

    this.args.triggerConfig.animations.push(animation);
  }

  @action
  deleteAnimation(animation) {
    animation.deleteRecord();
  }

  @action
  setAnimationObject(animationConfig, object) {
    animationConfig.object = object?.id;
  }

  @action
  toggleColors(animationConfig, property, checked) {
    animationConfig[property] = checked ? '#000000' : null;
  }
}
