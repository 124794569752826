import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { without } from 'ramda';

const SerialNumberTransferHardware = ['plc-hardware', 'datalogic', 'keyence', 'node-red'];

export default class TaskDeviceConfigurationSerialNumberTransfer extends Component {
  @service hardwareRepo;
  @service licensing;

  get hardwares() {
    return this.hardwareRepo.hardwareByType(
      without([this.licensing.license.disabledHardware], SerialNumberTransferHardware),
    );
  }
}
