import Model, { belongsTo, hasMany, attr } from '@ember-data/model';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { copyable, caption, arrayAttr } from 'eflex/decorators';

@copyable
@buildValidations({
  name: [validator('presence', true)],
})
class JemProcessDataDef extends Model {
  @attr('string', { defaultValue: 'float' }) type;
  @arrayAttr captions;
  @arrayAttr unitCaptions;
  @arrayAttr falseUnitCaptions;
  @caption name;
  @caption('unitCaptions') unit;
  @caption('falseUnitCaptions') falseUnit;

  @belongsTo('task', { async: false, inverse: 'jemProcessDataDefs' }) task;

  @hasMany('edhrMapping', { async: false, inverse: 'jemProcessDataDef' }) edhrMappings;

  @hasMany('jemProcessDataDefConfig', {
    async: false,
    inverse:
    'jemProcessDataDef',
    cascadeDelete: true,
  }) jemProcessDataDefConfigs;

  @hasMany('variableMapping', {
    async: false,
    inverse: 'jemProcessDataDefForValue',
    cascadeDelete: true,
  }) variableMappings;

  @hasMany('variableDef', {
    async: false,
    inverse: 'jemProcessDataDefForValue',
    cascadeDelete: true,
  }) variableDefsWithReference;

  get taskName() {
    return this.task?.name;
  }

  get errorIdentifier() {
    return this.taskName;
  }

  get copyableOptions() {
    return {
      ignoreAttributes: new Set(['task', 'edhrMappings', 'jemProcessDataDefConfigs', 'variableMappings']),
    };
  }
}

export default JemProcessDataDef;
