import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import getDelayTime from 'eflex/util/get-delay-time';
import $ from 'jquery';
import { all, task, timeout } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';

export default class WorkInstructionEditorLibraryThumbView extends Component {
  @service workInstructionRepo;
  @service currentUser;

  @tracked isDragging = false;

  scrollingActive = false;
  #scrollContainerSelector = '.library-scroll-container';

  scrollTime = getDelayTime(600);

  @task
  @waitFor
  *_dragScroll(scrollAmount) {
    while (this.scrollingActive) {
      // eslint-disable-next-line ember/no-jquery
      $(this.#scrollContainerSelector).animate({ scrollTop: scrollAmount }, this.scrollTime);
      yield timeout(this.scrollTime);
    }
  }

  @task({ drop: true })
  @waitFor
  *scrollDown() {
    this.scrollingActive = true;
    yield this._dragScroll.perform('+=300px');
  }

  @task({ drop: true })
  @waitFor
  *scrollUp() {
    this.scrollingActive = true;
    yield this._dragScroll.perform('-=300px');
  }

  @task
  @waitFor
  *stopScroll() {
    this.scrollingActive = false;
    yield all([
      this._dragScroll.cancelAll({ resetState: true }),
      this.scrollDown.cancelAll({ resetState: true }),
      this.scrollUp.cancelAll({ resetState: true }),
    ]);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    // eslint-disable-next-line ember/no-jquery
    $(this.#scrollContainerSelector).clearQueue();
  }

  @action
  libraryCardDraggable(workInstruction) {
    return this.workInstructionRepo.userCanEdit(this.currentUser, workInstruction);
  }

  @action
  dropOnFolder(folder, workInstruction) {
    this.isDragging = false;
    this.args.dropOnFolder(folder, workInstruction);
  }
}
