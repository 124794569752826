import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { task as trackedTask } from 'ember-resources/util/ember-concurrency';
import { ObjectId } from 'bson';
import { waitFor } from '@ember/test-waiters';

export default class BiPanelBoltData extends Component {
  @service intl;
  @service queryRunner;

  get params() {
    return {
      stationIds: this.args.stationIds,
      modelIds: this.args.modelIds,
      fasteners: this.args.fasteners,
    };
  }

  get selectedFasteners() {
    return this.args.fasteners;
  }

  fastenerOptions = trackedTask(this, this.getFastenerData, () => [
    this.params,
  ]);

  @task({ drop: true })
  @waitFor
  *getFastenerData(params) {
    const boltDataQuery = [
      {
        $match: {
          taskType: 'torque',
        },
      },
      this.getStationMatch(params.stationIds),
      {
        $lookup: {
          from: 'LocationBases',
          localField: '_id',
          foreignField: 'parent',
          as: 'taskConfigs',
        },
      },
      {
        $unwind: {
          path: '$taskConfigs',
        },
      },
      {
        $group: {
          _id: '$_id',
          taskName: {
            $first: {
              $first: '$captions.text',
            },
          },
          maxBoltNumber: {
            $max: '$taskConfigs.boltCount',
          },
        },
      },
      {
        $sort: {
          taskName: 1,
        },
      },
    ];

    let fastenerData = yield this.queryRunner.runQuery.perform(
      'LocationBases',
      boltDataQuery,
    );

    fastenerData = fastenerData.flatMap(({ taskName, maxBoltNumber, _id }) => {
      const fastenersArray = [];
      for (let i = 1; i <= maxBoltNumber; i++) {
        fastenersArray.push({
          _id,
          name: `${taskName} ${this.intl.t('fastener')} ${i}`,
          idAndFastener: `${_id}${i}`,
        });
      }
      fastenersArray.toSorted((a, b) => a.name - b.name);
      return fastenersArray;
    });

    return fastenerData;
  }

  getStationMatch(stationIds) {
    if (stationIds.length > 0) {
      return {
        $match: {
          parent: {
            $in: stationIds.map((stationId) => new ObjectId(stationId)),
          },
        },
      };
    } else {
      return { $match: {} };
    }
  }
}
