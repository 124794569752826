import { clone } from 'ramda';

const getSelectedLanguage = (currentUser) => (currentUser?.language != null ? currentUser?.language : 'english');

const findCaption = (captions, language) => captions?.find(item => item.language === language);

const getTildeCaption = (caption) => `~${caption.text}~`;

const getCaptionText = function (captions, currentUser) {
  const language = getSelectedLanguage(currentUser);
  let caption = findCaption(captions, language);

  if (caption != null) {
    return caption.text;
  }

  caption = findCaption(captions, 'english');

  if (caption != null) {
    return getTildeCaption(caption);
  }

  caption = captions?.[0];

  if (caption != null) {
    return getTildeCaption(caption);
  }

  return '';
};

const getUpdatedCaptions = function(val, captions, currentUser) {
  captions = clone(captions);

  const language = getSelectedLanguage(currentUser);
  const caption = findCaption(captions, language);

  if (caption != null) {
    caption.text = val;
  } else {
    captions.push({
      text: val,
      language,
    });
  }

  return captions;
};

export { getSelectedLanguage, getCaptionText, findCaption, getUpdatedCaptions };
