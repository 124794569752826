import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import { propEq, pipe, reject, prop } from 'ramda';
import { intoArray } from '@eflexsystems/ramda-helpers';
import { sortByProp } from 'ramda-adjunct';

const ScheduleCategories = {
  incomplete: 'incomplete',
  complete: 'complete',
};

export default class ProductionSchedulePanel extends Component {
  @service store;
  @service currentUser;

  @tracked scheduleCategories = ScheduleCategories;
  @tracked station;
  @tracked completedProductionSchedules = [];
  @tracked scheduleCategory = ScheduleCategories.incomplete;
  @tracked scheduleSkip = 0;
  @tracked scheduleTake = 30;
  @tracked scrollToTop = false;

  get showIncomplete() {
    return this.scheduleCategory === ScheduleCategories.incomplete;
  }

  get sortedProductionSchedules() {
    if (this.showIncomplete) {
      return pipe(
        intoArray(
          reject(prop('isDeleted')),
          reject(prop('isCompleted')),
          reject(propEq(this.args.station.currentProductionSchedule?.id, 'id')),
        ),
        sortByProp('order'),
      )(this.args.station.productionSchedules ?? []);
    } else {
      return this.completedProductionSchedules;
    }
  }

  get isDirty() {
    return this.args.station.productionSchedules.some(item => item.isDirty);
  }

  get showNoSchedulesMessage() {
    const noSchedules = this.sortedProductionSchedules.length === 0;

    if (this.showIncomplete) {
      return noSchedules && this.args.station.currentProductionSchedule == null;
    } else {
      return noSchedules;
    }
  }

  get showLoadMore() {
    if (this.showIncomplete || this.completedProductionSchedules.length === 0) {
      return false;
    }

    return this.completedProductionSchedules.length >= this.scheduleSkip;
  }

  @task({ drop: true })
  @waitFor
  *fetchCompletedSchedules() {
    const completedSchedules = yield this.store.query('productionSchedule', {
      stationId: this.args.station.id,
      isCompleted: true,
      skip: this.scheduleSkip,
      take: this.scheduleTake,
    });

    this.completedProductionSchedules = this.completedProductionSchedules.concat(completedSchedules);
    this.scheduleSkip += this.scheduleTake;
  }

  @action
  onDidUpdate() {
    Object.assign(this, {
      scheduleCategory: ScheduleCategories.incomplete,
      scheduleSkip: 0,
      scrollToTop: true,
    });
  }

  @task
  @waitFor
  *setScheduleCategory(category) {
    this.scheduleCategory = category;

    this.completedProductionSchedules.forEach(completedProductionSchedule => {
      completedProductionSchedule.unloadRecord();
    });
    this.completedProductionSchedules = [];
    this.scheduleSkip = 0;

    if (!this.showIncomplete) {
      yield this.fetchCompletedSchedules.perform();
    }

    this.scrollToTop = true;
  }

  @action
  addSchedule() {
    this.updateOrder(this.sortedProductionSchedules);

    const schedule = this.store.createRecord('productionSchedule', {
      station: this.args.station,
      order: this.sortedProductionSchedules.length,
    });

    this.args.station.productionSchedules.push(schedule);
  }

  @action
  deleteSchedule(schedule) {
    if (schedule.isNew) {
      schedule.rollbackAttributes();
    } else {
      schedule.deleteRecord();
    }

    this.updateOrder(this.sortedProductionSchedules);
  }

  @action
  updateOrder(scheduleList) {
    scheduleList.forEach((schedule, order) => { schedule.order = order; });
  }
}
