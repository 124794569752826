import { validator } from '@eflexsystems/ember-tracked-validations';
import { getOwner } from '@ember/application';

export default {
  name: [validator('presence', true)],

  value: [
    validator('exclusion', {
      get in() {
        return this.model.component?.duplicateOptionValues;
      },
      get message() {
        return getOwner(this.model).lookup('service:intl').t('logs.nonUniqueFields');
      },
      get disabled() {
        return this.model.isAlwaysRun;
      },
    }),
  ],
};
