import ApplicationSerializer from 'eflex/serializers/application';

export default class StationSerializer extends ApplicationSerializer {
  attrs = {
    tasks: {
      deserialize: false,
      serialize: false,
    },
    codes: {
      deserialize: false,
      serialize: false,
    },
    jemNotes: {
      deserialize: false,
      serialize: false,
    },
    jemPartMarriages: {
      deserialize: false,
      serialize: false,
    },
    productionSchedules: {
      serialize: false,
      deserialize: false,
    },
  };
}
