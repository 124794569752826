import { DEBUG } from '@glimmer/env';
import Ember from 'ember';
import { set } from '@ember/object';
import { getOwner, setOwner } from '@ember/application';
import { capabilities } from '@ember/component';
import { schedule } from '@ember/runloop';
import BaseComponentManager from './base-component-manager';
import * as destroyables from './destroyables';
const {
  setDestroyed,
  setDestroying
} = destroyables;
const CAPABILITIES = true // @ts-ignore
// @ts-ignore
? capabilities('3.13', {
  destructor: true,
  asyncLifecycleCallbacks: false,
  updateHook: false
}) : capabilities('3.4', {
  destructor: true,
  asyncLifecycleCallbacks: false
});
const scheduledDestroyComponent = true ? undefined : (component, meta) => {
  if (component.isDestroyed) {
    return;
  }
  Ember.destroy(component);
  meta.setSourceDestroyed();
  setDestroyed(component);
};
const destroy = true ? Ember.destroy : component => {
  if (component.isDestroying) {
    return;
  }
  let meta = Ember.meta(component);
  meta.setSourceDestroying();
  setDestroying(component);
  schedule('actions', component, component.willDestroy);
  schedule('destroy', this, scheduledDestroyComponent, component, meta);
};
const registerDestructor = true ? Ember._registerDestructor : true ? Ember.__loader.require('@glimmer/runtime').registerDestructor : undefined;

/**
 * This component manager runs in Ember.js environments and extends the base component manager to:
 *
 * 1. Properly destroy the component's associated `meta` data structure
 * 2. Schedule destruction using Ember's runloop
 */
class EmberGlimmerComponentManager extends BaseComponentManager(setOwner, getOwner, CAPABILITIES) {
  createComponent(ComponentClass, args) {
    const component = super.createComponent(ComponentClass, args);
    if (true) {
      registerDestructor(component, () => {
        component.willDestroy();
      });
    }
    return component;
  }
  destroyComponent(component) {
    destroy(component);
  }
}
// In Ember 3.12 and earlier, the updateComponent hook was mandatory.
// As of Ember 3.13, the `args` object is stable and each property of the
// object participates in the autotrack stack on its own. This means we do not
// need to set the `args` property on the component instance to invalidate
// tracked getters that rely on `args`, and therefore don't require the `updateComponent`
// hook at all.
if (!true) {
  EmberGlimmerComponentManager.prototype.updateComponent = function updateComponent(component, args) {
    let argSnapshot = args.named;
    if (DEBUG) {
      argSnapshot = Object.freeze(argSnapshot);
    }
    set(component, 'args', argSnapshot);
  };
}
export default EmberGlimmerComponentManager;