import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class BlobImage extends Component {
  @tracked dataUrl;

  previousBlob = this.args.blob;

  constructor() {
    super(...arguments);

    if (this.args.blob) {
      this.dataUrl = URL.createObjectURL(this.args.blob);
    }
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.revokeDataUrl();

    Object.assign(this, {
      dataUrl: null,
      previousBlob: null,
    });
  }

  @action
  updateDataUrl(element, [blob]) {
    if (this.previousBlob === blob) {
      return;
    }

    this.previousBlob = blob;
    this.revokeDataUrl();

    if (blob) {
      this.dataUrl = URL.createObjectURL(blob);
    }
  }

  @action
  revokeDataUrl() {
    if (!this.dataUrl) {
      return;
    }

    URL.revokeObjectURL(this.dataUrl);
  }
}
