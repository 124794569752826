const JEM_VIEW_MODES = {
  singleTask: 0,
  taskList: 1,
};

export default {
  JEM_VIEW_MODES,
};

export { JEM_VIEW_MODES };
