import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { isBlank } from '@ember/utils';
import TaskStatuses from 'eflex/constants/task-statuses';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';

export default class JemTaskSpecificDisplayBarcode extends Component {
  @tracked barcode;

  @service eflexAjax;

  @task({ drop: true })
  @waitFor
  *sendBarcode(status) {
    if (status === TaskStatuses.RESTART) {
      this.onTaskComplete(status);
      return;
    }

    if (isBlank(this.barcode)) {
      return;
    }

    yield this.eflexAjax.post.perform('jemBarcodes', {
      jemBarcode: {
        liveBuildStatus: this.args.childStatus?.parent.id,
        taskConfig: this.args.taskConfig?.id,
        barcode: this.barcode,
      },
    });

    this.barcode = null;
  }

  @task
  @waitFor
  *onSubmit(barcode, status) {
    this.barcode = barcode;
    yield this.sendBarcode.perform(status);
  }

  @action
  onTaskComplete(status) {
    this.barcode = null;
    this.args.sendTaskComplete(status, this.args.childStatus);
  }
}
