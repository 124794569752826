import Model, { attr } from '@ember-data/model';
import SyncStatuses from 'eflex/constants/integration-sync-statuses';

export default class KineticConfig extends Model {
  @attr('string') companyId;
  @attr('string') siteId;
  @attr('string') siteName;
  @attr('string') timezone;
  @attr('date') lastSync;

  @attr('number', { defaultValue: 0 }) syncStatus;

  get isSynced() {
    return this.syncStatus === SyncStatuses.SYNCED;
  }

  get isSyncing() {
    return this.syncStatus === SyncStatuses.SYNCING;
  }

  get isNotSynced() {
    return this.syncStatus === SyncStatuses.NO_SYNC;
  }
}
