var _class2, _descriptor, _descriptor2;
function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
/**
 * Copyright 2015, Yahoo! Inc.
 * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
 */
import { getOwner } from '@ember/application';
import { makeArray } from '@ember/array';
import { assert } from '@ember/debug';
import { dependentKeyCompat } from '@ember/object/compat';
import { cancel, next } from '@ember/runloop';
import Service from '@ember/service';
import { createIntl, createIntlCache, IntlErrorCode } from '@formatjs/intl';
import { tracked } from '@glimmer/tracking';
import EventEmitter from 'eventemitter3';
import { FormatDate, FormatList, FormatMessage, FormatNumber, FormatRelative, FormatTime } from '../-private/formatters';
import flatten from '../-private/utils/flatten';
import getDOM from '../-private/utils/get-dom';
import hydrate from '../-private/utils/hydrate';
import isArrayEqual from '../-private/utils/is-array-equal';
import normalizeLocale from '../-private/utils/normalize-locale';
let _class = (_class2 = class _class2 extends Service {
  /**
   * Returns an array of registered locale names
   *
   */
  get locales() {
    return Object.keys(this._intls);
  }

  /** @public **/
  set locale(localeName) {
    const proposed = makeArray(localeName).map(normalizeLocale);
    if (!isArrayEqual(proposed, this._locale)) {
      this._locale = proposed;
      cancel(this._timer);
      this._timer = next(() => {
        this._ee.emit('localeChanged');
        this._updateDocumentLanguage(this._locale);
      });
    }
  }
  get locale() {
    return this._locale;
  }

  /**
   * Returns the first locale of the currently active locales
   *
   */
  get primaryLocale() {
    return this.locale[0];
  }

  /** @public **/

  /** @public **/
  constructor() {
    super(...arguments);
    this.formatRelative = createFormatterProxy('relative');
    /** @public **/
    this.formatMessage = createFormatterProxy('message');
    /** @public **/
    this.formatNumber = createFormatterProxy('number');
    /** @public **/
    this.formatTime = createFormatterProxy('time');
    /** @public **/
    this.formatDate = createFormatterProxy('date');
    /** @public **/
    this.formatList = createFormatterProxy('list');
    /** @private **/
    _initializerDefineProperty(this, "_locale", _descriptor, this);
    /** @private **/
    this._timer = null;
    /** @private **/
    this._formats = null;
    /** @private **/
    this._formatters = null;
    /** @private */
    _initializerDefineProperty(this, "_intls", _descriptor2, this);
    /**
     * @private
     * @type {EventEmitter}
     */
    this._ee = null;
    this._cache = createIntlCache();
    const initialLocale = this.locale || ['en-us'];
    this._intls = {};
    this._ee = new EventEmitter();
    this.setLocale(initialLocale);
    this._owner = getOwner(this);
    this._formatters = this._createFormatters();
    if (!this._formats) {
      this._formats = this._owner.resolveRegistration('formats:main') || {};
    }
    this.onIntlError = this.onIntlError.bind(this);
    this.getIntl = this.getIntl.bind(this);
    this.getOrCreateIntl = this.getOrCreateIntl.bind(this);
    hydrate(this);
  }
  willDestroy() {
    super.willDestroy(...arguments);
    cancel(this._timer);
  }
  onIntlError(err) {
    if (err.code !== IntlErrorCode.MISSING_TRANSLATION) {
      throw err;
    }
  }

  /** @private **/
  onError({
    /* kind, */error
  }) {
    throw error;
  }

  /** @public **/
  lookup(key, localeName, options = {}) {
    const localeNames = this._localeWithDefault(localeName);
    let translation;
    for (let i = 0; i < localeNames.length; i++) {
      const messages = this.translationsFor(localeNames[i]);
      if (!messages) {
        continue;
      }
      translation = messages[key];
      if (translation !== undefined) {
        break;
      }
    }
    if (translation === undefined && options.resilient !== true) {
      const missingMessage = this._owner.resolveRegistration('util:intl/missing-message');
      return missingMessage.call(this, key, localeNames, options);
    }
    return translation;
  }

  /**
   * @private
   */
  getIntl(locale) {
    const resolvedLocale = Array.isArray(locale) ? locale[0] : locale;
    return this._intls[resolvedLocale];
  }
  getOrCreateIntl(locale, messages) {
    const resolvedLocale = Array.isArray(locale) ? locale[0] : locale;
    const existingIntl = this._intls[resolvedLocale];
    if (!existingIntl) {
      this._intls = {
        ...this._intls,
        [resolvedLocale]: this.createIntl(resolvedLocale, messages)
      };
    } else if (messages) {
      this._intls = {
        ...this._intls,
        [resolvedLocale]: this.createIntl(resolvedLocale, {
          ...(existingIntl.messages || {}),
          ...messages
        })
      };
    }
    return this._intls[resolvedLocale];
  }

  /**
   * @private
   * @param {String} locale Locale of intl obj to create
   */
  createIntl(locale, messages = {}) {
    const resolvedLocale = Array.isArray(locale) ? locale[0] : locale;
    return createIntl({
      locale: resolvedLocale,
      defaultLocale: resolvedLocale,
      formats: this._formats,
      defaultFormats: this._formats,
      onError: this.onIntlError,
      messages
    }, this._cache);
  }
  validateKeys(keys) {
    return keys.forEach(key => {
      assert(`[ember-intl] expected translation key "${key}" to be of type String but received: "${typeof key}"`, typeof key === 'string');
    });
  }

  /** @public **/
  t(key, options = {}) {
    let keys = [key];
    if (options.default) {
      if (Array.isArray(options.default)) {
        keys = [...keys, ...options.default];
      } else if (typeof options.default === 'string') {
        keys = [...keys, options.default];
      }
    }
    this.validateKeys(keys);
    for (let index = 0; index < keys.length; index++) {
      const key = keys[index];
      const message = this.lookup(key, options.locale, {
        ...options,
        // Note: last iteration will throw with the last key that was missing
        // in the future maybe the thrown error should include all the keys to help debugging
        resilient: keys.length - 1 !== index
      });

      // @formatjs/intl consider empty message to be an error
      if (message === '' || typeof message === 'number') {
        return message;
      }
      if (message) {
        return this.formatMessage({
          id: key,
          defaultMessage: message
        }, options);
      }
    }
  }

  /** @public **/
  exists(key, localeName) {
    const localeNames = this._localeWithDefault(localeName);
    assert(`[ember-intl] locale is unset, cannot lookup '${key}'`, Array.isArray(localeNames) && localeNames.length);
    return localeNames.some(localeName => key in (this.getIntl(localeName)?.messages || {}));
  }

  /** @public */
  setLocale(locale) {
    assert(`[ember-intl] no locale has been set!  See: https://ember-intl.github.io/ember-intl/docs/quickstart#4-configure-ember-intl`, locale);
    this.locale = locale;
    this.getOrCreateIntl(locale);
  }

  /** @public **/
  addTranslations(localeName, payload) {
    const locale = normalizeLocale(localeName);
    this.getOrCreateIntl(locale, flatten(payload));
  }

  /** @public **/
  translationsFor(localeName) {
    const locale = normalizeLocale(localeName);
    return this.getIntl(locale)?.messages;
  }

  /** @private **/
  _localeWithDefault(localeName) {
    if (!localeName) {
      return this._locale || [];
    }
    if (typeof localeName === 'string') {
      return makeArray(localeName).map(normalizeLocale);
    }
    if (Array.isArray(localeName)) {
      return localeName.map(normalizeLocale);
    }
  }

  /** @private **/
  _updateDocumentLanguage(locales) {
    const dom = getDOM(this);
    if (dom) {
      const [primaryLocale] = locales;
      const html = dom.documentElement;
      html.setAttribute('lang', primaryLocale);
    }
  }

  /** @private */
  _createFormatters() {
    return {
      message: new FormatMessage(),
      relative: new FormatRelative(),
      number: new FormatNumber(),
      time: new FormatTime(),
      date: new FormatDate(),
      list: new FormatList()
    };
  }
  /**
   * @private
   * @param {Function} fn
   * @returns {Function} unsubscribed from localeChanged
   */
  onLocaleChanged(...args) {
    this._ee.on('localeChanged', ...args);
    return () => {
      this._ee.off('localeChanged', ...args);
    };
  }
}, (_applyDecoratedDescriptor(_class2.prototype, "locale", [dependentKeyCompat], Object.getOwnPropertyDescriptor(_class2.prototype, "locale"), _class2.prototype), _descriptor = _applyDecoratedDescriptor(_class2.prototype, "_locale", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return null;
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "_intls", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return null;
  }
})), _class2);
export { _class as default };
function createFormatterProxy(name) {
  return function serviceFormatterProxy(value, formatOptions) {
    let locale;
    let intl;
    if (formatOptions && formatOptions.locale) {
      locale = this._localeWithDefault(formatOptions.locale);
      // Cannot use getOrCreateIntl since it triggers a re-render which
      // might cause infinite loop
      // This is also a case we're not optimizing for so let it take
      // the slow path
      intl = this.createIntl(locale);
    } else {
      locale = this.locale;
      intl = this.getIntl(locale);
    }
    return this._formatters[name].format(intl, value, formatOptions);
  };
}