// matches deviceIds sent by plcs
const rockwell = 4;
const siemens = 2;

const loadOptions = {
  scan: 0,
  auto: 1,
  selectModel: 2,
  scanOrder: 3,
  webCamScan: 4,
  bomSourceLookup: 5,
  bomSourceLookupAuto: 6,
};

export default {
  loadOptions,

  modelLoadOptions: [
    { value: loadOptions.scan, label: 'plant.station.opt.scan' },
    { value: loadOptions.auto, label: 'plant.station.opt.auto' },
    { value: loadOptions.selectModel, label: 'plant.station.opt.selectModel' },
    { value: loadOptions.scanOrder, label: 'plant.station.opt.scanOrder' },
    { value: loadOptions.webCamScan, label: 'webCam' },
  ],

  componentLoadOptions: [
    {
      value: loadOptions.scan,
      label: 'plant.station.opt.scan',
    },
    {
      value: loadOptions.webCamScan,
      label: 'webCam',
    },
    {
      value: loadOptions.bomSourceLookup,
      label: 'plant.station.opt.bomSourceLookup',
    },
    {
      value: loadOptions.bomSourceLookupAuto,
      label: 'plant.station.opt.bomSourceLookupAuto',
    },
  ],

  regexAllowedLoadOptions: new Set([loadOptions.scan, loadOptions.webCamScan]),
  autoLoadOptions: new Set([loadOptions.auto, loadOptions.bomSourceLookupAuto]),

  rockwell,
  siemens,
};
