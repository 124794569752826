import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { isPresent } from '@ember/utils';

export default class BaseCriteriaImageType extends Component {
  @tracked isDisplayed = Boolean(this.args.imageType);

  get imageTypeOptions() {
    return [
      { label: 'JPG', value: 'jpg' },
      { label: 'BMP', value: 'bmp' },
      { label: 'PDF', value: 'pdf' },
      { label: 'SVG', value: 'svg' },
      { label: 'PNG', value: 'png' },
    ];
  }

  @action
  onDisplayedChanged(isDisplayed) {
    this.args.removeParam('imageType');
    this.isDisplayed = isDisplayed;
  }

  @action
  onImageTypeSelection(imageType) {
    if (isPresent(imageType)) {
      this.args.updateParams({
        key: 'imageType',
        value: imageType.value,
        breadcrumb: imageType.label,
      });
    } else {
      this.args.removeParam('imageType');
    }
  }
}
