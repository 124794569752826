import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import TriggerEventValues, { TriggerEvents } from 'eflex/constants/tasks/trigger-events';
import { taskTypes } from 'eflex/constants/tasks/task-types';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

const WORK_INSTRUCTION_HARDWARE = ['work-instruction-hardware'];

export default class JemInstructionsConfiguration extends Component {
  @service triggerRepo;
  @service hardwareRepo;

  @tracked selectedInstructionTriggerEvent = TriggerEventValues.START;
  @tracked animationsToRun;

  triggerEvents = TriggerEvents;

  get showCopyToAll() {
    return this.args.showCopyToAll ?? true;
  }

  get config() {
    return this.triggerConfig ?? this.args.jemConfiguration;
  }

  get showAnimationConfig() {
    return (
      this.triggerConfig?.workInstruction?.isDynamic &&
      this.args.taskConfig?.task.taskType === taskTypes.torque
    );
  }

  get triggerConfig() {
    return this.args.taskConfig?.triggerConfigs.find(triggerConfig =>
      triggerConfig.constructor.modelName === 'work-instruction-hardware-trigger-config' &&
      triggerConfig.event === this.selectedInstructionTriggerEvent,
    );
  }

  _createInstructionTrigger() {
    const hardware = this.hardwareRepo.hardwareByType(WORK_INSTRUCTION_HARDWARE)[0];
    this.triggerRepo.createTrigger(hardware, this.args.taskConfig.task, this.selectedInstructionTriggerEvent);
  }

  @action
  copyToAll() {
    this.args.copyToAll?.(this.config, this.selectedInstructionTriggerEvent);
  }

  @action
  deleteJemImage() {
    if (this.args.disabled) {
      return;
    }

    this.config.deleteJemImage();
  }

  @action
  setWorkInstruction(workInstruction) {
    if (this.args.disabled) {
      return;
    }

    if (this.args.useEvent && this.triggerConfig == null) {
      this._createInstructionTrigger();
    }

    this.config.setWorkInstruction(workInstruction);
  }

  @action
  setExternalImageUrl(url) {
    if (this.args.useEvent && this.triggerConfig == null) {
      this._createInstructionTrigger();
    }

    this.config.setExternalImageUrl(url);
  }

  @action
  playAnimation(animation) {
    this.animationsToRun = [animation];
  }
}
