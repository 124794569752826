import { action } from '@ember/object';
import Component from '@glimmer/component';
import { isBlank } from '@ember/utils';

export default class InputsOpacity extends Component {
  get labelClass() {
    const opacity = parseInt(this.args.opacity);
    if (opacity < 10) {
      return 'ones';
    } else if (opacity < 100) {
      return 'tens';
    } else {
      return 'hundreds';
    }
  }

  @action
  updateOpacity(opacity) {
    if (isBlank(opacity) || opacity < 0 || isNaN(opacity)) {
      opacity = 0;
    } else if (opacity > 100) {
      opacity = 100;
    }

    this.args.update(opacity);
  }
}
