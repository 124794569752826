import { inject as service } from '@ember/service';
import BARCODE_SYMBOLOGIES from 'eflex/constants/barcode-symbologies';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { addObjectIfNotPresent } from 'eflex/util/array-helpers';
import { without } from 'ramda';

const BarcodeHardware = ['cognex-camera', 'web-cam', 'plc-hardware', 'datalogic', 'keyence', 'node-red'];

export default class TaskDeviceConfigBarcode extends Component {
  barcodeSymbologies = BARCODE_SYMBOLOGIES;

  @service store;
  @service licensing;
  @service stationRepo;
  @service hardwareRepo;

  get stations() {
    return without([this.args.task.parent], this.stationRepo.stations);
  }

  get hardwares() {
    return this.hardwareRepo.hardwareByType(
      without([this.licensing.license.disabledHardware], BarcodeHardware),
    );
  }

  get partMarriage1() {
    return this.args.task.jemPartMarriages.find(item => item.order === 1)?.stations;
  }

  get partMarriage2() {
    return this.args.task.jemPartMarriages.find(item => item.order === 2)?.stations;
  }

  @action
  updatePartMarriage(current, order, stations) {
    if (current == null) {
      const jemPartMarriage = this.store.createRecord('jemPartMarriage', {
        order,
        stations,
      });

      addObjectIfNotPresent(this.args.task.jemPartMarriages, jemPartMarriage);
    } else {
      this.args.task.jemPartMarriages.find(item => item.order === order).stations = stations;
    }
  }
}
