const FormatxAxisTimestamps = (chartData, selectedLevel) => {
  return {
    type: 'category',
    boundaryGap: true,
    data: chartData.map((m) => {
      if (selectedLevel === 'Hour') {
        return `${m.date}h`;
      }
      return m.date;
    }),
    axisLabel: {
      show: true,
      rotate: 75,
      interval: 'auto',
    },
  };
};

export default FormatxAxisTimestamps;
