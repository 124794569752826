import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import DecisionTypes from 'eflex/constants/tasks/decision-types';
import { chain, prop, reject, uniq } from 'ramda';
import { intoArray } from '@eflexsystems/ramda-helpers';

export default class DecisionConfig extends Component {
  @service taskRepo;

  get decisionDefConfigs() {
    return reject(prop('isDeleted'), this.args.taskConfig.decisionDefConfigs);
  }

  get showOptions() {
    return this.args.taskConfig.task.decisionType !== DecisionTypes.passFail;
  }

  get allDecisionTags() {
    const tags = intoArray(
      reject(prop('isDeleted')),
      chain(prop('decisionTags')),
      uniq,
    )(this.taskRepo.tasks);

    return tags.toSorted();
  }

  @action
  deleteDecisionDef(decisionDef) {
    decisionDef.decisionDefConfigs.forEach(decisionDefConfig => { decisionDefConfig.deleteRecord(); });
    decisionDef.deleteRecord();
  }
}
