import Component from '@glimmer/component';
import { task, timeout } from 'ember-concurrency';
import getDelayTime from 'eflex/util/get-delay-time';

const DELAY_BEFORE_SPINNER = 1000;

export default class SpinnerOverlay extends Component {
  get isEmberTests() {
    return window.isTesting;
  }

  get isIntegrationTests() {
    return window.isIntegrationTest;
  }

  @task
  *spinnerDelay() {
    yield timeout(getDelayTime(DELAY_BEFORE_SPINNER));
  }

  constructor() {
    super(...arguments);
    if (!this.isIntegrationTests && !this.isEmberTests) {
      this.spinnerDelay.perform();
    }
  }
}
