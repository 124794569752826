import ApplicationSerializer from 'eflex/serializers/application';

export default ApplicationSerializer.extend({
  attrs: {
    schedule: {
      serialize: false,
      deserialize: false,
    },
  },
});
