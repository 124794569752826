import { inject as service } from '@ember/service';
import { StrokeOptions, EflexObjTypes } from 'eflex/constants/work-instructions/tool-props';
import { generateStrokeDashArray } from 'eflex/util/stroke-helper';
import { all, task, waitForProperty, waitForQueue } from 'ember-concurrency';
import { fabric } from 'fabric';
import { updateObjectProperty, getArrowsByLine } from 'eflex/util/fabric-helpers';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { waitFor } from '@ember/test-waiters';

export default class WorkInstructionEditorToolPropertiesLines extends Component {
  @service imageEditor;
  @service systemConfig;
  @service store;

  @tracked selectedLineType = EflexObjTypes.STRAIGHT_LINE;
  @tracked selectedStrokeColor = '#49fb35';
  @tracked startArrow = false;
  @tracked endArrow = false;
  @tracked currentLine;
  @tracked startHidden = false;
  @tracked dynamicObjectName;
  @tracked selectedStrokeWidth = 3;
  @tracked selectedStrokeStyle = StrokeOptions.default;
  @tracked strokeDashArray = [];

  get lineTypes() {
    return [
      { label: 'imageEditor.lines.straight', value: EflexObjTypes.STRAIGHT_LINE },
      { label: 'imageEditor.lines.freeform', value: EflexObjTypes.FREE_LINE },
    ];
  }

  @task
  @waitFor
  *onDidInsert() {
    yield all([
      waitForProperty(this.imageEditor, 'canvas'),
      waitForQueue('afterRender'),
    ]);

    if (this._activeObject() != null) {
      this.updateSelectedProperties();
    } else {
      this.#updateFromSystemConfig();
    }

    this.imageEditor
      .on('selection:updated', this.updateSelectedProperties)
      .on('selection:cleared', this.#updateFromSystemConfig);

    this.imageEditor.canvas
      .on('mouse:down', this.#placeLine)
      .on('mouse:move', this.#adjustLine)
      .on('mouse:up', this.#releaseLine)
      .on('path:created', this.#pathCreated);
  }

  _isLine(obj) {
    return this.lineTypes.map(item => item.value).includes(obj?.eflex?.type);
  }

  _isLineValid(line) {
    return !(line.x1 === line.x2 && line.y1 === line.y2);
  }

  _activeObject() {
    const obj = this.imageEditor.canvas?.getActiveObject();
    if (this._isLine(obj)) {
      return obj;
    } else {
      return null;
    }
  }

  resetProperties() {
    this.link = '';
  }

  #updateFromSystemConfig = (event) => {
    this.resetProperties();
    const config = this.systemConfig.getWieDefaults('lines');

    Object.assign(this, {
      selectedLineType: config.type,

      startArrow: config.arrows?.start,
      endArrow: config.arrows?.end,

      selectedStrokeColor: config.stroke?.color,
      strokeDashArray: generateStrokeDashArray(config.stroke?.style, config.stroke?.width),
      selectedStrokeWidth: config.stroke?.width,
      selectedStrokeStyle: config.stroke?.style,
    });

    if (event == null) {
      this.imageEditor.canvas.set('isDrawingMode', this._isDrawingMode());
    }

    this.updateDrawingTool();
  };

  @action
  updateSelectedProperties() {
    const obj = this._activeObject();

    if (obj == null) {
      return;
    }

    Object.assign(this, {
      startArrow: this._arrow(obj, 'start')?.visible,
      endArrow: this._arrow(obj, 'end')?.visible,
      selectedStrokeColor: obj.stroke,
      selectedStrokeWidth: obj.strokeWidth,
      strokeDashArray: obj.strokeDashArray,
      selectedStrokeStyle: obj.eflex?.strokeDashArrayStyle ?? StrokeOptions.default,
      selectedLineType: obj.eflex?.type,
      dynamicObjectName: obj.eflex?.dynamicObjectName,
      startHidden: obj.eflex?.startHidden,
    });

    this.updateDrawingTool();
  }

  _updateArrowStyle() {
    const line = this._activeObject();
    if (!line) {
      return;
    }
    this._arrow(line).forEach((arrow) => {
      if (!arrow) {
        return;
      }
      arrow.eflex ??= {};
      updateObjectProperty(arrow.eflex, 'startHidden', this.startHidden);
      updateObjectProperty(arrow, 'strokeWidth', this.selectedStrokeWidth);
      updateObjectProperty(arrow, 'stroke', this.selectedStrokeColor);
      updateObjectProperty(arrow, 'fill', this.selectedStrokeColor);
      this.imageEditor.notifyModified(arrow);
    });
  }

  updateDrawingTool() {
    const { canvas } = this.imageEditor;
    canvas.freeDrawingBrush.width = this.selectedStrokeWidth;
    canvas.freeDrawingBrush.color = this.selectedStrokeColor;
    canvas.freeDrawingBrush.strokeDashArray = this.strokeDashArray;
  }

  _addStrokeStyle(path) {
    path.eflex ??= {};
    updateObjectProperty(path.eflex, 'strokeDashArrayStyle', this.selectedStrokeStyle);
    this.imageEditor.notifyModified(path);
  }

  _isDrawingMode() {
    return this.selectedLineType === EflexObjTypes.FREE_LINE;
  }

  #placeLine = ({ e }) => {
    const { canvas } = this.imageEditor;

    if (!this.imageEditor.inToolMode || canvas.isDrawingMode || canvas.getActiveObject() != null) {
      this.currentLine = null;
      return;
    }

    const pointer = canvas.getPointer(e);
    const points = [pointer.x, pointer.y, pointer.x, pointer.y];

    const line = new fabric.Line(points, {
      originX: 'center',
      originY: 'center',
      strokeWidth: parseInt(this.selectedStrokeWidth),
      stroke: this.selectedStrokeColor,
      strokeDashArray: this.strokeDashArray,
      selectable: false,
      hasControls: false,
      hasBorders: false,
      perPixelTargetFind: true,
      eflex: {
        strokeDashArrayStyle: this.selectedStrokeStyle,
        type: EflexObjTypes.STRAIGHT_LINE,
        dynamicObjectName: this.dynamicObjectName,
        startHidden: this.startHidden,
      },
    });

    this.imageEditor.applyItemKey(line);
    this.currentLine = line;
    this.imageEditor.disableHistory();
    canvas.add(line);
  };

  #adjustLine = ({ e }) => {
    const { canvas } = this.imageEditor;

    if (this.currentLine == null) {
      return;
    }
    const pointer = canvas.getPointer(e);

    this.currentLine.set({ x2: pointer.x, y2: pointer.y });
    canvas.renderAll();
  };

  #releaseLine = () => {
    if (this.currentLine == null) {
      return;
    }
    const { canvas } = this.imageEditor;
    this.currentLine.setCoords();

    if (!this._isLineValid(this.currentLine)) {
      this.imageEditor.removeObject(this.currentLine, true);
      this.currentLine = null;
      this.imageEditor.enableHistory();
      return;
    }

    this._addStrokeStyle(this.currentLine);
    let startArrow = null;
    let endArrow = null;

    const angle = this.imageEditor.calcLineAngle(
      this.currentLine.x1,
      this.currentLine.y1,
      this.currentLine.x2,
      this.currentLine.y2,
    );

    startArrow = this.imageEditor.createArrow({
      x: this.currentLine.x1,
      y: this.currentLine.y1,
      angle: angle - 180,
      itemKey: this.currentLine.eflex.itemKey,
      fill: this.selectedStrokeColor,
      stroke: this.selectedStrokeColor,
      strokeWidth: parseInt(this.selectedStrokeWidth),
      itemPosition: 'start',
      visible: this.startArrow,
    });

    endArrow = this.imageEditor.createArrow({
      x: this.currentLine.x2,
      y: this.currentLine.y2,
      angle,
      itemKey: this.currentLine.eflex.itemKey,
      fill: this.selectedStrokeColor,
      stroke: this.selectedStrokeColor,
      strokeWidth: parseInt(this.selectedStrokeWidth),
      itemPosition: 'end',
      visible: this.endArrow,
    });

    const startHandle = this.imageEditor.createHandle({
      x: this.currentLine.x1,
      y: this.currentLine.y1,
      itemKey: this.currentLine.eflex.itemKey,
      itemPosition: 'start',
    });

    const endHandle = this.imageEditor.createHandle({
      x: this.currentLine.x2,
      y: this.currentLine.y2,
      itemKey: this.currentLine.eflex.itemKey,
      itemPosition: 'end',
    });

    canvas.add(startArrow, endArrow, startHandle, endHandle);

    canvas.setActiveObject(this.currentLine);
    this._updateArrowStyle();
    canvas.renderAll();

    this.imageEditor.enableHistory();
    this.imageEditor.updateLineItemsPosition(this.currentLine);
    canvas.fire('object:modified', { target: this.currentLine });

    this.args.objectCreated?.(this.currentLine);
    this.currentLine = null;
  };

  #pathCreated = ({ path }) => {
    const { canvas } = this.imageEditor;
    path.eflex ??= {};
    path.setCoords();
    this._addStrokeStyle(path);
    updateObjectProperty(path.eflex, 'type', EflexObjTypes.FREE_LINE);
    this.imageEditor.notifyModified(path);
    canvas.set('isDrawingMode', false);
    canvas.renderAll();

    this.args.objectCreated?.(path);
  };

  _arrow(line, position) {
    const arrows = getArrowsByLine(this.imageEditor.canvas, line, position);

    if (position != null) {
      if (arrows.length > 1) {
        throw new Error('More arrows than expected for line.');
      }
      return arrows[0];
    } else {
      return arrows;
    }
  }

  _updateArrowVisibility(line, position, visible) {
    const arrow = this._arrow(line, position);
    updateObjectProperty(arrow, 'visible', visible);
    this.imageEditor.notifyModified(arrow);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.imageEditor
      .off('selection:updated', this.updateSelectedProperties)
      .off('selection:cleared', this.#updateFromSystemConfig);

    this.imageEditor.canvas
      ?.off('mouse:down')
      .off('mouse:move')
      .off('mouse:up')
      .off('path:created');
  }

  #updateDrawingToolAndArrows() {
    this.updateDrawingTool();
    this._updateArrowStyle();
  }

  @action
  updateDynamicObjectName(val) {
    this.dynamicObjectName = val;
    this.#updateDrawingToolAndArrows();
    const obj = this._activeObject();
    if (!obj) {
      return;
    }
    obj.eflex ??= {};
    updateObjectProperty(obj.eflex, 'dynamicObjectName', val);
    this.imageEditor.notifyModified(obj);
  }

  @action
  updateStartHidden(val) {
    this.startHidden = val;
    this.#updateDrawingToolAndArrows();
    const obj = this._activeObject();
    if (!obj) {
      return;
    }
    obj.eflex ??= {};
    updateObjectProperty(obj.eflex, 'startHidden', val);
    this.imageEditor.notifyModified(obj);
  }

  @action
  updateStrokeColor(val) {
    this.selectedStrokeColor = val;
    this.#updateDrawingToolAndArrows();
    const obj = this._activeObject();
    updateObjectProperty(obj, 'stroke', val);
    this.imageEditor.notifyModified(obj);
  }

  @action
  updateStroke(strokeDashArray, selectedStrokeStyle, selectedStrokeWidth) {
    Object.assign(this, {
      strokeDashArray,
      selectedStrokeStyle,
      selectedStrokeWidth,
    });

    this.#updateDrawingToolAndArrows();
    const obj = this._activeObject();

    if (!obj) {
      return;
    }

    obj.eflex ??= {};
    updateObjectProperty(obj, 'strokeWidth', selectedStrokeWidth);
    updateObjectProperty(obj.eflex, 'strokeDashArrayStyle', selectedStrokeStyle);
    updateObjectProperty(obj, 'strokeDashArray', strokeDashArray);
    this.imageEditor.notifyModified(obj);
  }

  @action
  switchLineType(type) {
    this.selectedLineType = type;
    this.imageEditor.canvas.set('isDrawingMode', this._isDrawingMode());
  }

  @action
  updateArrow(position, value) {
    const itemPosition = `${position}Arrow`;
    this[itemPosition] = value;

    const line = this._activeObject();
    if (line == null) {
      return;
    }
    this._updateArrowVisibility(line, position, this[itemPosition]);
  }

  @action
  onMakeDefault() {
    const lineDefault = this.store.createRecord('wieConfig/lines', {
      type: this.selectedLineType,
      stroke: this.store.createRecord('wieConfig/stroke', {
        color: this.selectedStrokeColor,
        style: this.selectedStrokeStyle,
        width: this.selectedStrokeWidth,
      }),
      arrows: this.store.createRecord('wieConfig/arrows', {
        start: this.startArrow,
        end: this.endArrow,
      }),
    });

    this.systemConfig.config.wie.editorDefaults.lines = lineDefault;
    return this.args.saveDefault();
  }
}
