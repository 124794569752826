var _class;
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
import Modifier from 'ember-modifier';
import { getOwner } from '@ember/application';
import { action } from '@ember/object';
import { assert } from '@ember/debug';
import { setGlobalRef, bucketFor, getNodeDestructors, watchFor } from './../utils/ref';
import { getReferencedKeys } from '../utils/prototype-reference';
import { registerDestructor } from '@ember/destroyable';
let RefModifier = (_class = class RefModifier extends Modifier {
  constructor() {
    super(...arguments);
    this._key = void 0;
    this._ctx = void 0;
    this._element = void 0;
    // to minimise overhead, user should be specific about
    // what they want to observe
    this.defaultMutationObserverOptions = {
      attributes: false,
      characterData: false,
      childList: false,
      subtree: false
    };
    setGlobalRef(getOwner(this));
    registerDestructor(this, () => {
      this.cleanMutationObservers();
      this.cleanResizeObservers();
      getNodeDestructors(this._element).forEach(cb => cb());
    });
  }
  markDirty() {
    bucketFor(this._ctx).dirtyTrackedCell(this._key);
  }
  cleanMutationObservers() {
    if (this._mutationsObserver) {
      this._mutationsObserver.disconnect();
    }
  }
  cleanResizeObservers() {
    if (this._resizeObserver) {
      this._resizeObserver.unobserve(this.element);
    }
  }
  installMutationObservers(named = {}) {
    this._mutationsObserver = new MutationObserver(this.markDirty);
    const opts = this.getObserverOptions(named);
    delete opts.resize;
    if (opts.attributes || opts.characterdata || opts.childlist) {
      // mutations observer throws if observe is attempted
      // with all these options disabled
      this._mutationsObserver.observe(this.element, opts);
    }
  }
  validateTrackedOptions(named = {}) {
    const args = ['subtree', 'attributes', 'children', 'resize', 'character'];
    if (args.some(name => name in named)) {
      assert(`"ember-ref-modifier", looks like you trying to use {{${named.debugName}}} without tracked flag or alias, but, with properties, related to tracked modifier (${args.join(', ')})`, this.isTracked(named));
    }
  }
  getObserverOptions(named = {}) {
    // to minimise overhead user
    // should be specific about
    // what they want to observe
    let resize = false;
    let subtree = this.defaultMutationObserverOptions.subtree;
    let attributes = this.defaultMutationObserverOptions.attributes;
    let character = this.defaultMutationObserverOptions.characterData;
    let children = this.defaultMutationObserverOptions.childList;
    if ('subtree' in named) {
      subtree = named.subtree;
    }
    if ('attributes' in named) {
      attributes = named.attributes;
    }
    if ('children' in named) {
      children = named.children;
    }
    if ('resize' in named) {
      resize = named.resize;
    }
    if ('character' in named) {
      character = named.character;
    }
    return {
      subtree,
      attributes,
      childList: children,
      resize,
      characterData: character
    };
  }
  installResizeObservers(element) {
    this._resizeObserver = new ResizeObserver(this.markDirty);
    this._resizeObserver.observe(element);
  }
  modify(element, positional, named) {
    const name = this.name(positional);
    const ctx = this.ctx(named, positional);
    this._key = name;
    this._ctx = ctx;
    this._element = element;
    assert(`You must provide string as first positional argument for {{${named.debugName}}}`, typeof name === 'string' && name.length > 0);
    this.validateTrackedOptions(named);
    this.cleanMutationObservers();
    this.cleanResizeObservers();
    if (name !== this._key || this._ctx !== ctx) {
      bucketFor(this._ctx).add(this._key, null);
    }
    watchFor(name, ctx, () => {
      const keys = getReferencedKeys(ctx, name);
      keys.forEach(keyName => {
        // consume keys with callbacks
        ctx[keyName];
      });
    });
    bucketFor(ctx).add(name, element);
    if (this.isTracked(named)) {
      this.installMutationObservers(named);
      if (this.getObserverOptions(named).resize) {
        this.installResizeObservers(element);
      }
    }
  }
  ctx(named = {}, positional = [undefined]) {
    assert(`ember-ref-bucket: You trying to use {{${named.debugName}}} as local reference for template-only component. Replace it to {{global-ref "${positional[0]}"}}`, named.bucket !== null);
    return named.bucket || getOwner(this);
  }
  isTracked(named = {}) {
    return named.tracked || false;
  }
  name(positional) {
    return positional[0];
  }
}, (_applyDecoratedDescriptor(_class.prototype, "markDirty", [action], Object.getOwnPropertyDescriptor(_class.prototype, "markDirty"), _class.prototype)), _class);
export { RefModifier as default };