import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import $ from 'jquery';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import { without, pluck, zipObj } from 'ramda';

export default class ComponentComplexOptionBuilder extends Component {
  @service store;
  @service notifier;

  queryBuilder;

  @task({ enqueue: true })
  @waitFor
  *setupQueryBuilder(element) {
    this.queryBuilder?.destroy();

    const filters = this.args.components.map(component => {
      const options = without([this.args.selectedOption], component.options);
      const values = zipObj(
        pluck('id', options),
        pluck('name', options),
      );

      return {
        id: component.id,
        label: component.name,
        type: 'string',
        input: 'select',
        operators: ['equal', 'not_equal'],
        values,
      };
    });

    const rules = this.args.selectedOption.toRulesObject();
    rules.rules ??= []; // ensure top level has rules

    yield import('jQuery-QueryBuilder');

    // eslint-disable-next-line ember/no-jquery
    $(element).queryBuilder({
      filters,
      rules,
      default_condition: this.args.selectedOption.condition,
      allow_empty: true,
      allow_invalid: true,
    });

    this.queryBuilder = element.queryBuilder;
  }

  @action
  updateComplexOption() {
    if (!this.queryBuilder.validate()) {
      this.notifier.sendError('components.options.invalidComplexOption');
      return;
    }

    const updated = this.queryBuilder.getRules({ allow_invalid: true });
    const allOptions = this.args.components.flatMap(component => component.options);

    this.args.selectedOption.condition = updated.condition;
    this.args.selectedOption.rules.forEach(rule => { rule.deleteRecord(); });

    updated.rules.forEach(rule => {
      this.#createRecordFromRule(rule, this.args.selectedOption, allOptions);
    });

    return this.args.onConfirm?.(this.args.selectedOption);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.queryBuilder?.destroy();
  }

  #createRecordFromRule(rule, parent, options) {
    const componentOption = options.find(item => item.id === rule.value);

    const newRule = this.store.createRecord('complexComponentOption', {
      parent,
      componentOption,
      comparedComponent: componentOption?.component,
    });

    if (rule.condition) {
      newRule.condition = rule.condition;
    }

    if (rule.operator) {
      newRule.operatorName = rule.operator;
    }

    rule.rules?.forEach(nestedRule => {
      this.#createRecordFromRule(nestedRule, newRule, options);
    });
  }
}
