import Controller from '@ember/controller';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import SyncStatuses from 'eflex/constants/integration-sync-statuses';

export default class SettingsKineticController extends Controller {
  @service currentUser;
  @service systemConfig;
  @service eflexAjax;

  get systemConfiguration() {
    return this.systemConfig.config;
  }

  get kinetic() {
    return this.systemConfiguration.kinetic;
  }

  get disabled() {
    return this.currentUser.isNotAdmin;
  }

  @task
  @waitFor
  *sync() {
    this.kinetic.syncStatus = SyncStatuses.SYNCING;
    yield this.systemConfiguration.save();
    yield this.eflexAjax.post.perform('kinetic/kineticSync', {
      siteId: this.kinetic.siteId,
      companyId: this.kinetic.companyId,
    });
  }

  @task
  @waitFor
  *unsync() {
    this.kinetic.syncStatus = SyncStatuses.NO_SYNC;
    this.kinetic.siteId = null;
    this.kinetic.companyId = null;

    yield this.systemConfiguration.save();
  }
}
