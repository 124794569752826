import { attr } from '@ember-data/model';
import BomSource from 'eflex/models/bom-source';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';

@buildValidations({
  address: [validator('presence', { presence: true })],
  userName: {
    get disabled() {
      return !this.model.useTokenAuth;
    },
    validators: [validator('presence', true)],
  },
  password: {
    get disabled() {
      return !this.model.useTokenAuth;
    },
    validators: [validator('presence', true)],
  },
  tokenUrl: {
    get disabled() {
      return !this.model.useTokenAuth;
    },
    validators: [validator('presence', true)],
  },
})
class OracleDellBoomiBomSource extends BomSource {
  @attr('string') userName;
  @attr('string') password;
  @attr('string') organizationId;
  @attr('string') tokenUrl;
  @attr('boolean', { defaultValue: false }) useTokenAuth;

  get isOracleDellBoomi() {
    return true;
  }

  get typeName() {
    return 'OracleDellBoomi';
  }
}

export default OracleDellBoomiBomSource;
