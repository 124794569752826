import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { sortByProp } from 'ramda-adjunct';

export default class ComponentContextSelector extends Component {
  @service taskRepo;
  @service defaultTaskConfigContext;

  get sortedAreaComponents() {
    return sortByProp('name', this.args.treeItem.area?.bomSource?.components ?? []);
  }

  get componentOptions() {
    let options = [];

    if (this.args.allowAll) {
      options = [this.defaultTaskConfigContext.getContext(this.args.treeItem)];
    }

    const component = this.args.treeItem.component;

    if (component) {
      options = options.concat(component.options);
    }

    return options;
  }

  get selectedOption() {
    if (this.args.selectedContext.allSelected) {
      return this.componentOptions[0];
    } else {
      return this.args.selectedContext;
    }
  }

  @action
  selectedComponent(component) {
    if (component == null || component === this.args.treeItem.component) {
      return;
    }

    this.args.treeItem.taskConfigs.forEach(taskConfig => { taskConfig.deleteRecord(); });
    this.taskRepo.createComponentOptionConfigs(this.args.treeItem, component);

    this.taskRepo.handleContextChange(this.args.treeItem, component);
    this.args.treeItem.component = component;
    this.args.contextWasSelected(this.componentOptions[0]);
  }
}
