var _dec, _class, _class2, _descriptor, _descriptor2;
function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
import { action } from '@ember/object';
import { isBlank } from '@ember/utils';
import Component from '@glimmer/component';
import { next } from '@ember/runloop';
import deprecateSubclassing from 'ember-bootstrap/utils/deprecate-subclassing';
import { ref } from 'ember-ref-bucket';
import { tracked } from '@glimmer/tracking';
import { guidFor } from '@ember/object/internals';

/**
 Internal component for modal's markup and event handling. Should not be used directly.

 @class ModalDialog
 @namespace Components
 @extends Glimmer.Component
 @private
 */
let ModalDialog = (_dec = ref('mainNode'), deprecateSubclassing(_class = (_class2 = class ModalDialog extends Component {
  constructor(...args) {
    super(...args);
    /**
     * @property id
     * @type null | HTMLElement
     */
    _initializerDefineProperty(this, "_element", _descriptor, this);
    /**
     * The id of the `.modal-title` element
     *
     * @property titleId
     * @type string
     * @default null
     * @private
     */
    _initializerDefineProperty(this, "titleId", _descriptor2, this);
    /**
     * If true clicking on the backdrop will be ignored and will not close modal.
     *
     * @property ignoreBackdropClick
     * @type boolean
     * @default false
     * @private
     */
    this.ignoreBackdropClick = false;
    /**
     * The target DOM element of mouse down event.
     *
     * @property mouseDownElement
     * @type object
     * @default null
     * @private
     */
    this.mouseDownElement = null;
  }
  /**
   * Name of the size class
   *
   * @property sizeClass
   * @type string
   * @readOnly
   * @private
   */
  get sizeClass() {
    let size = this.args.size;
    return isBlank(size) ? null : `modal-${size}`;
  }
  /**
   * Gets or sets the id of the title element for aria accessibility tags
   *
   * @method getSetTitleID
   * @private
   */
  getOrSetTitleId(modalNode) {
    //Title element may be set by user so we have to try and find it to set the id
    let nodeId = null;
    if (modalNode) {
      const titleNode = modalNode.querySelector('.modal-title');
      if (titleNode) {
        //Get title id of .modal-title
        nodeId = titleNode.id;
        if (!nodeId) {
          //no title id so we set one
          nodeId = `${guidFor(this)}-title`;
          titleNode.id = nodeId;
        }
      }
    }
    this.titleId = nodeId;
  }
  setInitialFocus(element) {
    let autofocus = element && element.querySelector('[autofocus]');
    if (autofocus) {
      next(() => autofocus.focus());
    }
  }
  /**
   * @event onClose
   * @public
   */

  handleKeyDown(e) {
    let code = e.keyCode || e.which;
    if (code === 27 && this.args.keyboard) {
      this.args.onClose?.();
    }
  }
  handleClick(e) {
    if (this.ignoreBackdropClick) {
      this.ignoreBackdropClick = false;
      return;
    }
    if (e.target !== this._element || !this.args.backdropClose) {
      return;
    }
    this.args.onClose?.();
  }
  handleMouseDown(e) {
    this.mouseDownElement = e.target;
  }
  handleMouseUp(e) {
    if (this.mouseDownElement !== this._element && e.target === this._element) {
      this.ignoreBackdropClick = true;
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "_element", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return null;
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "titleId", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return null;
  }
}), _applyDecoratedDescriptor(_class2.prototype, "getOrSetTitleId", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "getOrSetTitleId"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setInitialFocus", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "setInitialFocus"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleKeyDown", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleKeyDown"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleClick", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleClick"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleMouseDown", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleMouseDown"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleMouseUp", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleMouseUp"), _class2.prototype)), _class2)) || _class);
export { ModalDialog as default };