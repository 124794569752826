import Model, { attr, belongsTo } from '@ember-data/model';
import { copyable } from 'eflex/decorators';

@copyable
class HardwareInputDefConfig extends Model {
  @attr('string') state;
  @attr('boolean', { defaultValue: false }) changeState;

  @belongsTo('taskConfig', { async: false, inverse: 'hardwareInputDefConfigs' }) taskConfig;
  @belongsTo('hardwareInputDef', { async: false, inverse: 'hardwareInputDefConfigs' }) hardwareInputDef;

  get copyableOptions() {
    return {
      ignoreAttributes: new Set(['hardwareInputDef', 'taskConfig']),
    };
  }
}

export default HardwareInputDefConfig;
