import { modifier } from 'ember-modifier';

function observeIntersection(element, [callback, optionsObject], hashOptions) {
  const options = { ...optionsObject, ...hashOptions };

  const observer = new IntersectionObserver(callback, options);
  observer.observe(element);

  return function cleanupObserver() {
    observer.disconnect();
  };
}

export default modifier(observeIntersection);
