import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { task as trackedTask } from 'ember-resources/util/ember-concurrency';
import echartsTheme from 'eflex/echarts-theme';
import moment from 'moment-timezone';
import { waitFor } from '@ember/test-waiters';
import FormatDurationAxisBi from 'eflex/util/bi-duration-axis-formatter';
import { clone } from 'ramda';

export default class StationCycleTimeControlChart extends Component {
  @service queryRunner;
  @service intl;

  get params() {
    return {
      stationIds: this.args.stationIds,
      modelIds: this.args.modelIds,
      userIds: this.args.userIds,
      tags: this.args.tags,
      startDate: this.args.startDate,
      endDate: this.args.endDate,
      shiftNames: this.args.shiftNames,
      processData: this.args.processData,
    };
  }

  cycleTimeData = trackedTask(this, this.getCycleTimeData, () => [this.params]);

  // jscpd:ignore-start
  @task({ drop: true })
  @waitFor
  *getCycleTimeData(params) {
    const cycleTimeControlChartQuery = yield this.queryRunner.queryWithParams.perform(params, [
      {
        $sort: { timestamp: 1 },
      },
      {
        $group: {
          _id: null,
          cycleTimes: {
            $push: {
              cycleTime: '$cycleTime',
              timestamp: '$timestamp',
              serialNumber: '$serialNumber',
              station: '$location',
            },
          },
          avg: {
            $avg: '$cycleTime',
          },
          standardDeviation: {
            $stdDevPop: '$cycleTime',
          },
        },
      },
      {
        $project: {
          _id: 0,
          cycleTimes: 1,
          avg: 1,
          ucl: {
            $sum: [
              {
                $multiply: [3, '$standardDeviation'],
              },
              '$avg',
            ],
          },
          lcl: {
            $subtract: [
              '$avg',
              {
                $multiply: [3, '$standardDeviation'],
              },
            ],
          },
        },
      },
    ]);

    // jscpd:ignore-end

    let data = yield this.queryRunner.runQuery.perform('BuildStatuses', cycleTimeControlChartQuery);
    let formattedData = data?.[0]?.cycleTimes.map((datum) => {
      return {
        cycleTime: datum.cycleTime,
        timestamp: moment(datum.timestamp).format('MM/DD/YYYY hh:mm:ss A'),
        serialNumber: datum.serialNumber,
        station: datum.station.captions[0].text,
        ucl: data[0].ucl,
        lcl: data[0].lcl >= 0 ? data[0].lcl : 0,
      };
    });

    return {
      chartData: this.getEchartOptions(formattedData),
      exportData: () =>
        clone(formattedData)?.map((datum) => {
          return {
            cycleTime: moment.duration(datum.cycleTime, 'milliseconds').format('d[d] h[h] m[m] s[s]', {
              precision: 1,
              trim: 'both mid',
            }),
            timestamp: datum.timestamp,
            serialNumber: datum.serialNumber,
            station: datum.station,
          };
        }),
    };
  }

  getEchartOptions(controlChartData) {
    return {
      title: {
        text: this.intl.t('bi.chartLabel.cycleTimeStations'),
      },

      legend: {
        show: true,
        bottom: 5,
      },

      tooltip: {
        trigger: 'axis',
        formatter: (params) => {
          const tooltipData = params[0];
          return `<div style="min-width:285px;">${tooltipData.marker} ${
            params[1].axisValueLabel
          }<span style="float:right;font-weight:bold;">: ${moment
            .duration(tooltipData.value, 'milliseconds')
            .format('d[d] h[h] m[m] s[s]', {
              precision: 1,
              trim: 'both mid',
            })}</span></div>`;
        },
      },

      xAxis: {
        type: 'category',
        data: controlChartData?.map(item => item.timestamp),
        axisLabel: {
          show: true,
          rotate: 75,
          interval: 'auto',
        },
      },

      yAxis: {
        type: 'value',
        scale: true,
        axisLabel: {
          formatter: (value) => FormatDurationAxisBi(value),
        },
      },

      // jscpd:ignore-start

      series: [
        {
          name: 'cycle time',
          data: controlChartData?.map(item => item.cycleTime),
          type: 'line',
          color: echartsTheme.colorPalette.biPurple,
          markLine: {
            data: [{ type: 'average', name: 'Avg' }],
            label: {
              show: true,
              position: 'insideEndTop',
              formatter: (params) =>
                moment.duration(params.value).format('d[d] h[h] m[m] s[s]', {
                  precision: 1,
                  trim: 'both mid',
                }),
            },
          },
          symbol: 'emptyCircle',
          symbolSize: 7,
        },
        {
          name: this.intl.t('bi.chartLegend.lcl'),
          data: controlChartData?.map(item => item.lcl),
          type: 'line',
          symbol: 'none',
          color: echartsTheme.colorPalette.taskRowRed,
        },
        {
          name: this.intl.t('bi.chartLegend.ucl'),
          data: controlChartData?.map(item => item.ucl),
          type: 'line',
          symbol: 'none',
          color: echartsTheme.colorPalette.taskRowRed,
        },
      ],

      // jscpd:ignore-end
    };
  }
}
