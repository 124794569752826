import ApplicationSerializer from 'eflex/serializers/application';

export default class TriggerSerializer extends ApplicationSerializer {
  attrs = {
    triggerConfigs: {
      deserialize: false,
      serialize: false,
    },
    task: {
      deserialize: false,
      serialize: false,
    },
    station: {
      deserialize: false,
      serialize: false,
    },
    oeeButton: {
      deserialize: false,
      serialize: false,
    },
  };
}
