var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
import { action } from '@ember/object';
import Component from '@glimmer/component';
import arg from 'ember-bootstrap/utils/decorators/arg';
import { tracked } from '@glimmer/tracking';
import { getOwnConfig, macroCondition } from '@embroider/macros';
import { trackedRef } from 'ember-ref-bucket';

/**
 Internal (abstract) component for contextual help markup. Should not be used directly.

 @class ContextualHelpElement
 @namespace Components
 @extends Glimmer.Component
 @private
 */
let ContextualHelpElement = (_dec = trackedRef('popperElement'), (_class = class ContextualHelpElement extends Component {
  constructor(...args) {
    super(...args);
    /**
     * @property placement
     * @type string
     * @default 'top'
     * @public
     */
    _initializerDefineProperty(this, "placement", _descriptor, this);
    _initializerDefineProperty(this, "actualPlacement", _descriptor2, this);
    /**
     * @property fade
     * @type boolean
     * @default true
     * @public
     */
    _initializerDefineProperty(this, "fade", _descriptor3, this);
    /**
     * @property showHelp
     * @type boolean
     * @default false
     * @public
     */
    _initializerDefineProperty(this, "showHelp", _descriptor4, this);
    /**
     * If true component will render in place, rather than be wormholed.
     *
     * @property renderInPlace
     * @type boolean
     * @default true
     * @public
     */
    /**
     * Which element to align to
     *
     * @property popperTarget
     * @type {string|HTMLElement}
     * @public
     */
    /**
     * @property autoPlacement
     * @type boolean
     * @default true
     * @public
     */
    /**
     * The DOM element of the viewport element.
     *
     * @property viewportElement
     * @type object
     * @public
     */
    /**
     * Take a padding into account for keeping the tooltip/popover within the bounds of the element given by `viewportElement`.
     *
     * @property viewportPadding
     * @type number
     * @default 0
     * @public
     */
    /**
     * @property arrowClass
     * @private
     */
    this.arrowClass = 'arrow';
    this.placementClassPrefix = '';
    this.offset = [0, 0];
    _initializerDefineProperty(this, "popperElement", _descriptor5, this);
  }
  /**
   * popper.js modifier config
   *
   * @property popperModifiers
   * @type {object}
   * @private
   */
  get popperOptions() {
    let options = {
      placement: this.placement,
      onFirstUpdate: this.updatePlacement
    };

    // We need popperElement, so we wait for this getter to recompute once it's available
    if (!this.popperElement) {
      return options;
    }
    options.modifiers = [{
      name: 'arrow',
      options: {
        element: this.popperElement.querySelector(`.${this.arrowClass}`),
        padding: 4
      }
    }, {
      name: 'offset',
      options: {
        offset: this.offset
      }
    }, {
      name: 'preventOverflow',
      enabled: this.args.autoPlacement,
      options: {
        boundary: this.args.viewportElement,
        padding: this.args.viewportPadding
      }
    }, {
      name: 'flip',
      enabled: this.args.autoPlacement
    }, {
      name: 'onChange',
      enabled: true,
      phase: 'afterWrite',
      fn: this.updatePlacement
    }];
    return options;
  }
  get actualPlacementClass() {
    let ending = this.actualPlacement;
    if (macroCondition(getOwnConfig().isBS5)) {
      if (ending === 'right') {
        ending = 'end';
      }
      if (ending === 'left') {
        ending = 'start';
      }
    }
    return this.placementClassPrefix + ending;
  }
  updatePlacement(state) {
    // normalize argument
    state = state.state ?? state;
    if (this.actualPlacement === state.placement) {
      return;
    }
    this.actualPlacement = state.placement;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "placement", [arg], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return 'top';
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "actualPlacement", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return this.args.placement;
  }
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "fade", [arg], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return true;
  }
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "showHelp", [arg], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "popperElement", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "updatePlacement", [action], Object.getOwnPropertyDescriptor(_class.prototype, "updatePlacement"), _class.prototype)), _class));
export { ContextualHelpElement as default };