import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { task as trackedTask } from 'ember-resources/util/ember-concurrency';
import moment from 'moment-timezone';
import { formatStateColumnData } from 'eflex/util/bi-format-oee-state-cell';
import { waitFor } from '@ember/test-waiters';
import { clone } from 'ramda';

export default class OeeStatesTable extends Component {
  // jscpd:ignore-start
  @service queryRunner;
  @service intl;
  @service store;

  get params() {
    return {
      stationIds: this.args.stationIds,
      startDate: this.args.startDate,
      endDate: this.args.endDate,
      shiftNames: this.args.shiftNames,
      buttonIds: this.args.buttonIds,
      states: this.args.states,
    };
  }

  get tableConfiguration() {
    return {
      columns: [
        {
          title: this.intl.t('startTime'),
          field: 'startDate',
          formatter: 'textarea',
          resizable: true,
        },
        {
          title: this.intl.t('finishTime'),
          field: 'endDate',
          formatter: 'textarea',
          resizable: true,
        },
        {
          title: this.intl.t('scheduler.duration'),
          field: 'duration',
          formatter: 'textarea',
          resizable: true,
        },
        { title: this.intl.t('username'), field: 'username', resizable: true },
        {
          title: this.intl.t('state'),
          field: 'state',
          formatter: (cell) => formatStateColumnData(cell),
          hozAlign: 'center',
          vertAlign: 'center',
          width: 125,
          resizable: true,
          tooltip: false,
        },
      ],
      childConfiguration: this.childConfiguration,
      options: {
        paginationSize: false,
        groupBy: 'stationId',
        groupToggleElement: 'header',
        groupHeader: (value) => {
          return this.store.peekRecord('station', value).name.toUpperCase();
        },
      },
    };
  }

  get childConfiguration() {
    return {
      columns: [
        {
          title: this.intl.t('area'),
          field: 'areaName',
          formatter: 'textarea',
          resizable: true,
          headerSort: false,
        },
        {
          title: this.intl.t('group'),
          field: 'groupName',
          formatter: 'textarea',
          resizable: true,
          headerSort: false,
        },
        {
          title: this.intl.t('bi.chartLabel.oeeFaultCodes'),
          formatter: 'textarea',
          field: 'faultCode',
          resizable: true,
          headerSort: false,
        },
        {
          title: this.intl.t('bi.chartLabel.oeeFaultMessages'),
          field: 'faultDescription',
          formatter: 'textarea',
          resizable: true,
          headerSort: false,
        },
      ],
    };
  }

  allOeeStates = trackedTask(this, this.getAllStates, () => [this.params]);

  @task({ restartable: true })
  @waitFor
  *getAllStates() {
    const allStatesQuery = yield this.queryRunner.queryWithParams.perform(
      this.params,
      [
        {
          $count: 'count',
        },
      ],
      true,
    );

    return yield this.queryRunner.runQuery.perform('OeeStates', allStatesQuery);
  }

  @task({ drop: true })
  @waitFor
  *getOeeStateData(params = {}) {
    let sortStep;

    if (params.sort.length > 0) {
      const dir = params.sort[0].dir === 'asc' ? 1 : -1;
      const field = params.sort[0].field;
      sortStep = { [field]: dir };
    } else {
      sortStep = { endDate: -1 };
    }

    const query = yield this.queryRunner.queryWithParams.perform(
      this.params,
      [
        { $skip: params.skip ?? 0 },
        { $limit: params.size ?? 100_000 },
        {
          $match: {
            endDate: {
              $ne: null,
            },
          },
        },
        {
          $lookup: {
            from: 'LocationBases',
            localField: 'station._id',
            foreignField: '_id',
            as: 'station',
          },
        },
        {
          $unwind: {
            path: '$station',
          },
        },
        {
          $addFields: {
            areaId: {
              $toObjectId: {
                $arrayElemAt: [
                  {
                    $split: ['$station.path', '#'],
                  },
                  0,
                ],
              },
            },
            groupId: {
              $toObjectId: {
                $arrayElemAt: [
                  {
                    $split: ['$station.path', '#'],
                  },
                  1,
                ],
              },
            },
          },
        },
        {
          $lookup: {
            from: 'LocationBases',
            localField: 'areaId',
            foreignField: '_id',
            as: 'area',
          },
        },
        {
          $unwind: {
            path: '$area',
          },
        },
        {
          $lookup: {
            from: 'LocationBases',
            localField: 'groupId',
            foreignField: '_id',
            as: 'group',
          },
        },
        {
          $unwind: {
            path: '$group',
          },
        },
        {
          $project: {
            _id: 0,
            areaName: {
              $last: '$area.captions.text',
            },
            groupName: {
              $last: '$group.captions.text',
            },
            stationId: '$station._id',
            stationName: {
              $last: '$station.captions.text',
            },
            state: true,
            userName: true,
            startDate: true,
            endDate: true,
            duration: {
              $dateDiff: {
                startDate: '$startDate',
                endDate: '$endDate',
                unit: 'millisecond',
              },
            },
            faultCodes: true,
            customButtonColor: true,
          },
        },
        { $sort: sortStep },
      ],
      true,
    );

    const oeeStates = yield this.queryRunner.runQuery.perform('OeeStates', query);
    const rows = oeeStates
      ?.filter((row) => row.endDate)
      .map((state) => ({
        stationId: state.stationId,
        stationName: state.stationName,
        startDate: moment(state.startDate).format('MM/DD/YYYY hh:mm A'),
        endDate: moment(state.endDate).format('MM/DD/YYYY hh:mm A'),
        duration: moment
          .duration(state.duration, 'milliseconds')
          .format('d[d] h[h] m[m] s[s]', { precision: 1, trim: 'both mid' }),
        username: state.userName,
        state,
        isParentRow: true,

        children: [
          {
            areaName: state.areaName,
            groupName: state.groupName,
            faultCode: state.faultCodes?.codes
              ?.map((faultCode) => `${faultCode.code} - ${faultCode.description}`)
              .join(', '),
            faultDescription: state.faultCodes?.note,
          },
        ],
      }));

    return {
      count: this.allOeeStates?.value?.[0]?.count,
      data: rows,
      exportData: () => this.formatExportData(clone(rows)),
    };
    // jscpd:ignore-end
  }

  formatExportData(data) {
    return data.map((row) => {
      const { stationName, startDate, endDate, duration, username, state } = row;
      const { areaName, groupName, faultCode, faultDescription } = row.children[0];

      return {
        stationName,
        areaName,
        groupName,
        startDate,
        endDate,
        duration,
        username,
        state: state.state,
        faultCode: faultCode ?? '',
        faultDescription: faultDescription ?? '',
      };
    });
  }
}
