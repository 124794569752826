import { validator } from '@eflexsystems/ember-tracked-validations';

export default {
  model: [validator('presence', true)],

  target: [
    validator('number', {
      get gte() {
        if (this.model.startTime != null) {
          return this.model.passed;
        } else {
          return 1;
        }
      },
      integer: true,
      allowString: true,
      allowNone: false,
    }),
  ],
};
