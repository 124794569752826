var _dec, _dec2, _class, _class2, _descriptor;
function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
import { tagName } from '@ember-decorators/component';
import Component from '@ember/component';
import defaultValue from 'ember-bootstrap/utils/default-decorator';
import { action, computed } from '@ember/object';
import deprecateSubclassing from 'ember-bootstrap/utils/deprecate-subclassing';

/**
 Anchor element that triggers the parent dropdown to open.
 Use [Components.DropdownButton](Components.DropdownButton.html) if you want a button instead of an anchor tag.

 See [Components.Dropdown](Components.Dropdown.html) for examples.

 @class DropdownToggle
 @namespace Components
 @extends Ember.Component
 @publicø
 */
let DropdownToggle = (_dec = tagName(''), _dec2 = computed('isOpen'), _dec(_class = deprecateSubclassing(_class = (_class2 = class DropdownToggle extends Component {
  constructor(...args) {
    super(...args);
    /**
     * @property inNav
     * @type {boolean}
     * @private
     */
    _initializerDefineProperty(this, "inNav", _descriptor, this);
  }
  get 'aria-expanded'() {
    return this.isOpen ? 'true' : 'false';
  }

  /**
   * When clicking the toggle this action is called.
   *
   * @event onClick
   * @param {*} value
   * @public
   */
  onClick() {}
  handleClick(e) {
    e.preventDefault();
    this.onClick();
  }
  handleKeyDown(e) {
    this.onKeyDown(e);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "inNav", [defaultValue], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _applyDecoratedDescriptor(_class2.prototype, 'aria-expanded', [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, 'aria-expanded'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleClick", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleClick"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleKeyDown", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleKeyDown"), _class2.prototype)), _class2)) || _class) || _class);
export { DropdownToggle as default };