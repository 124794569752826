import { action } from '@ember/object';
import TaskStatuses from 'eflex/constants/task-statuses';
import Component from '@glimmer/component';
import { isBlank } from '@ember/utils';

export default class JemTaskSpecificDisplaySerialNumberTransferDetails extends Component {
  get displayedSerial() {
    return this.args.transferSerialNumber ?? this.args.buildStatus?.serialNumber;
  }

  get showSerial() {
    return !isBlank(this.displayedSerial) && this.args.childStatus?.isFinishedPassed;
  }

  get showInput() {
    return this.args.childStatus?.isStarted && !this.args.task?.hideBarcodeInput;
  }

  get expectedString() {
    return this.args.taskConfig?.expectedString;
  }

  @action
  onSubmit(barcode) {
    if (this.args.disabled) {
      return;
    }

    this.args.onSubmit(barcode, TaskStatuses.GOOD);
  }
}
