import Model, { attr, belongsTo } from '@ember-data/model';
import { caption, arrayAttr } from 'eflex/decorators';

class BomConfig extends Model {
  @attr('number') start;
  @attr('number') length;
  @attr('number') priority;
  @attr('string') value;
  @arrayAttr captions;
  @caption name;

  @belongsTo('bomSource', {
    async: false,
    polymorphic: true,
    inverse: 'configs',
    as: 'bom-config',
  }) bomSource;

  get polymorphic() {
    return true;
  }
}

export default BomConfig;
