import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

export default class WieImageUrl extends Helper {
  @service workInstructionImageRepo;

  compute([id]) {
    return this.workInstructionImageRepo.getWieImageUrl(id);
  }
}
