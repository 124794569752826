import Component from '@glimmer/component';
import { trackedFunction } from 'ember-resources/util/function';
import { waitForPromise } from '@ember/test-waiters';

export default class DiffModal extends Component {
  formattedDiff = trackedFunction(this, async () => {
    const left = this.args.left;
    const right = this.args.right;

    const jsondiffpatch = await waitForPromise(import('jsondiffpatch'));

    const delta = jsondiffpatch
      .create({
        objectHash(obj, index) {
          return obj.id ?? obj._id ?? obj?.eflex?.id ?? obj?.eflex?._id ?? `$$index:${index}`;
        },
      })
      .diff(left, right);

    jsondiffpatch.formatters.html.hideUnchanged();

    return jsondiffpatch.formatters.html.format(delta, left);
  });
}
