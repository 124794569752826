import Model, { belongsTo, hasMany } from '@ember-data/model';
import { copyable } from 'eflex/decorators';

@copyable
class Spindle extends Model {
  @belongsTo('task', { async: false, inverse: 'spindles' }) task;
  @hasMany('spindleConfig', { async: false, inverse: 'spindle', cascadeDelete: true }) spindleConfigs;

  get copyableOptions() {
    return {
      ignoreAttributes: new Set(['task', 'spindleConfigs']),
    };
  }
}

export default Spindle;
