import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { ref } from 'ember-ref-bucket';
import { waitFor } from '@ember/test-waiters';
import { task, timeout } from 'ember-concurrency';
import getDelayTime from 'eflex/util/get-delay-time';

export default class ConfigTable extends Component {
  @ref('scrollArea') scrollArea;
  @tracked topVisibility = 'o-0';
  @tracked bottomVisibility = 'opacity-1';

  @task({ keepLatest: true })
  @waitFor
  *onTopIntersection([{ isIntersecting }]) {
    if (isIntersecting) {
      this.topVisibility = 'o-0';
    } else {
      this.topVisibility = 'opacity-1';
    }

    yield timeout(getDelayTime(150));
  }

  @task({ keepLatest: true })
  @waitFor
  *onBottomIntersection([{ isIntersecting }]) {
    if (isIntersecting) {
      this.bottomVisibility = 'o-0';
    } else {
      this.bottomVisibility = 'opacity-1';
    }

    yield timeout(getDelayTime(150));
  }
}
