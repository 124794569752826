import Model, { attr, hasMany } from '@ember-data/model';
import WorkInstructionFolderValidations from 'eflex/validations/work-instruction-folder';
import { buildValidations } from '@eflexsystems/ember-tracked-validations';
import { tracked } from '@glimmer/tracking';
import { arrayAttr } from 'eflex/decorators';

@buildValidations(WorkInstructionFolderValidations)
class WorkInstructionFolder extends Model {
  @attr('string') name;
  @attr('date') createdAt;
  @attr('date') updatedAt;

  @hasMany('workInstruction', { async: false, inverse: 'folder' }) workInstructions;

  @arrayAttr wieEditorTags;
  @arrayAttr wieApproverTags;
  @arrayAttr wieDeployerTags;

  @tracked isSelected = false;

  get isFolder() {
    return true;
  }
}

export default WorkInstructionFolder;
