import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { TrackedArray } from 'tracked-built-ins';

export default class DebugHardwareMessagesController extends Controller {
  @tracked showJsonModal = false;
  @tracked displayedMessage;
  @tracked params = {};
  @tracked searchTerm;
  @tracked model = new TrackedArray();

  @action
  onShowJsonModal(msg) {
    if (msg == null) {
      return;
    }

    Object.assign(this, {
      showJsonModal: true,
      displayedMessage: msg,
    });
  }
}
