import { task } from 'ember-concurrency';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import { dedupeTracked } from 'tracked-toolbox';
import DataTypes, { DECIMAL_LENGTH } from 'eflex/constants/component-data-types';
import { isPresent } from '@ember/utils';
import { incrementPriority, decrementPriority, getNextPriority } from 'eflex/util/component-priority-helpers';
import BarcodeBomSource from 'eflex/components/bom-sources/barcode-bom-source';
import GepicsBomSource from 'eflex/components/bom-sources/gepics-bom-source';
import OracleDellBoomiBomSource from 'eflex/components/bom-sources/oracle-dell-boomi-bom-source';
import RestBomSource from 'eflex/components/bom-sources/rest-bom-source';
import RestPullBomSource from 'eflex/components/bom-sources/rest-pull-bom-source';

export default class BomSourceController extends Controller {
  @service componentRepo;
  @service bomSourceRepo;
  @service store;
  @service notifier;
  @service validationErrorNotifier;
  @service router;
  @service currentUser;

  @tracked bomSource;
  @tracked showOptionsPanel = false;
  @dedupeTracked selectedConfig;

  variableDataTypes = DataTypes;
  queryParams = ['selectedConfigId'];

  get selectedConfigId() {
    return this.selectedConfig?.id;
  }

  set selectedConfigId(id) {
    if (id == null || this.selectedConfig?.id === id) {
      return;
    }

    this.selectedConfig = this.store.peekRecord('component', id) ?? this.store.peekRecord('bomVariable', id);
  }

  get configComponent() {
    switch (this.bomSource.constructor.modelName) {
      case 'barcode-bom-source': {
        return BarcodeBomSource;
      }
      case 'gepics-bom-source': {
        return GepicsBomSource;
      }
      case 'oracle-dell-boomi-bom-source': {
        return OracleDellBoomiBomSource;
      }
      case 'rest-bom-source': {
        return RestBomSource;
      }
      case 'rest-pull-bom-source': {
        return RestPullBomSource;
      }
      default: {
        return null;
      }
    }
  }

  get selectedTasksCount() {
    return this.selectedConfig?.tasks?.length;
  }

  get hasSelectedTasks() {
    return this.selectedTasksCount > 0;
  }

  get nonDeletedComponents() {
    return this.bomSource?.components
      .filter(component => !component.isDeleted && !component.isAlwaysRun);
  }

  get nonDeletedVariables() {
    return this.bomSource.variables.filter(item => !item.isDeleted);
  }

  get disabled() {
    return this.currentUser.isNotAdmin;
  }

  get isDirty() {
    return this.bomSource?.isDirty || this.bomSource?.components.some(item => item.isDirty);
  }

  get isInvalid() {
    return this.bomSource?.isSelfOrChildInvalid ?? false;
  }

  // TODO: It seem like we're redo-ing this logic in a lot of places
  @task
  @waitFor
  *save() {
    if (this.isInvalid) {
      this.validationErrorNotifier.sendErrors(
        [this.bomSource].concat(this.nonDeletedComponents),
      );
      return;
    }

    yield this.bomSourceRepo.save.perform(this.bomSource);
  }

  @action
  rollback(fromTransitionModal = false) {
    if (this.bomSource.isDeleted) {
      return;
    }

    this.bomSource.rollbackAttributes();

    this.bomSource.areas.forEach(area => { area.rollbackAttributes(); });

    if (this.bomSource.isDeleted) {
      this.bomSource = null;

      if (!fromTransitionModal) {
        this.router.transitionTo('bomSources.index');
      }
    }
  }

  @action
  incrementPriority(config) {
    incrementPriority(config, config.peers);
  }

  @action
  decrementPriority(config) {
    decrementPriority(config, config.peers);
  }

  @action
  addComponent() {
    this.selectedConfig = this.store.createRecord('component', {
      bomSource: this.bomSource,
      priority: getNextPriority(this.nonDeletedComponents),
    });
  }

  @action
  addComplexComponent() {
    this.selectedConfig = this.store.createRecord('component', {
      bomSource: this.bomSource,
      priority: getNextPriority(this.nonDeletedComponents),
      isComplex: true,
    });
  }

  @action
  addVariable() {
    this.selectedConfig = this.store.createRecord('bomVariable', {
      bomSource: this.bomSource,
      priority: getNextPriority(this.nonDeletedVariables),
      length: DECIMAL_LENGTH,
    });
  }

  @action
  deleteConfig(config) {
    const errors = this.componentRepo.delete(config, this.nonDeletedComponents);

    if (isPresent(errors)) {
      errors?.forEach(captionParams => {
        this.notifier.sendError('components.deletionWarning', captionParams);
      });
      return;
    }

    this.showOptionsPanel = false;
    this.selectedConfig = null;
  }

  @action
  onOpenOptionsPanel(component) {
    Object.assign(this, {
      selectedConfig: component,
      showOptionsPanel: true,
    });
  }
}
