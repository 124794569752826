import AuthenticatedRoute from 'eflex/util/authenticated-route';
import { inject as service } from '@ember/service';

export default class BiRoute extends AuthenticatedRoute {
  @service store;

  async beforeModel() {
    await super.beforeModel(...arguments);
    return await this.store.findAll('schedule');
  }
}
