import { task, timeout } from 'ember-concurrency';
import EchartsTheme from 'eflex/echarts-theme';
import Component from '@glimmer/component';
import getDelayTime from 'eflex/util/get-delay-time';
import { waitFor } from '@ember/test-waiters';
import { unregisterDestructor, registerDestructor } from '@ember/destroyable';
import exportToCsv from 'eflex/util/export-to-csv';

export default class EChart extends Component {
  #destructor;

  @task({ restartable: true })
  @waitFor
  *onResize(element) {
    yield timeout(getDelayTime(100));
    const ECharts = yield this._loadEcharts.perform();
    ECharts.getInstanceByDom(element)?.resize();
  }

  @task({ restartable: true })
  @waitFor
  *startAutoRefresh(element, [onClick, option]) {
    if (window.isTesting || window.isIntegrationTest) {
      return;
    }

    while (this.args.autoRefresh > 0) {
      yield timeout(this.args.autoRefresh);
      yield this.createChart.perform(element, [onClick, option]);
    }
  }

  @task({ enqueue: true })
  @waitFor
  *createChart(element, [onClick, option]) {
    const ECharts = yield this._loadEcharts.perform();

    let chart = ECharts.getInstanceByDom(element);
    this.#destroyChart(chart);

    const options = { renderer: 'canvas' };

    if (window.isTesting) {
      options.width = 1;
      options.height = 1;
    }

    chart = ECharts.init(element, EchartsTheme, options);

    this.#destructor = registerDestructor(this, () => {
      this.#destroyChart(chart);
    });

    chart.setOption(option ?? {});

    if (onClick) {
      chart.on('click', (param) => {
        onClick(param?.dataIndex, chart);
      });
    }
  }

  @task
  @waitFor
  *_loadEcharts() {
    const echarts = yield import('echarts');
    window.ECharts ??= echarts;
    return echarts;
  }

  #destroyChart(chart) {
    if (!this.isDestroyed && !this.isDestroying && this.#destructor) {
      unregisterDestructor(this, this.#destructor);
    }

    if (!chart) {
      return;
    }

    chart.off('click');
    chart.dispose();
  }

  @task
  @waitFor
  *exportToCsv() {
    yield exportToCsv(this.args.exportData, this.args.option?.title?.text);
  }
}
