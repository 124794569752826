export default [
  { lang: 'english', label: 'English', code: 'en-us' },
  { lang: 'spanish', label: 'Español', code: 'es' },
  { lang: 'german', label: 'Deutsch', code: 'de' },
  { lang: 'chinese', label: '中國', code: 'zh' },
  { lang: 'hungarian', label: 'Magyar', code: 'hu' },
  { lang: 'portuguese', label: 'Português', code: 'pt' },
  { lang: 'korean', label: '한국어', code: 'ko' },
  { lang: 'japanese', label: '日本の', code: 'ja' },
  { lang: 'hindi', label: 'हिन्दी', code: 'hi' },
  { lang: 'polish', label: 'Polskie', code: 'pl' },
  { lang: 'french', label: 'Français', code: 'fr' },
];
