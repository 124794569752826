import BomSource from 'eflex/models/bom-source';
import { attr } from '@ember-data/model';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { serialNumberValidations } from 'eflex/validations/bom-sources';

@buildValidations({
  ...serialNumberValidations,

  dataTag: [
    validator('presence', { presence: true }),

    validator('number', {
      gte: 0,
      allowString: true,
      integer: true,
    }),
  ],

  inputDevice: [
    validator('presence', { presence: true }),
  ],

  outputDevice: [
    validator('presence', { presence: true }),
  ],
})
class GepicsBomSource extends BomSource {
  @attr('number') serialNumberStart;
  @attr('number') serialNumberLength;
  @attr('string') inputDevice;
  @attr('string') outputDevice;
  @attr('number') dataTag;

  get isGepics() {
    return true;
  }

  get usesVariableValidation() {
    return true;
  }

  get typeName() {
    return 'gepicsBomSource';
  }
}

export default GepicsBomSource;
