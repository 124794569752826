import { _ as _applyDecoratedDescriptor, a as _initializerDefineProperty } from '../applyDecoratedDescriptor-cd7b9b1b.js';
import { _ as _defineProperty } from '../defineProperty-1e8516b1.js';
import { setComponentTemplate } from '@ember/component';
import { hbs } from 'ember-cli-htmlbars';
import Component from '@glimmer/component';
import { inject } from '@ember/service';
import { next } from '@ember/runloop';
import { Resource } from 'ember-resources';
import { registerDestructor } from '@ember/destroyable';

var TEMPLATE = hbs("{{#if this.renderInPlace}}\n  {{yield}}\n{{else if this.target}}\n  {{#if this.target.multiple}}\n    {{#in-element this.target.element insertBefore=null}}\n      {{yield}}\n    {{/in-element}}\n  {{else}}\n    {{#in-element this.target.element}}\n      {{yield}}\n    {{/in-element}}\n  {{/if}}\n{{/if}}\n");

var _dec, _class, _descriptor;
let PortalTrackerResource = (_dec = inject('-portal'), (_class = class PortalTrackerResource extends Resource {
  constructor(owner) {
    super(owner);

    _initializerDefineProperty(this, "portalService", _descriptor, this);

    _defineProperty(this, "_target", void 0);

    registerDestructor(this, () => this._target && this.portalService.unregisterPortal(this._target));
  }

  get target() {
    return this._target ? this.portalService.getTarget(this._target) : undefined;
  }

  modify([newTarget]) {
    const previousTarget = this._target;
    next(() => {
      this.portalService.registerPortal(newTarget);

      if (previousTarget) {
        this.portalService.unregisterPortal(previousTarget);
      }
    });
    this._target = newTarget;
  }

}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "portalService", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class));
class Portal extends Component {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "tracker", PortalTrackerResource.from(this, () => [this.args.target]));
  }

  get target() {
    return this.tracker.target;
  }

  get renderInPlace() {
    return this.args.renderInPlace === true || !this.target && this.args.fallback === 'inplace';
  }

}
setComponentTemplate(TEMPLATE, Portal);

export { Portal as default };
