import Hardware from 'eflex/models/hardware';
import { attr } from '@ember-data/model';
import { isBlank } from '@ember/utils';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { validIpAddress } from 'eflex/constants/regex';
import verifyAddress from 'eflex/validations/ip-validator';
import portValidation from 'eflex/validations/port';
import { getOwner } from '@ember/application';

@buildValidations({
  name: [validator('presence', true)],

  port: [
    portValidation,
    validator('presence', true),
    validator('inline', {
      validate(port, options, datalogic) {
        return verifyAddress(datalogic, getOwner(datalogic).lookup('service:hardwareRepo').datalogics);
      },
    }),
  ],

  ipAddress: [
    validator('presence', true),
    validator('format', {
      regex: validIpAddress,
      allowBlank: false,
    }),
    validator('inline', {
      validate(ipAddress, options, datalogic) {
        return verifyAddress(datalogic, getOwner(datalogic).lookup('service:hardwareRepo').datalogics);
      },
    }),
  ],
})
class Datalogic extends Hardware {
  @attr('string') ipAddress;
  @attr('number', { defaultValue: 51_236 }) port;

  get isBarcodeHardware() {
    return true;
  }

  get isConfigured() {
    return !isBlank(this.ipAddress) && !isBlank(this.port);
  }
}

export default Datalogic;
