import ApplicationSerializer from 'eflex/serializers/application';

export default class BomSourceSerializer extends ApplicationSerializer {
  attrs = {
    areas: {
      serialize: 'ids',
      deserialize: 'ids',
    },
  };
}
