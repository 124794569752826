import Helper from '@ember/component/helper';
import { registerDestructor } from '@ember/destroyable';
import config from 'eflex/config/environment';

export default class BeforeUnload extends Helper {
  #hasListener;

  compute(positional, { isDirty, onBeforeUnload }) {
    if (window.isIntegrationTest || config.environment !== 'production') {
      return;
    }

    if (this.#hasListener == null) {
      registerDestructor(this, () => {
        this.#removeListener(onBeforeUnload);
      });
    }

    if (Boolean(isDirty) && !this.#hasListener) {
      this.#hasListener = true;
      addEventListener('beforeunload', onBeforeUnload);
    } else {
      this.#removeListener(onBeforeUnload);
    }
  }

  #removeListener(onBeforeUnload) {
    if (!this.#hasListener) {
      return;
    }

    this.#hasListener = false;
    removeEventListener('beforeunload', onBeforeUnload);
  }
}
