import Component from '@glimmer/component';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';

export default class NotificationLogAcknowledgeModal extends Component {
  @service systemConfig;

  get showChangeNotificationModal() {
    return this.systemConfig.jem?.changeNotifications &&
      !this.args.stationIsRunning &&
      isPresent(this.args.logs);
  }

  getDisplayUserName = (userFragment) => {
    if (!userFragment) {
      return null;
    }

    const fullName = `${userFragment.firstName ?? ''} ${userFragment.lastName ?? ''}`;

    if (fullName.trim().length > 0) {
      return fullName;
    } else {
      return userFragment.userName;
    }
  };
}
