import moment from 'moment-timezone';
import { helper } from '@ember/component/helper';

export default helper(function formatTime([value, format]) {
  if (!value) {
    return null;
  }

  format ??= 'LTS';

  return moment(new Date(value)).format(format);
});
