import { validator } from '@eflexsystems/ember-tracked-validations';
import { isPresent } from '@ember/utils';

export default {
  title: [
    validator('length', {
      min: 1,
      max: 10,
      allowBlank: false,
    }),
    validator('presence', {
      get disabled() {
        return isPresent(this.model.model);
      },
      presence: true,
      ignoreBlank: true,
    }),
  ],
};
