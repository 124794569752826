import ApplicationSerializer from 'eflex/serializers/application';

export default class JemProcessDataDefSerializer extends ApplicationSerializer {
  attrs = {
    task: {
      serialize: false,
      deserialize: false,
    },
    jemProcessDataDefConfigs: {
      serialize: false,
      deserialize: false,
    },
    variableMappings: {
      serialize: false,
      deserialize: false,
    },
    edhrMappings: {
      serialize: false,
      deserialize: false,
    },
  };
}
