import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { includes } from 'ramda';

export default class BiPanelShifts extends Component {
  @service scheduleRepo;

  get shifts() {
    const set = new Set();
    this.scheduleRepo.schedules.forEach((schedule) => {
      if (this.checkShiftAvailability(schedule)) {
        set.add(schedule.text);
      }
    });
    return [...set];
  }

  checkShiftAvailability(shift) {
    if (new Date(shift.startDate) > new Date(this.args.endDate) ||
      new Date(shift.endDate) < new Date(this.args.startDate)) {
      return false;
    }

    shift.stations?.forEach((station) => {
      if (!includes(station, this.args.stationIds)) {
        return false;
      }
    });

    return true;
  }
}
