import { ALLOWED_EXTENSION_MAPPINGS } from 'eflex/constants/allowed-file-upload-types';
import { waitForPromise } from '@ember/test-waiters';

export default async function (url) {
  let response;
  const extension = url.split('.').pop().split(/[#?]/)[0]?.toLowerCase();

  if (ALLOWED_EXTENSION_MAPPINGS[extension] != null) {
    return ALLOWED_EXTENSION_MAPPINGS[extension];
  }

  try {
    response = await waitForPromise(fetch(url, { method: 'HEAD' }));
  } catch {
    return 'text/html';
  }

  return response.headers.get('content-type');
}
