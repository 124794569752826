import { task, timeout } from 'ember-concurrency';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';
import SerialNumberStatuses from 'eflex/constants/serial-number-statuses';
import pProps from 'p-props';
import AuthenticatedRoute from 'eflex/util/authenticated-route';
import { waitFor } from '@ember/test-waiters';
import { TrackedArray } from 'tracked-built-ins';

export default class SerialNumberMonitorBomSourceRoute extends AuthenticatedRoute {
  @service notifier;
  @service bomSourceRepo;
  @service store;
  @service eventBus;
  @service buildDataRepo;

  @task
  @waitFor
  *fetch(e) {
    const data = yield this.getData.perform(e);
    if (this.controller.selectAll) {
      data.forEach(datum => { datum.isSelected = true; });
    }
    this.controller.buildData.push(...data);
  }

  @task
  @waitFor
  *search() {
    this.clean();

    try {
      const data = yield this.getData.perform({ skip: 0, take: 30 });
      if (this.controller.selectAll) {
        data.forEach(datum => { datum.isSelected = true; });
      }
      this.controller.buildData = new TrackedArray(data);
      this.eventBus.trigger('resetPagination');
    } catch (e) {
      console.error(e);
      this.notifier.sendError('logs.searchFailed');
    } finally {
      this.controller.selectAll = false;
    }

    if (isEmpty(this.controller.buildData)) {
      this.notifier.sendError('logs.noData');
    } else {
      this.controller.selectedSerialNumber = this.controller.buildData[0];
    }

    yield timeout(0);
  }

  @task
  @waitFor
  *searchWithParams(serialNumber, statusCategory) {
    Object.assign(this.controller.params, {
      serialNumber,
      statusCategory,
    });
    yield this.search.perform();
  }

  @task
  @waitFor
  *getData(params = {}) {
    const controllerParams = this.controller?.params ?? {};
    params = Object.assign({ skip: 0, take: 30 }, controllerParams, params);
    return [...(yield this.store.query('buildDatum', params))];
  }

  model(params) {
    return pProps({
      bomSource: this.bomSourceRepo.peekRecord(params.id),
      buildData: this.getData.perform({ bomSource: params.id }),
    });
  }

  setupController(controller, { bomSource, buildData }) {
    Object.assign(this.controller, {
      params: {
        bomSource: bomSource.id,
        statusCategory: SerialNumberStatuses.Queued.status,
      },
      selectedBomSource: bomSource,
      buildData: new TrackedArray(buildData),
      selectedSerialNumber: buildData[0],
    });

    Object.assign(this.controllerFor('serialNumberMonitor'), {
      selectedBomSource: bomSource,
      statusCategory: SerialNumberStatuses.Queued.status,
      selectedSerialNumber: buildData[0],
    });

    if (isEmpty(buildData)) {
      this.notifier.sendError('logs.noData');
    }
  }

  resetController(controller, isExiting) {
    if (!isExiting) {
      return;
    }

    this.clean();
    Object.assign(controller, {
      selectedBomSource: null,
      selectedSerialNumber: null,
      showBomBuilder: false,
      params: null,
      searchTerm: null,
    });
  }

  clean() {
    // using unloadAll throws an assertion when saving the same reloaded records later
    this.buildDataRepo.buildData.forEach(buildData => {
      buildData.unloadRecord();
    });

    Object.assign(this.controller, {
      selectedSerialNumber: null,
      buildData: new TrackedArray(),
      panelBuildDatum: null,
    });
  }
}
