import { validator } from '@eflexsystems/ember-tracked-validations';
import rangesOverlap from 'eflex/util/ranges-overlap';
import { checkUniqueField } from 'eflex/util/validators';
import { getOwner } from '@ember/application';

const validateBomSourceNoOverlapping = function() {
  return validator('inline', {
    validate(value, options, bomSource) {
      for (const mapping of bomSource.nonDeletedConfigs) {
        if (
          rangesOverlap(
            bomSource.serialNumberStart,
            bomSource.serialNumberLength,
            mapping.start,
            mapping.length,
          )
        ) {
          return getOwner(bomSource).lookup('service:intl').t('plant.area.serialNumberFieldOverlap');
        }
      }

      return true;
    },
  });
};

export const serialNumberValidations = {
  serialNumberStart: [
    validator('presence', { presence: true }),

    validator('number', {
      gt: 0,
      allowString: true,
      allowNone: false,
      integer: true,
    }),

    validateBomSourceNoOverlapping(),
  ],

  serialNumberLength: [
    validator('presence', { presence: true }),

    validator('number', {
      gt: 0,
      allowString: true,
      allowNone: false,
      integer: true,
    }),

    validateBomSourceNoOverlapping(),
  ],
};

export const sourceValidations = {
  source: [
    validator('presence', { presence: true }),
    validator('inline', {
      validate() {
        const owner = getOwner(this.model);
        const bomSources = owner.lookup('service:bomSourceRepo').allBomSources.filter((source) => {
          return source.source != undefined;
        });

        return checkUniqueField(this.model, bomSources, 'source').result;
      },
    }),
  ],
};
