import Model, { belongsTo, hasMany } from '@ember-data/model';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { isBelongsToRefDirty, rollbackBelongsTo } from 'eflex/util/relationship-helpers';
import { copyable, arrayAttr, caption } from 'eflex/decorators';

@copyable
@buildValidations({
  input: [validator('presence', true)],
})
class HardwareInputDef extends Model {
  @caption name;

  @arrayAttr captions;

  @belongsTo('hardwareIo', { async: false, inverse: 'hardwareInputDefs' }) input;
  @belongsTo('task', { async: false, inverse: 'hardwareInputDefs' }) task;

  @hasMany('hardwareInputDefConfig', {
    async: false,
    inverse: 'hardwareInputDef',
    cascadeDelete: true,
  }) hardwareInputDefConfigs;

  get isDirty() {
    return super.isDirty || isBelongsToRefDirty(this, 'input');
  }

  get isConnected() {
    return this.input?.isConnected;
  }

  get errorIdentifier() {
    return this.task?.name;
  }

  get copyableOptions() {
    return {
      ignoreAttributes: new Set(['task', 'hardwareInputDefConfigs']),
      copyByReference: new Set(['input']),
    };
  }

  rollbackAttributes() {
    rollbackBelongsTo(this, 'input');
    super.rollbackAttributes();
  }
}

export default HardwareInputDef;
