import IMAGE_EXTENSIONS from 'eflex/constants/image-extensions';
import THREED_EXTENSIONS from 'eflex/constants/threed-extensions';

export const ALLOWED_EXTENSION_MAPPINGS = {
  pdf: 'application/pdf',
  mp4: 'video/mp4',
  webm: 'video/webm',
  ogg: 'video/ogg',
  ogv: 'video/ogg',
  html: 'text/html',
  htm: 'text/html',
  svg: 'image/svg+xml',
};

Object.assign(ALLOWED_EXTENSION_MAPPINGS, IMAGE_EXTENSIONS);
Object.assign(ALLOWED_EXTENSION_MAPPINGS, THREED_EXTENSIONS);

export const MAX_UPLOAD_SIZE_MB = 100;
export const VIDEO_EXTENSIONS = ['mp4', 'webm', 'ogg'];
export const ALLOWED_EXTENSIONS = Object.keys(ALLOWED_EXTENSION_MAPPINGS);
export const MAX_UPLOAD_SIZE = MAX_UPLOAD_SIZE_MB * 1024 * 1024;
