import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { taskTypes } from 'eflex/constants/tasks/task-types';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';

export default class JemInstructions extends Component {
  @tracked showFullScreenImage = false;
  @tracked showDeleteTabModal = false;

  @service resizeListener;
  @service stationRepo;
  @service store;

  @cached
  get currentInstructionTriggerConfig() {
    const station = this.args.station;
    const type = this.args.type;

    if (
      this.args.isOnline &&
      !this.args.stationIsRunning &&
      (type === 'task' || type === 'station') &&
      station.loadJemConfiguration != null
    ) {
      return station.loadJemConfiguration;
    }

    if (type === 'station') {
      return this.stationRepo.getJemConfig(station, this.args.model);
    }

    if (type === 'task') {
      const triggerConfig = this.args.currentTaskConfig?.triggerConfigs.find(_triggerConfig =>
        _triggerConfig.constructor.modelName === 'work-instruction-hardware-trigger-config' &&
        _triggerConfig.event === 'start',
      );

      return triggerConfig ?? station.currentInstructionTriggerConfig;
    }

    return this.store.peekRecord('customTabJemConfiguration', type);
  }

  get showImageCaptureTab() {
    return this.args.task?.usesWebCam ||
      this.isWebCamLoad ||
      this.args.childStatus?.taskType === taskTypes.imageCapture;
  }

  get showVisionImageTab() {
    return this.args.childStatus?.taskType === taskTypes.vision;
  }

  get isWebCamLoad() {
    return this.args.isOnline && this.args.station.isWebCamScan && !this.args.stationIsRunning;
  }

  @action
  onDeleteTab() {
    this.args.onDeleteTab(this.currentInstructionTriggerConfig);
    this.showDeleteTabModal = false;
  }

  @action
  enterFullScreen() {
    this.showFullScreenImage = true;
    this.resizeListener.triggerResize();
  }

  @action
  exitFullScreen() {
    this.showFullScreenImage = false;
    this.resizeListener.triggerResize();
  }
}
