import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';

const AUTO_TRIGGER_TYPES = new Set(['captureAdvance', 'taskActive']);

export default class JemWebCamImage extends Component {
  @service eventBus;
  @service mediaDevices;

  @tracked capturedWebcamImage;

  previousTask = this.args.childStatus?.task;

  get task() {
    return this.args.childStatus?.task;
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.mediaDevices.destroyStream.perform();
    this.capturedWebcamImage = null;
    this.eventBus.trigger('clearCapturedImage');
  }

  @action
  onDidUpdate(element, [treeTask]) {
    const previousTask = this.previousTask;
    this.previousTask = treeTask;

    if (!treeTask || !treeTask.usesWebCam || previousTask === treeTask) {
      return;
    }

    this.capturedWebcamImage = null;

    if (this.args.childStatus?.isStarted && AUTO_TRIGGER_TYPES.has(treeTask.triggerType)) {
      this.eventBus.trigger('captureWebcamImage');
    } else {
      this.eventBus.trigger('clearCapturedImage');
    }
  }

  @action
  handleCapture(image) {
    this.capturedWebcamImage = image;

    this.eventBus.trigger('imageCaptured', {
      taskId: this.task.id,
      image,
    });
  }
}
