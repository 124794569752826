import Model, { attr, belongsTo } from '@ember-data/model';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { LineTypes } from 'eflex/constants/work-instructions/tool-props';

@buildValidations({
  type: [validator('inclusion', { in: LineTypes.options })],
})
class WieConfigLines extends Model {
  @belongsTo('wieConfig/stroke', { async: false, inverse: null, embedded: true }) stroke;
  @belongsTo('wieConfig/arrows', { async: false, inverse: null, embedded: true }) arrows;

  @attr('string') type;

  get isDirty() {
    return super.isDirty ||
      this.stroke.isDirty ||
      this.arrows.isDirty;
  }
}

export default WieConfigLines;
