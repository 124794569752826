import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { oeeReports } from 'eflex/constants/bi-report-options';
import moment from 'moment-timezone';
import QueryParams from 'eflex/constants/bi-query-params';

export default class BiOeeController extends Controller {
  // jscpd:ignore-start
  @tracked drawerCollapsed = false;
  @tracked queryParams = Object.keys(QueryParams);
  @tracked userIds = [];
  @tracked stationIds = [];
  @tracked shiftNames = [];
  @tracked beginDate = moment().startOf('day').toISOString();
  @tracked endDate = moment().endOf('day').toISOString();
  @tracked states = [];
  selectedReport = oeeReports.oeeStatesHistory;

  get reportOptions() {
    return Object.values(oeeReports);
  }

  @action
  resetCriteria() {
    Object.assign(this, {
      userIds: [],
      stationIds: [],
      shiftNames: [],
      beginDate: moment().startOf('day').toISOString(),
      endDate: moment().endOf('day').toISOString(),
      states: [],
    });
  }
  // jscpd:ignore-end
}
