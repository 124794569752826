export const FROM_TYPES = [
  {
    label: 'dataType.text',
    value: 'ascii',
  },
  {
    label: 'dataType.number',
    value: 'float',
  },
];

export const TO_TYPES = FROM_TYPES.concat([{
  label: 'variable',
  value: 'variable',
}]);

export const FLOAT_BYTES = 4;
export const MAX_BYTES = 408;
