import { getOwner } from '@ember/application';

// eslint-disable-next-line complexity
export default function validateTaskSequence(taskSequence, options, station) {
  const tasksByRow = new Map();

  station.children.forEach(task => {
    for (let i = task.row; i <= task.rowEnd; i++) {
      if (!tasksByRow.has(i)) {
        tasksByRow.set(i, []);
      }

      tasksByRow.get(i).push(task);
    }
  });

  for (const [_key, rowTasks] of tasksByRow) {
    if (rowTasks.length === 1) {
      continue;
    }

    const seenHardware = new Set();
    const seenInputs = new Set();

    for (const task of rowTasks) {
      const hardwareId = task.hardware?.id;

      if (hardwareId != null && !task.hardware?.allowParallel) {
        if (seenHardware.has(hardwareId)) {
          return getOwner(station).lookup('service:intl').t('logs.invalidTaskSequence');
        }

        seenHardware.add(hardwareId);
      }

      if (task.isErrorProofing) {
        for (const def of task.hardwareInputDefs) {
          const inputId = def.input?.id;
          if (inputId != null) {
            if (seenInputs.has(inputId)) {
              return getOwner(station).lookup('service:intl').t('logs.invalidTaskSequence');
            }

            seenInputs.add(inputId);
          }
        }
      }
    }
  }

  return true;
}
