import { validator } from '@eflexsystems/ember-tracked-validations';

export default {
  programName: [validator('presence', true)],

  x: [
    validator('number', {
      allowString: true,
      allowBlank: true,
    }),
  ],

  y: [
    validator('number', {
      allowString: true,
      allowBlank: true,
    }),
  ],
};
