import { SelectNoteTypes } from 'eflex/constants/jem/note-types';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';

const JemNotesViewModes = {
  view: 'view',
  add: 'add',
};

export default class JemNotesModal extends Component {
  @tracked mode = JemNotesViewModes.view;
  @tracked noteText = { content: null };
  @tracked noteType = SelectNoteTypes[0];

  selectNoteTypes = SelectNoteTypes;
  jemNotesViewModes = JemNotesViewModes;

  @service store;
  @service currentUser;

  @task
  @waitFor
  *save() {
    const record = this.store.createRecord('jemNote', {
      station: this.args.station,
      content: this.noteText.content,
      user: this.currentUser.user,
      type: this.noteType.value,
    });

    yield record.save();
    this.clearNote();
  }

  @action
  clearNote() {
    Object.assign(this, {
      noteText: { content: null },
      mode: JemNotesViewModes.view,
    });
  }
}
