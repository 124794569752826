import AuthenticatedRoute from 'eflex/util/authenticated-route';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';

export default class GridRoute extends AuthenticatedRoute {
  @service notifier;
  @service eventBus;

  take = 30;

  model(queryParams) {
    let params = { skip: 0, take: this.take };
    Object.assign(params, queryParams);
    return this.getData.perform(params);
  }

  resetController(controller) {
    Object.assign(controller, {
      params: null,
      searchTerm: null,
    });
  }

  @task
  @waitFor
  *fetch(e) {
    const data = yield this.getData.perform(e);
    if (this.controller.selectAll) {
      data.forEach(datum => { datum.isSelected = true; });
    }
    this.controller.model.push(...data);
  }

  @task
  @waitFor
  *search(params) {
    this.controller.params = params;

    try {
      const data = yield this.getData.perform({ skip: 0, take: this.take });
      if (this.controller.selectAll) {
        data.forEach(datum => { datum.isSelected = true; });
      }
      this.controller.model = data;
      this.eventBus.trigger('resetPagination');
    } catch (e) {
      console.error(e);
      this.notifier.sendError('logs.searchFailed');
    } finally {
      this.controller.selectAll = false;
    }
  }
}
