// jscpd:ignore-start
import Controller from '@ember/controller';
import QueryParams from 'eflex/constants/bi-query-params';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { andonReports } from 'eflex/constants/bi-report-options';

export default class BiProductionController extends Controller {
  queryParams = Object.keys(QueryParams);

  @tracked drawerCollapsed = false;
  @tracked stationIds = [];
  selectedReport = andonReports.stationSummary;

  get reportOptions() {
    return Object.values(andonReports);
  }

  @action
  resetCriteria() {
    Object.assign(this, {
      stationIds: [],
    });
  }

  // jscpd:ignore-end
}
