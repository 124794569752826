import Model, { attr, belongsTo } from '@ember-data/model';
import { inject as service } from '@ember/service';
import SerialNumberStatuses from 'eflex/constants/serial-number-statuses';
import moment from 'moment-timezone';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { tracked } from '@glimmer/tracking';
import { copyable, arrayAttr } from 'eflex/decorators';
import { clone } from 'ramda';
import { getOwner } from '@ember/application';

@copyable
@buildValidations({
  serialNumber: [
    validator('presence', true),

    validator('length', {
      get is() {
        return this.model.bomSource?.serialNumberLength;
      },
      get disabled() {
        return this.model.bomSource?.constructor.modelName !== 'barcode-bom-source';
      },
    }),

    validator('inline', {
      validate(serialNumber, options, buildDatum) {
        const buildData = getOwner(buildDatum).lookup('service:buildDataRepo').buildData;
        const existingSerial = buildData
          .filter(item => item.id !== buildDatum.id)
          .filter(item => item.bomSource === buildDatum.bomSource)
          .find(item => item.serialNumber === serialNumber);

        if (existingSerial) {
          return buildDatum.intl.t('bomSource.duplicateSerial', { serialNumber });
        }
        return true;
      },
    }),

    validator('ds-error'),
  ],
})
class BuildDatum extends Model {
  @attr('string') copiedId;
  @attr('string') serialNumber;
  @attr('string') copiedSerialNumber;
  @attr('string') status;
  @attr('number') errorCode;
  @attr rawBuildData;
  @attr('date') createdAt;
  @attr('date') updatedAt;
  @attr('boolean') userCreated;
  @arrayAttr components;
  @arrayAttr variables;

  @belongsTo('bomSource', { async: false, polymorphic: true, inverse: null }) bomSource;

  @tracked isSelected = false;

  @service intl;

  get errorIdentifier() {
    return this.serialNumber;
  }

  get canBeDeleted() {
    return !this.isFinished;
  }

  get isFinished() {
    return SerialNumberStatuses.Loaded.status === this.status;
  }

  get timeSinceReceived() {
    return moment(this.createdAt).fromNow();
  }

  get isPartial() {
    return this.components?.some((comp) => comp.option == null);
  }

  get statusDisplay() {
    let selectedStatus = SerialNumberStatuses.Invalid;

    for (const key in SerialNumberStatuses) {
      if (SerialNumberStatuses[key].status === this.status) {
        selectedStatus = SerialNumberStatuses[key];
        break;
      }
    }

    return {
      icon: selectedStatus.icon,
      className: selectedStatus.className,
      label: this.intl.t(`buildDatum.${selectedStatus.label}`),
    };
  }

  get partialDisplay() {
    const status = SerialNumberStatuses.Error;
    return {
      icon: status.icon,
      className: status.className,
      label: this.intl.t(`buildDatum.${status.label}`),
    };
  }

  get copyableOptions() {
    return {
      ignoreAttributes: new Set([
        'createdAt',
        'updatedAt',
      ]),
      copyByReference: new Set(['bomSource']),
      overwrite: {
        copiedId: this.id,
        copiedSerialNumber: this.serialNumber,
        status: SerialNumberStatuses.Queued.status,
        userCreated: true,
        components: clone(this.components),
        variables: clone(this.variables),
      },
    };
  }
}

export default BuildDatum;
