import { setInterval, clearInterval } from 'worker-timers';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class JemTaskSpecificDisplayTimerDetails extends Component {
  @tracked countdown = 0;

  get remainingTime() {
    const countdownTime = this.args.taskConfig?.interlockedTime;
    const cycleTimeInSeconds = this.args.childStatus?.cycleTime / 1000;
    const remaining = Math.max(0, countdownTime - cycleTimeInSeconds);

    if (Number.isNaN(remaining)) {
      return 0;
    }

    return remaining.toFixed(0);
  }

  constructor() {
    super(...arguments);
    this.updateCountdown(null, [
      this.args.taskConfig?.interlockedTime,
      this.args.childStatus?.elapsedTime,
      this.args.childStatus?.isStarted,
    ]);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this._clearTimer();
  }

  @action
  updateCountdown(element, [interlockedTime, elapsedTime, isStarted]) {
    let countdown = interlockedTime - Math.floor(elapsedTime / 1000);
    if (countdown < 0 || Number.isNaN(countdown)) {
      countdown = 0;
    }

    this.countdown = countdown;

    if (isStarted && this.timer == null) {
      this._handleCountdown();
    }
  }

  _clearTimer() {
    if (this.timer == null) {
      return;
    }

    clearInterval(this.timer);
    this.timer = null;
  }

  _handleCountdown() {
    this._clearTimer();

    if (this.countdown > 0) {
      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          if (this.args.buildStatus?.isHolding) {
            return;
          }

          this.countdown = this.countdown - 1;
        } else {
          this._clearTimer();
        }
      }, 1000);
    }
  }
}
