import Modifier from 'ember-modifier';
import { inject as service } from '@ember/service';
import { task, waitForEvent, waitForQueue } from 'ember-concurrency';

export default class OnResizeModifier extends Modifier {
  @service resizeListener;

  #didSetup = false;

  modify(element, positional) {
    if (window.isTesting || this.#didSetup) {
      return;
    }

    this.#didSetup = true;
    this._resizeListener.perform(element, positional[0]);
  }

  @task({ onState: null })
  *_resizeListener(element, callback) {
    while (true) {
      yield waitForEvent(this.resizeListener, 'resized');
      yield waitForQueue('afterRender');
      callback(element);
    }
  }
}
