import { validator } from '@eflexsystems/ember-tracked-validations';
import moment from 'moment-timezone';
import { getOwner } from '@ember/application';

export default {
  text: [
    validator('presence', {
      presence: true,
    }),
  ],

  stations: [
    validator('presence', {
      get disabled() {
        return this.model.isArchived;
      },
      presence: true,
    }),
  ],

  startDate: [
    validator('presence', {
      presence: true,
    }),
    validator('date', {
      before: '2033',
    }),
    validator('inline', {
      get disabled() {
        return !this.model.canEditStart;
      },
      validate(startDate, options, schedule) {
        const now = moment();
        if (now.isAfter(startDate) && !now.isSame(startDate, 'day')) {
          return getOwner(schedule).lookup('service:intl').t('schedules.startDatePastValidation');
        }

        if (moment(startDate).isAfter(schedule.endDate)) {
          return getOwner(schedule).lookup('service:intl').t('schedules.startDateValidation');
        }

        return true;
      },
    }),
  ],

  endDate: [
    validator('presence', {
      presence: true,
    }),
    validator('date', {
      before: '2033',
    }),
    validator('inline', {
      get disabled() {
        return this.model.isArchived;
      },
      validate(endDate, options, schedule) {
        const now = moment();
        if (now.isAfter(endDate) && !now.isSame(endDate, 'day')) {
          return getOwner(schedule).lookup('service:intl').t('schedules.endDatePastValidation');
        }

        if (moment(endDate).isBefore(schedule.startDate)) {
          return getOwner(schedule).lookup('service:intl').t('schedules.endDateValidation');
        }

        return true;
      },
    }),
  ],
};
