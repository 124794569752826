import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class InputsSearchBox extends Component {
  @action
  onInput(val) {
    if (this.args.disabled) {
      return;
    }

    this.args.onInput?.(val);
  }

  @action
  onSearch() {
    if (this.args.disabled) {
      return;
    }

    this.args.onSearch?.(this.args.searchValue);
  }
}
