var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { tracked } from '@glimmer/tracking';
import { assert } from '@ember/debug';
class TrackedStorageImpl {
  constructor(initialValue, isEqual) {
    this._value = this._lastValue = initialValue;
    this._isEqual = isEqual;
  }
}
__decorate([tracked], TrackedStorageImpl.prototype, "_value", void 0);
function tripleEq(a, b) {
  return a === b;
}
export function createStorage(initialValue, isEqual = tripleEq) {
  assert('the second parameter to `createStorage` must be an equality function or undefined', typeof isEqual === 'function');
  return new TrackedStorageImpl(initialValue, isEqual);
}
export function getValue(storage) {
  assert('getValue must be passed a tracked store created with `createStorage`.', storage instanceof TrackedStorageImpl);
  return storage._value;
}
export function setValue(storage, value) {
  assert('setValue must be passed a tracked store created with `createStorage`.', storage instanceof TrackedStorageImpl);
  const {
    _isEqual: isEqual,
    _lastValue: lastValue
  } = storage;
  if (!isEqual(value, lastValue)) {
    storage._value = storage._lastValue = value;
  }
}