import Component from '@glimmer/component';

class JemProductionScheduleLink extends Component {
  get productionCountLabel() {
    const productionSchedule = this.args.productionSchedule;

    if (!productionSchedule) {
      return '--/--';
    }

    let passedDisplay;

    if (productionSchedule?.startTime == null) {
      passedDisplay = '--';
    } else if (productionSchedule.passed == null) {
      passedDisplay = 1;
    } else if (this.args.stationIsRunning && !productionSchedule.isCompleted) {
      passedDisplay = productionSchedule.passed + 1;
    } else {
      passedDisplay = productionSchedule.passed;
    }

    return `${passedDisplay}/${productionSchedule.target}`;
  }
}

export default JemProductionScheduleLink;
