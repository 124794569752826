import Model, { attr } from '@ember-data/model';

export default class BackupHistory extends Model {
  @attr('date') started;
  @attr('date') finished;
  @attr('number') fileSize;
  @attr('string') fileName;
  @attr('string') hostName;
  @attr('string') type;
  @attr('string') captionId;
  @attr('boolean', { defaultValue: true }) hasFile;

  get fileUrl() {
    return `backups/files?name=${this.fileName}`;
  }
}
