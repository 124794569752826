import ApplicationAdapter from 'eflex/adapters/application';

export default class ComponentOptionAdapter extends ApplicationAdapter {
  _getPrefix(modelName, snapshot) {
    const componentId = snapshot.belongsTo('component').id;
    return `${this._buildURL('component', componentId)}}/${this.pathForType(modelName)}`;
  }

  urlForDeleteRecord(id, modelName, snapshot) {
    return `${this._getPrefix(modelName, snapshot)}/${id}`;
  }
}
