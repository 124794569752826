import Model, { attr } from '@ember-data/model';
import { buildValidations, validator } from '@eflexsystems/ember-tracked-validations';

@buildValidations({
  configUri: [
    validator('presence', {
      presence: true,
      get disabled() {
        return !this.model.enabled;
      },
    }),
  ],
  userNameProperty: [
    validator('presence', {
      presence: true,
      get disabled() {
        return !this.model.enabled;
      },
    }),
  ],
})
class SingleSignOnConfig extends Model {
  @attr('string') configUri;
  @attr('string') clientId;
  @attr('string') audience;
  @attr('string') additionalScopes;
  @attr('string') clientSecret;
  @attr('string', { defaultValue: 'preferred_username' }) userNameProperty;
  @attr('string', { defaultValue: 'id_token' }) tokenProperty;
  @attr('boolean', { defaultValue: false }) enabled;
  @attr('boolean', { defaultValue: true }) usePkce;
  @attr('boolean', { defaultValue: true }) useIdTokenForUserInfo;
}

export default SingleSignOnConfig;
