export default {
  userIds: {
    refreshModel: true,
  },
  modelIds: {
    refreshModel: true,
  },
  stationIds: {
    refreshModel: true,
  },
  tags: {
    refreshModel: true,
  },
  beginDate: {
    refreshModel: true,
  },
  endDate: {
    refreshModel: true,
  },
  shiftNames: {
    refreshModel: true,
  },
};
