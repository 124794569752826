import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { all, task } from 'ember-concurrency';
import { getCaptionText } from 'eflex/util/translation-helper';
import { waitFor } from '@ember/test-waiters';
import { removeObject } from 'eflex/util/array-helpers';
import { isEmpty } from '@ember/utils';

const NODE_DEPTH_COLOR = ['#daf1fd', '#8fd6f6', '#56a3d9', '#426070', '#000000'];
const KLAY_NODE_LIMIT = 25;

const COSE_LAYOUT = {
  name: 'cose',
  animate: true,
  nodeOverlap: 85,
  componentSpacing: 10,
  directed: true,
  fit: true,
  nodeDimensionsIncludeLabels: true,
};

const KLAY_LAYOUT = {
  name: 'klay',
  animate: true,
  directed: true,
  fit: true,
  nodeDimensionsIncludeLabels: true,
};

export default class GenealogyDiagram extends Component {
  @service intl;

  #currentPoppers = [];
  #hasLoadedCytoscape = false;
  #cy;
  @tracked cursorType;
  @tracked legend = [];

  @task({ enqueue: true })
  @waitFor
  *renderGraph(element, [marriageData]) {
    this.#cleanup();

    if (!marriageData || isEmpty(marriageData.buildStatuses)) {
      return;
    }

    const { default: cytoscape } = yield import('cytoscape');

    if (!this.#hasLoadedCytoscape) {
      this.#hasLoadedCytoscape = true;
      const [{ default: klay }, { default: popper }, { default: panzoom }] = yield all([
        import('cytoscape-klay'),
        import('cytoscape-popper'),
        import('cytoscape-panzoom'),
      ]);

      cytoscape.use(klay);
      cytoscape.use(popper);
      panzoom(cytoscape);
    }

    const graphData = this.#getGraphData(marriageData);

    let layout = KLAY_LAYOUT;
    if (graphData.nodes.length > KLAY_NODE_LIMIT) {
      layout = COSE_LAYOUT;
    }

    layout.ready = () => {
      this.#renderLegend(marriageData.maxDepth);
    };

    this.#cy = cytoscape({
      container: element.querySelector('.cytoscape-graph'),
      elements: graphData,
      style: [
        {
          selector: 'node',
          style: {
            'background-color': '#77778c',
            label: 'data(id)',
            height: 30,
            width: 30,
            'min-zoomed-font-size': 10,
            'text-valign': 'center',
            'line-color': '#a8eae5',
            'font-size': '8px',
            'font-weight': 'bold',
            'text-outline-width': 2,
            'text-outline-color': '#fff',
            shape: 'ellipse',
          },
        },
        {
          selector: 'edge',
          style: {
            width: 3,
            'curve-style': 'bezier',
            'line-color': '#dedede',
            'target-arrow-color': '#aaa',
            'source-arrow-color': '#aaa',
            'target-arrow-shape': 'triangle',
          },
        },
        {
          selector: 'node.not-married',
          style: {
            'border-width': '2px',
            'border-style': 'solid',
            'border-color': '#666666',
            shape: 'rectangle',
          },
        },
        {
          selector: 'node.depth-0',
          style: {
            height: 80,
            width: 80,
          },
        },
        {
          selector: 'node.depth-1',
          style: {
            'background-color': NODE_DEPTH_COLOR[0],
            height: 50,
            width: 50,
          },
        },
        {
          selector: 'node.depth-2',
          style: {
            'background-color': NODE_DEPTH_COLOR[1],
          },
        },
        {
          selector: 'node.depth-3',
          style: {
            'background-color': NODE_DEPTH_COLOR[2],
          },
        },
        {
          selector: 'node.depth-4',
          style: {
            'background-color': NODE_DEPTH_COLOR[3],
          },
        },
        {
          selector: 'node.depth-5',
          style: {
            'background-color': NODE_DEPTH_COLOR[4],
          },
        },
      ],
      maxZoom: 3,
      minZoom: 0.25,
      layout,
    });

    this.#cy.panzoom({
      sliderHandleIcon: 'icon icon-fa-minus',
      zoomInIcon: 'icon icon-fa-plus',
      zoomOutIcon: 'icon icon-fa-minus',
      resetIcon: 'icon icon-fa-up-right-and-down-left-from-center',
    });

    this.#cy.on('click', () => {
      this.#currentPoppers.forEach(tooltipId => {
        this.#removePopper(element, tooltipId);
      });
    });

    this.#cy.on('mouseover mouseup', 'node', (e) => {
      this.cursorType = 'pointer';
      this.#createPopper(element, e.target);
    });

    this.#cy.on('mouseout', 'node', (e) => {
      this.cursorType = 'default';
      this.#removePopperByTarget(element, e.target);
    });

    this.#cy.on('mousedown', 'node', (e) => {
      this.cursorType = 'grabbing';
      this.#removePopperByTarget(element, e.target);
    });

    this.#cy.on('click', 'node', (e) => {
      this.args.nodeClicked?.(e.target);
    });

    yield this.#cy.promiseOn('layoutstop');
  }

  #renderLegend(maxDepth) {
    const legendRows = [
      {
        label: this.intl.t('reporting.parts.partGenealogy.legendMain'),
        class: 'depth-0',
      },
    ];

    for (let i = 1; i <= maxDepth; i++) {
      legendRows.push({
        label: this.intl.t('reporting.parts.partGenealogy.legendDepth', { depth: i }),
        class: `depth-${i}`,
      });
    }

    legendRows.push({
      label: this.intl.t('reporting.parts.partGenealogy.legendEnd'),
      class: 'end',
    });

    this.legend = legendRows;
  }

  #getTargetTooltipId(target) {
    return `popper-target-${target.id()}`;
  }

  #getPopperElement(target, tooltipId) {
    const tooltip = document.createElement('div');
    tooltip.id = tooltipId;
    tooltip.classList.add('target-popper', 'border');

    const table = document.createElement('table');
    tooltip.append(table);

    const targetData = target.data();

    const popperContent = new Map([
      [this.intl.t('serialNumber'), targetData.id],
      [this.intl.t('marriageTask'), getCaptionText(targetData.locationCaptions)],
      [this.intl.t('plant.task.barcode.marriageLabel'), getCaptionText(targetData.marriageCaptions)],
      [this.intl.t('timestamp'), targetData.timestamp],
      [this.intl.t('depth'), targetData.depth],
    ]);

    for (const [label, value] of popperContent) {
      if (value) {
        this.#addPopperTableRow(table, label, value);
      }
    }

    return tooltip;
  }

  #addPopperTableRow(table, title, value) {
    const tr = table.insertRow();

    const tdTitle = tr.insertCell();
    tdTitle.classList.add('popper-title');
    tdTitle.textContent = title;

    const tdValue = tr.insertCell();
    tdValue.classList.add('popper-value');
    tdValue.textContent = value;
  }

  #createPopper(wrapperElement, target) {
    const tooltipId = this.#getTargetTooltipId(target);

    this.#currentPoppers.push(tooltipId);

    target.popper({
      content: () => {
        const tooltip = this.#getPopperElement(target, tooltipId);

        wrapperElement.append(tooltip);

        return tooltip;
      },
    });
  }

  #removePopperByTarget(wrapperElement, target) {
    if (!target || !target.id()) {
      return;
    }

    const tooltipId = this.#getTargetTooltipId(target);
    this.#removePopper(wrapperElement, tooltipId);
  }

  #removePopper(wrapperElement, tooltipId) {
    const existingTarget = wrapperElement.querySelector(`#${tooltipId}`);
    if (existingTarget) {
      existingTarget.remove();
      removeObject(this.#currentPoppers, tooltipId);
    }
  }

  #getGraphData(marriageData) {
    const { nodes, seenSerials } = this.#buildNodes(marriageData.buildStatuses);
    const edges = this.#buildEdges(marriageData.children, seenSerials);

    const hashMap = new Map();

    marriageData.children.forEach((child) => {
      if (child.scannedBarcode) {
        hashMap.set(child.scannedBarcode, child);
        if (!seenSerials.has(child.scannedBarcode) && seenSerials.has(child.serialNumber)) {
          seenSerials.add(child.scannedBarcode);
          nodes.push({ data: { id: child.scannedBarcode } });
          edges.push({
            data: {
              id: `${child.scannedBarcode}_${child.serialNumber}`,
              source: child.scannedBarcode,
              target: child.serialNumber,
            },
          });
        }
      }
    });

    nodes.forEach((node) => {
      const details = hashMap.get(node.data.id);
      node.classes = [`depth-${node.data.depth}`];

      if (details) {
        Object.assign(node.data, details);
        node.classes.push('is-married');
      } else {
        node.classes.push('not-married');
      }
    });

    return { nodes, edges };
  }

  #buildNodes(buildStatuses) {
    const nodes = [];
    const seenSerials = new Set();

    buildStatuses.forEach((buildStatus) => {
      const serialNumber = buildStatus.serialNumber;
      if (!seenSerials.has(serialNumber)) {
        seenSerials.add(serialNumber);
        nodes.push({ data: { id: serialNumber, depth: buildStatus.depth } });
      }
    });

    return { nodes, seenSerials };
  }

  #buildEdges(children, seenSerials) {
    const edges = [];
    children.forEach((child) => {
      // only create an edge when both the source and target exist
      if (seenSerials.has(child.scannedBarcode) && seenSerials.has(child.serialNumber)) {
        edges.push({
          data: {
            id: `${child.scannedBarcode}_${child.serialNumber}`,
            source: child.scannedBarcode,
            target: child.serialNumber,
          },
        });
      }
    });

    return edges;
  }

  #cleanup() {
    if (!this.#cy) {
      return;
    }

    this.#cy.removeAllListeners();
    this.#cy.destroy();
    this.#cy = null;
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.#cleanup();
  }
}
