import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import Component from '@glimmer/component';
import { waitFor } from '@ember/test-waiters';

export default class WebCamScanner extends Component {
  @service webCamScanner;

  previousCameraId = this.args.cameraId;
  previousBarcodeSymbology = this.args.task.barcodeSymbology;

  @task
  @waitFor
  *startCamera(element) {
    yield this.webCamScanner.startBarcodeScanner
      .linked()
      .perform(this.args.cameraId, element, this.args.task.barcodeSymbology);
  }

  @task
  @waitFor
  *onDidUpdate(element, [cameraId, barcodeSymbology]) {
    const cameraIdChanged = this.previousCameraId !== cameraId;
    const barcodeSymbologyChanged = this.previousBarcodeSymbology !== barcodeSymbology;
    this.previousCameraId = cameraId;
    this.previousBarcodeSymbology = barcodeSymbology;

    if (cameraIdChanged || barcodeSymbologyChanged) {
      yield this.webCamScanner.destroyBarcodeScanner();
      yield this.startCamera.perform(element);
    }
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.webCamScanner.destroyBarcodeScanner();
  }
}
