const userRoles = {
  ViewOnly: {
    label: 'ViewOnly',
    level: 0,
  },
  User: {
    label: 'User',
    level: 1,
  },
  Admin: {
    label: 'Admin',
    level: 2,
  },
};

let userRolesValues = [];
for (const role in userRoles) {
  userRolesValues.push(userRoles[role].label);
}

export { userRoles, userRolesValues };
