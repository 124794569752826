const TorqueFormats = {
  NEWTON_METER: {
    value: 0,
    label: 'Nm',
  },
  INCH_POUND: {
    value: 1,
    label: 'in/lb',
  },
};

const TorqueFormatArray = [TorqueFormats.NEWTON_METER, TorqueFormats.INCH_POUND];

export { TorqueFormatArray };

export default TorqueFormats;
