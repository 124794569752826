import { inject as service } from '@ember/service';
import AuthenticatedRoute from 'eflex/util/authenticated-route';
import WieSettings from 'eflex/constants/work-instructions/wie-settings';

export default class WorkInstructionsIndexRoute extends AuthenticatedRoute {
  @service currentUser;
  @service store;
  @service workInstructionRepo;

  queryParams = {
    showGetStartedModal: {
      refreshModel: true,
    },
    folderId: {
      refreshModel: true,
    },
  };

  model(params) {
    if (params.folderId) {
      return this.store.findRecord('workInstructionFolder', params.folderId);
    } else {
      return null;
    }
  }

  afterModel() {
    return this.store.query('workInstruction', { recentOnly: true });
  }

  setupController(controller, workInstructionFolder) {
    const queryParams = this.paramsFor('workInstructions.index');

    let props = {
      showGetStartedModal: queryParams.showGetStartedModal ?? false,
      selectedFolder: workInstructionFolder,
    };

    if (this.workInstructionRepo.userIsApproverDeployerOnly(this.currentUser)) {
      Object.assign(props, {
        currentLibraryView: WieSettings.libraryViews.APPROVAL,
        editorControlsCollapsed: true,
      });
    }

    Object.assign(controller, props);
  }

  resetController(controller) {
    controller.currentLibraryView = WieSettings.libraryViews.THUMB;
  }
}
