export default {
  Queued: {
    status: 'queued',
    icon: 'grid-icon-merged',
    className: 'good',
    label: 'queued',
  },
  Loaded: {
    status: 'loaded',
    icon: 'grid-icon-green-checkmark',
    className: 'good',
    label: 'loaded',
  },
  Invalid: {
    status: 'invalid',
    icon: 'grid-icon-warning-alert',
    className: 'invalid',
    label: 'invalid',
  },
  Error: {
    status: 'error',
    icon: 'grid-icon-warning-alert',
    className: 'error',
    label: 'error',
  },
};
