import { getOwner } from '@ember/application';

export const isBelongsToRefDirty = (model, key) => {
  const relationship = model.belongsTo(key);
  // eslint-disable-next-line no-unused-expressions
  relationship._ref; // needed to work with tracked
  const { localState, remoteState } = relationship.belongsToRelationship;
  return localState?.id !== remoteState?.id;
};

export const isHasManyRefDirty = (model, key) => {
  const relationship = model.hasMany(key);
  // eslint-disable-next-line no-unused-expressions
  relationship._ref; // needed to work with tracked
  const { localMembers, remoteMembers } = relationship.hasManyRelationship;

  if (localMembers.size !== remoteMembers.size) {
    return true;
  }

  for (const member of localMembers) {
    if (!remoteMembers.has(member)) {
      return true;
    }
  }

  return false;
};

export const rollbackBelongsTo = (model, key) => {
  const relationship = model.belongsTo(key);
  const { localState, remoteState } = relationship.belongsToRelationship;

  if (localState?.id === remoteState?.id) {
    return;
  }

  if (!remoteState) {
    model[key] = null;
    return;
  }

  model[key] = getOwner(model).lookup('service:store').peekRecord(remoteState);
};

export const rollbackHasMany = (model, key) => {
  const relationship = model.hasMany(key);
  const { remoteMembers } = relationship.hasManyRelationship;

  const store = getOwner(model).lookup('service:store');
  const value = [];

  remoteMembers.forEach(member => {
    value.push(store.peekRecord(member));
  });

  model[key] = value;
};
