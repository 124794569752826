import { StrokeOptions } from 'eflex/constants/work-instructions/tool-props';

const generateStrokeDashArray = function (strokeStyle, strokeWidth) {
  const width = parseInt(strokeWidth);

  switch (strokeStyle) {
    case StrokeOptions.DASHED: {
      return [width * 2, width * 2];
    }
    case StrokeOptions.DOTTED: {
      return [width / 4, width * 2];
    }
    default: {
      return [];
    }
  }
};

export { generateStrokeDashArray };
