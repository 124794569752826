var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
import Service from '@ember/service';
import { isBlank, isPresent } from '@ember/utils';
import { TrackedArray } from 'tracked-built-ins';
import { Promise } from 'rsvp';
/* eslint-disable @typescript-eslint/no-unused-vars */
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
/* eslint-enable @typescript-eslint/no-unused-vars */
let EmberFreestyleService = (_class = class EmberFreestyleService extends Service {
  constructor(...args) {
    super(...args);
    _initializerDefineProperty(this, "showLabels", _descriptor, this);
    _initializerDefineProperty(this, "showNotes", _descriptor2, this);
    _initializerDefineProperty(this, "showCode", _descriptor3, this);
    _initializerDefineProperty(this, "showApi", _descriptor4, this);
    _initializerDefineProperty(this, "menu", _descriptor5, this);
    _initializerDefineProperty(this, "showMenu", _descriptor6, this);
    _initializerDefineProperty(this, "allowRenderingAllSections", _descriptor7, this);
    this.defaultTheme = 'zenburn';
    // must be explicitly set to null here for (query-params s=null ss=null f=null) to work
    _initializerDefineProperty(this, "section", _descriptor8, this);
    _initializerDefineProperty(this, "subsection", _descriptor9, this);
    _initializerDefineProperty(this, "focus", _descriptor10, this);
    this.hljsVersion = '9.12.0';
    this.hljsPromise = null;
    this.hljsLanguagePromises = {};
  }
  get notFocused() {
    return !this.focus;
  }
  shouldShowSection(sectionName) {
    const focusedSection = this.section;
    if (isBlank(focusedSection) && this.allowRenderingAllSections) {
      return true;
    }
    return sectionName === focusedSection;
  }
  shouldShowSubsection(sectionName, subsectionName) {
    if (!this.shouldShowSection(sectionName)) {
      return false;
    }
    const focusedSubsection = this.subsection;
    return isBlank(focusedSubsection) || subsectionName === focusedSubsection;
  }
  hljsUrl() {
    return `https://cdnjs.cloudflare.com/ajax/libs/highlight.js/${this.hljsVersion}/highlight.min.js`;
  }
  hljsThemeUrl(theme) {
    return `https://cdnjs.cloudflare.com/ajax/libs/highlight.js/${this.hljsVersion}/styles/${theme}.min.css`;
  }
  hljsLanguageUrl(language) {
    return `https://cdnjs.cloudflare.com/ajax/libs/highlight.js/${this.hljsVersion}/languages/${language}.min.js`;
  }
  ensureHljs() {
    if (!this.hljsPromise) {
      this.hljsPromise = new Promise(resolve => {
        const src = this.hljsUrl();
        const script = document.createElement('script');
        script.type = 'application/javascript';
        script.src = src;
        script.onload = () => resolve();
        document.body.appendChild(script);
      });
    }
    return this.hljsPromise;
  }
  highlight(el) {
    this.ensureHljs().then(() => {
      return this.ensureHljsLanguage('handlebars');
    }).then(() => {
      hljs.highlightBlock(el);
    });
  }
  ensureHljsTheme(theme) {
    if (document.querySelector(`[data-hljs-theme=${theme}]`)) {
      return;
    }
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = this.hljsThemeUrl(theme);
    link.setAttribute('data-hljs-theme', `${theme}`);
    document.head.appendChild(link);
  }
  ensureHljsLanguage(language) {
    if (!this.hljsLanguagePromises[language]) {
      this.hljsLanguagePromises[language] = new Promise(resolve => {
        const src = this.hljsLanguageUrl(language);
        const script = document.createElement('script');
        script.type = 'application/javascript';
        script.src = src;
        script.onload = () => resolve();
        document.body.appendChild(script);
      });
    }
    return this.hljsLanguagePromises[language];
  }

  // menu - tree structure of named sections with named subsections

  registerSection(sectionName, subsectionName = '') {
    const menu = this.menu || new TrackedArray();
    if (!menu.find(s => s.name === sectionName)) {
      menu.push({
        name: sectionName,
        subsections: new TrackedArray()
      });
    }
    if (isPresent(subsectionName)) {
      const section = menu.find(s => s.name === sectionName);
      if (section && !section.subsections.find(s => s.name === subsectionName)) {
        section.subsections.push({
          name: subsectionName
        });
      }
    }
    this.menu = menu;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "showLabels", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return true;
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "showNotes", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return true;
  }
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "showCode", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return true;
  }
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "showApi", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return true;
  }
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "menu", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return null;
  }
}), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "showMenu", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return true;
  }
}), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "allowRenderingAllSections", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return true;
  }
}), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "section", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return null;
  }
}), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "subsection", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return null;
  }
}), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "focus", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return null;
  }
}), _applyDecoratedDescriptor(_class.prototype, "registerSection", [action], Object.getOwnPropertyDescriptor(_class.prototype, "registerSection"), _class.prototype)), _class);
export { EmberFreestyleService as default }; // DO NOT DELETE: this is how TypeScript knows how to look up your services.