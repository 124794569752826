import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { action } from '@ember/object';

export default class BiPanelProcessData extends Component {
  @service jemProcessDataDefRepo;

  get filteredProcessDataDefs() {
    let processDataDefs =
      this.jemProcessDataDefRepo.jemProcessDataDefs.filter(item => item.type === 'float');

    if (isPresent(this.args.stationIds)) {
      processDataDefs = processDataDefs.filter((processDataDef) =>
        this.args.stationIds.includes(processDataDef.task?.station?.id),
      );
    }

    return processDataDefs;
  }

  get controlType() {
    return this.args.controlType || 'power-select-multiple';
  }

  @action
  onChangeSingle(value) {
    this.args.onChange([value.id]);
  }
}
