import moment from 'moment-timezone';
import { helper } from '@ember/component/helper';

function formatMilliSeconds([value]) {
  value = parseInt(value);
  const duration = moment.duration(value);

  if (value < 1000) {
    return duration.format('0.SS');
  }

  const secondsFormat = value < 60_000 ? 's' : 'ss';

  return duration.format(`DD:HH:mm:${secondsFormat}.SSS`).slice(0, -2); //lop off last two zeros
}

export { formatMilliSeconds };
export default helper(formatMilliSeconds);
