import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import RejectStrategies from 'eflex/constants/tasks/reject-strategies';
import RerunStrategies from 'eflex/constants/rerun-strategies';
import RetryStrategies from 'eflex/constants/retry-strategies';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { isAncestorOf, toTree } from 'eflex/util/tree-helpers';
import TaskConfigBarcode from 'eflex/components/task-config/barcode';
import TaskConfigButton from 'eflex/components/task-config/button';
import TaskConfigCobot from 'eflex/components/task-config/cobot';
import TaskConfigDecision from 'eflex/components/task-config/decision';
import TaskConfigErrorProofing from 'eflex/components/task-config/errorproofing';
import TaskConfigLightGuide from 'eflex/components/task-config/lightguide';
import TaskConfigMultispindle from 'eflex/components/task-config/multispindle';
import TaskConfigNodeRed from 'eflex/components/task-config/nodered';
import TaskConfigPick from 'eflex/components/task-config/pick';
import TaskConfigPlc from 'eflex/components/task-config/plc';
import TaskConfigPushToSchedule from 'eflex/components/task-config/pushtoschedule';
import TaskConfigSerialNumberTransfer from 'eflex/components/task-config/serialnumbertransfer';
import TaskConfigTimer from 'eflex/components/task-config/timer';
import TaskConfigTorque from 'eflex/components/task-config/torque';
import TaskConfigVision from 'eflex/components/task-config/vision';

import TaskDeviceConfigBarcode from 'eflex/components/task-device-configuration/barcode';
import TaskDeviceConfigButton from 'eflex/components/task-device-configuration/button';
import TaskDeviceConfigCobot from 'eflex/components/task-device-configuration/cobot';
import TaskDeviceConfigEdhr from 'eflex/components/task-device-configuration/edhr';
import TaskDeviceConfigErrorProofing from 'eflex/components/task-device-configuration/errorproofing';
import TaskDeviceConfigImageCapture from 'eflex/components/task-device-configuration/imagecapture';
import TaskDeviceConfigLightGuide from 'eflex/components/task-device-configuration/lightguide';
import TaskDeviceConfigMultispindle from 'eflex/components/task-device-configuration/multispindle';
import TaskDeviceConfigNodeRed from 'eflex/components/task-device-configuration/nodered';
import TaskDeviceConfigPick from 'eflex/components/task-device-configuration/pick';
import TaskDeviceConfigPrint from 'eflex/components/task-device-configuration/print';
import TaskDeviceConfigSerialNumberTransfer from 'eflex/components/task-device-configuration/serialnumbertransfer';
import TaskDeviceConfigTimer from 'eflex/components/task-device-configuration/timer';
import TaskDeviceConfigTorque from 'eflex/components/task-device-configuration/torque';
import TaskDeviceConfigVision from 'eflex/components/task-device-configuration/vision';
import TaskDeviceConfigPlc from 'eflex/components/task-device-configuration/plc';

const TASK_CONFIGS_CHUNK_SIZE = 15;

export default class TaskPanel extends Component {
  @service taskRepo;
  @service taskConfigRepo;
  @service workInstructionRepo;
  @service workInstructionCache;
  @service store;
  @service defaultTaskConfigContext;
  @service userRepo;

  @tracked taskConfigsCurrentChunks = 15;
  @tracked selectedContext = this.defaultTaskConfigContext.getContext(this.args.taskParent);
  @tracked scrollToTop = false;

  previousTask = this.args.task;

  get rejectStrategies() {
    return [RejectStrategies.advance, RejectStrategies.stop];
  }

  get rerunStrategies() {
    return RerunStrategies;
  }

  get retryStrategies() {
    return RetryStrategies;
  }

  get hasComplexOption() {
    return this.args.task?.component?.options?.some(item => item.isSimple === false) ?? false;
  }

  get isTesting() {
    return window.isTesting ?? false;
  }

  get repairTaskTree() {
    let parent;

    if (this.args.task.usesJobs) {
      parent = this.args.task.parent;
    } else {
      parent = this.args.task.area;
    }

    return [toTree(parent, {
      lastModel: 'task',
      filter: (task) => {
        if (task.type === 'station' || task.parent !== this.args.taskParent) {
          return true;
        }

        return false;
      },
    })];
  }

  @cached
  get taskConfigs() {
    const treeTask = this.args.task;

    if (treeTask == null) {
      return [];
    }

    const context = this.selectedContext;
    const usesComponents = this.args.taskParent.usesComponents;

    if (!treeTask) {
      return [];
    } else if (context?.allSelected && usesComponents) {
      return treeTask.children.filter(item => item.configOption?.component === treeTask.component);
    } else if (treeTask.usesJobs || context.allSelected) {
      return treeTask.children;
    } else if (usesComponents) {
      return treeTask.children.filter(item => item.configOption === context);
    } else {
      return treeTask.children.filter(item => item.configModel === context);
    }
  }

  get taskConfigsChunked() {
    return this.taskConfigs.slice(0, this.taskConfigsCurrentChunks);
  }

  // eslint-disable-next-line complexity
  get taskConfigComponent() {
    switch (this.args.task.taskType) {
      case 'barcode': {
        return TaskConfigBarcode;
      }
      case 'button': {
        return TaskConfigButton;
      }
      case 'cobot': {
        return TaskConfigCobot;
      }
      case 'decision': {
        return TaskConfigDecision;
      }
      case 'errorProofing': {
        return TaskConfigErrorProofing;
      }
      case 'lightGuide': {
        return TaskConfigLightGuide;
      }
      case 'multispindle': {
        return TaskConfigMultispindle;
      }
      case 'nodeRed': {
        return TaskConfigNodeRed;
      }
      case 'pick': {
        return TaskConfigPick;
      }
      case 'plc': {
        return TaskConfigPlc;
      }
      case 'pushToSchedule': {
        return TaskConfigPushToSchedule;
      }
      case 'serialNumberTransfer': {
        return TaskConfigSerialNumberTransfer;
      }
      case 'timer': {
        return TaskConfigTimer;
      }
      case 'torque': {
        return TaskConfigTorque;
      }
      case 'vision': {
        return TaskConfigVision;
      }
      default: {
        return null;
      }
    }
  }

  // eslint-disable-next-line complexity
  get taskDeviceConfigComponent() {
    switch (this.args.task.taskType) {
      case 'barcode': {
        return TaskDeviceConfigBarcode;
      }
      case 'button': {
        return TaskDeviceConfigButton;
      }
      case 'cobot': {
        return TaskDeviceConfigCobot;
      }
      case 'edhr': {
        return TaskDeviceConfigEdhr;
      }
      case 'errorProofing': {
        return TaskDeviceConfigErrorProofing;
      }
      case 'imageCapture': {
        return TaskDeviceConfigImageCapture;
      }
      case 'lightGuide': {
        return TaskDeviceConfigLightGuide;
      }
      case 'multispindle': {
        return TaskDeviceConfigMultispindle;
      }
      case 'nodeRed': {
        return TaskDeviceConfigNodeRed;
      }
      case 'pick': {
        return TaskDeviceConfigPick;
      }
      case 'plc': {
        return TaskDeviceConfigPlc;
      }
      case 'print': {
        return TaskDeviceConfigPrint;
      }
      case 'serialNumberTransfer': {
        return TaskDeviceConfigSerialNumberTransfer;
      }
      case 'timer': {
        return TaskDeviceConfigTimer;
      }
      case 'torque': {
        return TaskDeviceConfigTorque;
      }
      case 'vision': {
        return TaskDeviceConfigVision;
      }
      default: {
        return null;
      }
    }
  }

  @cached
  get userVisibilityOptions() {
    return [...new Set(this.userRepo.users
      .flatMap(user => user.visibilityTags))]
      .toSorted();
  }

  @cached
  get userAuthorizationOptions() {
    return [...new Set(this.userRepo.users
      .flatMap(user => user.authorizationTags))]
      .toSorted();
  }

  @action
  copyWieToAll(triggerConfig, event) {
    this.workInstructionRepo.copyToAllContexts(this.args.task, triggerConfig, event);
  }

  constructor() {
    super(...arguments);
    this.workInstructionCache.setupAsync.perform();
  }

  @action
  onTreeItemDeleted(treeItem) {
    const treeTask = this.args.task;
    if (treeTask != null && (treeItem === treeTask || isAncestorOf(treeItem, treeTask))) {
      this.args.onCancel();
    }
  }

  @action
  onDidUpdate(elem, [treeTask]) {
    if (treeTask != null && treeTask !== this.previousTask) {
      this.scrollToTop = true;
    }

    this.previousTask = treeTask;
  }

  // jscpd:ignore-start
  @action
  selectedTaskType(value) {
    if (value === this.args.task?.taskType) {
      return;
    }

    this.taskRepo.changeType(this.args.task, value);
  }

  @action
  contextWasSelected(context) {
    this.selectedContext = context;
    if (context.allSelected) {
      this.scrollToTop = true;
    }
  }
  // jscpd:ignore-end

  @action
  tabBecameActive(tabName) {
    this.scrollToTop = true;
    this.args.tabBecameActive(tabName);
  }

  @action
  copyConfigToAll(taskConfig) {
    this.taskConfigRepo.copyToAllContexts(taskConfig);
  }

  @action
  copyEventToAll(taskConfig) {
    this.taskConfigRepo.copyEventToAllContexts(taskConfig);
  }

  @action
  copyDecisionToAll(taskConfig) {
    this.taskConfigRepo.copyDecisionToAllContexts(taskConfig);
  }

  @action
  loadMoreInstructions() {
    if (this.taskConfigsChunked.length < this.taskConfigs.length) {
      this.taskConfigsCurrentChunks += TASK_CONFIGS_CHUNK_SIZE;
    }
  }

  @action
  setRerunStrategy({ value }) {
    this.args.task.rerunStrategy = value;
  }

  @action
  setRetryStrategy({ value }) {
    this.args.task.retryStrategy = value;
  }

  @action
  updateTasksToRepair(tasksToRepair) {
    const tasks = tasksToRepair.map(task =>
      this.store.peekRecord('task', task.id),
    );

    this.args.task.tasksToRepair = tasks;
  }
}
