import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { toTree } from 'eflex/util/tree-helpers';

export default class TaskConfigPushToSchedule extends Component {
  @service store;
  @service areaRepo;

  get nonDeletedPushToScheduleConfigs() {
    return this.args.taskConfig.pushToScheduleConfigs.filter(item => !item.isDeleted);
  }

  get treeData() {
    return this.areaRepo.areas.map(area =>
      toTree(area, {
        lastModel: 'station',
        filter(treeItem) {
          if (treeItem.type === 'station') {
            return treeItem.productionScheduleEnabled;
          }

          return true;
        },
      }),
    );
  }

  @action
  addScheduleConfig() {
    this.store.createRecord('pushToScheduleConfig', {
      taskConfig: this.args.taskConfig,
    });
  }

  @action
  deleteScheduleConfig(scheduleConfig) {
    scheduleConfig.deleteRecord();
  }

  @action
  selectStation(scheduleConfig, [station]) {
    if (!station) {
      scheduleConfig.station = null;
      scheduleConfig.model = null;
      return;
    }

    scheduleConfig.station = this.store.peekRecord('station', station.id);

    if (scheduleConfig.model?.area != scheduleConfig.station.area) {
      scheduleConfig.model = scheduleConfig.station.models[0];
    }
  }
}
