import moment from 'moment-timezone';
import { helper } from '@ember/component/helper';

function formatDate([value, dateFormat, timeFormat]) {
  if (!value) {
    return null;
  }

  dateFormat ??= 'L';
  timeFormat ??= 'LTS';

  return moment(new Date(value)).format(`${dateFormat} ${timeFormat}`);
}

export { formatDate };
export default helper(formatDate);
