import moment from 'moment-timezone';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class BaseCriteriaDateRange extends Component {
  @tracked isDisplayed = this.beginDate || this.endDate;
  @tracked selectedRange;

  beginDatePicker;
  endDatePicker;

  get beginDate() {
    return this.args.beginDate ? new Date(this.args.beginDate) : null;
  }

  get endDate() {
    return this.args.endDate ? new Date(this.args.endDate) : null;
  }

  dateRangeOptions = [
    {
      label: 'datetime.today',
      value: 'today',
      beginDate: moment().startOf('day').toDate(),
      endDate: moment().add(1, 'hours').toDate(),
    },
    {
      label: 'datetime.yesterday',
      value: 'yesterday',
      beginDate: moment().subtract(1, 'days').startOf('day').toDate(),
      endDate: moment().subtract(1, 'days').endOf('day').toDate(),
    },
    {
      label: 'datetime.lastweek',
      value: 'week',
      beginDate: moment().subtract(7, 'days').toDate(),
      endDate: moment().add(1, 'hours').toDate(),
    },
    {
      label: 'datetime.lastmonth',
      value: 'month',
      beginDate: moment().subtract(1, 'months').toDate(),
      endDate: moment().add(1, 'hours').toDate(),
    },
    {
      label: 'datetime.lastquarter',
      value: 'quarter',
      beginDate: moment().subtract(3, 'months').toDate(),
      endDate: moment().add(1, 'hours').toDate(),
    },
  ];

  willDestroy() {
    super.willDestroy(...arguments);
    Object.assign(this, {
      beginDatePicker: null,
      endDatePicker: null,
    });
  }

  @action
  onBeginDateChange(beginDate) {
    if (beginDate != null) {
      this.args.updateParams({
        key: 'beginDate',
        value: beginDate.toJSON(),
        breadcrumb: `Begin Date: ${moment(beginDate).format('lll')}`,
      });
    } else {
      this.args.removeParam('beginDate');
    }
  }

  @action
  onEndDateChange(endDate) {
    if (endDate != null) {
      this.args.updateParams({
        key: 'endDate',
        value: endDate.toJSON(),
        breadcrumb: `End Date: ${moment(endDate).format('lll')}`,
      });
    } else {
      this.args.removeParam('endDate');
    }
  }

  @action
  onBeginDatePickerReady(instance) {
    this.beginDatePicker = instance;
  }

  @action
  onEndDatePickerReady(instance) {
    this.endDatePicker = instance;
  }

  @action
  openBeginDatePicker() {
    this.beginDatePicker.open();
  }

  @action
  openEndDatePicker() {
    this.endDatePicker.open();
  }

  @action
  onDisplayedChanged(isDisplayed) {
    this.onBeginDateChange(null);
    this.onEndDateChange(null);
    this.isDisplayed = isDisplayed;
  }

  @action
  onSelectedRange(range) {
    this.selectedRange = range;
    this.onBeginDateChange(range?.beginDate);
    this.onEndDateChange(range?.endDate);
  }
}
