import { validator } from '@eflexsystems/ember-tracked-validations';
import { checkUniqueField } from 'eflex/util/validators';
import { getOwner } from '@ember/application';

export default {
  serialNumber: [
    validator('inline', {
      validate(serialNumber, options, jemContainment) {
        const owner = getOwner(jemContainment);
        const jemContainments = owner.lookup('service:store').peekAll('jemContainment');
        const checkedContainments = jemContainments.filter(item => !item.isDeleted);

        return checkUniqueField(jemContainment, checkedContainments, 'serialNumber').result;
      },
    }),
    validator('presence', true),
  ],
};
