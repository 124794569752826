import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { task as trackedTask } from 'ember-resources/util/ember-concurrency';
import { isBlank } from '@ember/utils';
import { ObjectId } from 'bson';
import { action } from '@ember/object';
import echartsTheme from 'eflex/echarts-theme';
import { waitFor } from '@ember/test-waiters';

export default class ProcessDataAvgBarChart extends Component {
  // jscpd:ignore-start
  @service queryRunner;
  @service intl;
  @service router;

  get params() {
    return {
      stationIds: this.args.stationIds,
      modelIds: this.args.modelIds,
      userIds: this.args.userIds,
      tags: this.args.tags,
      startDate: this.args.startDate,
      endDate: this.args.endDate,
      shiftNames: this.args.shiftNames,
      processData: this.args.processData,
    };
  }

  processData = trackedTask(this, this.getData, () => [this.params]);

  @task({ drop: true })
  @waitFor
  *getData(params) {
    const averageProcessDataAggregation = [
      {
        $match: {
          'children.processData.0': {
            $exists: true,
          },
        },
      },
      {
        $unwind: {
          path: '$children',
        },
      },
      {
        $unwind: {
          path: '$children.processData',
        },
      },
      {
        $match: {
          'children.processData.type': 'float',
        },
      },
      this.getProcessDatumMatch(params.processData),
      {
        $addFields: {
          processDataName: '$children.processData.captions.text',
          taskName: '$children.location.captions.text',
          unit: '$children.processData.unitCaptions.text',
        },
      },
      {
        $group: {
          _id: '$children.processData.jemProcessDataDef',
          station: {
            $first: '$location',
          },
          processDataName: {
            $first: '$processDataName',
          },
          taskName: {
            $first: '$taskName',
          },
          unit: {
            $first: '$unit',
          },
          avg: {
            $avg: '$children.processData.value',
          },
        },
      },
      {
        $project: {
          stationName: {
            $first: '$station.captions.text',
          },
          processDataName: {
            $first: '$processDataName',
          },
          taskName: {
            $first: '$taskName',
          },
          avg: true,
          unit: {
            $first: '$unit',
          },
        },
      },
      {
        $sort: {
          avg: 1,
        },
      },
    ];

    const processDataQuery = yield this.queryRunner.queryWithParams.perform(
      params,
      averageProcessDataAggregation,
    );
    const chartData = yield this.queryRunner.runQuery.perform(
      'BuildStatuses',
      processDataQuery,
    );

    const unitMapping = new Map();
    chartData.forEach((data) => {
      unitMapping.set(data.processDataName, {
        processDataName: data.processDataName ?? '',
        unit: data.unit ?? '',
        stationName: data.stationName ?? '',
      });
    });

    return {
      chartData: this.getEchartOptions(chartData, unitMapping),
      exportData: () => chartData.map(({ _id, ...relevantData }) => relevantData),
    };
    // jscpd:ignore-end
  }

  getEchartOptions(chartData, unitMapping) {
    return {
      title: {
        text: this.intl.t('bi.chartLabel.processDataAvgBarChart'),
      },

      legend: {
        show: true,
        bottom: 5,
      },

      tooltip: {
        trigger: 'axis',
        formatter(params) {
          // the key is the processDataName only
          const axisLabelPartsArray = params[0].name.split(':');
          const mapKey = axisLabelPartsArray.at(-1).trim();
          const tooltipItems = params
            .map(({ marker, value }) =>
              `<div style="text-align:center">${unitMapping.get(mapKey).stationName}</div><br>${marker} ${
                unitMapping.get(mapKey).processDataName
              }: <span style="float:right;font-weight:bold;">${value} <span style="font-weight:normal;">${
                unitMapping.get(mapKey).unit
              }</span></span>`,
            )
            .join('<br>');
          return `<div style="min-width:200px;">${tooltipItems}</div>`;
        },
      },

      yAxis: {
        type: 'category',
        boundaryGap: true,
        data: chartData.map((data) => `${data.stationName}: ${data.taskName}: ${data.processDataName}`),
      },

      xAxis: [
        {
          type: 'value',
          position: 'bottom',
          axisLabel: {
            formatter: '{value}',
          },
        },
      ],

      series: [
        {
          name: this.intl.t('bi.chartLegend.avgProcessData'),
          data: chartData.map((m) => m?.avg?.toFixed(2)),
          type: 'bar',
          xAxisIndex: 0,
          color: echartsTheme.colorPalette.biPurple,
        },
      ],
      processDataDefIds: chartData.map(item => item._id),
    };
  }

  @action
  processDatumDrillDown(processData, dataIndex) {
    this.router.transitionTo('bi.processData.processDatum', {
      queryParams: {
        processDataDefIds: [processData.chartData.processDataDefIds[dataIndex]],
        stationIds: this.args.stationIds,
        modelIds: this.args.modelIds,
        userIds: this.args.userIds,
        tags: this.args.tags,
        beginDate: this.args.startDate,
        endDate: this.args.endDate,
        shiftNames: this.args.shiftNames,
      },
    });
  }

  getProcessDatumMatch(processData) {
    if (isBlank(processData)) {
      return {
        $match: {},
      };
    } else {
      return {
        $match: {
          'children.processData.jemProcessDataDef': {
            $in: processData.map(
              (processDataDef) => new ObjectId(processDataDef),
            ),
          },
        },
      };
    }
  }
}
