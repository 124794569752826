import { inject as service } from '@ember/service';
import { FROM_TYPES, TO_TYPES, FLOAT_BYTES, MAX_BYTES } from 'eflex/constants/plc-variable-data-types';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { pipe, pluck, sum } from 'ramda';

export default class TaskConfigPlc extends Component {
  @tracked currentVariableTypeTab = `deviceToVariables-${this.args.taskConfig.id}`;
  fromTypes = FROM_TYPES;
  toTypes = TO_TYPES;

  @service variableDefRepo;
  @service taskRepo;

  get variableDefConfigs() {
    return this.args.taskConfig?.variableDefConfigs.filter(item => !item.isDeleted);
  }

  get deviceToId() {
    return `deviceToVariables-${this.args.taskConfig.id}`;
  }

  get deviceFromId() {
    return `deviceFromVariables-${this.args.taskConfig.id}`;
  }

  get showDeviceFrom() {
    return this.currentVariableTypeTab === this.deviceFromId;
  }

  get displayVariables() {
    return this.variableDefConfigs?.filter(item => item.isFromDevice === this.showDeviceFrom) ?? [];
  }

  get filteredJemProcessDataDefs() {
    return this.allPreviousTasks
      ?.flatMap(_task => _task.jemProcessDataDefs)
      .filter(def => !def.isDeleted && def.type === 'float') ?? [];
  }

  get filteredVariableDefs() {
    return this.allPreviousTasks
      ?.flatMap(_task => _task.variableDefs)
      .filter(def => !def.isDeleted && def.isForPlc && def.isFromDevice) ?? [];
  }

  get allPreviousTasks() {
    return this.taskRepo.getAllPreviousTasks(this.args.task);
  }

  get availableByteLengths() {
    const totalConsumed = pipe(
      pluck('bytesConsumed'),
      sum,
    )(this.displayVariables);

    const availableBytes = MAX_BYTES - totalConsumed;
    const result = [];
    for (let i = FLOAT_BYTES; i <= availableBytes; i += FLOAT_BYTES) {
      result.push(i);
    }
    return result;
  }

  @action
  setVariableValue(variableDefConfig, value) {
    const variableDef = variableDefConfig.variableDef;

    switch (value.constructor?.modelName) {
      case 'variable-def': {
        variableDefConfig.value = null;
        variableDef.variableDefForValue = value;
        variableDef.jemProcessDataDefForValue = null;
        break;
      }
      case 'jem-process-data-def': {
        variableDefConfig.value = null;
        variableDef.variableDefForValue = null;
        variableDef.jemProcessDataDefForValue = value;
        break;
      }
      default: {
        variableDefConfig.value = value;
        variableDef.variableDefForValue = null;
        variableDef.jemProcessDataDefForValue = null;
      }
    }
  }

  @action
  addMultipleVariables(variableQuantity) {
    for (let i = 0; i < variableQuantity; i++) {
      this.variableDefRepo.createVariableDef({
        task: this.args.task,
        isFromDevice: this.showDeviceFrom,
      });
    }
  }

  @action
  deleteVariable(variableDef) {
    this.variableDefRepo.deleteVariable(variableDef);
  }

  @action
  selectedVariableType(variableDefConfig, selected) {
    const variableDef = variableDefConfig.variableDef;

    variableDefConfig.value = null;
    variableDefConfig.length = null;
    variableDef.variableDefForValue = null;
    variableDef.jemProcessDataDefForValue = null;
    variableDef.type = selected.value;
  }
}
