import Service, { inject as service } from '@ember/service';
import { camelize } from '@ember/string';

export default class TriggerRepoService extends Service {
  @service store;

  adamIoTriggers = this.store.peekAll('adamIoTrigger');
  audioTriggers = this.store.peekAll('audioTrigger');
  edhrTriggers = this.store.peekAll('edhrTrigger');
  lightGuideTriggers = this.store.peekAll('lightGuideTrigger');
  plcHardwareTriggers = this.store.peekAll('plcHardwareTrigger');
  nodeRedTriggers = this.store.peekAll('nodeRedTrigger');
  turckIoTriggers = this.store.peekAll('turckIoTrigger');
  workInstructionHardwareTriggers = this.store.peekAll('workInstructionHardwareTrigger');

  get allTriggers() {
    return this.adamIoTriggers
      .concat(this.audioTriggers)
      .concat(this.edhrTriggers)
      .concat(this.lightGuideTriggers)
      .concat(this.plcHardwareTriggers)
      .concat(this.nodeRedTriggers)
      .concat(this.turckIoTriggers)
      .concat(this.workInstructionHardwareTriggers);
  }

  createTrigger(hardware, location, event = 'start') {
    let trigger;
    const triggerType = `${camelize(hardware.constructor.modelName)}Trigger`;

    if (location.type === 'task') {
      trigger = this.store.createRecord(triggerType, {
        task: location,
        hardware,
        event,
      });

      location.taskConfigs.forEach((taskConfig) => {
        this.createTriggerConfig(trigger, taskConfig);
      });
    } else if (location.isButton) {
      trigger = this.store.createRecord(triggerType, {
        oeeButton: location,
        hardware,
        event,
      });

      this.createTriggerConfig(trigger, location);
    } else {
      trigger = this.store.createRecord(triggerType, {
        station: location,
        hardware,
        event,
      });

      this.createTriggerConfig(trigger, location);
    }
  }

  createTriggerConfig(trigger, location) {
    const triggerConfigType = `${camelize(trigger.constructor.modelName)}Config`;

    if (location.type === 'station') {
      this.store.createRecord(triggerConfigType, {
        parentTrigger: trigger,
        station: location,
      });
    } else if (location.isButton) {
      this.store.createRecord(triggerConfigType, {
        parentTrigger: trigger,
        oeeButton: location,
      });
    } else {
      this.store.createRecord(triggerConfigType, {
        parentTrigger: trigger,
        taskConfig: location,
      });
    }
  }
}
