import LocationRepoBase from 'eflex/services/location-repo-base';
import { task } from 'ember-concurrency';
import getNextTreeOrder from 'eflex/util/get-next-tree-order';
import { waitFor } from '@ember/test-waiters';

export default class AreaRepoService extends LocationRepoBase {
  areas = this.store.peekAll('area');

  create(properties = {}) {
    properties.order = getNextTreeOrder(this.areas);
    return super.create('area', properties);
  }

  @task
  @waitFor
  *createAndSave(properties = {}) {
    const area = this.create(properties);
    yield area.save();
    return area;
  }
}
