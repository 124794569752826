import { isBlank } from '@ember/utils';
import { task } from 'ember-concurrency';
import TaskStatuses from 'eflex/constants/task-statuses';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { waitFor } from '@ember/test-waiters';

export default class JemTaskSpecificDisplaySerialNumberTransferDetails extends Component {
  @tracked transferSerialNumber;

  @service eflexAjax;

  @task({ drop: true })
  @waitFor
  *sendSerialNumber(status) {
    if (status === TaskStatuses.RESTART) {
      this.onTaskComplete(status);
      return;
    }

    if (isBlank(this.transferSerialNumber)) {
      return;
    }

    yield this.eflexAjax.post.perform('jemSerialNumberTransfers', {
      jemSerialNumberTransfer: {
        liveBuildStatus: this.args.childStatus.parent.id,
        taskConfig: this.args.taskConfig.id,
        transferSerialNumber: this.transferSerialNumber,
      },
    });

    this.transferSerialNumber = null;
  }

  @task
  @waitFor
  *onSubmit(transferSerialNumber, taskStatus) {
    this.transferSerialNumber = transferSerialNumber;
    yield this.sendSerialNumber.perform(taskStatus);
  }

  @action
  onTaskComplete(status) {
    this.transferSerialNumber = null;
    this.args.sendTaskComplete(status, this.args.childStatus);
  }
}
