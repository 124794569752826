import TaskParent from 'eflex/models/task-parent';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import validateTaskSequence from 'eflex/util/validate-task-sequence';
import { attr } from '@ember-data/model';

@buildValidations({
  name: [validator('presence', true)],
  taskSequence: [
    validator('inline', { validate: validateTaskSequence }),
  ],
})
class Job extends TaskParent {
  @attr('string', { defaultValue: 'job' }) type;
  @attr('number', { defaultValue: 0 }) order;
}

export default Job;
