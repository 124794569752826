import Model, { attr } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';

export default class BuildHistory extends Model {
  @attr('string') serialNumber;
  @attr('boolean') isGood;
  @attr('date') latestTimestamp;

  @tracked isSelected = false;
}
