import smallestAvailable from 'eflex/util/smallest-available';
import DATA_TYPES from 'eflex/constants/process-data-types';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class TaskConfigButton extends Component {
  dataTypes = DATA_TYPES;

  @service jemProcessDataDefRepo;

  get nonDeletedDefs() {
    return this.args.task?.jemProcessDataDefs.filter(item => !item.isDeleted);
  }

  get bitDefs() {
    return this.jemProcessDataDefRepo.jemProcessDataDefs.filter(item => item.type === 'bit');
  }

  get units() {
    return this.jemProcessDataDefRepo.jemProcessDataDefs.filter(item => item.type !== 'bit').map(item => item.unit);
  }

  @action
  add() {
    this.jemProcessDataDefRepo.createProcessDataDef({
      displayedStart: smallestAvailable(this.nonDeletedDefs?.map(item => item.displayedStart)),
      task: this.args.task,
      type: 'ascii',
    });
  }

  @action
  delete(def) {
    if (this.args.disabled) {
      return;
    }

    this.jemProcessDataDefRepo.deleteProcessDataDef(def);
  }

  @action
  selectedDataDefType(dataDef, selected) {
    dataDef.type = selected.value;
  }
}
