import Service, { inject as service } from '@ember/service';

export default class DecisionDefRepoService extends Service {
  @service store;

  createDecisionDefForTask(task, defParams = {}) {
    defParams.task = task;
    const def = this.store.createRecord('decisionDef', defParams);
    task.taskConfigs.forEach(taskConfig => {
      this.store.createRecord('decisionDefConfig', {
        decisionDef: def,
        taskConfig,
      });
    });
    return def;
  }
}
