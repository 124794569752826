import Controller from '@ember/controller';
import { action } from '@ember/object';
import { isBlank, isEmpty, isPresent } from '@ember/utils';
import { task } from 'ember-concurrency';
import { JEM_VIEW_MODES } from 'eflex/constants/jem';
import TaskStatuses from 'eflex/constants/task-statuses';
import { taskTypes } from 'eflex/constants/tasks/task-types';
import { NotificationLogTypes } from 'eflex/constants/notification-log';
import { inject as service } from '@ember/service';
import { cached, tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import SplitView from 'eflex/components/split-view';
import BlankTemplate from 'eflex/components/blank-template';
import { sortByProps } from 'ramda-adjunct';
import { dedupeTracked } from 'tracked-toolbox';

const POPOVER_ERROR_KEYS = new Set([
  'badModel',
  'noBuildDatumFound',
  'buildDatumMissingConfig',
]);

const MAX_SIZE_OF_CONFIG_TABS = 4;

const CHANGELOG_DISPLAYED_TYPES = new Set([
  NotificationLogTypes.JEM,
  NotificationLogTypes.WIE,
]);

const RESET_INSTRUCTION_TYPES_ON_FINISH = new Set([
  taskTypes.imageCapture,
  taskTypes.vision,
  'station',
]);

const IMAGE_TASK_TYPES = new Set([taskTypes.imageCapture, taskTypes.vision]);

const validateSerialNumber = (serialNumber, station) =>
  !station.jemLoadRegexIsAllowed ||
  isBlank(station.jemLoadRegex) ||
  new RegExp(station.jemLoadRegex).test(serialNumber);

export default class JemStationsController extends Controller {
  queryParams = ['instructions'];

  @service currentUser;
  @service eflexAjax;
  @service intl;
  @service jemRepo;
  @service notifier;
  @service productionScheduleRepo;
  @service stationRepo;
  @service store;
  @service taskRepo;
  @service audioPlayer;
  @service logRepo;

  @tracked station;
  @dedupeTracked instructions = 'task';
  @tracked viewMode = JEM_VIEW_MODES.taskList;
  @tracked tempSerialNumber = '';
  @tracked showTabConfigModal = false;
  @tracked isOnline = true;
  @tracked showRejectTaskModal = false;
  @tracked showRepairTaskModal = false;
  @tracked showNotesModal = false;
  @tracked waitingForData = false;
  @tracked bomSourceLookupValue;
  @tracked configJemTabModalTitle;
  @tracked currentTab;
  @tracked rejectArgs;
  @tracked repairArgs;
  @tracked totalLogs;
  @tracked showInvalidPopover = false;
  @tracked job;
  @tracked _model;
  @tracked _buildStatus;
  @tracked _currentTask;

  @cached
  get currentRunningTimeInSeconds() {
    const buildStatus = this.buildStatus;

    if (buildStatus == null) {
      return 0;
    }

    const currentRunningTime = buildStatus.isStarted ? buildStatus.elapsedTime : buildStatus.cycleTime;

    return (currentRunningTime / 1000) + this.station.jemTransferOffset;
  }

  get buildStatus() {
    return this.isOnline ? this._buildStatus : null;
  }

  set buildStatus(val) {
    const previousBuildStatus = this._buildStatus;

    if (previousBuildStatus === val) {
      return;
    }

    this._buildStatus = val;
    previousBuildStatus?.unloadRecord();
  }

  @cached
  get model() {
    if (!this.station.usesModels) {
      return null;
    }

    if (!this.isOnline) {
      return this._model;
    }

    if (this.station.productionScheduleEnabled) {
      return this.productionSchedule?.model;
    }

    return this.buildStatus?.modelRecord ?? this._model;
  }

  set model(val) {
    if (this._model !== val) {
      this._model = val;
    }
  }

  @cached
  get currentTask() {
    if (this.stationIsRunning && !this._currentTask) {
      return this.buildStatus?.lastActiveChild?.task;
    }

    return this._currentTask;
  }

  set currentTask(val) {
    if (this._currentTask !== val) {
      this._currentTask = val;
    }
  }

  get splitViewComponent() {
    if (this.isListView) {
      return SplitView;
    } else {
      return BlankTemplate;
    }
  }

  get isListView() {
    return this.viewMode === JEM_VIEW_MODES.taskList;
  }

  get isSingleView() {
    return this.viewMode === JEM_VIEW_MODES.singleTask;
  }

  get authorized() {
    return this.currentUser.user.hasAuthorization(this.station.jemAuthorizedTags);
  }

  get notAuthorized() {
    return !this.authorized;
  }

  get area() {
    return this.station.area;
  }

  get taskRows() {
    let tasks;
    if (this.station.usesJobs) {
      tasks = this.job?.tasks ?? [];
    } else {
      tasks = this.station.tasks;
    }

    return sortByProps(['row', 'column'], tasks);
  }

  get stationIsRunning() {
    const isStarted = this.buildStatus?.isStarted ?? false;
    return isStarted && !this.waitingForData;
  }

  get logsToAcknowledge() {
    return this.logRepo.logs.filter(log =>
      CHANGELOG_DISPLAYED_TYPES.has(log.logType) &&
      this.currentUser.user.createdAt <= log.timestamp &&
      !log.acknowledgedBy.some(item => item.userName === this.currentUser.user.userName),
    );
  }

  get anyCustomIdentifiersEmpty() {
    return this.buildStatus.customIdentifierData.some((datum) => isEmpty(datum.value));
  }

  @cached
  get productionSchedule() {
    return this.jemRepo.getProductionScheduleForStation(this.station);
  }

  @cached
  get currentChildStatus() {
    if (!this.isOnline) {
      return null;
    }

    return this.buildStatus?.getChildStatusForTask(this.currentTask);
  }

  get buildStatusClass() {
    if (!this.isOnline || !this.buildStatus) {
      return null;
    }

    const { status, isStarted, isUnknown } = this.buildStatus;

    if (isUnknown || isStarted) {
      return 'part-value-active';
    } else if (TaskStatuses.isRejected(status)) {
      return 'part-value-failed';
    } else {
      return 'part-value-passed';
    }
  }

  @cached
  get currentTaskConfig() {
    const currentTask = this.currentTask;

    if (!currentTask) {
      return null;
    }

    return this.taskRepo.getConfigForModelOrBuildDatum(
      currentTask,
      this.model,
      this.buildStatus?.buildDatum,
    );
  }

  @task({ drop: true })
  @waitFor
  *onSubmitSerialNumber(serialNumber) {
    if (!this.isOnline) {
      return;
    }

    const liveBuildStatus = yield this.jemRepo.createLiveBuildStatus.perform(
      serialNumber,
      this.station,
      this.bomSourceLookupValue,
      this.model,
      this.job,
    );

    if (!liveBuildStatus) {
      return;
    }

    this.buildStatus = liveBuildStatus;
    this.bomSourceLookupValue = null;

    if (isPresent(this.tempSerialNumber)) {
      this.tempSerialNumber = '';
    }

    if (!this.waitingForData && this.anyCustomIdentifiersEmpty) {
      this.waitingForData = true;
      return;
    }

    yield this.onSaveNewBuildStatus.perform();
  }

  @task({ drop: true })
  @waitFor
  *setStatusAndSave(status, rejectCodes) {
    if (this.waitingForData) {
      this.clearBuildStatus();
      this.waitingForData = false;
      return;
    }

    if (rejectCodes) {
      this.buildStatus.rejectCodes = rejectCodes;
    }

    this.buildStatus.status = status;
    yield this.jemRepo.saveLiveStatus.perform(this.buildStatus);
  }

  @task({ drop: true })
  @waitFor
  *completeTask(taskStatus, childStatus) {
    if (childStatus.isHolding) {
      return;
    }

    if (taskStatus === TaskStatuses.REJECTED && this.station.confirmRejects) {
      Object.assign(this, {
        showRejectTaskModal: true,
        rejectArgs: [taskStatus, childStatus],
      });

      return;
    }

    if (taskStatus === TaskStatuses.GOOD && childStatus.task.confirmRepair) {
      Object.assign(this, {
        showRepairTaskModal: true,
        repairArgs: [taskStatus, childStatus],
      });

      return;
    }

    yield this.jemRepo.completeTask.perform(taskStatus, childStatus);
  }

  @task
  @waitFor
  *onHold(buildStatus, taskConfig) {
    yield this.jemRepo.toggleHold.perform(buildStatus, taskConfig);
  }

  @task
  @waitFor
  *onSerialNumberScanned(serialNumber) {
    if (!this.isOnline) {
      return;
    }

    if (this.station.usesBomSourceLookup && isEmpty(this.bomSourceLookupValue)) {
      this.bomSourceLookupValue = serialNumber;
      return;
    }

    this.tempSerialNumber = this.tempSerialNumber + serialNumber;

    if (validateSerialNumber(this.tempSerialNumber, this.station)) {
      yield this.onSubmitSerialNumber.perform(this.tempSerialNumber);
    }
  }

  @action
  onSerialNumberInput(val) {
    this.tempSerialNumber = val;
  }

  @task({ drop: true })
  @waitFor
  *onSaveNewBuildStatus() {
    if (!this.isOnline) {
      return;
    }

    if (this.anyCustomIdentifiersEmpty) {
      return;
    }

    if (this.waitingForData) {
      this.waitingForData = false;
    }

    try {
      yield this.jemRepo.saveLiveStatus.perform(this.buildStatus);
    } catch (error) {
      if (error.isValidationError && error.message != null) {
        if (POPOVER_ERROR_KEYS.has(error.message)) {
          this.showInvalidPopover = true;
        } else {
          this.notifier.sendError(error);
        }
      } else {
        console.error(error);
      }

      this.buildStatus = null;
    }
  }

  @task({ drop: true })
  @waitFor
  *onDeleteTab(jemConfig) {
    jemConfig?.deleteRecord();
    yield this.station.save();
    this.instructions = 'task';
  }

  @task
  @waitFor
  *onSetSingleTaskView() {
    yield this._changeViewMode.perform(JEM_VIEW_MODES.singleTask);
  }

  @task
  @waitFor
  *onSetTaskListView() {
    yield this._changeViewMode.perform(JEM_VIEW_MODES.taskList);
  }

  @task
  @waitFor
  *_changeViewMode(viewMode) {
    this.viewMode = viewMode;

    // Make sure we have updated timer information
    const buildStatuses = yield this.store.query('liveBuildStatus', {
      station: this.station.id,
      limit: 1,
    });

    yield this.onBuildStatusReceived.perform(buildStatuses[0]);
  }

  @task
  @waitFor
  *onConfirmTaskRejectModal(codesFragment) {
    const args = this.rejectArgs;
    this.onCloseRejectModal();
    this.currentChildStatus.rejectCodes = codesFragment;
    yield this.jemRepo.completeTask.perform(...args);
  }

  @task
  @waitFor
  *onConfirmTaskRepairModal(codesFragment) {
    const args = this.repairArgs;
    this.onCloseRepairModal();
    this.currentChildStatus.repair = codesFragment;
    yield this.jemRepo.completeTask.perform(...args);
  }

  @task
  @waitFor
  *onAcknowledgeChangeNotifications() {
    yield this.eflexAjax.post.perform('logs/acknowledgeMany', {
      acknowledgedBy: this.currentUser.user.toFragment(),
      afterDate: this.currentUser.user.createdAt,
      logTypes: [NotificationLogTypes.JEM, NotificationLogTypes.WIE],
      locationId: this.station.id,
    });

    this.logsToAcknowledge.forEach(logsToAcknowledge => { logsToAcknowledge.unloadRecord(); });
  }

  @task
  @waitFor
  *onSaveSelectedVariable(variable) {
    this.area.jemDisplayVariable = variable;
    yield this.area.save();
  }

  @task
  @waitFor
  *onAudioTrigger({ locationType, audioType, toPlay }) {
    if (locationType !== 'task' && locationType !== 'station') {
      return;
    }

    yield this.audioPlayer.play.perform(audioType, toPlay);
  }

  @action
  onJemNotificationLog({ logs }) {
    this.store.pushPayload({ logs });
  }

  @task
  @waitFor
  *onBuildStatusReceived(buildStatus) {
    if (
      this.station.productionScheduleEnabled &&
      (this.productionSchedule == null || this.productionSchedule.isCompleted)
    ) {
      yield this.productionScheduleRepo.getCurrent(this.station);
    }

    if (buildStatus?.status === TaskStatuses.STOPPED) {
      this.clearBuildStatus();
      return;
    }

    yield this.jemRepo.loadTaskConfigs.perform(this.station, this.job ?? buildStatus?.modelRecord);
    this.buildStatus = buildStatus;

    if (this.isOnline && buildStatus?.isStarted) {
      this.currentTask = null;
    }

    this.#setInstructionTab();
  }

  @task
  @waitFor
  *onBuildStatusWebsocket(status) {
    const buildStatus = yield this.jemRepo.pushFromWebSocket.perform(status);
    yield this.onBuildStatusReceived.perform(buildStatus);
  }

  @task
  @waitFor
  *fetchLogs(params = {}) {
    params.stationId = this.station.id;
    const logs = yield this.logRepo.fetchLogsForJem.perform(params);
    this.totalLogs = logs.meta?.count ?? 0;
  }

  #setInstructionTab() {
    if (!this.isOnline) {
      return;
    }

    if (!this.stationIsRunning) {
      if (this.station.isWebCamScan) {
        this.onSetInstructionType('imageCapture');
      } else if (RESET_INSTRUCTION_TYPES_ON_FINISH.has(this.instructions)) {
        this.onSetInstructionType('task');
      }
      return;
    }

    const childStatus = this.currentChildStatus;
    if (childStatus?.needsAuth) {
      this.onSetInstructionType('authorize');
    } else if (childStatus?.task?.usesWebCam) {
      this.onSetInstructionType('imageCapture');
    } else if (IMAGE_TASK_TYPES.has(childStatus?.taskType)) {
      this.onSetInstructionType(childStatus.taskType);
    } else {
      this.onSetInstructionType('task');
    }
  }

  clearBuildStatus() {
    this.buildStatus = null;
    this.#setInstructionTab();
  }

  #onShowTabConfigModal({ title, currentTab }) {
    Object.assign(this, {
      currentTab,
      showTabConfigModal: true,
      configJemTabModalTitle: title,
    });
  }

  @task
  @waitFor
  *onModelSelected(model) {
    this.clearBuildStatus();
    yield this.jemRepo.loadTaskConfigs.perform(this.station, model);
    this.model = model;
  }

  @task
  @waitFor
  *onJobSelected(job) {
    this.clearBuildStatus();
    yield this.jemRepo.loadTaskConfigs.perform(this.station, job);
    this.job = job;
  }

  @action
  onSetInstructionType(instructionType) {
    if (this.currentChildStatus?.needsAuth && instructionType !== 'authorize') {
      return;
    }

    this.instructions = instructionType;
  }

  @action
  onAddTab() {
    if (this.station.configuredJemTabs.length >= MAX_SIZE_OF_CONFIG_TABS) {
      this.notifier.sendWarning('jem.maxConfigTabs');
      return;
    }

    this.#onShowTabConfigModal({
      title: this.intl.t('title.addATab'),
      currentTab: this.stationRepo.createJemConfigTab(this.station),
    });
  }

  @action
  onConfigureTab(jemConfig) {
    this.#onShowTabConfigModal({
      title: this.intl.t('jem.tabConfiguration'),
      currentTab: jemConfig,
    });
  }

  @task
  @waitFor
  *onToggleLiveMode() {
    if (this.station.usesModels) {
      const model = this.station.models?.[0];
      yield this.jemRepo.loadTaskConfigs.perform(this.station, this.job ?? model);
      this.model = model;
    }

    const currentTask = this.currentTask;

    this.isOnline = !this.isOnline;

    if (this.isOnline) {
      this.currentTask = null;
    } else {
      this.currentTask = currentTask;
    }
  }

  @action
  closeTabConfigModal(tab) {
    Object.assign(this, {
      currentTab: null,
      showTabConfigModal: false,
    });

    if (tab != null) {
      this.instructions = tab.id;
    }
  }

  @action
  onCloseRejectModal() {
    Object.assign(this, {
      showRejectTaskModal: false,
      rejectArgs: null,
    });
  }

  @action
  onCloseRepairModal() {
    Object.assign(this, {
      showRepairTaskModal: false,
      repairArgs: null,
    });
  }

  @task
  @waitFor
  *onModelScanned(modelCode) {
    const matchingModel = this.area.advancedModelCodeEnabled
      ? this.area.models.find((model) => new RegExp(model.code).test(modelCode))
      : this.area.models.find(item => item.code === modelCode);

    if (matchingModel != null) {
      yield this.onModelSelected.perform(matchingModel);
      this.showInvalidPopover = false;
    } else {
      this.showInvalidPopover = true;
    }
  }

  @action
  onSelectTask(treeTask) {
    this.currentTask = treeTask;
    this.#setInstructionTab();
  }

  @action
  onTriggerError(msg) {
    this.notifier.jemTriggerError(msg.error);
  }

  @action
  onPrereqsNotMet() {
    this.notifier.sendError('stationPrereqsNotMet');
  }
}
