import GridRoute from 'eflex/util/grid-route';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { waitFor } from '@ember/test-waiters';
import { TrackedArray } from 'tracked-built-ins';

export default class EvisionNonConformingRoute extends GridRoute {
  @service store;

  @task
  @waitFor
  *getData(searchParams = {}) {
    const params = Object.assign(searchParams, this.controller?.params);

    const results = yield this.store.query('nonConformingImage', params);
    const data = new TrackedArray(results);

    data.meta = { count: results.meta?.count ?? 0 };

    return data;
  }

  @action
  refresh() {
    this.search.perform();
  }
}
