import Model, { attr, belongsTo } from '@ember-data/model';
import CustomIdentifierValidations from 'eflex/validations/custom-identifier';
import { buildValidations } from '@eflexsystems/ember-tracked-validations';
import { caption, arrayAttr } from 'eflex/decorators';

@buildValidations(CustomIdentifierValidations)
class CustomIdentifier extends Model {
  @attr('boolean', { defaultValue: true }) enabled;
  @arrayAttr captions;
  @caption name;

  @belongsTo('area', { inverse: 'customIdentifiers', async: false }) area;
}

export default CustomIdentifier;
