import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class JemJobDropdown extends Component {
  @service jobRepo;

  @action
  onJobScanned(jobName) {
    this.args.onChange(this.jobRepo.jobs.find(job => job.name === jobName));
  }
}
