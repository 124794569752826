import { inject as service } from '@ember/service';
import { isBlank, isEmpty, isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { getDescendants } from 'eflex/util/tree-helpers';
import { sortByProp } from 'ramda-adjunct';

export default class BaseCriteriaTree extends Component {
  @tracked searchTerm;
  @tracked isDisplayed = isPresent(this.selectedStations) || isPresent(this.selectedTasks);
  @tracked checkAll = true;

  @service intl;
  @service areaRepo;

  get selectedStations() {
    return this.areaRepo.areas
      .flatMap(area => area.stations)
      .filter(item => item.isChecked);
  }

  get selectedTasks() {
    return this.areaRepo.areas
      .flatMap(area => area.tasks)
      .filter(item => item.isChecked);
  }

  get isSearching() {
    return !isBlank(this.searchTerm);
  }

  get selectedLocationsText() {
    let selected;

    if (this.args.type === 'stations') {
      selected = this.selectedStations;
    } else {
      selected = this.selectedTasks;
    }

    const count = selected.length;

    if (count === 1) {
      return selected[0].name;
    } else {
      return `${this.intl.t('searching')} ${count} ${this.args.type}`;
    }
  }

  get numberOfMatches() {
    let count = this.matchedItems.length;
    if (count === 0) {
      count = 'No';
    }
    return `${count} matches`;
  }

  get filteredAreas() {
    let areas = this.areaRepo.areas;

    if (!isBlank(this.searchTerm)) {
      const searchRegex = new RegExp(`${this.searchTerm}`, 'i');
      areas = areas.filter(area =>
        searchRegex.test(area.name) ||
        area.children.some(group => searchRegex.test(group.name)) ||
        area.stations.some(station => searchRegex.test(station.name)) ||
        area.tasks.some(treeTask => searchRegex.test(treeTask.name)),
      );
    }

    return sortByProp('order', areas ?? []);
  }

  _updateParams() {
    if (this.args.type === 'tasks') {
      const taskIds = this.selectedTasks?.map(item => item.id);

      if (isEmpty(taskIds)) {
        this.args.removeParam('taskIds');
      } else {
        this.args.updateParams({
          key: 'taskIds',
          value: taskIds,
          breadcrumb: this.selectedLocationsText,
        });
      }
    } else {
      const stationIds = this.selectedStations?.map(item => item.id);

      if (isEmpty(stationIds)) {
        this.args.removeParam('stationIds');
      } else {
        this.args.updateParams({
          key: 'stationIds',
          value: stationIds,
          breadcrumb: this.selectedLocationsText,
        });
      }
    }
  }

  _setAllTreeItemsChecked(checkAll) {
    this.checkAll = checkAll;
    this.areaRepo.areas.forEach(area => {
      area.isChecked = checkAll;
      getDescendants(area, this.args.type).forEach(child => { child.isChecked = checkAll; });
    });
    this._updateParams();
  }

  @action
  onDisplayedChanged(isDisplayed) {
    this.isDisplayed = isDisplayed;

    if (isDisplayed) {
      this._setAllTreeItemsChecked(true);
    } else {
      this.args.removeParam('taskIds');
      this.args.removeParam('stationIds');
    }
  }

  @action
  onTreeItemChecked(treeItem, isChecked) {
    treeItem.isChecked = isChecked;

    if (isChecked) {
      let ancestor = treeItem.parent;
      while (ancestor) {
        ancestor.isChecked = isChecked;
        ancestor = ancestor.parent;
      }
    }

    getDescendants(treeItem, this.args.type).forEach(child => { child.isChecked = isChecked; });

    this._updateParams();
  }

  @action
  toggleAll() {
    this._setAllTreeItemsChecked(!this.checkAll);
  }
}
