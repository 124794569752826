import FileRetentionValidations from 'eflex/validations/file-retention';
import Model, { attr, belongsTo } from '@ember-data/model';
import { buildValidations } from '@eflexsystems/ember-tracked-validations';

@buildValidations(FileRetentionValidations)
class FileRetention extends Model {
  @belongsTo('cameraConfiguration', { inverse: null, async: false, embedded: true }) defaultCameraConfiguration;
  @attr('number') maxHardDriveUsage;
  @attr('number') deleteToHardDrivePercentage;
  @attr('string') archiveDirectory;

  get isDirty() {
    return super.isDirty || this.defaultCameraConfiguration?.isDirty;
  }
}

export default FileRetention;
