import config from 'eflex/config/environment';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';

momentDurationFormatSetup(moment);

// native toLocaleString is crazy slow
moment.duration.fn.format.defaults.useToLocaleString = false;

if (config.environment !== 'production') {
  moment.deprecationHandler = function (ignored, msg) {
    throw new Error(msg);
  };
}
