import { inject as service } from '@ember/service';
import LocationRepoBase from 'eflex/services/location-repo-base';
import { all, task } from 'ember-concurrency';
import getNextTreeOrder from 'eflex/util/get-next-tree-order';
import { waitFor } from '@ember/test-waiters';
import { without, clone } from 'ramda';
import { recursiveSave } from 'eflex/util/tree-helpers';
import { removeObject } from 'eflex/util/array-helpers';

const COPY_PREREQ_IGNORED_RELATIONSHIPS = new Set(['station', 'configModel', 'configOption']);

const copyVariableMappings = (variableMappings, station, stationCopy) => {
  return variableMappings.map(variableMapping => {
    const variableMappingCopy = variableMapping.copy(true);

    if (variableMapping.jemProcessDataDefForValue) {
      const taskIndex = station.tasks.indexOf(variableMapping.jemProcessDataDefForValue.task);

      if (taskIndex > -1) {
        const jemProcessDataDefIndex = station.tasks[taskIndex]
          .jemProcessDataDefs
          .indexOf(variableMapping.jemProcessDataDefForValue);

        variableMappingCopy.jemProcessDataDefForValue = stationCopy
          .tasks[taskIndex]
          .jemProcessDataDefs[jemProcessDataDefIndex];
      }
    }

    if (variableMapping.variableDefForValue) {
      const taskIndex = station.tasks.indexOf(variableMapping.variableDefForValue.task);

      if (taskIndex > -1) {
        const variableDefIndex = station
          .tasks[taskIndex]
          .variableDefs
          .indexOf(variableMapping.variableDefForValue);

        variableMappingCopy.variableDefForValue = stationCopy
          .tasks[taskIndex]
          .variableDefs[variableDefIndex];
      }
    }

    return variableMappingCopy;
  });
};

const copyEdhrMappings = (edhrMappings, station, stationCopy) => {
  return edhrMappings.map(edhrMapping => {
    const edhrMappingCopy = edhrMapping.copy(true);

    if (edhrMapping.dataTask) {
      edhrMappingCopy.dataTask = stationCopy.tasks[station.tasks.indexOf(edhrMapping.dataTask)];
    }

    if (edhrMapping.jemProcessDataDef) {
      const taskIndex = station.tasks.indexOf(edhrMapping.jemProcessDataDef.task);

      if (taskIndex > -1) {
        const jemProcessDataDefIndex = station.tasks[taskIndex]
          .jemProcessDataDefs
          .indexOf(edhrMapping.jemProcessDataDef);

        edhrMappingCopy.jemProcessDataDef = stationCopy
          .tasks[taskIndex]
          .jemProcessDataDefs[jemProcessDataDefIndex];
      }
    }

    if (edhrMapping.variableDef) {
      const taskIndex = station.tasks.indexOf(edhrMapping.variableDef.task);

      if (taskIndex > -1) {
        const variableDefIndex = station.tasks[taskIndex].variableDefs.indexOf(edhrMapping.variableDef);

        edhrMappingCopy.variableDef = stationCopy
          .tasks[taskIndex]
          .variableDefs[variableDefIndex];
      }
    }

    return edhrMappingCopy;
  });
};

export default class StationRepoService extends LocationRepoBase {
  @service licensing;
  @service eflexAjax;
  @service locationRepo;
  @service notifier;
  @service taskConfigRepo;
  @service taskRepo;

  stations = this.store.peekAll('station');

  @task
  @waitFor
  *createAndSave(properties = {}) {
    if (this.licensing.license.atStationLimit) {
      this.notifier.sendError('licensing.jemStationLimitExceeded');
      return;
    }

    const station = this.create(properties);

    yield station.save();
    yield this._recreateBuildData.perform(station);
    return station;
  }

  @task
  @waitFor
  *move(dropped, droppedOn) {
    if (!this.dropAllowed(dropped, droppedOn)) {
      if (droppedOn != null) {
        this.notifier.sendError('plant.invalidParent', { treeItem: droppedOn?.name });
      }

      return;
    }

    if (dropped.isSelfOrChildInvalid) {
      this.validationErrorNotifier.sendErrors([dropped]);
      return;
    }

    const loadTaskConfigPromises = [this.taskConfigRepo.loadTaskConfigs.perform(dropped)];

    yield all(loadTaskConfigPromises);

    const droppedOnParent = droppedOn.parent;
    let newParent;

    if (dropped.type === droppedOn.type) {
      newParent = droppedOnParent;
    } else if (dropped.type === droppedOn.childType) {
      newParent = droppedOn;
    }

    const droppedParent = dropped.parent;
    dropped.parent = newParent;
    const parentChildren = newParent.stations;
    removeObject(droppedParent.stations, dropped);

    if (droppedParent === droppedOnParent) {
      const index = parentChildren.indexOf(droppedOn) + 1;
      parentChildren.splice(index, 0, dropped);
    } else if (droppedParent === droppedOn) {
      parentChildren.splice(0, 0, dropped);
    } else {
      parentChildren.push(dropped);
    }

    if (droppedParent === newParent) {
      this._reorder(droppedParent);
      yield recursiveSave(droppedParent);
    } else {
      this.updateLocationPaths(dropped);
      this._reorder(droppedParent);
      this._reorder(newParent);

      const savedStations = droppedParent
        .stations
        .filter(item => item.isDirty)
        .concat(newParent.stations.filter(item => item.isDirty));

      const savedTasks = savedStations.flatMap(station => station.tasks).filter(item => item.isDirty);

      yield this.locationRepo.bulkSave.perform({
        stations: savedStations,
        tasks: savedTasks,
        taskConfigs: savedTasks.flatMap(_task => _task.taskConfigs).filter(item => item.isDirty),
      });
    }

    yield this._recreateBuildData.perform(dropped);
  }

  @task
  @waitFor
  *_recreateBuildData(station) {
    if (station.usesModels || station.usesJobs) {
      return;
    }

    yield all([this.eflexAjax.post.perform('buildData/station', { stationId: station.id })]);
  }

  create(properties = {}) {
    const { area } = properties.parent;

    Object.assign(properties, {
      usesComponents: area.usesComponents && this.licensing.license?.componentBasedEnabled,
      usesJobs: area.usesJobs ?? false,
      oee: area.oeeEnabled && this.licensing.license?.oeeEnabled,
      order: getNextTreeOrder(properties.parent.stations),
    });

    const station = super.create('station', properties);

    this.store.createRecord('stationLoadJemConfiguration', { station });

    if (station.usesComponents) {
      const alwaysRunOption = station.area.alwaysRunComponent?.options?.[0];
      this.createJemPrerequisite(station, alwaysRunOption, true);
    } else {
      station.area.models.forEach(model => {
        this.createJemConfig(station, model);
        this.createJemPrerequisite(station, model);
      });
    }

    return station;
  }

  dropAllowed(dropped, droppedOn) {
    return dropped != null &&
      droppedOn != null &&
      dropped !== droppedOn &&
      dropped.type === 'station' &&
      (droppedOn.type === 'group' || droppedOn.type === 'station') &&
      dropped.area === droppedOn.area;
  }

  getJemConfig(station, context) {
    if (context == null) {
      return null;
    }

    return station.jemConfigurations.find(item => item.modelId === context.id);
  }

  createJemConfig(station, model) {
    return (
      this.getJemConfig(station, model) ??
      this.store.createRecord('stationJemConfiguration', {
        model,
        station,
      })
    );
  }

  getJemPrerequisite(station, context) {
    if (station.usesModels) {
      return station.jemPrerequisites.find(prereq => prereq.configModel === context);
    } else {
      return station.jemPrerequisites.find(prereq => prereq.configOption === context);
    }
  }

  createJemPrerequisite(station, context, enabled = false) {
    if (this.getJemPrerequisite(station, context)) {
      return;
    }

    if (station.usesModels) {
      this.store.createRecord('jemPrerequisite', {
        configModel: context,
        station,
        enabled,
      });
    } else {
      this.store.createRecord('jemPrerequisite', {
        configOption: context,
        station,
        enabled,
      });
    }
  }

  copyJemPrereqToAllContexts(sourcePrereq) {
    if (sourcePrereq == null) {
      return;
    }

    const destinationPrereqs = without([sourcePrereq], sourcePrereq.station.jemPrerequisites);

    destinationPrereqs.forEach((prereq) => {
      if (prereq.isDeleted) {
        return;
      }

      prereq.eachAttribute(attr => {
        prereq[attr] = clone(sourcePrereq[attr]);
      });

      prereq.eachRelationship(relationship => {
        if (COPY_PREREQ_IGNORED_RELATIONSHIPS.has(relationship)) {
          return;
        }

        prereq[relationship] = sourcePrereq[relationship];
      });
    });
  }

  createJemConfigTab(station) {
    return this.store.createRecord('customTabJemConfiguration', { station });
  }

  copyStation(station, options = {}) {
    const order = getNextTreeOrder(station.parent.stations);
    const stationCopy = station.copy(true, options);
    stationCopy.order = order;
    this.updateLocationPaths(stationCopy);

    station.tasks.forEach(treeTask => {
      const taskCopy = treeTask.copy(true);
      taskCopy.parent = stationCopy;
      this.updateLocationPaths(taskCopy);

      if (treeTask.edhrMappings) {
        taskCopy.edhrMappings = copyEdhrMappings(treeTask.edhrMappings, station, stationCopy);
      }

      treeTask.triggers.forEach(trigger => {
        const triggerCopy = trigger.copy(true);
        triggerCopy.task = taskCopy;

        if (trigger.edhrMappings) {
          triggerCopy.edhrMappings = copyEdhrMappings(trigger.edhrMappings, station, stationCopy);
        }

        if (trigger.variableMappings) {
          triggerCopy.variableMappings = copyVariableMappings(trigger.variableMappings, station, stationCopy);
        }
      });

      treeTask.taskConfigs.forEach(taskConfig => {
        this.taskConfigRepo.copyTaskConfig(taskConfig, taskCopy);
      });

    });

    station.triggers.forEach((trigger) => {
      const triggerCopy = trigger.copy(true);
      triggerCopy.station = stationCopy;

      if (trigger.variableMappings) {
        triggerCopy.variableMappings = copyVariableMappings(trigger.variableMappings, station, stationCopy);
      }

      trigger.triggerConfigs.forEach(triggerConfig => {
        const triggerConfigCopy = triggerConfig.copy(true);
        triggerConfigCopy.parentTrigger = triggerCopy;
        triggerConfigCopy.station = stationCopy;
      });
    });

    return stationCopy;
  }
}
