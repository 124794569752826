const TriggerEventsValues = {
  START: 'start',
  PASS: 'pass',
  REJECT: 'reject',
  SCRAP: 'scrap',
  CYCLE_TIME: 'cycleTimeWarning',
  STOP: 'stop',
  HOLD: 'hold',
  UNHOLD: 'unhold',
};

const TriggerEvents = [TriggerEventsValues.START, TriggerEventsValues.PASS, TriggerEventsValues.REJECT];
const StationEvents = TriggerEvents.concat([
  TriggerEventsValues.SCRAP,
  TriggerEventsValues.CYCLE_TIME,
  TriggerEventsValues.STOP,
  TriggerEventsValues.HOLD,
  TriggerEventsValues.UNHOLD,
]);
const OeeEvents = [TriggerEventsValues.START, TriggerEventsValues.STOP];

export { TriggerEvents, StationEvents, OeeEvents };
export default TriggerEventsValues;
