import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { ErrorProofingStates } from 'eflex/constants/tasks/error-proofing-states';
import { ErrorProofingTriggerValues } from 'eflex/constants/tasks/trigger-types';
import Component from '@glimmer/component';
import { pluck, pipe, prop, reject } from 'ramda';
import { intoArray } from '@eflexsystems/ramda-helpers';
import { compact, sortByPaths } from 'ramda-adjunct';

const ErrorProofingHardware = ['adam-io', 'turck-io'];

export default class TaskConfigErrorProofing extends Component {
  hardwareInputStates = ErrorProofingStates;

  @service hardwareRepo;
  @service hardwareInputDefRepo;

  get showChangeState() {
    return this.args.task.triggerType === ErrorProofingTriggerValues.taskActive;
  }

  get hardwareInputDefs() {
    return this.args.task.hardwareInputDefs.filter(item => !item.isDeleted);
  }

  get hardwareInputs() {
    return this.hardwareRepo
      .hardwareByType(ErrorProofingHardware)
      .flatMap(hardware => hardware.hardwareIos);
  }

  get remainingInputs() {
    return this.hardwareInputs.length - this.hardwareInputDefs.length;
  }

  get remainingHardwareInputs() {
    const selectedHardwareInputs = intoArray(
      pluck('input'),
      compact,
    )(this.hardwareInputDefs ?? []);

    return pipe(
      intoArray(
        reject(prop('outputOnly')),
        reject(hardwareInput => selectedHardwareInputs.includes(hardwareInput)),
      ),
      sortByPaths([
        ['hardware', 'name'],
        ['hardware', 'id'],
        ['index'],
      ]),
    )(this.hardwareInputs);
  }

  @action
  addMultipleInputs(inputQuantity) {
    for (let i = 0; i < inputQuantity; i++) {
      if (this.remainingInputs) {
        this.hardwareInputDefRepo.createHardwareInputDef({
          task: this.args.task,
        });
      }
    }
  }

  @action
  deleteHardwareInputDef(hardwareInputDef) {
    this.hardwareInputDefRepo.deleteHardwareInputDef(hardwareInputDef);
  }

  hardwareSearchMatcher(input, term) {
    if (input.hardware.name.includes(term) || input.inputName.includes(term)) {
      return 1;
    } else {
      return -1;
    }
  }
}
