import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { task as trackedTask } from 'ember-resources/util/ember-concurrency';
import moment from 'moment-timezone';
import echartsTheme from 'eflex/echarts-theme';
import { waitFor } from '@ember/test-waiters';
import { clone } from 'ramda';

export default class FaultCodeSummary extends Component {
  @service queryRunner;
  @service intl;

  get params() {
    return {
      stationIds: this.args.stationIds,
      startDate: this.args.startDate,
      endDate: this.args.endDate,
      shiftNames: this.args.shiftNames,
    };
  }

  oeeFaultCodeData = trackedTask(this, this.getData, () => [this.params]);

  // jscpd:ignore-start
  @task({ drop: true })
  @waitFor
  *getData(params) {
    const oeeFaultCodeQuery = yield this.queryRunner.queryWithParams.perform(
      params,
      [
        {
          $match: {
            state: 'faulted',
            faultCodes: {
              $exists: true,
            },
          },
        },
        {
          $unwind: {
            path: '$faultCodes.codes',
          },
        },
        {
          $group: {
            _id: '$faultCodes.codes.code',
            totalTimeInState: {
              $sum: {
                $dateDiff: {
                  startDate: '$startDate',
                  endDate: '$endDate',
                  unit: 'millisecond',
                },
              },
            },
            doc: {
              $first: '$$ROOT',
            },
          },
        },
        {
          $replaceRoot: {
            newRoot: {
              $mergeObjects: [
                {
                  totalTimeInState: '$totalTimeInState',
                },
                '$doc',
              ],
            },
          },
        },
        {
          $sort: {
            totalTimeInState: 1,
          },
        },
      ],
      true,
    );

    const oeeFaultCodes = yield this.queryRunner.runQuery.perform('OeeStates', oeeFaultCodeQuery);
    return {
      chartData: this.getEchartOptions(oeeFaultCodes),
      exportData: () => this.formatExportData(clone(oeeFaultCodes)),
    };
  }

  getEchartOptions(faultCodeData) {
    return {
      title: {
        text: this.intl.t('bi.chartLabel.faultCodeSumary'),
      },

      legend: {
        show: true,
        bottom: 5,
      },

      tooltip: {
        trigger: 'axis',
        formatter(params) {
          const tooltipItems = params
            .map(
              ({ marker, seriesName, value }) =>
                `${marker} ${seriesName} <span style="float:right;font-weight:bold;">${moment
                  .duration(value, 'milliseconds')
                  .format('d[d] h[h] m[m] s[s]', {
                    precision: 1,
                    trim: 'both mid',
                  })}</span>`,
            )
            .join('<br>');
          return `<div style="min-width:200px;">${tooltipItems}</div>`;
        },
      },

      yAxis: {
        type: 'category',
        boundaryGap: true,
        data: faultCodeData.map(({ faultCodes }) => {
          return `${faultCodes.codes.code}: ${faultCodes.codes.description}`;
        }),
      },

      xAxis: [
        {
          type: 'value',
          axisLabel: {
            formatter: (value) => {
              return moment.duration(value, 'milliseconds').format('d[d] h[h] m[m] s[s]');
            },
          },
        },
      ],

      // jscpd:ignore-end

      series: [
        {
          name: this.intl.t('bi.chartLegend.timeFaulted'),
          data: faultCodeData.map(item => item.totalTimeInState),
          label: {
            show: true,
            position: 'right',
            color: echartsTheme.colorPalette.biGrey,
            formatter: (params) => {
              return moment.duration(params.value, 'milliseconds').format('d[d] h[h] m[m] s[s]', {
                precision: 1,
                trim: 'both mid',
              });
            },
          },
          color: echartsTheme.colorPalette.danger,
          type: 'bar',
          xAxisIndex: 0,
        },
      ],
    };
  }

  formatExportData(data) {
    data.forEach((faultCode) => {
      const { totalTimeInState } = faultCode;
      faultCode.totalTimeInState = moment.duration(totalTimeInState, 'milliseconds').format('d[d] h[h] m[m] s[s]', {
        precision: 1,
        trim: 'both mid',
      });
      delete faultCode.station;
      delete faultCode.faultCodes;
      delete faultCode._id;
      delete faultCode.startDate;
      delete faultCode.endDate;
      delete faultCode.customButtonColor;
      delete faultCode.button;
      delete faultCode.scheduleHistory;
    });

    return data;
  }
}
