import { inject as service } from '@ember/service';
import LocationRepoBase from 'eflex/services/location-repo-base';
import { task } from 'ember-concurrency';
import { capitalize } from '@ember/string';
import { action } from '@ember/object';
import { waitFor } from '@ember/test-waiters';
import { taskTypes } from 'eflex/constants/tasks/task-types';
import { removeObjects } from 'eflex/util/array-helpers';
import { without, clone } from 'ramda';

export default class TaskConfigRepoService extends LocationRepoBase {
  @service store;
  @service triggerRepo;
  @service workInstructionRepo;

  loadedTasks = new Set();
  loadedTaskParents = new Set();
  loadedTaskConfigContexts = new Map();

  @task
  @waitFor
  *loadTaskConfigs(parent) {
    const parentType = parent.constructor.modelName;
    const params = { ancestorPath: parent.path };

    if (parentType === 'station' || parentType === 'job') {
      if (this.loadedTaskParents.has(parent.id)) {
        return;
      }
      yield this.store.query('taskConfig', params);
      this.loadedTaskParents.add(parent.id);
      parent.tasks?.forEach(treeTask => { this.loadedTasks.add(treeTask.id); });
      return;
    }

    if (this.loadedTasks.has(parent.id) || this.loadedTaskParents.has(parent.parent.id)) {
      return;
    }

    yield this.store.query('taskConfig', params);
    this.loadedTasks.add(parent.id);
  }

  @task
  @waitFor
  *loadContextTaskConfigs(station, context) {
    let taskParent = station;

    if (station.usesJobs) {
      taskParent = context;
    }

    const parentCacheId = taskParent.id;

    if (this.loadedTaskParents.has(parentCacheId)) {
      return;
    }

    let cacheKey = true;
    const params = { ancestorPath: taskParent.path };

    if (context && (station.usesModels || station.usesComponents)) {
      cacheKey = context.id;
      params.model = context.id;
    }

    const parentCacheSet = this.loadedTaskConfigContexts.get(parentCacheId);

    if (parentCacheSet?.has(cacheKey)) {
      return;
    }

    yield this.store.query('taskConfig', params);

    if (parentCacheSet) {
      parentCacheSet.add(cacheKey);
    } else {
      this.loadedTaskConfigContexts.set(parentCacheId, new Set([cacheKey]));
    }
  }

  @action
  copyAllToAllContexts(sourceConfig) {
    this.copyToAllContexts(sourceConfig);
    this.copyEventToAllContexts(sourceConfig);

    sourceConfig.triggerConfigs
      .filter(item => item.constructor.modelName === 'work-instruction-hardware-trigger-config')
      .forEach(triggerConfig => {
        this.workInstructionRepo.copyToAllContexts(
          sourceConfig.parent,
          triggerConfig,
          triggerConfig.event,
        );
      });
  }

  create(attrs = {}) {
    const config = super.create('taskConfig', attrs);

    config.task.triggers.forEach(trigger => {
      this.triggerRepo.createTriggerConfig(trigger, config);
    });

    this.#applyDefaults(config);

    return config;
  }

  #applyDefaults(config) {
    const taskType = config.task?.taskType;

    if (taskType == null) {
      return;
    }

    config.task.nonDeletedJemProcessDataDefs.forEach(def => {
      this.store.createRecord('jemProcessDataDefConfig', {
        jemProcessDataDef: def,
        taskConfig: config,
      });
    });

    config.task.nonDeletedVariableDefs.forEach(def => {
      this.store.createRecord('variableDefConfig', {
        taskConfig: config,
        variableDef: def,
      });
    });

    config.task.nonDeletedHardwareInputDefs.forEach(def => {
      this.store.createRecord('hardwareInputDefConfig', {
        taskConfig: config,
        hardwareInputDef: def,
      });
    });

    config.task.nonDeletedDecisionDefs.forEach(def => {
      this.store.createRecord('decisionDefConfig', {
        taskConfig: config,
        decisionDef: def,
      });
    });

    config.task.nonDeletedSpindles.forEach((spindle, i) => {
      this.store.createRecord('spindleConfig', {
        taskConfig: config,
        spindle,
        name: `Spindle ${i + 1}`,
      });
    });

    const setupFunc = `_setup${capitalize(taskType)}`;
    this[setupFunc]?.(config);
  }

  changeType(taskConfig) {
    taskConfig.hardwareInputDefConfigs.forEach(hardwareInputDefConfig => { hardwareInputDefConfig.deleteRecord(); });
    taskConfig.jemProcessDataDefConfigs.forEach(jemProcessDataDefConfig => { jemProcessDataDefConfig.deleteRecord(); });
    taskConfig.decisionDefConfigs.forEach(decisionDefConfig => { decisionDefConfig.deleteRecord(); });
    taskConfig.variableDefConfigs.forEach(variableDefConfig => { variableDefConfig.deleteRecord(); });
    taskConfig.spindleConfigs.forEach(spindleConfig => { spindleConfig.deleteRecord(); });
    taskConfig.pushToScheduleConfigs.forEach(pushToScheduleConfig => { pushToScheduleConfig.deleteRecord(); });

    this.#applyDefaults(taskConfig);
  }

  delete(deleted) {
    deleted.deleteRecord();
  }

  copyToAllContexts(sourceConfig) {
    if (sourceConfig == null) {
      return;
    }

    const destinationConfigs = without([sourceConfig], sourceConfig.parent.children);

    destinationConfigs.forEach((config) => {
      if (config.isDeleted) {
        return;
      }

      config.eachAttribute(attr => {
        if (attr === 'path') {
          return;
        }

        config[attr] = clone(sourceConfig[attr]);
      });

      config.jemProcessDataDefConfigs.forEach((defConfig) => {
        this.#copyToDefConfig(sourceConfig, defConfig, 'jemProcessDataDef');
      });

      config.hardwareInputDefConfigs.forEach((defConfig) => {
        this.#copyToDefConfig(sourceConfig, defConfig, 'hardwareInputDef');
      });

      config.variableDefConfigs.forEach((defConfig) => {
        this.#copyToDefConfig(sourceConfig, defConfig, 'variableDef');
      });

      if (sourceConfig.parent.taskType === taskTypes.decision) {
        config.decisionDefConfigs.forEach((defConfig) => {
          this.#copyToDefConfig(sourceConfig, defConfig, 'decisionDef');
        });
      }

      config.spindleConfigs.forEach((defConfig) => {
        this.#copyToDefConfig(sourceConfig, defConfig, 'spindle');
      });

      let barcodeStringIndex = 0;
      config.strings.forEach(barcodeString => {
        if (barcodeString.isDeleted) {
          barcodeStringIndex += 1;
          return;
        }

        const toCopy = sourceConfig.strings[barcodeStringIndex];

        barcodeString.eachAttribute(attr => {
          barcodeString[attr] = clone(toCopy[attr]);
        });

        barcodeStringIndex += 1;
      });

      config.pushToScheduleConfigs = sourceConfig.pushToScheduleConfigs
        .filter(item => !item.isDeleted)
        .map(pushToScheduleConfig => pushToScheduleConfig.copy());
    });
  }

  copyEventToAllContexts(sourceConfig) {
    if (sourceConfig == null) {
      return;
    }

    const destinationConfigs = without([sourceConfig], sourceConfig.parent.children);

    destinationConfigs.forEach(config => {
      config.triggerConfigs
        .filter(item => item.constructor.modelName !== 'work-instruction-hardware-trigger-config')
        .forEach(triggerConfig => {
          this.#copyToDefConfig(sourceConfig, triggerConfig, 'parentTrigger', 'triggerConfigs');
        });
    });
  }

  copyDecisionToAllContexts(sourceConfig) {
    if (sourceConfig == null) {
      return;
    }

    const destinationConfigs = without([sourceConfig], sourceConfig.parent.children);

    destinationConfigs.forEach(config => {
      config.decisionDefConfigs.forEach(decisionDefConfig => {
        this.#copyToDefConfig(sourceConfig, decisionDefConfig, 'decisionDef');
      });
    });
  }

  #copyToDefConfig(sourceConfig, defConfig, defName, configsName = null) {
    if (defConfig.isDeleted) {
      return;
    }

    configsName ??= `${defName}Configs`;

    const toCopy = sourceConfig[configsName].find(item => item[defName] === defConfig[defName]);

    defConfig.eachAttribute(attr => {
      defConfig[attr] = clone(toCopy[attr]);
    });

    defConfig.eachRelationship(relationship => {
      if (relationship === 'taskConfig') {
        return;
      }

      defConfig[relationship] = toCopy[relationship];
    });
  }

  removeInvalidBoltAnimations(taskConfig) {
    const boltCount = parseInt(taskConfig.boltCount);

    taskConfig.triggerConfigs
      .filter(item => item.constructor.modelName === 'work-instruction-hardware-trigger-config')
      .forEach(function (triggerConfig) {
        const invalidAnimations = triggerConfig.animations
          .filter(item => item.bolt !== 'all')
          .filter((animation) => parseInt(animation.bolt) > boltCount);

        removeObjects(triggerConfig.animations, invalidAnimations);
      });
  }

  copyTaskConfig(taskConfig, parent) {
    const taskConfigCopy = taskConfig.copy(true);
    taskConfigCopy.parent = parent;
    this.updateLocationPaths(taskConfigCopy);

    taskConfig.variableDefConfigs.forEach((variableDefConfig, index) => {
      const variableDefConfigCopy = variableDefConfig.copy(true);
      variableDefConfigCopy.variableDef = parent.variableDefs[index];
      variableDefConfigCopy.taskConfig = taskConfigCopy;
    });

    taskConfig.jemProcessDataDefConfigs.forEach((jemProcessDataDefConfig, index) => {
      const jemProcessDataDefConfigCopy = jemProcessDataDefConfig.copy(true);
      jemProcessDataDefConfigCopy.jemProcessDataDef = parent.jemProcessDataDefs[index];
      jemProcessDataDefConfigCopy.taskConfig = taskConfigCopy;
    });

    taskConfig.hardwareInputDefConfigs.forEach((hardwareInputDefConfig, index) => {
      const hardwareInputDefConfigCopy = hardwareInputDefConfig.copy(true);
      hardwareInputDefConfigCopy.hardwareInputDef = parent.hardwareInputDefs[index];
      hardwareInputDefConfigCopy.taskConfig = taskConfigCopy;
    });

    taskConfig.triggerConfigs.forEach((triggerConfig, index) => {
      const triggerConfigCopy = triggerConfig.copy(true);
      triggerConfigCopy.parentTrigger = parent.triggers[index];
      triggerConfigCopy.taskConfig = taskConfigCopy;
    });

    taskConfig.decisionDefConfigs.forEach((decisionDefConfig, index) => {
      const decisionDefConfigCopy = decisionDefConfig.copy(true);
      decisionDefConfigCopy.decisionDef = parent.decisionDefs[index];
      decisionDefConfigCopy.taskConfig = taskConfigCopy;
    });

    taskConfig.spindleConfigs.forEach((spindleConfig, index) => {
      const spindleConfigCopy = spindleConfig.copy(true);
      spindleConfigCopy.spindle = parent.spindles[index];
      spindleConfigCopy.taskConfig = taskConfigCopy;
    });

    taskConfig.strings.forEach(barcodeString => {
      const barcodeStringCopy = barcodeString.copy(true);
      barcodeStringCopy.taskConfig = taskConfigCopy;
    });

    return taskConfigCopy;
  }

  _setupBarcode(config) {
    config.enabled = false;
    config.strings = config.task?.strings.map(string => string.copy()) ?? [];
  }

  _setupVision(config) {
    config.strings = config.task?.strings.map(string => string.copy()) ?? [];
  }

  _setupPick(config) {
    if (!config.task?.hardwareInput) {
      config.pickSensorEnabled = false;
    }
  }
}
