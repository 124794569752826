var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
let NewVersionNotifier = (_class = class NewVersionNotifier extends Component {
  constructor(...args) {
    super(...args);
    /** @type {import("ember-cli-new-version/services/new-version").default} */
    _initializerDefineProperty(this, "newVersion", _descriptor, this);
    _initializerDefineProperty(this, "updateMessage", _descriptor2, this);
    _initializerDefineProperty(this, "showReload", _descriptor3, this);
    _initializerDefineProperty(this, "reloadButtonText", _descriptor4, this);
  }
  /**
   * @returns {string | undefined}
   */
  get message() {
    if (this.newVersion.isNewVersionAvailable) {
      return this.updateMessage.replace('{oldVersion}', this.newVersion.currentVersion).replace('{newVersion}', this.newVersion.latestVersion);
    }
    return undefined;
  }
  close() {
    this.newVersion.ignoreVersion(this.newVersion.latestVersion);
    return false;
  }
  reload() {
    if (typeof window !== 'undefined' && window.location) {
      window.location.reload(true);
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "newVersion", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "updateMessage", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return this.args.updateMessage ?? 'This application has been updated from version {oldVersion} to {newVersion}. Please save any work, then refresh browser to see changes.';
  }
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "showReload", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return this.args.showReload ?? true;
  }
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "reloadButtonText", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return this.args.reloadButtonText ?? 'Reload';
  }
}), _applyDecoratedDescriptor(_class.prototype, "close", [action], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reload", [action], Object.getOwnPropertyDescriptor(_class.prototype, "reload"), _class.prototype)), _class);
export { NewVersionNotifier as default };