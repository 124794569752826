import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { task as trackedTask } from 'ember-resources/util/ember-concurrency';
import moment from 'moment-timezone';
import echartsTheme from 'eflex/echarts-theme';
import { waitFor } from '@ember/test-waiters';
import { clone } from 'ramda';
import { action } from '@ember/object';
export default class CycleTimeStation extends Component {
  // jscpd:ignore-start
  @service queryRunner;
  @service intl;
  @service router;

  get params() {
    return {
      stationIds: this.args.stationIds,
      modelIds: this.args.modelIds,
      userIds: this.args.userIds,
      tags: this.args.tags,
      startDate: this.args.startDate,
      endDate: this.args.endDate,
      shiftNames: this.args.shiftNames,
    };
  }

  cycleTimeData = trackedTask(this, this.getData, () => [this.params]);

  @task({ drop: true })
  @waitFor
  *getData(params) {
    const cycleTimeQuery = yield this.queryRunner.queryWithParams.perform(params, [
      {
        $group: {
          _id: '$location._id',
          name: {
            $first: {
              $first: '$location.captions.text',
            },
          },
          stationTargetCycleTime: {
            $last: '$location.targetCycleTime',
          },
          numberOfCycles: {
            $sum: 1,
          },
          numberOfCyclesOverTarget: {
            $sum: {
              $cond: {
                if: {
                  $and: [
                    {
                      $gte: ['$location.targetCycleTime', 1],
                    },
                    {
                      $gt: [
                        '$cycleTime',
                        {
                          $multiply: ['$location.targetCycleTime', 1000],
                        },
                      ],
                    },
                  ],
                },
                then: 1,
                else: 0,
              },
            },
          },
          avgStationCycleTime: {
            $avg: '$cycleTime',
          },
        },
      },
      {
        $project: {
          _id: true,
          name: true,
          avgStationCycleTime: true,
          stationTargetCycleTime: true,
          percentageOfCyclesOverTarget: {
            $divide: ['$numberOfCyclesOverTarget', '$numberOfCycles'],
          },
        },
      },
      {
        $sort: {
          avgStationCycleTime: 1,
        },
      },
    ]);

    const cycleTimeData = yield this.queryRunner.runQuery.perform('BuildStatuses', cycleTimeQuery);

    return {
      chartData: this.getEchartOptions(cycleTimeData),
      exportData: () => this.formatCycleTimeExportData(clone(cycleTimeData)),
    };
    // jscpd:ignore-end
  }

  getEchartOptions(cycleTimeData) {
    return {
      title: {
        text: this.intl.t('bi.chartLabel.cycleTimeByStation'),
      },

      legend: {
        show: true,
        bottom: 5,
      },

      tooltip: {
        trigger: 'axis',
        formatter: (params) => {
          const tooltipItems = [];
          params.forEach(({ marker, seriesName, value, name }, index) => {
            switch (index) {
              case 0: {
                tooltipItems.push(
                  `<div>${name}</div>${marker} ${seriesName}:<span style="float:right;font-weight:bold;">${moment
                    .duration(value, 'seconds')
                    .format('d[d] h[h] m[m] s[s]', {
                      precision: 1,
                      trim: 'both mid',
                    })}</span>`,
                );
                break;
              }
              case 1: {
                tooltipItems.push(
                  `${marker} ${seriesName}:<span style="float:right;font-weight:bold;">${value}%</span>`,
                );
                break;
              }
            }
          });
          return `<div style="min-width:285px;">${tooltipItems.join('<br/>')}</div>`;
        },
      },

      yAxis: {
        type: 'category',
        boundaryGap: true,
        data: cycleTimeData.map(item => item.name),
      },

      xAxis: [
        {
          type: 'value',
          show: false,
        },
        {
          type: 'value',
          max: 100,
          interval: 10,
          position: 'bottom',
          axisLabel: {
            formatter: '{value}%',
          },
        },
      ],

      series: [
        {
          name: this.intl.t('bi.chartLegend.avgCycleTime'),
          data: cycleTimeData.map((m) => {
            const avgStationCycleTime = Number((m.avgStationCycleTime / 1000).toFixed(1));
            const targetCycleTime = m.stationTargetCycleTime;

            return {
              value: avgStationCycleTime,
              itemStyle: {
                color:
                  avgStationCycleTime > targetCycleTime && targetCycleTime && targetCycleTime > 0
                    ? echartsTheme.colorPalette.danger
                    : echartsTheme.colorPalette.success,
              },
            };
          }),
          label: {
            show: true,
            position: 'right',
            color: echartsTheme.colorPalette.biGrey,
            formatter: (params) =>
              moment.duration(params.value, 'seconds').format('d[d] h[h] m[m] s[s]', {
                precision: 1,
                trim: 'both mid',
              }),
          },
          type: 'bar',
          xAxisIndex: 0,
          markLine: {
            data: [{ type: 'average', name: 'Avg' }],
            label: {
              show: true,
              position: 'end',
              formatter: (params) =>
                `${this.intl.t('bi.chartLegend.avg')}: ${moment
                  .duration(params.value, 'seconds')
                  .format('d[d] h[h] m[m] s[s]', {
                    precision: 1,
                    trim: 'both mid',
                  })}`,
            },
            symbol: 'none',
          },
        },
        {
          name: this.intl.t('bi.chartLegend.pctOverCycle'),
          data: cycleTimeData.map((m) => Math.round(m.percentageOfCyclesOverTarget * 100)),
          type: 'line',
          xAxisIndex: 1,
          color: echartsTheme.colorPalette.taskRowRed,
        },
      ],
      stationIds: cycleTimeData.map(item => item._id),
    };
  }

  formatCycleTimeExportData(exportData) {
    exportData.forEach((station) => {
      station.avgStationCycleTime = moment
        .duration(station.avgStationCycleTime, 'seconds')
        .format('d[d] h[h] m[m] s[s]', {
          precision: 1,
          trim: 'both mid',
        });
      station.percentageOfCyclesOverTarget = `${Math.round(station.percentageOfCyclesOverTarget * 100)}%`;
      delete station._id;
    });

    return exportData;
  }

  @action
  stationDrillDown(stationCycleTimeData, dataIndex) {
    this.router.transitionTo('bi.cycleTime.stations', {
      queryParams: {
        stationIds: [stationCycleTimeData.stationIds[dataIndex]],
        modelIds: this.args.modelIds,
        userIds: this.args.userIds,
        tags: this.args.tags,
        beginDate: this.args.startDate,
        endDate: this.args.endDate,
        shiftNames: this.args.shiftNames,
      },
    });
  }
}
