import { task, all } from 'ember-concurrency';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { anyInvalid } from 'eflex/util/getter-helpers';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import { userRolesValues } from 'eflex/constants/user-roles';

export default class SettingsUsersController extends Controller {
  @tracked isEditing = false;
  @tracked showUserConfigPanel = false;
  @tracked showChangePasswordModal = false;
  @tracked showChangeBadgeIdModal = false;
  @tracked password;
  @tracked passwordConfirmation;
  @tracked badgeId;
  @tracked badgeIdConfirmation;
  @tracked selectedUser;
  @tracked users = [];

  @service validationErrorNotifier;
  @service notifier;
  @service currentUser;
  @service store;
  @service intl;

  get isInvalid() {
    return anyInvalid(this.users);
  }

  get isDirty() {
    return this.users.some(item => item.isDirty);
  }

  get userRoleOptions() {
    return userRolesValues;
  }

  get changePasswordEnabled() {
    if (this.isDirty) {
      return false;
    }

    return (this.selectedUser && this.currentUser.user.isAdmin) || !this.currentUser.user?.isAdmin;
  }

  get formattedUserPermissions() {
    return this.currentUser.user.permissions
      ?.map(permission => this.intl.t(`permissions.${permission}`))
      .join(', ');
  }

  @task
  @waitFor
  *save() {
    const dirtyUsers = this.users.filter(item => item.isDirty);

    if (dirtyUsers.some(item => item.isInvalid)) {
      this.validationErrorNotifier.sendErrors(dirtyUsers);
      return;
    }

    const showNotice = this.selectedUser?.changedAttributes().timeoutLength != null;

    yield all(dirtyUsers.map(dirtyUser => dirtyUser.save()));

    if (showNotice) {
      this.notifier.sendWarning('settings.users.updatedTimeout');
    }
  }

  @task
  @waitFor
  *removeBadgeId() {
    this.selectedUser.badgeId = '';
    yield this.selectedUser.save();

    this.notifier.sendSuccess('logs.badgeIdRemoved');
    this.selectedUser.badgeId = null;
    Object.assign(this, {
      showChangeBadgeIdModal: false,
      badgeId: null,
      badgeIdConfirmation: null,
    });
  }

  @task
  @waitFor
  *changeBadgeId() {
    if (!this.badgeId || !this.badgeIdConfirmation) {
      this.notifier.sendError('logs.badgeIdCannotBeBlank');
      return;
    }

    if (this.badgeId !== this.badgeIdConfirmation) {
      this.notifier.sendError('logs.badgeIdsDoNotMatch');
      return;
    }

    this.selectedUser.badgeId = this.badgeId;

    try {
      yield this.selectedUser.save();
    } catch (e) {
      this.notifier.sendError(e);
      this.selectedUser.badgeId = null;
      Object.assign(this, {
        badgeId: null,
        badgeIdConfirmation: null,
      });
      return;
    }

    this.notifier.sendSuccess('logs.badgeIdUpdated');
    this.selectedUser.badgeId = null;
    Object.assign(this, {
      showChangeBadgeIdModal: false,
      badgeId: null,
      badgeIdConfirmation: null,
    });
  }

  @task
  @waitFor
  *passwordChangeComplete() {
    if (!this.password || !this.passwordConfirmation) {
      this.notifier.sendError('logs.passwordCannotBeBlank');
      return;
    }

    if (this.password !== this.passwordConfirmation) {
      this.notifier.sendError('logs.passwordsDoNotMatch');
      return;
    }

    this.selectedUser.password = this.password;
    yield this.selectedUser.save();

    Object.assign(this, {
      showChangePasswordModal: false,
      password: null,
      passwordConfirmation: null,
    });
  }

  @action
  createUser() {
    const user = this.store.createRecord('user');

    Object.assign(this, {
      selectedUser: user,
      showUserConfigPanel: true,
    });
  }

  @action
  rollback() {
    this.users.forEach(user => { user.rollbackAttributes(); });

    if (this.selectedUser?.isDeleted) {
      this.selectedUser = this.users[0];
    }

    this.closePanel();
  }

  @action
  editUser() {
    Object.assign(this, {
      selectedUser: this.selectedUser,
      isEditing: true,
      showUserConfigPanel: true,
    });
  }

  @action
  closePanel() {
    Object.assign(this, {
      selectedUser: null,
      password: null,
      passwordConfirmation: null,
      showUserConfigPanel: false,
      isEditing: false,
    });
  }

  @action
  openChangeEncryptedPasswordModal() {
    this.selectedUser ??= this.currentUser.user;
    this.showChangePasswordModal = true;
  }

  @action
  openChangeEncryptedBadgeIdModal() {
    this.selectedUser ??= this.currentUser.user;
    this.showChangeBadgeIdModal = true;
  }

  @action
  cancelPasswordChange() {
    Object.assign(this, {
      showChangePasswordModal: false,
      password: null,
      passwordConfirmation: null,
    });
  }

  @action
  cancelBadgeIdChange() {
    Object.assign(this, {
      showChangeBadgeIdModal: false,
      badgeId: null,
      badgeIdConfirmation: null,
    });
  }

  @action
  deleteUser() {
    this.selectedUser.deleteRecord();
  }
}
