import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

const MAX_SPINDLES = 50;

export default class TaskConfigMultispindle extends Component {
  @service spindleRepo;

  get remainingSpindles() {
    return MAX_SPINDLES - this.args.task.spindles.length;
  }

  get nonDeletedSpindleConfigs() {
    return this.args.taskConfig.spindleConfigs.filter(item => !item.isDeleted);
  }

  @action
  addSpindles(quantity) {
    for (let i = 0; i < quantity; i++) {
      this.spindleRepo.createSpindle({ task: this.args.task });
    }
  }

  @action
  deleteSpindle(spindle) {
    this.spindleRepo.deleteSpindle(spindle);
  }
}
