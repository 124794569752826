import Hardware from 'eflex/models/hardware';
import { inject as service } from '@ember/service';

export default class Audio extends Hardware {
  @service intl;

  get triggerDisplayName() {
    return this.intl.t('hardware.defaultAudio');
  }

  get hardwareGroup() {
    return this.triggerDisplayName;
  }

  get isConfigured() {
    return true;
  }
}
