import Component from '@glimmer/component';
import { trackedFunction } from 'ember-resources/util/function';
import { waitForPromise } from '@ember/test-waiters';

export default class JsonModal extends Component {
  formattedJson = trackedFunction(this, async () => {
    const displayed = this.args.displayed;
    const { default: JSONFormatter } = await waitForPromise(import('json-formatter-js'));
    return new JSONFormatter(displayed).render();
  });
}
