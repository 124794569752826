import { helper } from '@ember/component/helper';
import { isPresent } from '@ember/utils';

export const castInputValueToNumber = function({ target }) {
  const val = target.value;
  if (isPresent(val)) {
    return Number(val);
  }

  return val;
};

export default helper(function inputNum([func]) {
  return function(event) {
    func(castInputValueToNumber(event));
  };
});
