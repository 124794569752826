import Model, { attr, belongsTo } from '@ember-data/model';
import { copyable } from 'eflex/decorators';
import { rollbackBelongsTo, isBelongsToRefDirty } from 'eflex/util/relationship-helpers';

@copyable
class VariableDefConfig extends Model {
  @attr('boolean', { defaultValue: true }) enabled;
  @attr value;

  @belongsTo('taskConfig', { async: false, inverse: 'variableDefConfigs' }) taskConfig;
  @belongsTo('variableDef', { async: false, inverse: 'variableDefConfigs' }) variableDef;
  @belongsTo('variableDef', { async: false, inverse: null }) variableDefForValue;
  @belongsTo('jemProcessDataDef', { async: false, inverse: null }) jemProcessDataDefForValue;

  get isDirty() {
    return super.isDirty ||
      isBelongsToRefDirty(this, 'variableDefForValue') ||
      isBelongsToRefDirty(this, 'jemProcessDataDefForValue');
  }

  get isFromDevice() {
    return this.variableDef?.isFromDevice;
  }

  get bytesConsumed() {
    return this.variableDef?.bytesConsumed;
  }

  get selectedValue() {
    return this.variableDefForValue ?? this.jemProcessDataDefForValue ?? this.formattedValue;
  }

  get formattedValue() {
    if (this.value != null) {
      return { name: this.value };
    } else {
      return '';
    }
  }

  get copyableOptions() {
    return {
      ignoreAttributes: new Set(['variableDef', 'taskConfig']),
    };
  }

  rollbackAttributes() {
    rollbackBelongsTo(this, 'variableDefForValue');
    rollbackBelongsTo(this, 'jemProcessDataDefForValue');
    super.rollbackAttributes();
  }
}

export default VariableDefConfig;
