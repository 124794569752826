import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { clone, pipe, reject } from 'ramda';
import { sortByProp } from 'ramda-adjunct';

export default class BomBuilderPanel extends Component {
  @service store;

  get buildDatumComponents() {
    return pipe(
      reject(component => {
        if (component.isAlwaysRun) {
          return true;
        }

        if (component.id != null) {
          const componentRecord = this.store.peekRecord('component', component.id);
          return componentRecord?.isComplex ?? false;
        }

        return false;
      }),
      sortByProp('priority'),
    )(this.args.buildDatum?.components ?? []);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.args.onClose?.();
  }

  getVariableInputType = (variableId) => {
    if (!this.args.bomSource?.usesVariableValidation) {
      return 'text';
    }

    const bomVariable = this.store.peekRecord('bomVariable', variableId);

    if (bomVariable?.isNumeric) {
      return 'number';
    }

    return 'text';
  };

  getVariableInputMaxLength = (variableId) => {
    if (!this.args.bomSource?.usesVariableValidation) {
      return null;
    }

    return this.store.peekRecord('bomVariable', variableId)?.length;
  };

  @action
  setOption(componentId, option) {
    if (!this.args.buildDatum) {
      return;
    }

    this.args.buildDatum.components = this.args.buildDatum.components.map(component => {
      if (component.id !== componentId) {
        return clone(component);
      }

      if (option.id == null) {
        return {
          ...clone(component),
          option: null,
          rawValue: null,
        };
      } else {
        return {
          ...clone(component),
          option: {
            id: option.id,
            captions: clone(option.captions),
            priority: option.priority,
          },
          rawValue: option.value,
        };
      }
    });
  }

  @action
  setVariableRawValue(variableId, rawValue) {
    if (!this.args.buildDatum) {
      return;
    }

    this.args.buildDatum.variables = this.args.buildDatum.variables.map(variable => {
      if (variable.id === variableId) {
        variable.rawValue = rawValue;
      }

      return variable;
    });
  }
}
