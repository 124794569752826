import GridRoute from 'eflex/util/grid-route';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import { TrackedArray } from 'tracked-built-ins';

export default class DebugPacketsRoute extends GridRoute {
  @service store;

  @task
  @waitFor
  *getData(searchFields = {}) {
    const params = Object.assign(searchFields, this.controller?.params);

    if (params.stationIds != null) {
      params.stationFacsIds = params.stationIds.map(stationId =>
        this.store.peekRecord('station', stationId).facsId,
      );
    }

    return new TrackedArray(yield this.store.query('packet', params));
  }
}
