import Service, { inject as service } from '@ember/service';
import { task, taskGroup } from 'ember-concurrency';
import TaskStatuses from 'eflex/constants/task-statuses';
import { waitFor } from '@ember/test-waiters';
import { clone, pipe, head, prop, reject } from 'ramda';
import { sortByProp } from 'ramda-adjunct';
import { isBlank, isEmpty } from '@ember/utils';
import StationOptions from 'eflex/constants/stations/station-options';

export default class JemRepoService extends Service {
  @service store;
  @service currentUser;
  @service eflexAjax;
  @service fileUploader;
  @service systemConfig;
  @service notifier;
  @service taskConfigRepo;

  @taskGroup({ enqueue: true }) liveStatusEvents;

  @task({ group: 'liveStatusEvents' })
  @waitFor
  *saveLiveStatus(liveStatus) {
    yield liveStatus.save();
  }

  @task({ group: 'liveStatusEvents' })
  @waitFor
  *toggleHold(buildStatus, taskConfig) {
    if (!buildStatus || !taskConfig) {
      return;
    }

    yield this.eflexAjax.post.perform('jem/holds', {
      liveBuildStatus: buildStatus.id,
      taskConfig: taskConfig.id,
    });
  }

  @task({ group: 'liveStatusEvents' })
  @waitFor
  *pushFromWebSocket(status) {
    // covers auto load cases where we are unable to side load this relationship
    if (status.buildDatum != null && this.store.peekRecord('buildDatum', status.buildDatum) == null) {
      yield this.store.findRecord('buildDatum', status.buildDatum);
    }

    if (status.id) {
      const existing = this.store.peekRecord('liveBuildStatus', status.id);
      if (existing) {
        existing.rollbackAttributes();
      }
    }
    return this.store.push(this.store.normalize('liveBuildStatus', status));
  }

  @task({ drop: true })
  @waitFor
  *uploadWebcamImage(serialNumber, capturedWebcamImage, treeTask, status) {
    return yield this.fileUploader.post.perform(
      'webcamImages',
      new File(
        [capturedWebcamImage],
        `${this._getVisionFilename(serialNumber, treeTask, status)}.jpg`,
      ),
      { serialNumber },
    );
  }

  @task
  @waitFor
  *loadTaskConfigs(station, context) {
    if (station.usesModels && station.productionScheduleEnabled) {
      context = this.getProductionScheduleForStation(station)?.model;
    }

    yield this.taskConfigRepo.loadContextTaskConfigs.perform(station, context);
  }

  @task
  @waitFor
  *createLiveBuildStatus(
    serialNumber,
    station,
    bomSourceLookupValue,
    model,
    job,
  ) {
    if (isBlank(serialNumber) || (station.usesBomSourceLookup && isEmpty(bomSourceLookupValue))) {
      return;
    }

    if (station.loadOption === StationOptions.loadOptions.selectModel && model) {
      model = model.asHistory();
    } else {
      model = null;
    }

    const identifiers = station.area.customIdentifiers.filter(item => item.enabled);

    const previousStatus = yield this._getPreviousStatusIfNecessary.perform(identifiers, serialNumber, station);

    return this.store.createRecord('liveBuildStatus', {
      serialNumber,
      status: TaskStatuses.STARTED,
      userId: this.currentUser.user?.id,
      userName: this.currentUser.user?.userName,
      model,
      job: job?.id,
      bomSourceLookupValue,
      area: station.area.id,
      location: { id: station.id },
      customIdentifierData: identifiers.map((identifier) => this.store.createRecord('customIdentifierDatum', {
        customIdentifier: identifier.id,
        captions: clone(identifier.captions),
        value: previousStatus?.customIdentifierData.find(item => item.customIdentifier === identifier.id)?.value,
      })),
    });
  }

  @task
  @waitFor
  *_getPreviousStatusIfNecessary(identifiers, serialNumber, station) {
    if (isEmpty(identifiers) || StationOptions.autoLoadOptions.has(station.loadOption)) {
      return;
    }

    let searchSerialNumbers = [serialNumber];

    if (station.area.bomSource?.constructor.modelName === 'barcode-bom-source') {
      const source = station.area.bomSource;
      const start = source.serialNumberStart - 1;
      const end = start + source.serialNumberLength;
      const trimmedSerial = serialNumber.slice(start, end);
      if (!isEmpty(trimmedSerial)) {
        searchSerialNumbers.push(trimmedSerial);
      }
    }

    return (yield this.store.query('buildStatus', {
      area: station.area.id,
      take: 1,
      serialNumbers: searchSerialNumbers,
    }))[0];
  }

  @task({ drop: true })
  @waitFor
  *completeTask(taskStatus, childStatus) {
    if (childStatus.status !== taskStatus) {
      childStatus.status = taskStatus;
    }

    childStatus.processData?.forEach(function (datum) {
      if (datum.value === '') {
        datum.value = null;
      }
    });

    const buildStatus = childStatus.parent;

    const { user } = this.currentUser;
    if (user != null) {
      Object.assign(buildStatus, {
        userId: user.id,
        userName: user.userName,
      });
    }

    yield this.saveLiveStatus.perform(buildStatus);
  }

  _getVisionFilename(serialNumber, treeTask, status) {
    const config = this.systemConfig.config;
    const fileFormat = config.evision.namingConvention.selectedOptions;
    serialNumber = serialNumber.replace(/[/\\_]/g, '').trim();

    return fileFormat
      .join(config.evision.namingConvention.fieldSeparator)
      .replace('taskId', treeTask.facsId)
      .replace('stationId', treeTask.parent.facsId)
      .replace('serialNumber', serialNumber)
      .replace('passFail', TaskStatuses.isRejected(status) ? 'fail' : 'pass');
  }

  getProductionScheduleForStation(station) {
    if (station.currentProductionSchedule) {
      return station.currentProductionSchedule;
    }

    return pipe(
      reject(prop('isCompleted')),
      sortByProp('order'),
      head,
    )(station.productionSchedules ?? []);
  }
}
