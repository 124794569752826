var _class, _descriptor, _descriptor2;
function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { guidFor } from '@ember/object/internals';
let DraggableObject = (_class = class DraggableObject extends Component {
  constructor(...args) {
    super(...args);
    _initializerDefineProperty(this, "dragCoordinator", _descriptor, this);
    _initializerDefineProperty(this, "isDraggingObject", _descriptor2, this);
  }
  get sortingScope() {
    return this.args.sortingScope ?? 'drag-objects';
  }
  get isDraggable() {
    return this.args.isDraggable ?? true;
  }
  onDragStart(event) {
    if (!this.isDraggable) {
      event.preventDefault();
      return;
    }
    let dataTransfer = event.dataTransfer;
    const obj = this.args.content;
    dataTransfer.setData('Text', guidFor(obj));
    this.isDraggingObject = true;
    this.dragCoordinator.dragStarted(obj, event, this);
    if (!this.dragCoordinator.enableSort && this.dragCoordinator.sortComponentController) {
      //disable drag if sorting is disabled
      event.preventDefault();
      return;
    }
    this.args.onDragStart?.(obj, event);
    if (this.dragCoordinator.enableSort) {
      this.args.onDraggingSortItem?.(obj, event);
    }
  }
  onDragEnd(event) {
    if (!this.isDraggingObject) {
      return;
    }
    const obj = this.args.content;
    this.isDraggingObject = false;
    this.args.onDragEnd?.(obj, event);
  }
  onDragOver(event) {
    if (this.dragCoordinator.enableSort) {
      this.dragCoordinator.draggingOver(event, this, event.target);
    }
    return false;
  }
  onDrop(event) {
    //Firefox is navigating to a url on drop, this prevents that from happening
    event.preventDefault();
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dragCoordinator", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isDraggingObject", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _applyDecoratedDescriptor(_class.prototype, "onDragStart", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onDragStart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDragEnd", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onDragEnd"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDragOver", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onDragOver"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDrop", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onDrop"), _class.prototype)), _class);
export { DraggableObject as default };