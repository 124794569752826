import { intoArray } from '@eflexsystems/ramda-helpers';
import { pipe, last, filter, gte, sort, uniq, __ } from 'ramda';

export default function (nums, min = 1, maxAllowed = null) {
  min = parseInt(min);

  nums = pipe(
    intoArray(
      filter(gte(__, min)),
      uniq(),
    ),
    sort((a, b) => a - b),
  )(nums);

  if (maxAllowed != null && nums.length === maxAllowed - min) {
    return;
  }

  const max = parseInt(last(nums));

  let i;

  for (i = min; i <= max; i++) {
    if (nums[i - min] !== i) {
      break;
    }
  }

  return i;
}
