import Service, { inject as service } from '@ember/service';
import { hardwareTypes } from 'eflex/constants/hardware-types';
import { bomSourceModelNames } from 'eflex/constants/bom-source-types';
import { capitalize } from '@ember/string';
import { later } from '@ember/runloop';

const UPDATE_TREE_ITEM_EVENT_TYPES = new Set(['task', 'station']);
const DELETE_TREE_ITEM_EVENT_TYPES = new Set(['area', 'group', 'station', 'task']);

const LISTENED_TYPES = [
  'area',
  'group',
  'station',
  'task',
  'taskConfig',
  'job',
  'model',
  'hardwareIo',
  'productionSchedule',
  'systemConfiguration',
  ...hardwareTypes,
  ...bomSourceModelNames,
];

const schedulePush = (record, cb) => {
  if (record == null) {
    cb();
    return;
  }

  if (
    record.isDestroyed ||
    !record.isLoaded ||
    (!record.isDirty && record.isDeleted) ||
    (record.isSaving && record.isDeleted)
  ) {
    return;
  }

  if (record?.isSaving) {
    // eslint-disable-next-line ember/no-runloop
    later(null, () => {
      schedulePush(record, cb);
    }, 10);
  } else {
    cb();
  }
};

export default class TreeUpdateListenerService extends Service {
  @service store;
  @service webSocket;
  @service eventBus;

  start() {
    for (let type of LISTENED_TYPES) {
      this.webSocket.addListener(`updated${capitalize(type)}`, json => {
        this._updateFromWebSocket(type, json);
      });
      this.webSocket.addListener(`deleted${capitalize(type)}`, json => {
        this._deleteFromWebSocket(type, json);
      });
    }
  }

  willDestroy() {
    super.willDestroy(...arguments);
    LISTENED_TYPES.forEach((type) => {
      const capitalizedType = capitalize(type);
      this.webSocket.removeListener(`updated${capitalizedType}`);
      this.webSocket.removeListener(`deleted${capitalizedType}`);
    });
  }

  _updateFromWebSocket(type, treeItem) {
    const id = treeItem.id ?? treeItem._id;
    let record = this.store.peekRecord(type, id);

    schedulePush(record, () => {
      record = this.store.push(this.store.normalize(type, treeItem));
    });

    if (UPDATE_TREE_ITEM_EVENT_TYPES.has(type)) {
      this.eventBus.trigger('updatedTreeItem', record);
    }
  }

  _deleteFromWebSocket(type, id) {
    const deleted = this.store.peekRecord(type, id);

    if (deleted == null) {
      return;
    }

    if (DELETE_TREE_ITEM_EVENT_TYPES.has(type)) {
      this.eventBus.trigger('deletedTreeItem', deleted);
    }

    schedulePush(deleted, () => {
      deleted.unloadRecord();
    });
  }
}
