import Control from 'ember-bootstrap/components/bs-form/element/control';
import { action } from '@ember/object';

export default class DatePickerControl extends Control {
  get isInvalid() {
    return this.args.validationType === 'error';
  }

  @action
  datePickerReady(selectedDates, dateStr, instance) {
    this.args.datePickerReady?.(instance);
  }

  @action
  onChange([date]) {
    this.args.onChange?.(date);
  }
}
