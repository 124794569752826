import { inject as service } from '@ember/service';
import Helper from '@ember/component/helper';
import cleanHelperArgs from 'eflex/util/clean-helper-args';

export default class TriggerEventBus extends Helper {
  @service eventBus;

  compute(params) {
    return (...extraArgs) => {
      extraArgs = cleanHelperArgs(extraArgs);
      this.eventBus.trigger(...params, ...extraArgs);
    };
  }
}
