import { inject as service } from '@ember/service';
import { isBlank } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class BaseCriteriaTextField extends Component {
  @service intl;

  @tracked isDisplayed = Boolean(this.value);

  get value() {
    return this.args.params[this.args.key]?.value;
  }

  @action
  onDisplayedChanged(isDisplayed) {
    this.isDisplayed = isDisplayed;
    this.args.removeParam(this.args.key);
  }

  @action
  onChange(value) {
    const key = this.args.key;

    if (isBlank(value)) {
      this.args.removeParam(key);
    } else {
      this.args.updateParams({
        key,
        value,
        breadcrumb: this.intl.t(`breadcrumbs.${key}`, { [key]: value }),
      });
    }
  }
}
