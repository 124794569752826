import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { task as trackedTask } from 'ember-resources/util/ember-concurrency';
import { ObjectId } from 'bson';
import echartsTheme from 'eflex/echarts-theme';
import moment from 'moment-timezone';
import { waitFor } from '@ember/test-waiters';
import { omit } from 'ramda';
import { getCaptionText } from 'eflex/util/translation-helper';

export default class ProcessDatumControlChart extends Component {
  // jscpd:ignore-start
  @service queryRunner;
  @service intl;
  @service currentUser;

  get params() {
    return {
      stationIds: this.args.stationIds,
      modelIds: this.args.modelIds,
      userIds: this.args.userIds,
      tags: this.args.tags,
      startDate: this.args.startDate,
      endDate: this.args.endDate,
      shiftNames: this.args.shiftNames,
      processData: this.args.processData,
    };
  }

  processDatumValue = trackedTask(this, this.getProcessDatumData, () => [this.params]);

  @task({ drop: true })
  @waitFor
  *getProcessDatumData(params) {
    const controlChartDataQuery = yield this.queryRunner.queryWithParams.perform(params, [
      {
        $match: {
          'children.processData.0': {
            $exists: true,
          },
        },
      },
      {
        $unwind: {
          path: '$children',
        },
      },
      {
        $unwind: {
          path: '$children.processData',
        },
      },
      {
        $match: {
          'children.processData.jemProcessDataDef': {
            $in: params.processData.map((processDataDef) => new ObjectId(processDataDef)),
          },
        },
      },
      {
        $sort: {
          timestamp: 1,
        },
      },
      {
        $group: {
          _id: null,
          processDataPoints: {
            $push: '$children.processData',
          },
          avg: {
            $avg: '$children.processData.value',
          },
          standardDeviation: {
            $stdDevPop: '$children.processData.value',
          },
        },
      },
      {
        $project: {
          _id: 0,
          processDataPoints: 1,
          avg: 1,
          ucl: {
            $sum: [
              {
                $multiply: [3, '$standardDeviation'],
              },
              '$avg',
            ],
          },
          lcl: {
            $subtract: [
              '$avg',
              {
                $multiply: [3, '$standardDeviation'],
              },
            ],
          },
        },
      },
    ]);

    let preFormattedChartData = yield this.queryRunner.runQuery.perform('BuildStatuses', controlChartDataQuery);
    let postFormattedChartData = preFormattedChartData
      .find(item => item.avg)
      ?.processDataPoints
      ?.map((processDataPoint) => {
        return {
          value: processDataPoint.value,
          timestamp: moment(processDataPoint.timestamp).format('MM/DD/YYYY hh:mm:ss A'),
          unit: getCaptionText(processDataPoint?.unitCaptions, this.currentUser),
          ucl: preFormattedChartData[0].ucl,
          lcl: preFormattedChartData[0].lcl >= 0 ? preFormattedChartData[0].lcl : 0,
          upperLimit: processDataPoint?.upperLimit,
          lowerLimit: processDataPoint?.lowerLimit,
        };
      });

    return {
      chartData: this.getEchartOptions(postFormattedChartData),
      exportData: () => postFormattedChartData?.map(omit(['unit']), postFormattedChartData),
    };
  }

  getEchartOptions(controlChartData) {
    return {
      title: {
        text: this.intl.t('bi.chartLabel.processDatumControlChart'),
      },

      legend: {
        show: true,
        bottom: 5,
      },

      tooltip: {
        trigger: 'axis',
        formatter(params) {
          const tooltipItems = params
            .map(
              ({ marker, seriesName, value }) =>
                `${marker} ${seriesName} <span style="float:right;font-weight:bold;">${value
                  ?.toFixed(2)
                  .replace(/\.00/, '')}</span>`,
            )
            .join('<br>');
          return `<div style="min-width:200px;"> <div style="text-align:center"> ${params[0]?.name}
                    </div> ${tooltipItems}</div>`;
        },
      },

      xAxis: {
        type: 'category',
        data: controlChartData?.map(item => item.timestamp),
        axisLabel: {
          show: true,
          rotate: 75,
          interval: 'auto',
        },
      },

      yAxis: {
        type: 'value',
        scale: true,
      },

      series: [
        {
          name: controlChartData?.[0]?.unit?.toUpperCase(),
          data: controlChartData?.map(item => item.value),
          type: 'line',
          color: echartsTheme.colorPalette.biPurple,
          markLine: {
            data: [{ type: 'average', name: 'Avg' }],
            label: {
              show: true,
              position: 'insideEndTop',
              formatter: (params) =>
                `${this.intl.t('bi.chartLegend.avg')}: ${
                  params.value
                } ${controlChartData?.[0]?.unit?.toUpperCase()}`,
            },
          },
          symbol: 'emptyCircle',
          symbolSize: 7,
        },
        {
          name: this.intl.t('bi.chartLegend.lcl'),
          data: controlChartData?.map(item => item.lcl),
          type: 'line',
          symbol: 'none',
          color: echartsTheme.colorPalette.taskRowRed,
        },
        {
          name: this.intl.t('bi.chartLegend.ucl'),
          data: controlChartData?.map(item => item.ucl),
          type: 'line',
          symbol: 'none',
          color: echartsTheme.colorPalette.taskRowRed,
        },
        {
          name: this.intl.t('bi.chartLegend.upperLimit'),
          data: controlChartData?.map(item => item.upperLimit),
          type: 'line',
          symbol: 'none',
          color: echartsTheme.colorPalette.taskRowGreen,
        },
        {
          name: this.intl.t('bi.chartLegend.lowerLimit'),
          data: controlChartData?.map(item => item.lowerLimit),
          type: 'line',
          symbol: 'none',
          color: echartsTheme.colorPalette.taskRowGreen,
        },
      ],

      // jscpd:ignore-end
    };
  }
}
