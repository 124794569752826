import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';

export default class BomCopyPanel extends Component {
  @tracked serialNumber = this.args.datumToCopy.serialNumber;
  @tracked target = 1;
  @tracked start = 1;

  @service buildDataRepo;
  @service notifier;

  get startDisabled() {
    return parseInt(this.target) === 1;
  }

  @task({ drop: true })
  @waitFor
  *copyDatum() {
    const target = parseInt(this.target);
    const start = parseInt(this.start);

    if (target < 1 || target > 1000 || start < 0) {
      this.notifier.sendError('bomBuilder.invalidCopyParameters');
      return;
    }

    const copies = yield this.buildDataRepo.copyTargetAmount.perform(
      this.args.datumToCopy,
      this.serialNumber,
      target,
      start,
    );

    this.args.updateModel(copies);
    this.notifier.sendSuccess('bomBuilder.serialsGenerated');
    this.args.onClose();
  }
}
