import Component from '@glimmer/component';

export default class TaskDeviceConfigHardwareSelect extends Component {
  get hardwares() {
    let hardwares = this.args.hardwares;

    if (this.args.default) {
      hardwares = [null].concat(hardwares);
    }

    return hardwares;
  }
}
