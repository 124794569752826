import AuthenticatedRoute from 'eflex/util/authenticated-route';
import { inject as service } from '@ember/service';

export default class SerialNumberMonitorRoute extends AuthenticatedRoute {
  @service bomSourceRepo;

  setupController(controller) {
    controller.selectedBomSource = this.bomSourceRepo.allBomSources[0];
  }
}
