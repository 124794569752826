import Model, { attr, belongsTo } from '@ember-data/model';

export default class EvisionConfig extends Model {
  @belongsTo('fileRetention', { inverse: null, async: false, embedded: true }) fileRetention;
  @belongsTo('namingConvention', { inverse: null, async: false, embedded: true }) namingConvention;
  @attr('number') thumbnailWidth;
  @attr('number') thumbnailHeight;

  get isDirty() {
    return super.isDirty ||
      this.fileRetention.isDirty ||
      this.namingConvention.isDirty;
  }
}
