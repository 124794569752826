import { attr } from '@ember-data/model';
import BomSource from 'eflex/models/bom-source';
import { buildValidations } from '@eflexsystems/ember-tracked-validations';
import { sourceValidations } from 'eflex/validations/bom-sources';

@buildValidations({ ...sourceValidations })
class RestBomSource extends BomSource {
  @attr('string') source;

  get isRest() {
    return true;
  }

  get typeName() {
    return 'REST';
  }
}

export default RestBomSource;
