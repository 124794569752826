import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { task as trackedTask } from 'ember-resources/util/ember-concurrency';
import moment from 'moment-timezone';
import TaskAndFastenerMatcher from 'eflex/util/bi-task-fastener-matcher';
import echartsTheme from 'eflex/echarts-theme';
import { waitFor } from '@ember/test-waiters';
import { clone } from 'ramda';

export default class BoltDataTable extends Component {
  @service queryRunner;
  @service intl;
  @service systemConfig;

  get params() {
    return {
      stationIds: this.args.stationIds,
      fasteners: this.args.fasteners,
      modelIds: this.args.modelIds,
      userIds: this.args.userIds,
      tags: this.args.tags,
      startDate: this.args.startDate,
      endDate: this.args.endDate,
      shiftNames: this.args.shiftNames,
    };
  }

  get tableConfiguration() {
    return {
      columns: [
        {
          title: this.intl.t('serialNumber'),
          field: 'serialNumber',
          formatter: 'textarea',
          resizable: true,
        },
        {
          title: this.intl.t('station'),
          field: 'station',
          formatter: 'textarea',
          resizable: true,
        },
        {
          title: this.intl.t('user'),
          field: 'user',
          formatter: 'textarea',
          resizable: true,
        },
        {
          title: this.intl.t('task'),
          field: 'taskName',
          formatter: (cell) => this.formatTaskColumnData(cell),
          resizable: true,
        },
        {
          title: this.intl.t('bi.chartLabel.boltNumber'),
          field: 'boltNumber',
          formatter: 'textarea',
          width: 200,
          resizable: true,
        },
        {
          title: this.intl.t('bi.chartLabel.boltFinishTime'),
          field: 'boltFinishTime',
          formatter: (cell) => this.formatDateTimeString(cell),
          width: 210,
        },
        {
          title: this.intl.t('torque'),
          field: 'torque',
          formatter: (cell) => this.formatTorqueAndAngleColumn(cell, true),
          width: 125,
        },
        {
          title: this.intl.t('angle'),
          field: 'angle',
          formatter: (cell) => this.formatTorqueAndAngleColumn(cell, false),
          width: 125,
        },
        {
          title: this.intl.t('status'),
          formatter: (cell) => this.formatStatusColumn(cell),
          headerSort: false,
          width: 75,
        },
      ],
    };
  }

  allTorqueData = trackedTask(this, this.getAllTorqueData, () => [this.params]);

  @task({ restartable: true })
  @waitFor
  *getAllTorqueData() {
    // jscpd:ignore-start
    const allTorqueDataQuery = yield this.queryRunner.queryWithParams.perform(this.params, [
      {
        $match: {
          'children.boltProcessData.0': {
            $exists: true,
          },
        },
      },
      {
        $unwind: {
          path: '$children',
        },
      },
      {
        $unwind: {
          path: '$children.boltProcessData',
        },
      },
      {
        $addFields: {
          idAndFastener: {
            $concat: [
              {
                $toString: '$children.location._id',
              },
              {
                $toString: '$children.boltProcessData.boltNumber',
              },
            ],
          },
        },
      },
      TaskAndFastenerMatcher(this.params.fasteners),
      {
        $count: 'count',
      },
    ]);
    return yield this.queryRunner.runQuery.perform('BuildStatuses', allTorqueDataQuery);
  }

  @task({ restartable: true })
  @waitFor
  *getTorqueData(params = {}) {
    let sortStep;

    if (params.sort.length > 0) {
      const dir = params.sort[0].dir === 'asc' ? 1 : -1;
      const field = params.sort[0].field;
      sortStep = { [field]: dir };
    } else {
      sortStep = { boltFinishTime: -1 };
    }

    const query = yield this.queryRunner.queryWithParams.perform(this.params, [
      {
        $limit: 100_000,
      },
      {
        $match: {
          'children.boltProcessData.0': {
            $exists: true,
          },
        },
      },
      {
        $unwind: {
          path: '$children',
        },
      },
      {
        $unwind: {
          path: '$children.boltProcessData',
        },
      },
      {
        $addFields: {
          idAndFastener: {
            $concat: [
              {
                $toString: '$children.location._id',
              },
              {
                $toString: '$children.boltProcessData.boltNumber',
              },
            ],
          },
        },
      },
      TaskAndFastenerMatcher(this.params.fasteners),
      { $skip: params.skip ?? 0 },
      { $limit: params.size ?? 100_000 },
      {
        $project: {
          serialNumber: 1,
          station: {
            $first: '$location.captions.text',
          },
          user: '$userName',
          taskName: {
            $first: '$children.location.captions.text',
          },

          boltNumber: '$children.boltProcessData.boltNumber',
          torqueStatus: '$children.boltProcessData.torqueStatus',
          angleStatus: '$children.boltProcessData.angleStatus',
          errorCode: '$children.boltProcessData.errorCode',
          boltFinishTime: '$children.boltProcessData.timestamp',
          torque: '$children.boltProcessData.torque',
          torqueFormat: '$children.boltProcessData.torqueFormat',
          angle: '$children.boltProcessData.angle',
        },
      },
      { $sort: sortStep },
    ]);

    // jscpd:ignore-end

    const torqueData = yield this.queryRunner.runQuery.perform('BuildStatuses', query);

    return {
      count: this.allTorqueData.value[0].count,
      data: torqueData,
      exportData: () => this.formatExportData(clone(torqueData)),
    };
  }

  formatTaskColumnData = (cell) => {
    const { taskName, torqueStatus, angleStatus, errorCode } = cell.getData();
    const backgroundColor =
      this.getColumnStatus(torqueStatus, errorCode) && this.getColumnStatus(angleStatus, errorCode)
        ? echartsTheme.colorPalette.taskRowGreen
        : echartsTheme.colorPalette.taskRowRed;

    Object.assign(cell.getElement().style, {
      backgroundColor,
      color: 'black',
    });

    return taskName;
  };

  formatTorqueAndAngleColumn = (cell, isTorque) => {
    let { torqueStatus, angleStatus, torque, angle, torqueFormat } = cell.getData();
    let fontColor;

    if (isTorque && this.getColumnStatus(torqueStatus)) {
      fontColor = echartsTheme.colorPalette.taskRowGreen;
    } else if (!isTorque && this.getColumnStatus(angleStatus)) {
      fontColor = echartsTheme.colorPalette.taskRowGreen;
    } else {
      fontColor = echartsTheme.colorPalette.taskRowRed;
    }

    Object.assign(cell.getElement().style, {
      color: fontColor,
    });

    torque = torque ?? '0';
    angle = angle ?? '0';
    torqueFormat = torqueFormat ?? this.systemConfig.jem.torqueFormat;

    return isTorque ? `${torque} ${torqueFormat}` : `${angle}°`;
  };

  formatStatusColumn = (cell) => {
    const { torqueStatus, angleStatus, errorCode } = cell.getData();
    const boltIsGood = this.boltIsGood(torqueStatus, angleStatus, errorCode);

    Object.assign(cell.getElement().style, {
      backgroundColor: boltIsGood ? echartsTheme.colorPalette.taskRowGreen : echartsTheme.colorPalette.taskRowRed,
      color: boltIsGood ? echartsTheme.colorPalette.success : echartsTheme.colorPalette.danger,
      textAlign: 'center',
    });
    return boltIsGood ? '✔' : '✖';
  };

  formatDateTimeString = (cell) => {
    const { boltFinishTime } = cell.getData();
    return moment(boltFinishTime).format('MM/DD/YYYY h:mm:ss A');
  };

  formatExportData = (data) => {
    data.forEach((row) => {
      row.torque = `${row.torque} ${row.torqueFormat ?? this.systemConfig.jem.torqueFormat}`;
      row.angle = `${row.angle}°`;
      row.boltFinishTime = moment(row.boltFinishTime).format('MM/DD/YYYY h:mm:ss A');
      row.status = this.boltIsGood(row.torqueStatus, row.angleStatus, row.errorCode) ? '✔' : '✖';

      delete row._id;
      delete row.torqueFormat;
    });
    return data;
  };

  getColumnStatus = (status) => {
    return status === 1;
  };

  boltIsGood = (torqueStatus, angleStatus, errorCode) => {
    return !!(this.getColumnStatus(torqueStatus) && this.getColumnStatus(angleStatus) && !errorCode);
  };
}
