import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class HardwareTurckIo extends Component {
  get typeOptions() {
    return [
      { label: 'input', value: false },
      { label: 'output', value: true },
    ];
  }

  @action
  setOutputOnly(hardwareIo, { value }) {
    hardwareIo.outputOnly = value;
  }
}
