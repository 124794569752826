const hardwareTypes = [
  'adamIo',
  'audio',
  'cognexCamera',
  'datalogic',
  'edhr',
  'keyence',
  'lightGuide',
  'nodeRed',
  'openProtocolTool',
  'plcHardware',
  'turckIo',
  'universalRobot',
  'webCam',
  'workInstructionHardware',
  'zebraPrinter',
].toSorted();

const unavailableTypes = ['audio', 'workInstructionHardware'];

export { hardwareTypes, unavailableTypes };
