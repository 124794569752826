import Helper from '@ember/component/helper';
import { assert } from '@ember/debug';
/**
 * This helper is activated only on _updates_ to it's arguments (both positional
 * and named). It does not run during or after initial render, or before it is
 * un-rendered (removed from the DOM).
 */
export default class DidUpdateHelper extends Helper {
  constructor(...args) {
    super(...args);
    this.didRun = false;
  }
  compute(positional, named) {
    const fn = positional[0];
    assert(`\`{{did-update fn}}\` expects a function as the first parameter. You provided: ${fn}`, typeof fn === 'function');
    if (!this.didRun) {
      this.didRun = true;

      // Consume individual properties to entangle tracking.
      // https://github.com/emberjs/ember.js/issues/19277
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      positional.forEach(() => {});
      Object.values(named);
      return;
    }
    fn(positional.slice(1), named);
  }
}