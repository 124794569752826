import { isEmpty } from '@ember/utils';
import { pluck, prop } from 'ramda';
import { maxOf, intoArray } from '@eflexsystems/ramda-helpers';
import { compact } from 'ramda-adjunct';

const incrementPriority = function (selected, siblings) {
  if (selected == null || selected.priority === 1) {
    return;
  }

  const { priority } = selected;
  const siblingToSwap = siblings.find(item => item.priority === priority - 1);
  if (siblingToSwap == null) {
    return;
  }

  selected.priority = priority - 1;
  siblingToSwap.priority = priority;
};

const decrementPriority = function (selected, siblings) {
  const maxPriority = maxOf(prop('priority'), siblings) ?? 0;
  if (selected == null || selected.priority === maxPriority) {
    return;
  }

  const { priority } = selected;
  const siblingToSwap = siblings.find(item => item.priority === priority + 1);
  if (siblingToSwap == null) {
    return;
  }

  selected.priority = priority + 1;
  siblingToSwap.priority = priority;
};

const getNextPriority = function (arr) {
  const priorities = intoArray(
    pluck('priority'),
    compact,
  )(arr);

  if (isEmpty(priorities)) {
    return 1;
  }

  return Math.max(...priorities) + 1;
};

export { incrementPriority, decrementPriority, getNextPriority };
