var _class, _descriptor;
function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
import { handleUnauthorized } from "@eflexsystems/ember-simple-auth-oidc";
import { inject as service } from "@ember/service";
import JSONAPIAdapter from "@ember-data/adapter/json-api";
let OIDCJSONAPIAdapter = (_class = class OIDCJSONAPIAdapter extends JSONAPIAdapter {
  constructor(...args) {
    super(...args);

    // Proxy ember-data requests to ensure prior token refresh
    _initializerDefineProperty(this, "session", _descriptor, this);
    return new Proxy(this, {
      get(target, prop, receiver) {
        if (["findRecord", "createRecord", "updateRecord", "deleteRecord", "findAll", "query", "findMany"].includes(prop)) {
          return new Proxy(target[prop], {
            async apply(...args) {
              await target.session.refreshAuthentication.perform();
              return Reflect.apply(...args);
            }
          });
        }
        return Reflect.get(target, prop, receiver);
      }
    });
  }
  get headers() {
    return this.session.headers;
  }
  handleResponse(status, ...args) {
    if (status === 401) {
      handleUnauthorized(this.session);
    }
    return super.handleResponse(status, ...args);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { OIDCJSONAPIAdapter as default };