import Controller, { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';

export default class JobsController extends Controller {
  @service jobRepo;
  @service router;
  @service store;
  @controller('jobs/job') jobController;

  @action
  loadJob(jobId) {
    this.router.transitionTo('jobs.job', jobId);
  }

  @task
  *createAndLoadJob(name) {
    const job = yield this.jobRepo.createAndSave.perform({ name });
    this.loadJob(job.id);
  }

  @task
  *deleteJob() {
    yield this.jobController.job.destroyRecord();
    this.jobController.job = null;
    this.router.transitionTo('jobs');
  }
}
