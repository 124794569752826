import ApplicationSerializer from 'eflex/serializers/application';

export default class CustomIdentifierSerializer extends ApplicationSerializer {
  attrs = {
    area: {
      serialize: false,
      deserialize: false,
    },
  };
}
