import EmberObject from '@ember/object';
import { inject } from '@ember/service';
function _initializerDefineProperty(target, property, descriptor, context) {
  if (!descriptor) return;
  Object.defineProperty(target, property, {
    enumerable: descriptor.enumerable,
    configurable: descriptor.configurable,
    writable: descriptor.writable,
    value: descriptor.initializer ? descriptor.initializer.call(context) : void 0
  });
}
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object.keys(descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;
  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }
  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);
  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }
  if (desc.initializer === void 0) {
    Object.defineProperty(target, property, desc);
    desc = null;
  }
  return desc;
}
var _class2, _descriptor;
/**
  > ⚠️ CAUTION you likely want the docs for [<Interface> Serializer](/ember-data/release/classes/%3CInterface%3E%20Serializer)
  > as extending this abstract class is unnecessary.

  `Serializer` is an abstract base class that you may override in your
  application to customize it for your backend. The minimum set of methods
  that you should implement is:

    * `normalizeResponse()`
    * `serialize()`

  And you can optionally override the following methods:

    * `normalize()`

  For an example implementation, see
  [JSONSerializer](JSONSerializer), the included JSON serializer.

  @class Serializer
  @public
  @extends Ember.EmberObject
*/
let _class = (_class2 = class _class2 extends EmberObject {
  constructor(...args) {
    super(...args);
    _initializerDefineProperty(this, "store", _descriptor, this);
  }
  /**
    The `store` property is the application's `store` that contains
    all records. It can be used to look up serializers for other model
    types that may be nested inside the payload response.
     Example:
     ```js
    Serializer.extend({
      extractRelationship(relationshipModelName, relationshipHash) {
        let modelClass = this.store.modelFor(relationshipModelName);
        let relationshipSerializer = this.store.serializerFor(relationshipModelName);
        return relationshipSerializer.normalize(modelClass, relationshipHash);
      }
    });
    ```
     @property store
    @type {Store}
    @public
  */

  /**
    The `normalizeResponse` method is used to normalize a payload from the
    server to a JSON-API Document.
     http://jsonapi.org/format/#document-structure
     Example:
     ```js
    Serializer.extend({
      normalizeResponse(store, primaryModelClass, payload, id, requestType) {
        if (requestType === 'findRecord') {
          return this.normalize(primaryModelClass, payload);
        } else {
          return payload.reduce(function(documentHash, item) {
            let { data, included } = this.normalize(primaryModelClass, item);
            documentHash.included.push(...included);
            documentHash.data.push(data);
            return documentHash;
          }, { data: [], included: [] })
        }
      }
    });
    ```
     @since 1.13.0
    @method normalizeResponse
    @public
    @param {Store} store
    @param {Model} primaryModelClass
    @param {Object} payload
    @param {String|Number} id
    @param {String} requestType
    @return {Object} JSON-API Document
  */

  /**
    The `serialize` method is used when a record is saved in order to convert
    the record into the form that your external data source expects.
     `serialize` takes an optional `options` hash with a single option:
     - `includeId`: If this is `true`, `serialize` should include the ID
      in the serialized object it builds.
     Example:
     ```js
    Serializer.extend({
      serialize(snapshot, options) {
        let json = {
          id: snapshot.id
        };
         snapshot.eachAttribute((key, attribute) => {
          json[key] = snapshot.attr(key);
        });
         snapshot.eachRelationship((key, relationship) => {
          if (relationship.kind === 'belongsTo') {
            json[key] = snapshot.belongsTo(key, { id: true });
          } else if (relationship.kind === 'hasMany') {
            json[key] = snapshot.hasMany(key, { ids: true });
          }
        });
         return json;
      },
    });
    ```
     @method serialize
    @public
    @param {Snapshot} snapshot
    @param {Object} [options]
    @return {Object}
  */
  /**
    The `normalize` method is used to convert a payload received from your
    external data source into the normalized form `store.push()` expects. You
    should override this method, munge the hash and return the normalized
    payload.
     Example:
     ```js
    Serializer.extend({
      normalize(modelClass, resourceHash) {
        let data = {
          id:            resourceHash.id,
          type:          modelClass.modelName,
          attributes:    resourceHash
        };
        return { data: data };
      }
    })
    ```
     @method normalize
    @public
    @param {Model} typeClass
    @param {Object} hash
    @return {Object}
  */
  normalize(typeClass, hash) {
    return hash;
  }
}, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [inject], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _class2);
export { _class as default };