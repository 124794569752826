import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class TaskConfigNodeRed extends Component {
  @tracked currentVariableTypeTab = `deviceToVariables-${this.args.taskConfig.id}`;

  @service variableDefRepo;
  @service taskRepo;

  get filteredJemProcessDataDefs() {
    return this.allPreviousTasks
      ?.flatMap(_task => _task.jemProcessDataDefs)
      .filter(item => !item.isDeleted) ?? [];
  }

  get showDeviceFrom() {
    return this.currentVariableTypeTab === this.deviceFromId;
  }

  get deviceToId() {
    return `deviceToVariables-${this.args.taskConfig.id}`;
  }

  get deviceFromId() {
    return `deviceFromVariables-${this.args.taskConfig.id}`;
  }

  get filteredVariableDefs() {
    return this.allPreviousTasks
      ?.flatMap(_task => _task.variableDefs)
      .filter(def => !def.isDeleted && def.isFromDevice) ?? [];
  }

  // jscpd:ignore-start
  get displayVariables() {
    return this.args.task.variableDefs
      .filter(item => !item.isDeleted)
      .filter(item => item.isFromDevice === this.showDeviceFrom) ?? [];
  }

  get allPreviousTasks() {
    return this.taskRepo.getAllPreviousTasks(this.args.task);
  }

  @action
  setVariableValue(variableDefConfig, value) {
    this.variableDefRepo.setVariableValue(variableDefConfig, value);
  }

  @action
  addMultipleVariables(variableQuantity) {
    for (let i = 0; i < variableQuantity; i++) {
      this.variableDefRepo.createVariableDef({
        task: this.args.task,
        isFromDevice: this.showDeviceFrom,
      });
    }
  }
  // jscpd:ignore-end

  @action
  deleteVariable(variableDef) {
    this.variableDefRepo.deleteVariable(variableDef);
  }
}
