import Service, { inject as service } from '@ember/service';
import { clone } from 'ramda';
import config from 'eflex/config/environment';

const IMAGE_ZIP_MAX = 1000;

export default class ZipImagesService extends Service {
  @service notifier;
  @service currentUser;

  zip({ selectAll, totalImageCount, params, selectedImageIds }) {
    if (selectAll && totalImageCount > IMAGE_ZIP_MAX) {
      this.notifier.sendError('logs.tooManyImagesForZip');
      return;
    }

    params = clone(params) ?? {};

    if (!selectAll) {
      params.selectedImages = selectedImageIds.join(',');
    }

    const url = `${config.servicePrefix}/evisionImages?ext=zip&${new URLSearchParams(params)}`;

    const authZipUrl = this.currentUser.getUserTokenUrl(url);
    window.location = authZipUrl;
  }
}
