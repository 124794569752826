import { userRoles } from 'eflex/constants/user-roles';
import UserValidations from 'eflex/validations/user';
import Model, { attr } from '@ember-data/model';
import { intersection } from 'ramda';
import { isEmpty } from '@ember/utils';
import { buildValidations } from '@eflexsystems/ember-tracked-validations';
import { tracked } from '@glimmer/tracking';
import { arrayAttr } from 'eflex/decorators';

const inRole = (user, requiredRole) => {
  const role = userRoles[user.role];

  if (role == null) {
    return false;
  }

  return role.level >= requiredRole.level;
};

@buildValidations(UserValidations)
class User extends Model {
  @attr('date', { defaultValue() { return new Date(); } }) createdAt;
  @attr('string') userName;
  @attr('string', { defaultValue: '' }) firstName;
  @attr('string', { defaultValue: '' }) lastName;
  @attr('string') badgeId;
  @attr('string', { defaultValue: 'english' }) language;
  @attr('string') email;
  @attr('string') role;
  @attr('string') password;
  @attr('string') phone;
  @attr('string') notes;
  @attr('boolean', { defaultValue: true }) isEnabled;
  @attr('number', { defaultValue: 1440 }) timeoutLength;
  @attr('boolean', { defaultValue: true }) timeoutWarning;
  @attr('string') encryptedPassword;
  @attr('boolean') hasBadgeId; // set in serializer
  @attr('boolean', { defaultValue: false }) isSso;

  @arrayAttr permissions;
  @arrayAttr authorizationTags;
  @arrayAttr visibilityTags;
  @arrayAttr wieEditorTags;
  @arrayAttr wieApproverTags;
  @arrayAttr wieDeployerTags;

  @tracked passwordConfirmation;

  get errorIdentifier() {
    return this.userName;
  }

  get isAdmin() {
    return inRole(this, userRoles.Admin);
  }

  get isNotAdmin() {
    return !this.isAdmin;
  }

  get isUser() {
    return inRole(this, userRoles.User);
  }

  get isNotUser() {
    return !this.isUser;
  }

  get isViewOnly() {
    return inRole(this, userRoles.ViewOnly);
  }

  get isNotViewOnly() {
    return !this.isViewOnly;
  }

  get displayUsername() {
    const fullName = `${this.firstName} ${this.lastName}`;

    if (fullName.trim().length > 0) {
      return fullName;
    } else {
      return this.userName;
    }
  }

  hasPermission(permission, includeAdmin = true) {
    return (includeAdmin && this.isAdmin) || this.permissions?.includes(permission);
  }

  hasPermissions(permissions) {
    return this.isAdmin || permissions.every((val) => this.hasPermission(val));
  }

  hasAnyPermission(permissions) {
    return this.isAdmin || permissions.some((val) => this.hasPermission(val));
  }

  hasAuthorization(authorizedTags) {
    return this.isAdmin || !isEmpty(intersection(this.authorizationTags, authorizedTags));
  }

  toFragment() {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      userName: this.userName,
    };
  }
}

export default User;
