import { isEmpty } from '@ember/utils';
import { taskGroup, didCancel, task, waitForEvent } from 'ember-concurrency';
import { mergeRight } from 'ramda';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { destroy, associateDestroyableChild, registerDestructor } from '@ember/destroyable';
import { waitFor } from '@ember/test-waiters';
import exportToCsv from 'eflex/util/export-to-csv';

const setNested = (datum) => {
  if (isEmpty(datum?.children)) {
    return;
  }

  datum._children = [];
  datum.children.forEach(child => {
    setNested(child);
  });
};

export default class TabulatorTable extends Component {
  //  @tableConfiguration expected format:
  //    columns:  []
  //    options:  null
  //    childConfiguration: null
  @taskGroup({ enqueue: true }) loadingTasks;

  @tracked startCount = 0;
  @tracked endCount = 0;
  @tracked totalCount = 0;
  scrollHeight = 0;

  @task({ group: 'loadingTasks' })
  @waitFor
  *createTable(element) {
    const options = mergeRight(
      {
        autoResize: !window.isTesting && !window.isIntegrationTest,
        ajaxURL: 'blank',
        columnHeaderSortMulti: false,
        columns: this.args.tableConfiguration.columns,
        dataTree: true,
        dataTreeBranchElement: false,
        dataTreeChildIndent: 50,
        dataTreeCollapseElement: this.#getExtraIcon(element, 'collapse'),
        dataTreeExpandElement: this.#getExtraIcon(element, 'expand'),
        footerElement: '.table-footer',
        height: '100%',
        layout: 'fitColumns',
        layoutColumnsOnNewData: true,
        renderVerticalBuffer: 5000,
        columnDefaults: {
          headerSortTristate: true,
          resizable: false,
          tooltip: true,
        },

        rowFormatter: (row) => {
          this.scrollHeight = element.querySelector('.tabulator-tableholder').scrollTop;

          const rowElement = row.getElement();
          const classes = ['parent-row'];

          if (row._row.modules?.dataTree?.children) {
            classes.push('has-children');
          }
          rowElement.classList.add(...classes);
        },
      },
      this.args.tableConfiguration.options ?? {},
    );

    if (this.args.data) {
      options.data = this.args.data;
    } else {
      Object.assign(options, {
        sortMode: 'remote',
        pagination: true,
        paginationMode: 'remote',
        paginationButtonCount: 0,
        paginationSize: 30,
        ajaxRequestFunc: async (url, config, params) => {
          try {
            return await this._requestData.perform(element, params);
          } catch (e) {
            if (!didCancel(e)) {
              throw e;
            }

            return {
              data: [],
              exportData: () => [],
              last_page: 0,
            };
          }
        },
      });
    }

    const { TabulatorFull } = yield import('tabulator-tables');
    let table = new TabulatorFull(element.querySelector('.tabulator-table'), options);

    table.on('dataLoaded', (data) => {
      if (!data) {
        return;
      }

      // hack to use built-in expandable rows with nested tables
      data?.forEach(datum => {
        setNested(datum);
      });
    });

    table.on('dataTreeRowCollapsed', (row) => {
      this.#setTableScroll(element);
      this.#onDataTreeRowCollapsed(row);
    });

    table.on('dataTreeRowExpanded', (row) => {
      this.#setTableScroll(element);
      this._onDataTreeRowExpanded.perform(row, {
        rowData: row.getData(),
        tableConfiguration: this.args.tableConfiguration.childConfiguration,
        expandElement: this.#getExtraIcon(element, 'expand'),
        collapseElement: this.#getExtraIcon(element, 'collapse'),
      });
    });

    registerDestructor(this, () => {
      table.destroy();
      table = null;
    });

    yield this._afterTableRender.perform(table);

    if (!this.args.data) {
      element.querySelector('.tabulator-page[data-page="first"]').innerHTML =
        '<div class="icon icon-previous-double-arrow"/>';
      element.querySelector('.tabulator-page[data-page="prev"]').innerHTML = '<div class="icon icon-previous-arrow"/>';
      element.querySelector('.tabulator-page[data-page="next"]').innerHTML = '<div class="icon icon-next-arrow"/>';
      element.querySelector('.tabulator-page[data-page="last"]').innerHTML =
        '<div class="icon icon-next-double-arrow"/>';
    }
  }

  @task
  @waitFor
  *_onDataTreeRowExpanded(parentRow, args) {
    const parentElement = parentRow.getElement();
    parentElement.classList.add('opened');
    parentElement.destroyChild = {};

    associateDestroyableChild(this, parentElement.destroyChild);
    let childContainer = document.createElement('div');
    childContainer.classList.add('child-container');

    let childElement = document.createElement('div');
    if (args.rowData) {
      childElement.classList.add('child-table', `child-${args.rowData.id}`);
    }

    childContainer.append(childElement);
    parentElement.append(childContainer);

    const { TabulatorFull } = yield import('tabulator-tables');
    let table = new TabulatorFull(
      childElement,
      mergeRight(
        {
          autoResize: !window.isTesting && !window.isIntegrationTest,
          columnHeaderSortMulti: false,
          columns: args.tableConfiguration.columns,
          data: args.rowData?.children,
          dataTree: true,
          dataTreeBranchElement: false,
          dataTreeChildIndent: 50,
          dataTreeCollapseElement: args.collapseElement,
          dataTreeExpandElement: args.expandElement,
          height: '100%',
          layout: 'fitColumns',
          layoutColumnsOnNewData: true,
          columnDefaults: {
            headerSortTristate: true,
            resizable: false,
            tooltip: true,
          },

          rowFormatter: (row) => {
            const rowElement = row.getElement();
            const classes = ['child-row'];

            if (row._row.modules?.dataTree?.children) {
              classes.push('has-children');
            }

            rowElement.classList.add(...classes);
          },
        },
        args.tableConfiguration.options ?? {},
      ),
    );

    registerDestructor(table, () => {
      table.destroy();
      childElement.remove();
      childContainer.remove();
      childElement = null;
      childContainer = null;
      table = null;
    });

    associateDestroyableChild(parentElement.destroyChild, table);

    table.on('dataTreeRowCollapsed', (row) => {
      this.#onDataTreeRowCollapsed(row);
    });

    table.on('dataTreeRowExpanded', (row) => {
      this._onDataTreeRowExpanded.perform(row, {
        rowData: row.getData(),
        tableConfiguration: args.tableConfiguration.childConfiguration,
        expandElement: args.expandElement,
        collapseElement: args.collapseElement,
      });
    });

    yield this._afterTableRender.perform(table);
  }

  @task
  @waitFor
  *_afterTableRender(table) {
    table.on('rowClick', (e, row) => {
      e.stopPropagation();
      e.preventDefault();

      const headerClick = e.target.parentElement.className.startsWith('tabulator-col');

      if (!headerClick && !isEmpty(row.getData()?.children)) {
        row.treeToggle();
      }
    });

    yield waitForEvent(table, 'tableBuilt');
  }

  @task
  @waitFor
  *_requestData(element, params) {
    const skip = (params.page - 1) * params.size;
    params.skip = skip;

    const result = yield this.args.getRowData?.(params);

    if (result == null) {
      return {
        data: [],
        exportData: () => [],
        last_page: 0,
      };
    }

    const { data, count } = result;

    let endCount = skip + params.size;
    if (endCount > count) {
      endCount = count;
    }

    Object.assign(this, {
      startCount: skip + 1,
      endCount,
      totalCount: count,
    });

    const table = yield this._getTable.perform(element);

    return {
      data,
      last_page: Math.ceil(count / table.options?.paginationSize),
    };
  }

  @task({ group: 'loadingTasks' })
  @waitFor
  *onDidUpdate(element, [data]) {
    const table = yield this._getTable.perform(element);
    table?.setData(data);
  }

  @task
  @waitFor
  *_getTable(element) {
    const { TabulatorFull } = yield import('tabulator-tables');
    return TabulatorFull.findTable(element.querySelector('.tabulator-table'))[0];
  }

  #setTableScroll(element) {
    element.querySelector('.tabulator-tableholder').scrollTop = this.scrollHeight;
  }

  #getExtraIcon(element, name) {
    return element.querySelector(`.extra-icons .${name}-icon`);
  }

  #onDataTreeRowCollapsed(row) {
    const rowElement = row.getElement();
    rowElement.classList.remove('opened');
    destroy(rowElement.destroyChild);
  }

  @task
  @waitFor
  *exportToCsv() {
    yield exportToCsv(this.args.exportDataFunction, this.args.exportFilename);
  }
}
