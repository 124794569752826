import { modifier } from 'ember-modifier';

export default modifier(function conditionalAutofocus(element, [takeFocus, selector = 'input:not([disabled])']) {
  if (!takeFocus) {
    return;
  }

  const childElement = element.querySelector(selector);

  if (childElement) {
    childElement.focus();
  } else {
    element.focus();
  }
}, { eager: false });
