import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { registerDestructor } from '@ember/destroyable';
import Split from 'split.js';
import { action } from '@ember/object';

const DEFAULT_GUTTER_SIZE = 32;

export default class SplitView extends Component {
  @service resizeListener;
  sizes = JSON.parse(localStorage.getItem(this.args.name));

  _onDragEnd(split) {
    this.resizeListener.triggerResize();
    if (split) {
      localStorage.setItem(
        this.args.name,
        JSON.stringify(split.getSizes()),
      );
    }
  }

  @action
  onDidInsert() {
    let split = Split(this.args.splitElements, {
      direction: 'horizontal',
      minSize: [0, 0],
      sizes: this.sizes ?? this.args.sizes,
      gutterSize: DEFAULT_GUTTER_SIZE,
      onDragEnd: () => {
        this._onDragEnd(split);
      },
      onDrag: () => {
        this.resizeListener.triggerResize();
      },
    });

    registerDestructor(this, () => {
      split?.destroy();
      split = null;
    });
  }
}
