import { task } from 'ember-concurrency';
import Service, { inject as service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

export default class WebCamScannerService extends Service {
  @service mediaDevices;
  @service eventBus;

  @task({ enqueue: true })
  @waitFor
  *startBarcodeScanner(cameraId, element, barcodeSymbology) {
    this._cleanupBarcodeScanner();

    if (!this.mediaDevices.devices.some(item => item.deviceId === cameraId)) {
      cameraId = undefined;
    }

    this._barcodeScanner = yield this._getReader.perform(barcodeSymbology);

    const seen = new Set();

    yield this._barcodeScanner.decodeFromVideoDevice(cameraId, element, (value, err) => {
      if (this._barcodeScanner.isCancelled) {
        this._cleanupBarcodeScanner();
        return;
      }

      if (err != null) {
        return;
      }

      const { text } = value;

      if (seen.has(text)) {
        return;
      }

      seen.add(text);
      this.eventBus.trigger('barcodeScanned', text);
    });
  }

  @task
  @waitFor
  *_getReader(barcodeSymbology) {
    if (window.isTesting) {
      return {
        isCancelled: false,
        reader: {
          hints: {
            get() {
              return barcodeSymbology;
            },
          },
        },
        stopContinuousDecode() {},
        reset() {},
        decodeFromVideoDevice() {
          return Promise.resolve();
        },
      };
    }

    const { BrowserMultiFormatReader, DecodeHintType } = yield import(
      /* webpackExports: ["BrowserMultiFormatReader", "DecodeHintType"] */
      '@zxing/library'
    );

    if (barcodeSymbology != null) {
      const hints = new Map([[DecodeHintType.POSSIBLE_FORMATS, [barcodeSymbology]]]);
      return new BrowserMultiFormatReader(hints);
    } else {
      return new BrowserMultiFormatReader();
    }
  }

  @waitFor
  async willDestroy() {
    super.willDestroy(...arguments);
    await this.destroyBarcodeScanner();
  }

  @waitFor
  async destroyBarcodeScanner() {
    this._cleanupBarcodeScanner();

    await this.startBarcodeScanner.cancelAll({ resetState: true });
  }

  _cleanupBarcodeScanner() {
    if (!this._barcodeScanner) {
      return;
    }

    this._barcodeScanner.isCancelled = true;
    this._barcodeScanner.stopContinuousDecode();
    this._barcodeScanner.videoCanPlayListener = null;
    this._barcodeScanner.videoEndedListener = null;
    this._barcodeScanner.videoPlayingEventListener = null;
    this._barcodeScanner.reset();
  }
}
