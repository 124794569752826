import BsButton from 'ember-bootstrap/components/bs-button';

export default class EflexBsButton extends BsButton {
  '__ember-bootstrap_subclass' = true;

  get __disabled() {
    const { disabled, _disabled, preventConcurrency } = this.args;
    return (preventConcurrency !== false && this.isPending) || disabled || _disabled;
  }
}
