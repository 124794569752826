var _class, _descriptor, _descriptor2;
function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
import config from "@eflexsystems/ember-simple-auth-oidc/config";
import { inject as service } from "@ember/service";
import { enqueueTask } from "ember-concurrency";
import SessionServiceESA from "ember-simple-auth/services/session";
let Service = (_class = class Service extends SessionServiceESA {
  constructor(...args) {
    super(...args);
    _initializerDefineProperty(this, "router", _descriptor, this);
    _initializerDefineProperty(this, "config", _descriptor2, this);
  }
  singleLogout() {
    const session = this.session; // InternalSession
    const authenticator = session._lookupAuthenticator(session.authenticator);
    const idToken = this.data.authenticated.id_token;

    // Invalidate the ember-simple-auth session
    this.invalidate();

    // Trigger a single logout on the authorization server
    return authenticator.singleLogout(idToken);
  }
  get redirectUri() {
    const {
      protocol,
      host
    } = location;
    const path = this.router.currentURL;
    return `${protocol}//${host}${path}`;
  }

  /**
   * Watch the `data.authenticated.id_token` to recomputed the headers as
   * according to the openid-connect specification the `id_token` must always
   * be included.
   * https://openid.net/specs/openid-connect-core-1_0.html#TokenResponse
   */
  get headers() {
    const headers = {};
    if (this.isAuthenticated) {
      const token = this.data.authenticated[this.config.tokenPropertyName];
      Object.assign(headers, {
        [this.config.authHeaderName]: `${this.config.authPrefix} ${token}`
      });
    }
    return headers;
  }
  *refreshAuthentication() {
    const expireTime = this.data.authenticated.expireTime;
    const isExpired = expireTime && expireTime <= new Date().getTime();
    if (this.isAuthenticated && isExpired) {
      try {
        return yield this.session.authenticate("authenticator:oidc", {
          redirectUri: this.redirectUri,
          isRefresh: true
        });
      } catch (e) {
        console.warn("Token is invalid. Re-authentification is required.");
      }
    }
  }
  async requireAuthentication(transition, routeOrCallback) {
    await this.refreshAuthentication.perform();
    return super.requireAuthentication(transition, routeOrCallback);
  }

  /**
   * This method is called after a successful authentication and continues an
   * intercepted transition if a URL is stored in `nextURL` in the
   * localstorage. Otherwise call the parent/super to invoke the normal
   * behavior of the `sessionAuthenticated` method.
   *
   * @method handleAuthentication
   * @public
   */
  handleAuthentication(routeAfterAuthentication) {
    const nextURL = this.data.nextURL;
    // nextURL is stored to the localStorage using the
    // session service's set method
    // eslint-disable-next-line ember/classic-decorator-no-classic-methods
    this.set("data.nextURL", undefined);
    if (nextURL) {
      this.router.replaceWith(nextURL);
    } else {
      super.handleAuthentication(routeAfterAuthentication);
    }
  }

  /**
   * Overwriting the standard behavior of handleInvalidation,
   * which is redirecting to the rootURL of the app. Since the OIDC addon
   * also triggers a redirect in some cases and this could lead to conflicts
   * we disable the ember-simple-auth behavior.
   * If you wish to redirect after invalidating the session, please handle
   * this by overwriting this method in your application route or at the
   * exact location where the session is invalidated.
   */
  handleInvalidation() {}
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "config", [config], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "refreshAuthentication", [enqueueTask], Object.getOwnPropertyDescriptor(_class.prototype, "refreshAuthentication"), _class.prototype)), _class);
export { Service as default };