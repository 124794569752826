import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import $ from 'jquery';
import Component from '@glimmer/component';
import { dequal } from 'dequal';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import { task, timeout, waitForQueue } from 'ember-concurrency';
import { waitForPromise, waitFor } from '@ember/test-waiters';
import { registerDestructor } from '@ember/destroyable';
import { modifier } from 'ember-modifier';

export default class TaskCardGrid extends Component {
  @service store;
  #destructor;

  previousCardIds = this.args.taskParent.children.map(item => item.id);

  @task
  @waitFor
  *reloadGrid(element) {
    yield waitForQueue('afterRender');
    this.destroyGridster(element, false);
    this.loadGridster(element);
  }

  @task
  @waitFor
  *_updateAllCoordinates(ui) {
    yield this._scrollGrid.cancelAll({ resetState: true });

    ui.$player[0]
      .closest('.task-card-grid')
      .querySelectorAll('.gridster-card-holder')
      .forEach(taskCard => {
        const dimensions = {
          column: parseInt(taskCard.dataset.col),
          row: parseInt(taskCard.dataset.row),
          sizeY: parseInt(taskCard.dataset.sizey),
        };

        const treeTask = this.store.peekRecord('task', taskCard.dataset.taskId);
        const update = {};

        for (const prop in dimensions) {
          if (treeTask[prop] != dimensions[prop]) {
            update[prop] = dimensions[prop];
          }
        }

        if (isNotNilOrEmpty(update)) {
          Object.assign(treeTask, update);
        }
      });
  }

  @task
  @waitFor
  *_scrollGrid(ui) {
    const parent = ui.$player[0].closest('.task-grid-wrapper');
    const top = ui.pointer?.top ?? 0;

    if (top > window.innerHeight - 100) {
      yield this._scrollLoop.perform(parent, 5);
    } else if (top < parent.getBoundingClientRect().top + 100) {
      yield this._scrollLoop.perform(parent, -5);
    } else {
      yield this._scrollLoop.cancelAll({ resetState: true });
    }
  }

  @task({ drop: true })
  *_scrollLoop(parent, scrollAmount) {
    while (true) {
      yield timeout(10);
      parent.scrollTop += scrollAmount;
    }
  }

  initializeGridster = modifier(async (element) => {
    await waitForPromise(import('@eflexsystems/gridster/src/jquery.gridster.js'));
    if (this.isDestroying || this.isDestroyed) {
      return;
    }
    this.loadGridster(element);
  });

  updateGridster = modifier(async (element, [tasks]) => {
    const cardIds = tasks.map(item => item.id);

    if (!this.previousCardIds) {
      this.previousCardIds = cardIds;
      return;
    }

    if (!dequal(this.previousCardIds, cardIds)) {
      this.previousCardIds = cardIds;
      await this.reloadGrid.perform(element);
    }
  });

  @action
  loadGridster(element) {
    /* eslint-disable-next-line ember/no-jquery */
    const gridsterElem = $(element.querySelector('.gridster-cards'));

    const gridster = gridsterElem?.gridster({
      min_rows: 256,
      max_rows: 600,
      max_cols: 256,
      autogrow_cols: true,
      shift_widgets_up: false,
      move_widgets_down_only: true,
      shift_larger_widgets_down: false,
      widget_margins: [6, 6],
      widget_base_dimensions: [225, 62],
      ignore_self_occupied: true,
      resize: {
        enabled: true,
        axes: ['y'],
        remove_holes: false,
        handle_append_to: '.task-card',
        handle_class: 'task-resize-handle',
        stop: (e, ui) => { this._updateAllCoordinates.perform(ui); },
      },
      draggable: {
        /* eslint-disable-next-line ember/no-jquery */
        scroll_target: $(element),
        autoscroll: true,
        stop: (e, ui) => { this._updateAllCoordinates.perform(ui); },
        drag: (e, ui) => { this._scrollGrid.perform(ui); },
        ignore_dragging: [
          '.task-card-dropdown-caret',
          'input',
          'textarea',
          'select',
          'button',
          '.ember-power-select-option',
          '.ember-power-select-options',
        ],
      },
      collision: {
        wait_for_mouseup: true,
      },
    });

    if (!this.#destructor) {
      this.#destructor = registerDestructor(this, () => {
        this.destroyGridster(element, true);
        this.#destructor = null;
      });
    }

    const gridsterData = gridster?.data('gridster');

    if (this.args.disabled) {
      gridsterData.disable();
      gridsterData.disable_resize();
    } else {
      gridsterData.enable_resize();
    }
  }

  destroyGridster(element, isFullDestroy) {
    /* eslint-disable-next-line ember/no-jquery */
    $(element.querySelector('.gridster-cards'))
      .data('gridster')
      ?.destroy(isFullDestroy);
  }
}
