import { isEmpty } from '@ember/utils';

const generateCsvBlob = async (data) => {
  const { stringify } = await import('csv-stringify/browser/esm/sync');
  const csv = stringify(data, { header: true });
  return new Blob([csv], { type: 'text/csv;charset=utf-8' });
};

export default async function exportToCsv(exportFunction, filename, saveAsOverride = null) {
  if (!exportFunction || !filename) {
    return;
  }

  const data = exportFunction();

  if (isEmpty(data)) {
    return;
  }

  const blob = await generateCsvBlob(data);
  const saveFunc = saveAsOverride ?? (await import('file-saver')).default;
  saveFunc(blob, `${filename}.csv`);
}
