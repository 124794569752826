import Component from '@glimmer/component';
import { action } from '@ember/object';
import { isBlank, isEmpty } from '@ember/utils';

class JemBomSourceLookupInput extends Component {
  @action
  onEnter() {
    if (
      isEmpty(this.args.bomSourceLookupValue) ||
      isBlank(this.args.tempSerialNumber)
    ) {
      return;
    }

    this.args.onEnter();
  }
}

export default JemBomSourceLookupInput;
