import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import ModelValidations from 'eflex/validations/model';
import { buildValidations } from '@eflexsystems/ember-tracked-validations';
import modelDisplayName from 'eflex/helpers/model-display-name';

@buildValidations(ModelValidations)
class EflexModel extends Model {
  @attr('string') name;
  @attr('string') code;
  @attr('string') description;

  @belongsTo('area', { async: false, inverse: 'models' }) area;
  @hasMany('taskConfig', { async: false, inverse: 'configModel' }) taskConfigs;
  @hasMany('productionSchedule', { async: false, inverse: 'model', cascadeDelete: true }) productionSchedules;
  @hasMany('jemConfiguration', { polymorphic: true, async: false, inverse: 'model' }) jemConfigurations;
  @hasMany('jemPrerequisite', { async: false, inverse: 'configModel', cascadeDelete: true }) jemPrerequisites;

  get displayName() {
    return modelDisplayName(this);
  }

  asHistory() {
    return {
      id: this.id,
      area: this.area?.id,
      name: this.name,
      code: this.code,
    };
  }
}

export default EflexModel;
