import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class EmberGrid extends Component {
  @tracked _previousDataLength = 0;
  @tracked _hideLoad = false;
  @tracked _take = 30;
  @tracked _skip = 0;

  get showLoadMore() {
    if (this.args.fetch == null || this._hideLoad) {
      return false;
    }

    return this.args.data?.length - this._previousDataLength >= this._take;
  }

  @action
  onResetPagination() {
    Object.assign(this, {
      _take: 30,
      _skip: 0,
      _hideLoad: false,
      _previousDataLength: 0,
    });
  }

  @action
  sendFetch() {
    const dataLength = this.args.data?.length;

    if (this._previousDataLength === dataLength) {
      this._hideLoad = true;
    }

    this._previousDataLength = dataLength;

    this._skip = this._skip + this._take;

    return this.args.fetch?.({
      skip: this._skip,
      take: this._take,
    });
  }
}
