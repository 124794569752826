import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import EdhrProcessDataTypes from 'eflex/constants/edhr-process-data-types';
import Component from '@glimmer/component';
import { isPresent } from '@ember/utils';
import { CAN_SEND_IMAGES_TASK_TYPES } from 'eflex/constants/tasks/task-types';
import { range, pipe, concat } from 'ramda';
import { sortByProp, concatRight } from 'ramda-adjunct';

export default class EdhrTriggerConfig extends Component {
  @service store;
  @service intl;

  get parentTrigger() {
    return this.args.triggerConfig.parentTrigger;
  }

  get nonDeletedMappings() {
    return this.parentTrigger.edhrMappings.filter(item => !item.isDeleted);
  }

  get task() {
    return this.parentTrigger.task;
  }

  get showMappings() {
    return !this.args.isStationEvent && isPresent(this.nonDeletedMappings);
  }

  get processDataOptions() {
    const jemProcessDataDefs = this.task.jemProcessDataDefs.map(jemProcessDataDef => ({
      id: jemProcessDataDef.id,
      processDataType: EdhrProcessDataTypes.jemProcessDataDef,
      name: jemProcessDataDef.name,
      jemProcessDataDef,
    }));

    let variableDefs = [];

    if (this.task.isNodeRed) {
      variableDefs = this.task.variableDefs.filter(item => item.isFromDevice).map(variableDef => ({
        id: variableDef.id,
        processDataType: EdhrProcessDataTypes.variableDef,
        name: variableDef.name,
        variableDef,
      }));
    }

    let bolts = [];

    if (this.task.isTorque) {
      bolts = range(1, this.task.maxBoltCount + 1).flatMap(i => [
        this._boltToProcessDatum(i, EdhrProcessDataTypes.boltTorque),
        this._boltToProcessDatum(i, EdhrProcessDataTypes.boltAngle),
      ]);
    }

    let options = pipe(
      concatRight(jemProcessDataDefs),
      concatRight(variableDefs),
      sortByProp('name'),
      // these should always be last
      concatRight([
        this._getMappingOption(EdhrProcessDataTypes.currentDateTime),
        this._getMappingOption(EdhrProcessDataTypes.hardwareName),
        this._getMappingOption(EdhrProcessDataTypes.userDefinedString),
      ]),
    )(bolts);

    if (CAN_SEND_IMAGES_TASK_TYPES.has(this.task.taskType)) {
      options = concat(options, [this._getMappingOption(EdhrProcessDataTypes.sendImage)]);
    }

    if (this.task.isBarcode) {
      options = concat(options, [this._getMappingOption(EdhrProcessDataTypes.scannedBarcode)]);
    }

    if (this.task.isDecision) {
      options = concat(options, [this._getMappingOption(EdhrProcessDataTypes.selectedDecision)]);
    }

    return options;
  }

  _boltToProcessDatum(boltNumber, processDataType) {
    return {
      id: this._getBoltId(boltNumber, processDataType),
      processDataType,
      name: `${this.intl.t('bolt')} ${boltNumber} ${this.intl.t(processDataType)}`,
      boltIndex: boltNumber,
    };
  }

  _getBoltId(boltNumber, boltFieldType) {
    return String(boltNumber) + boltFieldType;
  }

  _getMappingOption(processDataType) {
    return {
      id: processDataType,
      processDataType,
      name: this.intl.t(processDataType),
    };
  }

  @action
  addMapping(count) {
    for (let i = 1; i <= count; i++) {
      this.store.createRecord('edhrMapping', { edhrTrigger: this.parentTrigger });
    }
  }

  @action
  removeMapping(mapping) {
    mapping.deleteRecord();
  }

  @action
  setProcessDatum(mapping, processDatum) {
    Object.assign(mapping, {
      boltIndex: processDatum.boltIndex,
      processDataType: processDatum.processDataType,
      jemProcessDataDef: processDatum.jemProcessDataDef,
      variableDef: processDatum.variableDef,
    });
  }

  getDataMappingReference = (mapping) => {
    switch (mapping.processDataType) {
      case EdhrProcessDataTypes.jemProcessDataDef: {
        return this.processDataOptions.find(item => item.id === mapping.jemProcessDataDef?.id);
      }
      case EdhrProcessDataTypes.variableDef: {
        return this.processDataOptions.find(item => item.id === mapping.variableDef?.id);
      }
      case EdhrProcessDataTypes.boltTorque:
      case EdhrProcessDataTypes.boltAngle: {
        return this.processDataOptions
          .find(item => item.id === this._getBoltId(mapping.boltIndex, mapping.processDataType));
      }
      default: {
        return this.processDataOptions.find(item => item.id === mapping.processDataType);
      }
    }
  };
}
