import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class TaskConfigLightguide extends Component {
  @service variableDefRepo;
  @service taskRepo;

  get variables() {
    return this.args.task?.variableDefs?.filter(item => !item.isDeleted);
  }

  get filteredJemProcessDataDefs() {
    return this.allPreviousTasks
      ?.flatMap(_task => _task.jemProcessDataDefs)
      .filter(item => !item.isDeleted) ?? [];
  }

  get filteredVariableDefs() {
    return this.allPreviousTasks
      ?.flatMap(_task => _task.variableDefs)
      .filter(def => !def.isDeleted && def.isFromDevice) ?? [];
  }

  get allPreviousTasks() {
    return this.taskRepo.getAllPreviousTasks(this.args.task);
  }

  @action
  setVariableValue(variableDefConfig, value) {
    this.variableDefRepo.setVariableValue(variableDefConfig, value);
  }

  @action
  addMultipleVariables(variableQuantity) {
    for (let i = 0; i < variableQuantity; i++) {
      this.variableDefRepo.createVariableDef({ task: this.args.task });
    }
  }

  @action
  deleteVariable(variableDef) {
    this.variableDefRepo.deleteVariable(variableDef);
  }
}
