const EPState = {
  on: 'on',
  off: 'off',
  none: '',
};

const ErrorProofingStates = [
  { value: 'on', label: 'on' },
  { value: 'off', label: 'off' },
  { value: null, label: 'none' },
];

export { ErrorProofingStates, EPState };
