import LocaleMap from 'eflex/util/locale-map';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class DropDownsLanguage extends Component {
  @service currentUser;
  languages = LocaleMap;

  @action
  languageChanged(language) {
    return this.currentUser.changeLanguage.perform(language.lang);
  }
}
