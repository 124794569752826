import Model, { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { NotificationLogTypes, NotificationLogLevels } from 'eflex/constants/notification-log';
import { tracked } from '@glimmer/tracking';
import { objectAttr, arrayAttr } from 'eflex/decorators';
import { getCaptionText } from 'eflex/util/translation-helper';
import { isPresent } from '@ember/utils';

export default class Log extends Model {
  @attr('date', { defaultValue() { return new Date(); } }) timestamp;
  @attr('string') source;
  @attr('boolean', { defaultValue: true }) isActive;
  @attr('boolean', { defaultValue: false }) pendingDeploy;
  @attr('string') level;
  @attr('string', { defaultValue: NotificationLogTypes.GLOBAL }) logType;
  @attr('string') captionId;
  @attr('string') plainMessage;
  @arrayAttr formattedStrings;
  @arrayAttr acknowledgedBy;
  @objectAttr createdBy;
  @objectAttr location;

  @tracked isSelected = false;

  @service intl;
  @service userRepo;
  @service currentUser;

  get needsToAcknowledge() {
    return this.userRepo.users.filter(user =>
      user.createdAt != null &&
      user.createdAt <= this.timestamp &&
      !this.acknowledgedBy.some(item => item.userName === user.userName),
    );
  }

  get message() {
    if (this.plainMessage != null) {
      return this.plainMessage;
    } else if (this.captionId) {
      return this.#translate({
        captionId: this.captionId,
        formattedStrings: this.formattedStrings,
        location: this.location,
      });
    } else {
      return null;
    }
  }

  get levelIcon() {
    switch (this.level) {
      case NotificationLogLevels.ALARM: {
        return 'circle-exclamation';
      }
      case NotificationLogLevels.ERROR: {
        return 'exclamation';
      }
      case NotificationLogLevels.WARN: {
        return 'triangle-exclamation';
      }
      default: {
        return null;
      }
    }
  }

  acknowledge(user) {
    if (!this.acknowledgedBy.some(item => item.userName === user.userName)) {
      this.acknowledgedBy.push(user.toFragment());
    }

    if (this.logType === NotificationLogTypes.GLOBAL || this.needsToAcknowledge.length === 0) {
      this.isActive = false;
    }
  }

  #translate({ captionId, formattedStrings, location }) {
    const stringObject = {};
    formattedStrings?.forEach((str, i) => {
      stringObject[`${i}`] = str;
    });

    let locationCaptions = location?.captions;

    if (isPresent(locationCaptions)) {
      const locationName = getCaptionText(location?.captions, this.currentUser);
      stringObject.treeItem = locationName;
    }

    return this.intl.t(captionId, stringObject);
  }
}
