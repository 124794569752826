import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

const MAX_STRINGS = 16;

export default class TaskConfigVision extends Component {
  @service store;
  @service intl;

  get allStringsEnabled() {
    return this.args.taskConfig.strings?.every(item => item.enabled);
  }

  get remainingStrings() {
    return MAX_STRINGS - this.args.task.strings.length;
  }

  @action
  addMultipleStrings(stringQuantity) {
    const task = this.args.task;

    for (let i = 0; i < stringQuantity; i++) {
      if (this.remainingStrings) {
        const stringCount = task.strings.length + 1;
        const name = this.intl.t('plant.task.vision.subtaskDefaultName');

        task.strings.push(this.store.createRecord('barcodeString', { name: `${name} ${stringCount}` }));

        task.taskConfigs.forEach(config => {
          config.strings.push(this.store.createRecord('barcodeString'));
        });
      }
    }
  }

  @action
  removeString(index) {
    this.args.task.taskConfigs.forEach(config => {
      config.strings.splice(index, 1);
    });

    this.args.task.strings.splice(index, 1);
  }

  @action
  toggleStrings() {
    this.args.taskConfig.strings.forEach(string => { string.enabled = !this.allStringsEnabled; });
  }

  @action
  onIsBarcodeReaderChanged(taskString, index, checked) {
    taskString.isBarcodeReader = checked;

    if (!checked) {
      this.args.task.taskConfigs.forEach(config => {
        config.strings[index].barcodeString = '';
      });
    }
  }
}
