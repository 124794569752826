import ApplicationSerializer from 'eflex/serializers/application';

export default class VariableDefConfigSerializer extends ApplicationSerializer {
  attrs = {
    taskConfig: {
      serialize: false,
      deserialize: false,
    },
    variableDefForValue: {
      serialize: 'ids',
      deserialize: 'ids',
    },
    jemProcessDataDefForValue: {
      serialize: 'ids',
      deserialize: 'ids',
    },
  };
}
