import Component from '@glimmer/component';
import { action } from '@ember/object';
import { indexOf } from 'ramda';

export default class PowerSelectArrows extends Component {
  @action
  setOptionAtIndex(i) {
    let newSelected;
    if (this.args.selected != null) {
      const index = indexOf(this.args.selected, this.args.options) + i;
      newSelected = this.args.options[index] ?? this.args.selected;
    } else {
      newSelected = this.args.options[0];
    }

    this.args.onChange?.(newSelected);
  }
}
