import { inject as service } from '@ember/service';
import Model, { attr, belongsTo } from '@ember-data/model';
import numbro from 'numbro';
import { filter, propEq, pipe } from 'ramda';
import { sortByProp } from 'ramda-adjunct';

export default class BoltProcessDatum extends Model {
  @attr('number') boltNumber;
  @attr('number') torqueStatus;
  @attr('number') angleStatus;
  @attr('number') tighteningStatus;
  @attr('number') torque;
  @attr('number') angle;
  @attr('number') errorCode;
  @attr('string') torqueFormat;

  @belongsTo('buildStatusChild', { inverse: 'boltProcessData', async: false }) buildStatusChild;

  @service intl;
  @service systemConfig;

  get timestamp() {
    return this.buildStatusChild?.timestamp;
  }

  get name() {
    return this.buildStatusChild.taskName;
  }

  get isPassed() {
    if (
      this.errorCode == null &&
      this.torqueStatus == null &&
      this.angleStatus == null &&
      this.tighteningStatus == null
    ) {
      return null;
    }

    return (
      this.errorCode == null &&
      this.torqueStatus === 1 &&
      this.angleStatus === 1 &&
      this.tighteningStatus === 1
    );
  }

  get isRejected() {
    return this.isPassed != null && !this.isPassed;
  }

  get isStarted() {
    return this.isPassed == null && this.buildStatusChild.isStarted;
  }

  get isFinished() {
    return !this.isStarted;
  }

  get groupedBolts() {
    return pipe(
      filter(propEq(this.boltNumber, 'boltNumber')),
      sortByProp('timestamp'),
    )(this.buildStatusChild.boltProcessData ?? []);
  }

  get isStaleResult() {
    if (this.groupedBolts.at(-1) === this) {
      return false;
    }

    if (
      this.groupedBolts.at(-1).isStarted &&
      this.groupedBolts.at(-2) === this
    ) {
      return false;
    }

    return true;
  }

  get isCollapsedResult() {
    if (
      this.groupedBolts.at(-1).isFinished &&
      this.groupedBolts.at(-1) !== this
    ) {
      return true;
    }

    return false;
  }

  get translatedError() {
    if (this.errorCode == null) {
      return null;
    }

    const key = `stitchError.${this.errorCode}`;

    if (this.intl.exists(key) || window.isTesting) {
      return this.intl.t(key);
    } else {
      return this.intl.t('stitchError.unknown', { code: this.errorCode });
    }
  }

  get formattedData() {
    const boltTranslation = this.intl.t('bolt');
    const torqueTranslation = this.intl.t('torque');
    const angleTranslation = this.intl.t('angle');
    const torqueStatus = this.torqueStatus === 1 && this.errorCode == null;
    const angleStatus = this.angleStatus === 1 && this.errorCode == null;
    const torqueFormat =
      this.torqueFormat ??
      this.systemConfig.jem.torqueFormat;

    return [
      {
        timestamp: this.timestamp,
        displayName: `${this.name} - ${boltTranslation} ${this.boltNumber} ${torqueTranslation}`,
        displayValue: `${numbro(this.torque ?? 0).format({ mantissa: 3 })} ${torqueFormat}`,
        displayClass: torqueStatus ? 'good' : 'bad',
        torqueStatus,
      },
      {
        timestamp: this.timestamp,
        displayName: `${this.name} - ${boltTranslation} ${this.boltNumber} ${angleTranslation}`,
        displayValue: `${this.angle}°`,
        displayClass: angleStatus ? 'good' : 'bad',
        angleStatus,
      },
    ];
  }
}
