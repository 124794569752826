import config from 'eflex/config/environment';

const NavBarMap = [
  {
    key: 'plant',
    label: 'plant.editor',
    faIcon: 'align-center',
    children: [
      { key: 'plant', label: 'plant.editor.config', faIcon: 'gear' },
      { key: 'codes', label: 'plant.editor.codes', icon: 'code-bracket' },
      { key: 'workInstructions', label: 'plant.editor.workInstructions', faIcon: 'image' },
      { key: 'bomSources', label: 'plant.editor.bomSources', icon: 'schedule-icon', lic: 'componentBased' },
      { key: 'hardware', label: 'plant.editor.hardware', icon: 'hardware-icon' },
      {
        key: 'serialNumberMonitor',
        label: 'plant.editor.serialNumberMonitor.index',
        faIcon: 'magnifying-glass',
        lic: 'serialNumberMonitor',
      },
      { key: 'schedules', label: 'plant.editor.schedules', faIcon: 'clock' },
      { key: 'productionScheduler', label: 'plant.editor.productionScheduler', faIcon: 'clock' },
    ],
  },

  { key: 'jobs', label: 'jobs', faIcon: 'align-center', lic: 'jobs' },

  {
    key: 'reporting',
    label: 'reporting',
    faIcon: 'chart-bar',
    children: [
      { key: 'parts.buildHistory', label: 'reporting.parts.partHistory', faIcon: 'barcode' },
      { key: 'parts.genealogy', label: 'reporting.parts.partGenealogy', faIcon: 'diagram-project' },
    ],
  },

  {
    key: 'bi',
    label: 'businessIntelligence',
    icon: 'business-intelligence-icon',
    lic: 'bi',
    children: [
      { key: 'bi.andon', label: 'bi.andon', faIcon: 'arrow-down-9-1' },
      { key: 'bi.quality', label: 'bi.quality', icon: 'wip-icon' },
      { key: 'bi.production', label: 'bi.production', faIcon: 'align-left' },
      { key: 'bi.cycleTime', label: 'bi.cycleTime', faIcon: 'stopwatch' },
      { key: 'bi.processData', label: 'bi.processData', faIcon: 'database' },
      { key: 'bi.torqueData', label: 'bi.torqueData', icon: 'torque-data-icon' },
      { key: 'bi.oee', label: 'bi.oee', icon: 'oee-nav-icon' },
      { key: 'bi.serialNumberReport', label: 'bi.serialNumberReport', faIcon: 'magnifying-glass' },
    ],
  },

  { key: 'containParts', label: 'containParts', faIcon: 'triangle-exclamation' },

  { key: 'repair', label: 'repair', faIcon: 'wrench' },

  { key: 'jem', label: 'jem', faIcon: 'list' },

  { key: 'oee', label: 'oee', icon: 'oee-nav-icon', lic: 'oee' },

  { key: 'status', label: 'status.plcs', faIcon: 'circle-dot' },

  { key: 'settings', label: 'settings', faIcon: 'gear' },

  { key: 'logs', label: 'logs', faIcon: 'clipboard' },

  {
    key: 'evision',
    label: 'evision',
    icon: 'eflex-vision-icon',
    lic: 'visionOrImageCapture',
    children: [
      { key: 'evision.imageRetrieval', label: 'evision.imageRetrieval', faIcon: 'image' },
      { key: 'evision.camera', label: 'evision.cameras', faIcon: 'camera' },
      { key: 'evision.nonConforming', label: 'evision.nonconf', faIcon: 'wrench' },
    ],
  },

  {
    key: 'debug',
    label: 'debug',
    faIcon: 'eye',
    children: [
      { key: 'debug.packets', label: 'packets', faIcon: 'ethernet' },
      { key: 'debug.liveBuildStatusLogs', label: 'liveBuildStatusLogs', faIcon: 'pallet' },
      { key: 'debug.audits', label: 'audits', faIcon: 'clipboard-list' },
      { key: 'debug.hardwareMessages', label: 'hardwareMessages', faIcon: 'hammer' },
      { key: 'debug.systemLogs', label: 'systemLogs', faIcon: 'warehouse' },
    ],
  },
];

if (config.APP.development) {
  NavBarMap.push({
    key: 'development',
    label: 'development',
    faIcon: 'user-ninja',
    children: [
      { key: 'styleguide', label: 'styleguide', faIcon: 'eye' },
      { key: 'schema', label: 'schema', faIcon: 'database' },
      { key: 'dataGenerator', label: 'treeGenerator', faIcon: 'table' },
    ],
  });
}

export default NavBarMap;
