import AuthenticatedRoute from 'eflex/util/authenticated-route';
import { inject as service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { isEmpty, isPresent } from '@ember/utils';
import { task } from 'ember-concurrency';
import { TrackedArray } from 'tracked-built-ins';
import { dissoc } from 'ramda';
import pProps from 'p-props';

export default class PartsBuildHistoryRoute extends AuthenticatedRoute {
  @service store;
  @service notifier;
  @service eventBus;
  @service router;

  model(queryParams) {
    const stationId = queryParams.station;
    const params = dissoc('station', queryParams);
    let station;
    if (stationId) {
      params.stationIds = [stationId];
      station = this.store.peekRecord('station', stationId);
    }

    return pProps({
      buildHistories: this._getData.perform(params),
      station,
    });
  }

  setupController(controller, { buildHistories, station }) {
    if (isPresent(station)) {
      station.isChecked = true;
    }

    Object.assign(controller, {
      buildHistories: new TrackedArray(buildHistories),
      buildStatuses: new TrackedArray(),
      images: [],
    });

    if (buildHistories[0]) {
      controller.fetchBuildHistory.perform(buildHistories[0]);
    }
  }

  resetController(controller) {
    super.resetController(...arguments);
    Object.assign(controller, {
      params: null,
      searchTerm: null,
    });
  }

  @task
  @waitFor
  *fetch(params) {
    const buildHistories = yield this._getData.perform(params);
    if (this.controller.selectAll) {
      buildHistories.forEach(buildHistory => { buildHistory.isSelected = true; });
    }
    this.controller.buildHistories.push(...buildHistories);
  }

  @task
  @waitFor
  *search(params) {
    this.controller.params = params;

    if (isEmpty(params.stationIds)) {
      this.controller.station = null;
    }

    try {
      const buildHistories = yield this._getData.perform({ skip: 0, take: 30 });
      if (this.controller.selectAll) {
        buildHistories.forEach(buildHistory => { buildHistory.isSelected = true; });
      }
      Object.assign(this.controller, {
        buildHistories: new TrackedArray(buildHistories),
        selectAll: false,
      });
      this.eventBus.trigger('resetPagination');
      yield this.controller.fetchBuildHistory.perform(this.controller.buildHistories[0]);
    } catch (e) {
      console.error(e);
      this.notifier.sendError('logs.searchFailed');
    }
  }

  @task
  @waitFor
  *_getData(params = {}) {
    if (params.skip === 0) {
      Object.assign(this.controller, {
        selectedBuildHistory: null,
        selectedBuildStatus: null,
      });
    }

    params = Object.assign({ skip: 0, take: 30 }, this.controller?.params, params);

    return [...(yield this.store.query('buildHistory', params))];
  }

  @task
  @waitFor
  *searchMarriageSerialNumber(searchTerm) {
    this.controller.searchTerm = searchTerm;
    yield this.search.perform({ searchTerm });
    this.router.transitionTo('parts.buildHistory.index');
  }
}
