import AuthenticatedRoute from 'eflex/util/authenticated-route';
import { inject as service } from '@ember/service';

export default class SettingsUsersRoute extends AuthenticatedRoute {
  @service store;

  model() {
    return this.store.findAll('user');
  }

  setupController(controller, users) {
    Object.assign(controller, {
      users,
      selectedUser: null,
    });
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.showUserConfigPanel = false;
    }
  }
}
