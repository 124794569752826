import Model, { attr, belongsTo } from '@ember-data/model';

export default class ScheduleHistory extends Model {
  @attr('string') text;
  @attr('date') startDate;
  @attr('date') endDate;

  @belongsTo('schedule', { async: false, inverse: null }) schedule;
  @belongsTo('station', { async: false, inverse: null }) station;
}
