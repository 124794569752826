import Model, { belongsTo, attr } from '@ember-data/model';
import { getCaptionText } from 'eflex/util/translation-helper';

export default class CameraStatus extends Model {
  @attr('boolean') isConnected;
  @attr('string') ipAddress;
  @attr('number') imageRate;
  @attr('number') imageRateLastHour;
  @attr('number') rejectRate;
  @attr('number') rejectRateLastHour;
  @attr('string') taskId;
  @attr('string') stationId;
  @attr task;
  @attr station;

  @belongsTo('evisionImage', { async: false, inverse: null, embedded: true }) lastImage;

  get taskName() {
    return getCaptionText(this.task?.captions, this.currentUser);
  }

  get stationName() {
    return getCaptionText(this.station?.captions, this.currentUser);
  }

  get lastImageDateTime() {
    return this.lastImage?.dateCreated;
  }

  get lastImageFilePath() {
    return this.lastImage?.filePath;
  }

  get imageRateStyle() {
    if (this.imageRate === 0) {
      return 'badge badge-pill alert-danger';
    } else {
      return '';
    }
  }

  get rejectPercentage() {
    if (this.imageRate === 0 || this.rejectRate == null) {
      return 0;
    } else {
      return (this.rejectRate / this.imageRate) * 100;
    }
  }
}
