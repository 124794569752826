var _class, _descriptor, _descriptor2;
function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
import { tracked } from '@glimmer/tracking';
let Options = (_class = class Options {
  constructor(model, attribute, options = {}, defaultOptions = {}, globalOptions = {}) {
    _initializerDefineProperty(this, "model", _descriptor, this);
    _initializerDefineProperty(this, "attribute", _descriptor2, this);
    Object.assign(this, {
      model,
      attribute
    });
    for (const source of [globalOptions, defaultOptions, options]) {
      const props = Object.keys(source);
      for (const prop of props) {
        const descriptor = Object.getOwnPropertyDescriptor(source, prop);
        Object.defineProperty(this, prop, descriptor);
      }
    }
  }
  toObject() {
    // eslint-disable-next-line no-unused-vars
    const {
      model,
      attribute,
      ...options
    } = this;
    return options;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "model", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "attribute", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { Options as default };