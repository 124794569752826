import Component from '@glimmer/component';

export default class PaginationFooter extends Component {
  get pageMax() {
    return Math.ceil(this.args.recordCount / this.args.take);
  }

  get upperCount() {
    return this.args.page * this.args.take;
  }
}
