import ApplicationSerializer from 'eflex/serializers/application';

export default class JemConfigurationSerializer extends ApplicationSerializer {
  attrs = {
    station: {
      serialize: false,
      deserialize: false,
    },
  };
}
