import Hardware from 'eflex/models/hardware';
import { attr } from '@ember-data/model';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';

@buildValidations({
  name: [validator('presence', true)],
  url: [validator('presence', true)],
})
class LightGuide extends Hardware {
  @attr('string') url;

  @service intl;

  get isConfigured() {
    return isPresent(this.url);
  }

  get hardwareGroup() {
    return this.intl.t('lightGuide');
  }

  get isConnected() {
    return true;
  }
}

export default LightGuide;
