import { validator } from '@eflexsystems/ember-tracked-validations';
import { checkUniqueField } from 'eflex/util/validators';
import { getOwner } from '@ember/application';

export default {
  timeoutLength: [
    validator('number', {
      gte: 2,
      lte: 9_999_999,
      allowString: true,
      allowNone: false,
      get disabled() {
        return this.model.isSso;
      },
    }),
    validator('presence', {
      presence: true,
      get disabled() {
        return this.model.isSso;
      },
    }),
  ],
  userName: [
    validator('presence', true),
    validator('length', { min: 4 }),
    validator('inline', {
      validate(userName, options, user) {
        if (userName?.includes(' ')) {
          return getOwner(user).lookup('service:intl').t('errors.spaceInUsername');
        }
        return true;
      },
    }),
    validator('inline', {
      validate(userName, options, user, attribute) {
        const checked = getOwner(user).lookup('service:userRepo').users.filter(item => !item.isDeleted);
        return checkUniqueField(user, checked, attribute).result;
      },
    }),
  ],

  password: [
    validator('presence', {
      presence: true,
      get disabled() {
        return !this.model.isNew || this.model.isSso;
      },
    }),
  ],

  passwordConfirmation: [
    validator('confirmation', {
      get disabled() {
        return !this.model.isNew;
      },
      on: 'password',
    }),
  ],

  role: [validator('presence', true)],
};
