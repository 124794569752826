import ApplicationSerializer from 'eflex/serializers/application';

export default class JobSerializer extends ApplicationSerializer {
  attrs = {
    tasks: {
      deserialize: false,
      serialize: false,
    },
  };
}
