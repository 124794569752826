/**
 * A basic Fetch Handler which converts a request into a
 * `fetch` call presuming the response to be `json`.
 *
 * ```ts
 * import Fetch from '@ember-data/request/fetch';
 *
 * manager.use([Fetch]);
 * ```
 *
 * @module @ember-data/request/fetch
 * @main @ember-data/request/fetch
 */

const _fetch = typeof fetch !== 'undefined' ? fetch : typeof FastBoot !== 'undefined' ? FastBoot.require('node-fetch') : () => {
  throw new Error('No Fetch Implementation Found');
};
/**
 * A basic handler which converts a request into a
 * `fetch` call presuming the response to be `json`.
 *
 * ```ts
 * import Fetch from '@ember-data/request/fetch';
 *
 * manager.use([Fetch]);
 * ```
 *
 * @class Fetch
 * @public
 */
const Fetch = {
  async request(context) {
    const response = await _fetch(context.request.url, context.request);
    context.setResponse(response);

    // if we are an error, we will want to throw
    if (!response.ok || response.status >= 400) {
      const text = await response.text();
      let errorPayload;
      try {
        errorPayload = JSON.parse(text);
      } catch {
        // void;
      }
      const error = new Error(`[${response.status}] ${response.statusText} - ${response.url}`);
      error.content = errorPayload;
      throw error;
    } else {
      return response.json();
    }
  }
};
export { Fetch as default };