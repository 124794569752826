import { helper } from '@ember/component/helper';
import numbro from 'numbro';

export default helper(function formatBytes([bytes]) {
  if (bytes != null) {
    return numbro(bytes).format({
      output: 'byte',
      base: 'decimal',
      spaceSeparated: true,
      mantissa: 2,
    });
  }
});
