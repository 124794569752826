import Model, { attr } from '@ember-data/model';
import TaskStatuses from 'eflex/constants/task-statuses';

export default class ErrorProofingProcessDatum extends Model {
  @attr('number') status;
  @attr('string') state;
  @attr('boolean', { defaultValue: false }) changedState;

  get isPassed() {
    return this.status === TaskStatuses.GOOD;
  }

  get isFailed() {
    return this.status === TaskStatuses.REJECTED;
  }
}
