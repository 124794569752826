import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import TaskStatuses from 'eflex/constants/task-statuses';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { waitFor } from '@ember/test-waiters';

export default class JemTaskSpecificDisplayImageCapture extends Component {
  @tracked capturedWebcamImage;

  @service jemRepo;
  @service notifier;
  @service eventBus;

  get task() {
    return this.args.childStatus?.task;
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.capturedWebcamImage = null;
  }

  @task
  *onImageCaptured({ taskId, image }) {
    if (!this.args.childStatus?.isStarted || taskId !== this.task?.id) {
      return;
    }

    this.capturedWebcamImage = image;

    if (this.task?.triggerType === 'captureAdvance' && image != null) {
      yield this.onTaskComplete.perform(TaskStatuses.GOOD);
    }
  }

  @task({ drop: true })
  @waitFor
  *onTaskComplete(status) {
    let webcamImageUrl;
    if (status > TaskStatuses.STOPPED && this.capturedWebcamImage != null) {
      try {
        ({ webcamImageUrl } = yield this.jemRepo.uploadWebcamImage.perform(
          this.args.childStatus.parent.serialNumber,
          this.capturedWebcamImage,
          this.args.childStatus.task,
          status,
        ));
      } catch (e) {
        this.notifier.sendError(e);
        if (status !== TaskStatuses.REJECTED) {
          return;
        }
      }
    }

    if (webcamImageUrl != null) {
      this.args.childStatus.visionUrl = webcamImageUrl;
    }

    this.capturedWebcamImage = null;
    this.eventBus.trigger('clearCapturedImage');
    this.args.sendTaskComplete(status, this.args.childStatus);
  }

  @action
  onStartWebcamCapture() {
    if (this.capturedWebcamImage != null) {
      this.eventBus.trigger('clearCapturedImage');
    } else {
      this.eventBus.trigger('captureWebcamImage');
    }
  }
}
