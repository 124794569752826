// DO NOT CHANGE THE ORDER OF THESE KEYS
// its used for the order on the oee page
const OEE_STATES = {
  STATES: {
    RUNNING: 'running',
    FAULTED: 'faulted',
    STARVED: 'starved',
    BLOCKED: 'blocked',
    CHANGEOVER: 'changeover',
    BREAKS: 'breaks',
  },
};

OEE_STATES.BUTTONS = [
  {
    state: OEE_STATES.STATES.RUNNING,
    label: 'oee.running',
    background: 'running',
  },
  {
    state: OEE_STATES.STATES.FAULTED,
    label: 'oee.faulted',
    background: 'faulted',
  },
  {
    state: OEE_STATES.STATES.STARVED,
    label: 'oee.starved',
    background: 'default',
  },
  {
    state: OEE_STATES.STATES.BLOCKED,
    label: 'oee.blocked',
    background: 'default',
  },
  {
    state: OEE_STATES.STATES.CHANGEOVER,
    label: 'oee.changeover',
    background: 'default',
  },
  {
    state: OEE_STATES.STATES.BREAKS,
    label: 'oee.breaks',
    background: 'default',
  },
];

OEE_STATES.STATE_COLORS = new Map([
  ['running', '#30c100'],
  ['faulted', '#e43743'],
  ['starved', '#fada4a'],
  ['blocked', '#2d7fbe'],
  ['changeover', 'chocolate'],
  ['breaks', '#FF7F0E'],
]);

export default OEE_STATES;
