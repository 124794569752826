import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class TaskConfigTorque extends Component {
  @service taskConfigRepo;
  @service taskRepo;

  limitSources = [
    {
      label: 'useDeviceLimits',
      value: 'false',
    },
    {
      label: 'useEflexLimits',
      value: 'true',
    },
  ];

  get finalAngleDisabled() {
    return !this.args.taskConfig.finalAngleLimitsEnabled || this.allLimitsDisabled;
  }

  get finalTorqueDisabled() {
    return !this.args.taskConfig.finalTorqueLimitsEnabled || this.allLimitsDisabled;
  }

  get allLimitsDisabled() {
    return !this.args.task.torqueUseEflexLimits;
  }

  get currentLimitSource() {
    return this.limitSources.find(item => item.value === String(this.args.task.torqueUseEflexLimits));
  }

  @action
  setUseEflexLimits(value) {
    this.args.task.torqueUseEflexLimits = JSON.parse(value);
  }

  @action
  setBoltCount(val) {
    this.args.taskConfig.boltCount = val;

    this.taskRepo.removeEdhrMappingsBelowMaxBoltCount(this.args.task);
    this.taskConfigRepo.removeInvalidBoltAnimations(this.args.taskConfig);
  }
}
