import Model, { attr, belongsTo } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { isBlank, isEmpty, isPresent } from '@ember/utils';
import preciseTruncate from 'eflex/util/precise-truncate';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { tracked } from '@glimmer/tracking';
import { caption, arrayAttr } from 'eflex/decorators';
import { getOwner } from '@ember/application';

@buildValidations({
  value: [
    validator('number', {
      allowString: false,
      integer: false,
      allowBlank: true,
      get disabled() {
        return this.model.type !== 'float';
      },
    }),

    validator('inline', {
      validate(value, options, jemProcessDatum) {
        if (jemProcessDatum.required && isBlank(value)) {
          return getOwner(jemProcessDatum).lookup('service:intl').t('errors.inputIsRequired');
        }

        const lowerNum = parseFloat(jemProcessDatum.lowerLimit);
        const upperNum = parseFloat(jemProcessDatum.upperLimit);
        const valueNum = parseFloat(value);
        const valueOutOfRange = jemProcessDatum.type === 'float' && (valueNum < lowerNum || valueNum > upperNum);

        if (valueOutOfRange) {
          return getOwner(jemProcessDatum).lookup('service:intl').t('errors.invalidJemProcessDatum');
        }

        return true;
      },
    }),
  ],

})
class JemProcessDatum extends Model {
  @attr('string') type;
  @attr('string') jemProcessDataDef;
  @attr('string') jemProcessDataDefConfigId;
  @attr('number') lowerLimit;
  @attr('number') upperLimit;
  @attr('number', { defaultValue: 2 }) decimalLength;
  @attr value;
  @arrayAttr captions;
  @arrayAttr falseUnitCaptions;
  @arrayAttr unitCaptions;

  @caption name;
  @caption('falseUnitCaptions') falseUnit;
  @caption('unitCaptions') unit;

  @belongsTo('buildStatusChild', { inverse: 'processData', async: false }) buildStatusChild;

  @tracked wasFocused = false;

  @service store;
  @service intl;

  get unitName() {
    if (isEmpty(this.unitCaptions)) {
      return this.intl.t('yes');
    } else {
      return this.unit;
    }
  }

  get falseUnitName() {
    if (isEmpty(this.falseUnitCaptions)) {
      return this.intl.t('no');
    } else {
      return this.falseUnit;
    }
  }

  get required() {
    return this.jemProcessDataDefConfig?.required;
  }

  get timestamp() {
    return this.buildStatusChild?.timestamp;
  }

  get jemProcessDataDefConfig() {
    if (this.jemProcessDataDefConfigId != null) {
      return this.store.peekRecord('jemProcessDataDefConfig', this.jemProcessDataDefConfigId);
    } else {
      return null;
    }
  }

  get bitOptions() {
    return [
      {
        label: this.unitName,
        value: true,
      },
      {
        label: this.falseUnitName,
        value: false,
      },
    ];
  }

  get displayName() {
    const taskName = this.buildStatusChild.taskName;
    if (isPresent(taskName)) {
      return `${taskName} - ${this.name}`;
    } else {
      return this.name;
    }
  }

  get displayValue() {
    if (this.type === 'bit') {
      if (this.value === true || this.value === 'true') {
        return this.unitName;
      } else {
        return this.falseUnitName;
      }
    }

    let value;

    if (this.type === 'float' && this.value?.toFixed != null) {
      value = preciseTruncate(this.value, this.decimalLength);
    } else {
      value = this.value;
    }

    if (isPresent(value) && isPresent(this.unitCaptions)) {
      value = `${value} ${this.unitName}`;
    }

    return value;
  }
}

export default JemProcessDatum;
