import Model, { belongsTo, hasMany } from '@ember-data/model';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { copyable, arrayAttr, caption } from 'eflex/decorators';

@copyable
@buildValidations({
  name: [
    validator('presence', {
      presence: true,
      ignoreBlank: true,
    }),
  ],
})
class DecisionDef extends Model {
  @arrayAttr captions;
  @caption name;

  @belongsTo('task', { async: false, inverse: 'decisionDefs' }) task;
  @hasMany('decisionDefConfig', { async: false, inverse: 'decisionDef', cascadeDelete: true }) decisionDefConfigs;

  get errorIdentifier() {
    return this.task?.name;
  }

  get copyableOptions() {
    return {
      ignoreAttributes: new Set(['task', 'decisionDefConfigs']),
    };
  }
}

export default DecisionDef;
