import { validator } from '@eflexsystems/ember-tracked-validations';

export default {
  compareLocation: [
    validator('number', {
      gt: 0,
      allowString: true,
      allowNone: false,
    }),
  ],
};
