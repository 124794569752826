import Model, { attr } from '@ember-data/model';
import WieApproveRejectValidations from 'eflex/validations/work-instruction-approve-reject';
import WieSettings from 'eflex/constants/work-instructions/wie-settings';
import { inject as service } from '@ember/service';
import { buildValidations } from '@eflexsystems/ember-tracked-validations';

@buildValidations(WieApproveRejectValidations)
class WorkInstructionApproveReject extends Model {
  @attr('string') username;
  @attr('string') state;
  @attr('string') notes;

  @service userRepo;

  get displayUsername() {
    return this.userRepo.users.find(item => item.userName === this.username)?.displayUsername;
  }

  approve() {
    this.state = WieSettings.approvalStates.APPROVED;
  }

  reject() {
    this.state = WieSettings.approvalStates.REJECTED;
  }
}

export default WorkInstructionApproveReject;
