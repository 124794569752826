import { isBlank } from '@ember/utils';
import { isString, isNumber } from 'ramda-adjunct';
import { getOwner } from '@ember/application';

export const checkUniqueField = function (model, collection, field, fieldIsNumber = false) {
  const target = model[field];
  if (isBlank(target)) {
    return { result: true, duplicate: null };
  }

  let ownValue = target;

  if (isString(ownValue)) {
    ownValue = ownValue.trim();
  }

  if (fieldIsNumber) {
    ownValue = parseInt(ownValue);
  }

  const inUseItem = collection?.find((item) => {
    if (item === model) {
      return false;
    }

    let toCheck = item[field];

    if (isString(toCheck)) {
      toCheck = toCheck.trim();
    }

    if (fieldIsNumber) {
      toCheck = parseInt(toCheck);
    }
    return toCheck === ownValue;
  });

  if (inUseItem != null) {
    return {
      result: getOwner(model).lookup('service:intl').t('logs.nonUniqueFields'),
      duplicate: inUseItem,
    };
  } else {
    return {
      result: true,
      duplicate: null,
    };
  }
};

export const checkUniqueNumber = function (model, collection, field) {
  return checkUniqueField(model, collection, field, true).result;
};

export const rangeCorrectness = function (model, lowerLimit, upperLimit, errorMessage) {
  const lowerNum = parseFloat(lowerLimit);
  const upperNum = parseFloat(upperLimit);

  if (isNumber(lowerNum) && isNumber(upperNum) && lowerNum > upperNum) {
    return getOwner(model).lookup('service:intl').t(errorMessage);
  }
  return true;
};
