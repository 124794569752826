import LocationRepoBase from 'eflex/services/location-repo-base';
import { task } from 'ember-concurrency';
import getNextTreeOrder from 'eflex/util/get-next-tree-order';
import { waitFor } from '@ember/test-waiters';

export default class GroupRepoService extends LocationRepoBase {
  create(properties = {}) {
    properties.order = getNextTreeOrder(properties.parent.groups);
    return super.create('group', properties);
  }

  @task
  @waitFor
  *createAndSave(properties = {}) {
    const group = this.create(properties);
    yield group.save();
    return group;
  }
}
