import Service, { inject as service } from '@ember/service';
import { task as trackedTask } from 'ember-resources/util/ember-concurrency';
import { waitForProperty, task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';

export default class SystemConfigService extends Service {
  @service store;
  @service eflexAjax;
  @service webSocket;

  _systemConfigs = this.store.peekAll('systemConfiguration');

  oidcConfig = trackedTask(this, this.getOidcConfig, () => []);

  @task
  @waitFor
  *getOidcConfig() {
    yield waitForProperty(this.webSocket, 'isConnected', true);
    return yield this.eflexAjax.get.perform('oidcConfig');
  }

  get config() {
    return this._systemConfigs[0];
  }

  get evision() {
    return this.config?.evision;
  }

  get jem() {
    return this.config?.jem;
  }

  get wie() {
    return this.config?.wie;
  }

  getWieDefaults(tool) {
    return this.config?.wie?.editorDefaults[tool];
  }

  getWieTags(type) {
    return [...new Set(this.config?.wie?.[type])].toSorted();
  }

  get biUrl() {
    return this.config?.biUrl;
  }
}
