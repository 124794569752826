import OEE_STATES from 'eflex/constants/oee-states';

const formatStateColumnData = (cell) => {
  const { state } = cell.getData();
  const color = OEE_STATES.STATE_COLORS.get(state?.state) ?? state?.customButtonColor;

  Object.assign(cell.getElement().style, {
    backgroundColor: color,
    textAlign: 'center',
    color: '#22252c',
  });
  return state?.state?.toUpperCase();
};

export { formatStateColumnData };
