var _class, _descriptor;
function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
import { registerDestructor, isDestroying, isDestroyed } from '@ember/destroyable';
import { tracked } from '@glimmer/tracking';
let lastGlobalRef = null;
const buckets = new WeakMap();
const nodeDestructors = new WeakMap();
let FieldCell = (_class = class FieldCell {
  constructor() {
    _initializerDefineProperty(this, "value", _descriptor, this);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "value", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return null;
  }
})), _class);
export function setGlobalRef(value) {
  lastGlobalRef = value;
}
export function resolveGlobalRef() {
  return lastGlobalRef;
}
function createBucket() {
  return {
    bucket: {},
    keys: {},
    createTrackedCell(key) {
      if (!(key in this.keys)) {
        this.keys[key] = new FieldCell();
      }
    },
    get(name) {
      this.createTrackedCell(name);
      return this.bucket[name] || null;
    },
    dirtyTrackedCell(name) {
      this.createTrackedCell(name);
      const val = this.keys[name].value;
      this.keys[name].value = val;
    },
    getTracked(name) {
      this.createTrackedCell(name);
      return this.keys[name].value;
    },
    add(name, value) {
      this.createTrackedCell(name);
      this.keys[name].value = value;
      this.bucket[name] = value;
      if (!(name in this.notificationsFor)) {
        this.notificationsFor[name] = [];
      }
      this.notificationsFor[name].forEach(fn => fn());
    },
    addNotificationFor(name, fn) {
      if (!(name in this.notificationsFor)) {
        this.notificationsFor[name] = [];
      }
      this.notificationsFor[name].push(fn);
      return () => {
        this.notificationsFor[name] = this.notificationsFor[name].filter(cb => cb !== cb);
      };
    },
    notificationsFor: {}
  };
}
export function getNodeDestructors(node) {
  return nodeDestructors.get(node) || [];
}
export function registerNodeDestructor(node, cb) {
  if (!nodeDestructors.has(node)) {
    nodeDestructors.set(node, []);
  }
  nodeDestructors.get(node).push(cb);
}
export function unregisterNodeDestructor(node, cb) {
  const destructors = nodeDestructors.get(node) || [];
  nodeDestructors.set(node, destructors.filter(el => el !== cb));
}
export function bucketFor(rawCtx) {
  const ctx = rawCtx;
  if (!buckets.has(ctx)) {
    buckets.set(ctx, createBucket());
    if (isDestroyed(ctx) || isDestroying(ctx)) {
      try {
        return buckets.get(ctx);
      } finally {
        buckets.delete(ctx);
      }
    }
    registerDestructor(ctx, () => {
      buckets.delete(ctx);
    });
  }
  return buckets.get(ctx);
}
export function watchFor(name, bucketRef, cb) {
  const bucket = bucketFor(bucketRef);
  return bucket.addNotificationFor(name, cb);
}