import Model, { belongsTo, attr } from '@ember-data/model';
import { copyable } from 'eflex/decorators';

@copyable
class TriggerConfig extends Model {
  @attr('boolean', { defaultValue: true }) enabled;

  // trigger is an internal ember-data property name
  @belongsTo('station', { async: false, as: 'trigger-config', inverse: 'triggerConfigs' }) station;
  @belongsTo('oeeButton', { async: false, as: 'trigger-config', inverse: 'triggerConfigs' }) oeeButton;
  @belongsTo('taskConfig', { async: false, as: 'trigger-config', inverse: 'triggerConfigs' }) taskConfig;
  @belongsTo('trigger', {
    async: false,
    as: 'trigger-config',
    polymorphic: true,
    inverse: 'triggerConfigs',
  }) parentTrigger;

  get polymorphic() {
    return true;
  }

  get hardware() {
    return this.parentTrigger?.hardware;
  }

  get event() {
    return this.parentTrigger?.event;
  }

  get location() {
    return this.taskConfig ?? this.station ?? this.oeeButton;
  }

  get errorIdentifier() {
    return this.location?.errorIdentifier ?? this.location?.name;
  }

  get copyableOptions() {
    return {
      ignoreAttributes: new Set(['parentTrigger', 'taskConfig', 'station']),
      copyByReference: new Set(['hardwareIo', 'workInstruction']),
    };
  }
}

export default TriggerConfig;
