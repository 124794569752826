import { inject as service } from '@ember/service';
import Helper from '@ember/component/helper';
import cleanHelperArgs from 'eflex/util/clean-helper-args';

export default class TransitionToHelper extends Helper {
  @service router;

  compute(params, queryParams) {
    return (...extraArgs) => {
      extraArgs = cleanHelperArgs(extraArgs);

      if (queryParams) {
        extraArgs.push({ queryParams });
      }

      this.router.transitionTo(...params, ...extraArgs);
    };
  }
}
