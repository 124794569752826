import { inject as service } from '@ember/service';
import DecisionTypes from 'eflex/constants/tasks/decision-types';
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class TaskConfigDecision extends Component {
  @service decisionDefRepo;
  @service intl;

  decisionTypes = DecisionTypes;

  get showOptions() {
    return this.args.task.decisionType !== DecisionTypes.passFail;
  }

  @action
  addDecisionDef() {
    this.decisionDefRepo.createDecisionDefForTask(this.args.task);
  }

  @action
  selectDecisionType(decisionType) {
    if (decisionType === DecisionTypes.passFail) {
      this.args.task.decisionDefs
        .flatMap(decisionDef => decisionDef.decisionDefConfigs)
        .forEach(decisionDef => { decisionDef.deleteRecord(); });
      this.args.task.decisionDefs.forEach(decisionDef => { decisionDef.deleteRecord(); });

      this.decisionDefRepo.createDecisionDefForTask(this.args.task, {
        name: this.intl.t('pass'),
      });

      this.decisionDefRepo.createDecisionDefForTask(this.args.task, {
        name: this.intl.t('fail'),
      });

      this.args.task.decisionRequired = false;
    }

    this.args.task.decisionType = decisionType;
  }
}
