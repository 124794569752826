import { validator } from '@eflexsystems/ember-tracked-validations';
import moment from 'moment-timezone';
import { isEmpty, isPresent } from '@ember/utils';
import { getOwner } from '@ember/application';
import { pipe, join, map, filter, without, reject, prop, intersection } from 'ramda';
import { intoArray } from '@eflexsystems/ramda-helpers';

const checkRangeOverlap = function (start, end, startToCompare, endToCompare) {
  if (!startToCompare || !endToCompare) {
    return false;
  }

  return start.isBetween(startToCompare, endToCompare, null, '[)') ||
    end.isBetween(startToCompare, endToCompare, null, '(]') ||
    start.isBefore(startToCompare) && end.isAfter(endToCompare);
};

export default {
  endTime: [
    validator('inline', {
      get disabled() {
        return this.model.schedule.isArchived || !this.model.isTimeSet;
      },
      validate(endTime, options, scheduleDay) {
        const start = scheduleDay.startTimeWithTimezone;
        const end = scheduleDay.endTimeWithTimezone;

        if (scheduleDay.prevDay.passesMidnight && moment(scheduleDay.startTime).isBefore(scheduleDay.prevDay.endTime)) {
          return getOwner(scheduleDay).lookup('service:intl').t('schedules.daysOverlap', {
            overlappingSchedules: `${scheduleDay.schedule.text} - ${scheduleDay.prevDay.dayName}`,
          });
        }

        if (scheduleDay.schedule.isArchived) {
          return true;
        }

        const stationIds = scheduleDay.schedule.stations.map(item => item.id);
        const scheduleStart = moment(scheduleDay.schedule.startDate);
        const scheduleEnd = moment(scheduleDay.schedule.endDate);

        const schedules = getOwner(scheduleDay).lookup('service:scheduleRepo').schedules;
        const overlappingSchedules = pipe(
          intoArray(
            without([scheduleDay.schedule]),
            reject(prop('isArchived')),
            filter(schedule =>
              intersection(stationIds, schedule.stations.map(item => item.id)).length,
            ),
            filter(schedule =>
              scheduleStart.isBetween(schedule.startDate, schedule.endDate, null, '[)') ||
                end.isBetween(schedule.startDate, schedule.endDate, null, '(]') ||
                scheduleStart.isBefore(schedule.startDate) && scheduleEnd.isAfter(schedule.endDate),
            ),
            map(schedule => {
              const overlaps = [];
              const day = schedule.days.find(item => item.day === scheduleDay.day);

              if (checkRangeOverlap(start, end, day.startTimeWithTimezone, day.endTimeWithTimezone)) {
                overlaps.push(`${schedule.text} - ${day.dayName}`);
              }

              if (checkRangeOverlap(start, end, day.prevStart, day.prevEnd)) {
                overlaps.push(`${schedule.text} - ${day.prevDay.dayName}`);
              }

              if (checkRangeOverlap(start, end, day.nextStart, day.nextEnd)) {
                overlaps.push(`${schedule.text} - ${day.nextDay.dayName}`);
              }

              return overlaps.join(', ');
            }),
            reject((overlap) => isEmpty(overlap)),
          ),
          join(', '),
        )(schedules);

        if (isPresent(overlappingSchedules)) {
          return getOwner(scheduleDay).lookup('service:intl').t('schedules.daysOverlap', { overlappingSchedules });
        }

        return true;
      },
    }),
  ],
};
