import ApplicationSerializer from 'eflex/serializers/application';

export default class SpindleConfigSerializer extends ApplicationSerializer {
  attrs = {
    taskConfig: {
      serialize: false,
      deserialize: false,
    },
  };
}
