import Model from '@ember-data/model';

Object.defineProperty(Model.prototype, 'isDirty', {
  get() {
    return this.hasDirtyAttributes || this.isDeleted;
  },
});

Object.defineProperty(Model.prototype, 'isInvalid', {
  configurable: false,
  get() {
    return this.validations?.isInvalid ?? false;
  },
});
