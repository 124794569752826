import Model, { belongsTo } from '@ember-data/model';

export default class WieConfigEditorDefaults extends Model {
  @belongsTo('wieConfig/normal', { inverse: null, async: false, embedded: true }) normal;
  @belongsTo('wieConfig/text', { inverse: null, async: false, embedded: true }) text;
  @belongsTo('wieConfig/lines', { inverse: null, async: false, embedded: true }) lines;
  @belongsTo('wieConfig/shape', { inverse: null, async: false, embedded: true }) shape;
  @belongsTo('wieConfig/image', { inverse: null, async: false, embedded: true }) image;
  @belongsTo('wieConfig/icons', { inverse: null, async: false, embedded: true }) icons;

  get isDirty() {
    return super.isDirty ||
      this.normal.isDirty ||
      this.text.isDirty ||
      this.lines.isDirty ||
      this.shape.isDirty ||
      this.image.isDirty ||
      this.icons.isDirty;
  }
}
