import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class QuantitySelect extends Component {
  @tracked count = 1;

  get max() {
    return this.args.max ?? 99;
  }

  get uppercase() {
    return this.args.uppercase ?? true;
  }

  get isSmall() {
    return this.args.size === 'sm';
  }

  @action
  submit() {
    this.args.onSubmit?.(this.count);
    this.count = 1;
  }

  @action
  increment() {
    if (this.count < this.max) {
      this.count = this.count + 1;
    }
  }

  @action
  decrement() {
    if (this.count > 1) {
      this.count = this.count - 1;
    }
  }
}
