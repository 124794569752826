import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { task, waitForProperty } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';

export default class InputsGroupObject extends Component {
  @service imageEditor;

  @tracked grouped = false;

  @task
  @waitFor
  *onDidInsert() {
    yield waitForProperty(this.imageEditor, 'canvas');
    this.#checkGrouped();

    this.imageEditor
      .on('selection:created', this.#checkGrouped)
      .on('selection:updated', this.#checkGrouped);
  }

  @action
  setGrouped(grouped) {
    const selectedObjects = this.imageEditor.canvas.getActiveObject();
    if (selectedObjects == null) {
      return;
    }

    this.grouped = grouped;

    if (grouped) {
      this.imageEditor.groupSelection();
    } else {
      this.imageEditor.ungroupSelection();
    }
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.imageEditor
      .off('selection:created', this.#checkGrouped)
      .off('selection:updated', this.#checkGrouped);
  }

  #checkGrouped = () => {
    const selectedObjects = this.imageEditor.canvas.getActiveObject();
    if (selectedObjects == null) {
      return;
    }

    this.grouped = selectedObjects.type === 'group';
  };
}
