const AnimationTypes = {
  SHOW: 'show',
  HIDE: 'hide',
  FLASH: 'flash',
  RESET: 'reset',
};

const AnimationTypeValues = [AnimationTypes.SHOW, AnimationTypes.HIDE, AnimationTypes.FLASH, AnimationTypes.RESET];

export { AnimationTypes, AnimationTypeValues };
export default AnimationTypes;
