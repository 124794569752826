import TriggerConfig from 'eflex/models/trigger-config';
import { belongsTo, hasMany, attr } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { isBelongsToRefDirty, rollbackBelongsTo } from 'eflex/util/relationship-helpers';

export default class WorkInstructionHardwareTriggerConfig extends TriggerConfig {
  @service workInstructionRepo;

  @attr('string') externalImageUrl;
  @attr('number') page;

  @belongsTo('workInstruction', { async: false, inverse: 'workInstructionHardwareTriggerConfigs' }) workInstruction;

  @hasMany('workInstructionAnimation', {
    async: false,
    inverse: 'workInstructionHardwareTriggerConfig',
    embedded: true,
  }) animations;

  get isDirty() {
    return super.isDirty ||
      isBelongsToRefDirty(this, 'workInstruction') ||
      this.animations.some(item => item.isDirty);
  }

  get hasExternalUrl() {
    return isPresent(this.externalImageUrl);
  }

  get fullExternalUrl() {
    if (this.externalImageUrl == null) {
      return null;
    } else if (this.page == null) {
      return this.externalImageUrl;
    } else {
      return `${this.externalImageUrl}#page=${this.page}`;
    }
  }

  copyAnimations(animations) {
    for (const animation of animations) {
      const copy = animation.copy();
      this.animations.push(copy);
    }
  }

  setExternalImageUrl(url, page) {
    this.workInstructionRepo.setExternalImageUrl(this, url, page);
  }

  setWorkInstruction(workInstruction) {
    this.workInstructionRepo.setWorkInstruction(this, workInstruction);
  }

  deleteJemImage() {
    this.workInstructionRepo.deleteJemImage(this);
  }

  rollbackAttributes() {
    rollbackBelongsTo(this, 'workInstruction');
    super.rollbackAttributes();
  }
}
