import AuthenticatedRoute from 'eflex/util/authenticated-route';
import { inject as service } from '@ember/service';

export default class JemIndexRoute extends AuthenticatedRoute {
  @service router;
  @service store;

  async beforeModel() {
    await super.beforeModel(...arguments);
    let station = this.controllerFor('jem.stations').station;

    if (!station) {
      const stationId = localStorage.getItem('jemSelectedTreeItem');
      station = stationId != null ? this.store.peekRecord('station', stationId) : null;
    }

    if (station) {
      this.router.transitionTo('jem.stations', station.id);
    } else {
      localStorage.removeItem('jemSelectedTreeItem');
    }
  }
}
