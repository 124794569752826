const bomSources = {
  rest: 'restBomSource',
  restPull: 'restPullBomSource',
  barcode: 'barcodeBomSource',
  oracleDellBoomi: 'oracleDellBoomiBomSource',
  gepics: 'gepicsBomSource',
};

const bomSourceModelNames = Object.values(bomSources);

const bomSourceDropdownOptions = [
  {
    id: bomSources.rest,
    translationKey: 'bomSources.rest',
  },
  {
    id: bomSources.restPull,
    translationKey: 'bomSources.restPull',
  },
  {
    id: bomSources.barcode,
    translationKey: 'bomSources.barcode',
  },
  {
    id: bomSources.oracleDellBoomi,
    translationKey: 'bomSources.oracleDellBoomi',
  },
  {
    id: bomSources.gepics,
    translationKey: 'bomSources.gepics',
  },
];

const BOM_SOURCE_ALLOWS_LOOKUP_TYPES = new Set(['oracleDellBoomiBomSource', 'restPullBomSource']);

export default bomSources;
export {
  bomSourceDropdownOptions,
  bomSourceModelNames,
  BOM_SOURCE_ALLOWS_LOOKUP_TYPES,
};
