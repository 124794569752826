import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';

export default class JemTaskSpecificDisplayBarcodeDetails extends Component {
  get showInput() {
    return this.args.childStatus?.isStarted && !this.args.task.hideBarcodeInput;
  }

  get barcodeStrings() {
    if (this.args.task.passThrough) {
      return [this.args.childStatus?.getRawBuildDataValue(this.args.taskConfig)];
    }

    return this.args
      .taskConfig
      ?.strings
      .filter(item => item.enabled)
      .map(item => item.barcodeString) ?? [];
  }

  get barcodeStringsDisplay() {
    const barcodeStrings = this.barcodeStrings;

    if (barcodeStrings.some((string) => isEmpty(string))) {
      return null;
    }

    return barcodeStrings.slice(0, 3).join(', ');
  }

  @action
  onSubmit(barcode) {
    if (!this.showInput || this.args.disabled) {
      return;
    }

    this.args.onSubmit(barcode);
  }
}
