import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { task, waitForProperty, waitForQueue } from 'ember-concurrency';
import WieSettings from 'eflex/constants/work-instructions/wie-settings';
import { EditorModes, EflexObjTypes, ObjectTypesToEditorModes } from 'eflex/constants/work-instructions/tool-props';
import { decOpacityToHex } from 'eflex/util/opacity-helper';
import { generateStrokeDashArray } from 'eflex/util/stroke-helper';
import { getCanvasCoordsForClick, removeSelectedText, pasteTextAtLocation } from 'eflex/util/fabric-helpers';
import moment from 'moment-timezone';
import $ from 'jquery';
import { keyResponder, onKey } from 'ember-keyboard';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import { compact } from 'ramda-adjunct';

const INVALID_PASTE = 'INVALID_PASTE';
const TEST_ELEMENT = 'TEST_ELEMENT';
const CLIPBOARD_CANVAS_IDENTIFIER = 'CANVAS';
const CROP_VISIBLE_TYPES = new Set(['image', EflexObjTypes.CROPBOX]);
const TEXT_EVENTS = new Set(['TEXTAREA', 'INPUT']);
const DESELECT_SKIP_TARGETS = new Set(['div', 'h4', 'label']);

class ToolsDisabled {
  @tracked arrange = true;
  @tracked group = false;
}

@keyResponder
class WorkInstructionsWorkInstructionController extends Controller {
  @service notifier;
  @service imageEditor;
  @service store;
  @service workInstructionRepo;
  @service currentUser;
  @service workInstructionImageRepo;
  @service systemConfig;
  @service eventBus;
  @service router;
  @service validationErrorNotifier;
  @service intl;

  @tracked workInstruction;
  @tracked currentLibraryView = WieSettings.libraryViews.THUMB;
  @tracked currentDrawingMode = EditorModes.NORMAL;
  @tracked workInstructionToSave;
  @tracked toolsDisabled = new ToolsDisabled();
  @tracked selectedType;
  @tracked selectedFolder;
  @tracked enableGrid = false;
  @tracked downloadQueue = [];
  @tracked showSaveModal = false;
  @tracked autoVersion;
  @tracked showChangeNotificationModal = false;
  @tracked canCopy = false;
  @tracked showContextMenu = false;
  @tracked contextMenuX = 0;
  @tracked contextMenuY = 0;
  @tracked pasteX = 0;
  @tracked pasteY = 0;
  @tracked currentDrawerTab = 'tool-properties';

  editorModes = EditorModes;
  supportedFileTypes = WieSettings.supportedFileTypes;
  controlKey = /Mac/.test(navigator.userAgent) ? 'cmd' : 'ctrl';

  get isInvalid() {
    return this.workInstructionToSave?.isInvalid ?? false;
  }

  get isListView() {
    return this.currentLibraryView === WieSettings.libraryViews.LIST;
  }

  get isLibraryFullScreen() {
    return this.isListView || this.currentLibraryView === WieSettings.libraryViews.APPROVAL;
  }

  get isDirty() {
    return this.workInstructionToSave?.isDirty ?? false;
  }

  get canEdit() {
    return this.workInstructionRepo.userCanEdit(this.currentUser, this.workInstruction);
  }

  get canCreate() {
    return this.workInstructionRepo.userCanCreate(this.currentUser, this.selectedFolder);
  }

  get canCreateFolder() {
    return this.workInstructionRepo.userHasRootFolderEditor(this.currentUser);
  }

  get writeableFolders() {
    return this.workInstructionRepo.getWritableFolders(this.currentUser);
  }

  get defaultFolder() {
    if (this.canCreateFolder) {
      return this.intl.t('none');
    } else {
      return null;
    }
  }

  get contextMenuTop() {
    if (!this.showContextMenu) {
      // eslint-disable-next-line getter-return
      return;
    }

    return `${this.contextMenuY}px`;
  }

  get contextMenuLeft() {
    if (!this.showContextMenu) {
      // eslint-disable-next-line getter-return
      return;
    }

    return `${this.contextMenuX}px`;
  }

  get visibleMode() {
    if (this.selectedType == null) {
      return this.currentDrawingMode;
    }

    if (this.currentDrawingMode === EditorModes.CROP && CROP_VISIBLE_TYPES.has(this.selectedType)) {
      return EditorModes.CROP;
    }

    const mode = Object.keys(ObjectTypesToEditorModes).find(editorMode =>
      ObjectTypesToEditorModes[editorMode].has(this.selectedType),
    ) ?? EditorModes.NORMAL;

    if (mode === EditorModes.SELECTION && isEmpty(this.imageEditor.canvas.getActiveObjects())) {
      return EditorModes.NORMAL;
    }

    return mode;
  }

  @task
  @waitFor
  *initEditor() {
    Object.assign(this, {
      keyboardActivated: true,
      workInstructionToSave: this.workInstruction,
      selectedFolder: this.workInstruction.folder,
    });

    yield waitForQueue('afterRender');

    /* eslint-disable-next-line ember/no-jquery */
    $(window)
      .on('copy.windowCopy', (event) => {
        this._handleCopy.perform(event);
      })
      .on('cut.windowCut', (event) => {
        this._handleCut.perform(event);
      })
      .on('paste.windowPaste', (event) => {
        this._handlePaste.perform(event);
      });

    const options = {
      width: this.workInstruction.width,
      height: this.workInstruction.height,
    };

    if (this.workInstruction?.isNew) {
      const config = this.systemConfig.getWieDefaults('normal');

      options.backgroundColor = config.background;
    }

    this.imageEditor.initialize('work-instruction-canvas', options);

    this.imageEditor
      .on('selection:cleared', this.#selectionCleared)
      .on('wie:history:modified', this.#wieHistoryModified)
      .on('wie:canvas:loaded', this.#wieCanvasLoaded)
      .on('wie:crop:applied', this.#wieCropApplied)
      .on('object:added', this.#objectChange)
      .on('object:removed', this.#objectChange)
      .on('selection:created', this.#selectionChange)
      .on('selection:updated', this.#selectionChange);

    yield this.loadCanvasFromModel.perform();
    this.#disableGrid();
  }

  @task
  @waitFor
  *loadCanvasFromModel() {
    const savedCanvas = this.workInstruction?.canvas;
    if (savedCanvas != null) {
      yield this.imageEditor.loadCanvasJson.perform(savedCanvas);
    } else {
      this.imageEditor.enableHistory();
    }
  }

  @action
  onDeleteWorkInstructions(currentWorkInstructionIsDeleted) {
    if (!currentWorkInstructionIsDeleted) {
      return;
    }

    this.workInstruction = null;
    this.workInstructionToSave = null;
    this.router.transitionTo('workInstructions');
  }

  @task
  @waitFor
  *downloadInstructions(fileType) {
    yield this.workInstructionRepo.downloadInstructions.perform(this.downloadQueue, fileType);
  }

  @task
  @waitFor
  *_addImage(file) {
    const config = this.systemConfig.getWieDefaults('image');

    const options = {
      opacity: config.opacity / 100,
      stroke: config.stroke?.color + decOpacityToHex(config.stroke?.opacity),
      strokeDashArray: generateStrokeDashArray(config.stroke?.style, config.stroke?.width),
      strokeWidth: config.stroke?.width,
      eflex: {
        fileName: file.name,
        strokeDashArrayStyle: config.stroke?.style,
      },
    };

    const { blobToDataURL } = yield import('blob-util');
    const dataUrl = yield blobToDataURL(file);
    yield this.imageEditor.addImage.perform(dataUrl, options);
  }

  #disableGrid() {
    this.imageEditor.disableGrid();
    this.enableGrid = false;
  }

  @task
  @waitFor
  *toggleGrid(val) {
    this.enableGrid = val;
    yield this.imageEditor.toggleGrid.perform();
  }

  @task
  @waitFor
  *saveCanvasAs() {
    if (this.workInstructionToSave?.folder != null) {
      this.selectedFolder = this.workInstructionToSave.folder;
    }

    if (this.workInstruction?.isDirty && !this.workInstruction?.isNew) {
      this.workInstruction.rollbackAttributes();
    }

    yield this.save.perform(true, {
      deployedName: null,
      deployedCanvas: null,
      deployedImage: null,
      deployedImageThumb: null,
    });
  }

  @task({ drop: true })
  @waitFor
  *save(fromSaveAs = false, additionalProperties = {}) {
    this.setEditorMode(EditorModes.NORMAL, true);

    if (!this.isInvalid) {
      const dupeName = yield this.store.queryRecord('workInstruction', {
        ids: [this.workInstructionToSave.id],
        name: this.workInstructionToSave.name,
      });

      if (dupeName) {
        this.notifier.sendError('workInstruction.duplicateName', { name: this.workInstructionToSave.name });
        return;
      }
    }

    this.showSaveModal = false;

    yield this.imageEditor.saveCanvasImages.perform(fromSaveAs);
    this.#disableGrid();

    Object.assign(this.workInstructionToSave, {
      autoVersion: this.autoVersion ?? moment().format('YYYYMMDDHHmmss'),
      canvas: this.imageEditor.getCanvasJson(),
      width: this.imageEditor.getCanvasWidth(),
      height: this.imageEditor.getCanvasHeight(),
      displayImageData: this.imageEditor.getCanvasImageDataUrl(),
      approvalRequested: false,
      approvalsRejects: [],
      ...additionalProperties,
    });

    if (this.isInvalid) {
      this.validationErrorNotifier.sendErrors(this.workInstructionToSave);
      return;
    }

    yield this.workInstructionRepo.saveWithImageData.perform(this.workInstructionToSave);

    Object.assign(this, {
      workInstruction: this.workInstructionToSave,
      autoVersion: null,
    });

    if (this.workInstruction.hasAssignedLocations && this.systemConfig.jem?.changeNotifications) {
      this.showChangeNotificationModal = true;
      yield waitForProperty(this, 'showChangeNotificationModal', false);
    }

    if (!this.workInstruction?.isNew && fromSaveAs) {
      this.loadWorkInstruction(this.workInstruction);
    }
  }

  @task
  @waitFor
  *saveDefault() {
    yield this.systemConfig.config.save();
    this.notifier.sendSuccess('imageEditor.makeDefaultSaved');
  }

  @action
  rollback() {
    if (!this.workInstruction?.isDirty || this.workInstruction?.isDestroyed) {
      return;
    }

    this.workInstruction.rollbackAttributes();

    if (this.workInstruction.isDeleted) {
      if (this.workInstruction === this.workInstructionToSave) {
        this.workInstructionToSave = null;
      }
      this.workInstruction = null;
    }
  }

  @action
  startSaveCanvasAs() {
    this.setEditorMode(EditorModes.NORMAL, true);

    if (!this.workInstruction?.isNew) {
      this.workInstructionToSave = this.workInstruction.copy(true);
      this.workInstructionToSave.folder = this.selectedFolder;
    }

    Object.assign(this, {
      autoVersion: moment().format('YYYYMMDDHHmmss'),
      showSaveModal: true,
    });
  }

  // NOTE: Any browser copy or cut event needs to set the flag
  // so a proper determination can be made when pasting. With image
  // uploads the initial object added is of input but type checkbox
  // we still want to copy and paste so its not treated same as
  // the input or textarea
  @task
  @waitFor
  *_copyOrCut(event, isCutting = false) {
    let breakExecution = false;
    const activeObject = this.imageEditor.canvas?.getActiveObject();

    if (TEXT_EVENTS.has(event?.target.tagName) && event?.target.type !== 'checkbox') {
      this.imageEditor.clearClipboard();
      breakExecution = true;
    } else if (activeObject?.type === 'textbox' && activeObject?.isEditing) {
      const text = activeObject.getSelectedText();

      if (event != null) {
        event.originalEvent.clipboardData.setData('text', text);
        event.preventDefault();
      } else {
        yield navigator.clipboard.writeText(text);

        if (isCutting) {
          removeSelectedText(activeObject);
          this.imageEditor.canvas.renderAll();
        }

        breakExecution = true;
      }
    }

    return breakExecution;
  }

  @task
  @waitFor
  *_handleCopy(event) {
    if (yield this._copyOrCut.perform(event)) {
      return;
    }

    if (event?.target.tagName !== TEST_ELEMENT) {
      yield this.imageEditor.copy.perform();
    }
  }

  @task
  @waitFor
  *_handleCut(event) {
    if (yield this._copyOrCut.perform(event, true)) {
      return;
    }

    if (event?.target.tagName !== TEST_ELEMENT) {
      yield this.imageEditor.cut.perform();
    }
  }

  @task
  @waitFor
  *_handlePaste(event, x = null, y = null) {
    const activeObject = this.imageEditor.canvas?.getActiveObject();

    if (event != null) {
      return yield this._handlePasteEvent.perform(event);
    } else if (activeObject?.type === 'textbox' && activeObject?.isEditing) {
      const clipboardText = yield navigator.clipboard.readText();
      if (clipboardText?.length < 1) {
        return;
      }
      pasteTextAtLocation(activeObject, clipboardText, activeObject.selectionStart);
      this.imageEditor.canvas.renderAll();
    } else {
      yield this.imageEditor.paste.perform(x, y);
    }
  }

  @task
  @waitFor
  *_handlePasteEvent(event) {
    if (TEXT_EVENTS.has(event.target.tagName) && event.target.type !== 'checkbox') {
      return INVALID_PASTE;
    }

    event.preventDefault();
    event.stopPropagation();

    const pastedItem = [...event.originalEvent.clipboardData.items].find(
      (item) => item.type.includes('image') || item.type === 'text/plain',
    );

    if (pastedItem == null) {
      return;
    }

    if (pastedItem.type === 'text/plain') {
      const pastedString = yield new Promise((resolve) => { pastedItem.getAsString(resolve); });

      if (pastedString !== CLIPBOARD_CANVAS_IDENTIFIER) {
        return;
      }

      yield this.imageEditor.paste.perform();
    } else {
      const file = pastedItem.getAsFile();

      if (!this.workInstructionImageRepo.fileAllowed(file)) {
        return;
      }

      yield this._addImage.perform(file);
    }
  }

  @task
  @waitFor
  *undo() {
    yield this.imageEditor.undo.perform();
    this.setEditorMode(EditorModes.NORMAL, true);
  }

  @task
  @waitFor
  *redo() {
    yield this.imageEditor.redo.perform();
    this.setEditorMode(EditorModes.NORMAL, true);
  }

  @task
  @waitFor
  *refreshInstruction() {
    yield this.store.findRecord('workInstruction', this.workInstruction.id, { reload: true });
    yield this.loadCanvasFromModel.perform();
  }

  @task
  @waitFor
  *onAddImage([file]) {
    if (file == null || !WieSettings.supportedFileTypes.includes(file.type)) {
      this.notifier.sendError('plant.station.jem.wrongImageFormat');
      return;
    }

    if (file.size > WieSettings.maxFileSize) {
      this.notifier.sendError('plant.station.jem.fileTooLarge');
      return;
    }

    yield this._addImage.perform(file);
  }

  @task
  @waitFor
  *copy() {
    this.showContextMenu = false;
    yield this._handleCopy.perform();
  }

  @task
  @waitFor
  *paste() {
    this.showContextMenu = false;
    yield this._handlePaste.perform(null, this.pasteX, this.pasteY);
  }

  @task
  @waitFor
  *cut() {
    this.showContextMenu = false;
    yield this._handleCut.perform();
  }

  @onKey('Delete')
  @onKey('Backspace')
  deleteKey() {
    this.imageEditor.removeSelectedObject();
  }

  @onKey('ArrowUp')
  upKey() {
    if (this.currentDrawingMode === EditorModes.CROP) {
      return;
    }

    this.imageEditor.moveActiveObject('y', -1);
  }

  @onKey('ArrowDown')
  downKey() {
    if (this.currentDrawingMode === EditorModes.CROP) {
      return;
    }

    this.imageEditor.moveActiveObject('y', 1);
  }

  @onKey('ArrowRight')
  rightKey() {
    if (this.currentDrawingMode === EditorModes.CROP) {
      return;
    }

    this.imageEditor.moveActiveObject('x', 1);
  }

  @onKey('ArrowLeft')
  leftKey() {
    if (this.currentDrawingMode === EditorModes.CROP) {
      return;
    }

    this.imageEditor.moveActiveObject('x', -1);
  }

  @action
  loadWorkInstruction(workInstruction) {
    this.router.transitionTo('workInstructions.workInstruction', workInstruction.id);
  }

  @action
  remove() {
    this.imageEditor.removeSelectedObject(false);
  }

  @action
  cancelSave() {
    Object.assign(this, {
      workInstructionToSave: this.workInstruction,
      showSaveModal: false,
    });
  }

  @action
  contextMenu(event) {
    if (event.target.tagName !== 'CANVAS') {
      return;
    }
    event.preventDefault();

    const { x, y } = getCanvasCoordsForClick(this.imageEditor.canvas, event);

    this.imageEditor.activateObjectByPoint(x, y);

    Object.assign(this, {
      canCopy: this.imageEditor.canvas.getActiveObject() != null,
      showContextMenu: true,
      contextMenuX: event.x,
      contextMenuY: event.y,
      pasteX: x,
      pasteY: y,
    });
  }

  @action
  deselectWIEObjects(e) {
    if (this.isLibraryFullScreen) {
      this.showContextMenu = false;
      return;
    }

    const skipTargets = [
      // eslint-disable-next-line ember/no-jquery
      $(e?.target).parents('.property-drawer')?.length,
      // eslint-disable-next-line ember/no-jquery
      $(e?.target).parents('.library-card')?.length,
      !DESELECT_SKIP_TARGETS.has(e?.target.tagName?.toLowerCase()),
    ];

    if (!isEmpty(compact(skipTargets))) {
      this.showContextMenu = false;
      return;
    }

    this.showContextMenu = false;
    this.setEditorMode(EditorModes.NORMAL, true);
  }

  @action
  objectCreated(obj) {
    this.setEditorMode(EditorModes.NORMAL, true);
    this.imageEditor.canvas.setActiveObject(obj);
  }

  @action
  onLockChange(bool) {
    const disabled = this.imageEditor.cannotArrange() || bool;
    this.toolsDisabled.arrange = disabled;
  }

  @action
  onPanelTabActive(type) {
    this.currentDrawerTab = type;
    if (type === 'tool-properties' || type === 'files') {
      this.currentLibraryView = WieSettings.libraryViews.THUMB;
    }
  }

  // service hooks start
  #selectionCleared = () => {
    this.selectedType = null;
    this.eventBus.trigger('updateSelectedTool');
  };

  #selectionChange = () => {
    const obj = this.imageEditor.canvas.getActiveObject();
    if (obj == null || obj.eflex?.childObject != null) {
      return;
    }

    this.selectedType = obj.eflex?.type ?? obj.type;

    const disabledGroup = obj._objects?.reduce((acc, _obj) => {
      if (_obj.eflex?.dynamicObjectName?.length > 0 || this.imageEditor.isLocked(_obj)) {
        acc++;
      }
      return acc;
    }, 0);

    const disabledArrange = this.imageEditor.cannotArrange() || this.imageEditor.isLocked(obj);

    Object.assign(this.toolsDisabled, {
      arrange: disabledArrange,
      group: disabledGroup,
    });

    if (this.currentDrawingMode === EditorModes.CROP && !CROP_VISIBLE_TYPES.has(this.selectedType)) {
      this.setEditorMode(EditorModes.NORMAL, false);
    }

    this.currentDrawerTab = 'tool-properties';
    this.eventBus.trigger('updateSelectedTool');
    this.imageEditor.canvas.renderAll();
  };

  #objectChange = () => {
    this.toolsDisabled.arrange = this.imageEditor.cannotArrange();
  };

  #wieCanvasLoaded = () => {
    this.toolsDisabled.arrage = this.imageEditor.cannotArrange;
    this.currentDrawerTab = 'tool-properties';
  };

  #wieHistoryModified = () => {
    this.workInstruction.canvas = this.imageEditor.getCanvasJson();
  };

  #wieCropApplied = (image) => {
    this.setEditorMode(EditorModes.NORMAL, true);
    this.imageEditor.canvas.setActiveObject(image);
  };
  // service hooks start

  cleanup() {
    // eslint-disable-next-line ember/no-jquery
    $(window)
      .off('.windowCopy')
      .off('.windowPaste')
      .off('.windowCut');

    this.imageEditor
      .off('selection:cleared', this.#selectionCleared)
      .off('wie:history:modified', this.#wieHistoryModified)
      .off('wie:canvas:loaded', this.#wieCanvasLoaded)
      .off('wie:crop:applied', this.#wieCropApplied)
      .off('object:added', this.#objectChange)
      .off('object:removed', this.#objectChange)
      .off('selection:created', this.#selectionChange)
      .off('selection:updated', this.#selectionChange);

    this.imageEditor.cleanup();
    this.workInstruction?.rollbackAttributes();

    Object.assign(this, {
      currentLibraryView: WieSettings.libraryViews.THUMB,
      keyboardActivated: false,
      currentDrawingMode: EditorModes.NORMAL,
      selectedType: null,
      selectedFolder: null,
      enableGrid: false,
      downloadQueue: [],
      showSaveModal: false,
      autoVersion: null,
      showChangeNotificationModal: false,
      canCopy: false,
      showContextMenu: false,
      contextMenuX: 0,
      contextMenuY: 0,
      pasteX: 0,
      pasteY: 0,
      workInstruction: null,
      workInstructionToSave: null,
      currentDrawerTab: 'tool-properties',
    });
  }

  @action
  setEditorMode(mode, discardActive) {
    this.currentDrawingMode = mode;

    switch (mode) {
      case EditorModes.NORMAL: {
        this.imageEditor.startNormalMode(discardActive);
        break;
      }
      case EditorModes.LINES: {
        this.imageEditor.startFreeDrawingMode();
        break;
      }
      case EditorModes.TEXT: {
        this.imageEditor.startTextMode();
        break;
      }
      case EditorModes.SHAPE:
      case EditorModes.ICONS: {
        this.imageEditor.startIconsOrShapeMode();
        break;
      }
      case EditorModes.CROP: {
        if (this.selectedType !== 'image') {
          this.imageEditor.startNormalMode(discardActive);
        }
        break;
      }
      default: {
        throw new Error('Unknown editor mode.');
      }
    }

    this.currentDrawerTab = 'tool-properties';
  }

  @action
  onNew() {
    this.router.transitionTo('workInstructions.index', {
      queryParams: {
        showGetStartedModal: true,
        folderId: this.selectedFolder?.id,
      },
    });
  }
}

export default WorkInstructionsWorkInstructionController;
