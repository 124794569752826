import AuthenticatedRoute from 'eflex/util/authenticated-route';
import { inject as service } from '@ember/service';

export default class RepairRoute extends AuthenticatedRoute {
  @service store;

  model() {
    return this.store.findAll('jemContainment');
  }

  setupController(controller, jemContainments) {
    controller.jemContainments = jemContainments;
  }
}
