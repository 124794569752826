export default {
  boltAngle: 'boltAngle',
  boltTorque: 'boltTorque',
  currentDateTime: 'currentDateTime',
  hardwareName: 'hardwareName',
  jemProcessDataDef: 'jemProcessDataDef',
  scannedBarcode: 'scannedBarcode',
  userDefinedString: 'userDefinedString',
  variableDef: 'variableDef',
  selectedDecision: 'selectedDecision',
  sendImage: 'sendImage',
};
