import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import moment from 'moment-timezone';
import QueryParams from 'eflex/constants/bi-query-params';
import { cycleTimeReports } from 'eflex/constants/bi-report-options';

export default class BiCycleTimeController extends Controller {
  // jscpd:ignore-start
  queryParams = Object.keys(QueryParams);

  @tracked drawerCollapsed = false;
  @tracked userIds = [];
  @tracked modelIds = [];
  @tracked stationIds = [];
  @tracked tags = [];
  @tracked shiftNames = [];
  @tracked beginDate = moment().startOf('day').toISOString();
  @tracked endDate = moment().endOf('day').toISOString();
  @tracked selectedToken;
  selectedReport = cycleTimeReports.cycleTimeSummary;

  get reportOptions() {
    return Object.values(cycleTimeReports);
  }

  @action
  resetCriteria() {
    Object.assign(this, {
      userIds: [],
      modelIds: [],
      stationIds: [],
      tags: [],
      shiftNames: [],
      beginDate: moment().startOf('day').toISOString(),
      endDate: moment().endOf('day').toISOString(),
    });
  }
  // jscpd:ignore-end
}
