export default {
  // jscpd:ignore-start
  colorPalette: {
    biBlue: '#2D7FBE', // bi-blue
    biPurple: '#9538e0', // bi-purple
    danger: '#eb1726', // $danger
    warning: '#fada4a', // $warning
    success: '#30c100', // $success
    biGrey: '#B5B5B5', // $secondary-border
    taskRowRed: '#e67a81', // $jem-task-row-red
    taskRowGreen: '#a4e886', // $jem-task-row-green
    labelBackground: '#F6F8FC',
    labelBorder: '#8C8D8E',
    oeeBackground: '#22252c', // $no-chrome-body-bg,
  },

  excessBiColors: [
    '#364062',
    '#314080',
    '#095194',
    '#1792C5',
    '#51A7D2',
    '#138892',
    '#6ECFBD',
    '#73AB48',
    '#118560',
    '#1D4635',
    '#FAD299',
    '#D2C6A9',
    '#0F0F0F',
    '#FBAA30',
    '#F2E448',
    '#FD712E',
    '#923036',
    '#6E0212',
    '#C9417A',
    '#F29FC4',
    '#826DA9',
    '#684474',
    '#AB8D66',
    '#B0643E',
    '#54463E',
    '#B5B5B5',
    '#878787',
    '#4F5152',
  ],

  textStyle: {
    fontFamily: 'Open Sans',
  },

  title: {
    textStyle: {
      fontWeight: 'normal',
      fontSize: 16,
      color: '#FFFFFF',
    },
  },

  grid: {
    containLabel: true,
    left: 25,
    right: 55,
  },

  legend: {
    textStyle: {
      color: '#B5B5B5',
      fontSize: 12,
      align: 'right',
      verticleAlign: 'bottom',
    },
  },

  yAxis: {
    axisLabel: {
      color: '#FFFFFF',
      fontSize: 12,
      axisLine: {
        show: true,
      },
      axisTick: {
        show: true,
      },
      splitLine: {
        show: false,
      },
    },

    nameTextStyle: {
      fontWeight: 'normal',
      fontSize: 14,
      color: '#B5B5B5',
    },

    nameLocation: 'middle',
  },

  xAxis: {
    axisLine: {
      show: true,
    },
    axisTick: {
      show: true,
    },
    splitLine: {
      show: false,
    },
  },

  timeAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: '#B5B5B5',
      },
    },
    axisLabel: {
      lineStyle: {
        color: '#B5B5B5',
      },
    },
    axisTick: {
      show: false,
    },
    splitLine: {
      show: false,
    },
  },

  logAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: '#B5B5B5',
      },
    },
    axisLabel: {
      lineStyle: {
        color: '#B5B5B5',
      },
    },
    axisTick: {
      show: true,
    },
    splitLine: {
      show: false,
    },
  },

  valueAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: '#B5B5B5',
      },
    },
    axisLabel: {
      lineStyle: {
        color: '#B5B5B5',
      },
    },
    axisTick: {
      show: true,
    },
    splitLine: {
      show: false,
    },
  },

  categoryAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: '#B5B5B5',
      },
    },
    axisLabel: {
      lineStyle: {
        color: '#B5B5B5',
      },
    },
    axisTick: {
      show: false,
    },
    splitLine: {
      show: false,
    },
  },

  line: {
    symbol: 'circle',
    symbolSize: 3.5,
    lineStyle: {
      width: 4,
    },
  },

  bar: {
    barMaxWidth: 40,
  },

  markLine: {
    symbol: 'none',
    lineStyle: {
      type: 'solid',
      color: '#fff',
      width: 3,
    },

    label: {
      fontWeigth: 'bolder',
      color: '#fff',
    },
  },
  // jscpd:ignore-end
};
