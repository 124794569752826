import moment from 'moment-timezone';

export default function (chartData, level) {
  let interval, start, end;
  const data = [];
  switch (level) {
    case 'Month': {
      interval = {
        interval: 'months',
        format: 'YYYY-MM',
      };
      break;
    }
    case 'Week': {
      interval = {
        interval: 'weeks',
        format: 'gggg-ww',
      };
      break;
    }
    case 'Day': {
      interval = {
        interval: 'days',
        format: 'YYYY-MM-DD',
      };
      break;
    }
    case 'Hour': {
      interval = {
        interval: 'hours',
        format: 'YYYY-MM-DD HH',
      };
      break;
    }
  }

  if (!chartData[0]) {
    return data;
  }

  if (level === 'Week') {
    start = moment().week(chartData[0].date.split('-').at(-1)).startOf('week');
    end = moment().week(chartData.at(-1).date.split('-').at(-1)).endOf('week');
  } else {
    start = moment(chartData[0].date, interval.format);
    end = moment(chartData.at(-1).date, interval.format);
  }

  while (start.isSameOrBefore(end)) {
    const newDate = start.format(interval.format);
    const existingDate = chartData.find(item => item.date === newDate);

    if (!existingDate) {
      data.push({
        date: newDate,
        rejectCount: 0,
        passCount: 0,
      });
    } else {
      data.push(existingDate);
    }
    start.add(1, interval.interval);
  }

  return data;
}
