const MAX_FILE_SIZE_MB = 5;

export default {
  maxFileSize: MAX_FILE_SIZE_MB * 1024 * 1024,
  supportedFileTypes: ['image/jpeg', 'image/png', 'image/gif'],
  imageTypes: {
    ASSET: 'wie-asset',
    DISPLAY: 'wie-display',
    THUMB: 'thumb',
  },
  getStartedTypes: {
    FIXED: 'fixed',
    CUSTOM: 'custom',
  },
  libraryViews: {
    THUMB: 'thumb',
    LIST: 'list',
    APPROVAL: 'approval',
  },
  approvalStates: {
    APPROVED: 'approved',
    REJECTED: 'rejected',
    PENDING: 'pending',
  },
};
