import BuildStatus from 'eflex/models/build-status';
import { hasMany, attr } from '@ember-data/model';
import TaskStatuses from 'eflex/constants/task-statuses';
import { cached } from '@glimmer/tracking';

export default class LiveBuildStatus extends BuildStatus {
  @attr('number', { defaultValue: 0 }) elapsedTime;

  @hasMany('buildStatusChild', { async: false, inverse: 'liveBuildStatus', embedded: true }) children;
  @hasMany('customIdentifierDatum', { inverse: 'liveBuildStatus', async: false, embedded: true }) customIdentifierData;

  get isUnknown() {
    return this.status === TaskStatuses.UNKNOWN;
  }

  get isActive() {
    return this.startTime != null && !this.endTime && this.status > 0;
  }

  @cached
  get isHolding() {
    return this.isStarted && this.children.some(item => item.hasActiveHold);
  }

  get isRunning() {
    return this.isStarted && !this.isHolding;
  }

  get statusClass() {
    if (this.isHolding) {
      return 'bg-warning';
    }

    return super.statusClass;
  }

  get statusTranslation() {
    if (this.isHolding) {
      return this.intl.t('hold');
    }

    return super.statusTranslation;
  }
}
