import Model, { attr, belongsTo } from '@ember-data/model';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { copyable } from 'eflex/decorators';
import { isBelongsToRefDirty, rollbackBelongsTo } from 'eflex/util/relationship-helpers';

@copyable
@buildValidations({
  station: [
    validator('presence', true),
    validator('inline', {
      validate(station) {
        if (!station.productionScheduleEnabled) {
          return station.intl.t('task.pushToSchedule.stationValidation');
        }
        return true;
      },
    }),
  ],
  model: [validator('presence', true)],
  scheduleTarget: {
    validators: [
      validator('presence', true),
      validator('number', {
        allowString: true,
        allowNone: false,
        integer: true,
        gt: 0,
      }),
    ],
  },
})
class PushToScheduleConfig extends Model {
  @attr('number', { defaultValue: 1 }) scheduleTarget;

  @belongsTo('taskConfig', { async: false, inverse: 'pushToScheduleConfigs' }) taskConfig;
  @belongsTo('station', { async: false, inverse: null }) station;
  @belongsTo('model', { async: false, inverse: null }) model;

  get isDirty() {
    return super.isDirty ||
      isBelongsToRefDirty(this, 'station') ||
      isBelongsToRefDirty(this, 'model');
  }

  get copyableOptions() {
    return {
      ignoreAttributes: new Set(['taskConfig']),
      copyByReference: new Set(['station', 'model']),
    };
  }

  rollbackAttributes() {
    rollbackBelongsTo(this, 'station');
    rollbackBelongsTo(this, 'model');
    super.rollbackAttributes();
  }
}

export default PushToScheduleConfig;
