import BaseSession from 'ember-simple-auth/services/session';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class JwtSession extends BaseSession {
  @service dataPreloader;

  // ember-simple-auth sets isAuthenticated to true before it has actually finished loading
  // https://github.com/simplabs/ember-simple-auth/blob/master/packages/ember-simple-auth/addon/internal-session.js#L104
  @tracked isActuallyAuthenticated = false;

  get isOidc() {
    return false;
  }

  get loginRoute() {
    return 'login';
  }

  async handleAuthentication(routeAfterAuthentication) {
    await this.dataPreloader.loadCurrentUserAndData.perform();
    super.handleAuthentication(routeAfterAuthentication);
    this.isActuallyAuthenticated = true;
  }

  handleInvalidation(routeAfterInvalidation) {
    this.isActuallyAuthenticated = false;
    super.handleInvalidation(routeAfterInvalidation);
  }
}
