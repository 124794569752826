import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { trackedFunction } from 'ember-resources/util/function';

export default class CodeConfirmationModal extends Component {
  @service currentUser;
  @service codeRepo;

  @tracked selected = [];
  @tracked note;

  codes = trackedFunction(this, async () => {
    return await this.codeRepo.retrieveCodes(this.args.codeType, this.args.station);
  });

  get invalid() {
    return isEmpty(this.selected);
  }

  get timestamp() {
    return moment().format('M-DD-YY h:mma').slice(0, -1);
  }

  codeMatcher(code, term) {
    return `${code.code} - ${code.description}`.indexOf(term);
  }

  @action
  onConfirm() {
    return this.args.onConfirm({
      note: this.note,
      codes: this.selected.map(code => ({
        code: code.code,
        description: code.description,
      })),
    });
  }
}
