import { validator } from '@eflexsystems/ember-tracked-validations';
import { getOwner } from '@ember/application';

const checkValueGreaterThanThumbnails = function (value, options, cameraConfiguration) {
  if (value > cameraConfiguration.daysToStoreThumbnails) {
    return getOwner(cameraConfiguration).lookup('service:intl').t('evision.cameras.valueLessThanThumbnail');
  }
  return true;
};

export default {
  daysToStorePassedCompressed: [
    validator('number', { gte: 0, allowString: true, allowNone: false }),
    validator('inline', { validate: checkValueGreaterThanThumbnails }),
  ],

  daysToStoreFailedCompressed: [
    validator('number', { gte: 0, allowString: true, allowNone: false }),
    validator('inline', { validate: checkValueGreaterThanThumbnails }),
  ],

  daysToStoreFailedRaw: [
    validator('number', { gte: 0, allowString: true, allowNone: false }),
    validator('inline', { validate: checkValueGreaterThanThumbnails }),
  ],

  daysToStorePassedRaw: [
    validator('number', { gte: 0, allowString: true, allowNone: false }),
    validator('inline', { validate: checkValueGreaterThanThumbnails }),
  ],

  daysToStoreThumbnails: [
    validator('number', { gte: 0, allowString: true, allowNone: false }),
    validator('inline', {
      validate(daysToStoreThumbnails, options, cameraConfiguration) {
        if (
          daysToStoreThumbnails < cameraConfiguration.daysToStorePassedCompressed ||
          daysToStoreThumbnails < cameraConfiguration.daysToStoreFailedCompressed ||
          daysToStoreThumbnails < cameraConfiguration.daysToStoreFailedRaw ||
          daysToStoreThumbnails < cameraConfiguration.daysToStorePassedRaw
        ) {
          return getOwner(cameraConfiguration).lookup('service:intl').t('evision.cameras.thumbnailValueTooLow');
        }

        return true;
      },
    }),
  ],
};
