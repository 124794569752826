const toRegex = function (string, regexFlags = '') {
  let regex;

  if (string.endsWith('*') && string.match(/\*/g).length === 1) {
    regex = `${string.replace('*', '')}`;
    return new RegExp(`^${regex}`, regexFlags);
  }

  regex = string
    .replaceAll('*', '.*')
    .replaceAll('?', '.');

  return new RegExp(`^${regex}$`, regexFlags);
};

const isWildcard = function (string) {
  if (string == null) {
    return false;
  }

  return string.includes('*') || string.includes('?');
};

export default {
  toRegex,
  isWildcard,

  convertWildcard(searchTerm, options = {}) {
    if (isWildcard(searchTerm)) {
      return toRegex(searchTerm, options.regexFlags);
    } else {
      return searchTerm;
    }
  },
};
