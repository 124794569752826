var _class;
import Control from '../control';
import deprecateSubclassing from 'ember-bootstrap/utils/deprecate-subclassing';

/**

 @class FormElementControlRadio
 @namespace Components
 @extends Components.FormElementControl
 @private
 */
let FormElementControlRadio = deprecateSubclassing(_class = class FormElementControlRadio extends Control {
  /**
   * @property inline
   * @type {Boolean}
   * @default false
   * @public
   */
}) || _class;
export { FormElementControlRadio as default };