import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import DropdownsJemStationSelectedOption from 'eflex/components/drop-downs/jem-station/selected-option';
import { filter, pipe, collectBy, sortBy, map } from 'ramda';
import { sortByPaths } from 'ramda-adjunct';

export default class DropdownsJemStation extends Component {
  @service currentUser;
  @service stationRepo;

  get selectedItemComponent() {
    return DropdownsJemStationSelectedOption;
  }

  get options() {
    let stations = this.stationRepo.stations;

    // Prevent annoying errors caused by puppeteer and
    // webdriver browsers being open at the same time.
    // Remove after all webdriver tests are converted to puppeteer
    if (window.isIntegrationTest) {
      stations = stations.filter(station =>
        station.belongsTo('parent').value(),
      );
    }

    if (this.args.filter != null) {
      stations = stations.filter(item => item[this.args.filter]);
    }

    return pipe(
      collectBy(station => station.area?.id),
      sortBy(areaStations => areaStations[0].area?.order),
      map(areaStations => ({
        groupName: areaStations[0].area?.name,
        options: pipe(
          sortByPaths([
            ['group', 'order'],
            ['order'],
          ]),
          filter(station =>
            this.currentUser.user.isAdmin || !station.hideFromOperator,
          ),
        )(areaStations),
      })),
    )(stations);
  }

  matcher(item, term) {
    const pattern = new RegExp(`${term}`, 'i');

    if (pattern.test(item.name) || pattern.test(item.area.name)) {
      return 1;
    } else {
      return -1;
    }
  }
}
