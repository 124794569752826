import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { all, task, taskGroup, timeout } from 'ember-concurrency';
import { isEmpty, isPresent } from '@ember/utils';
import getDelayTime from 'eflex/util/get-delay-time';
import wieSettings from 'eflex/constants/work-instructions/wie-settings';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import { TrackedArray } from 'tracked-built-ins';
import { addObjectsIfNotPresent, removeObjects, removeObject, addObjectIfNotPresent } from 'eflex/util/array-helpers';
import { sortByProp } from 'ramda-adjunct';

const LIBRARY_VIEWS_NEEDING_RELOAD = new Set(['thumb', 'list']);

export default class WorkInstructionEditorLibrary extends Component {
  @service imageEditor;
  @service currentUser;
  @service workInstructionRepo;
  @service intl;

  @tracked selectedCards = new TrackedArray();
  @tracked lastSelected;
  @tracked selectAllItems = false;
  @tracked searchTerm;
  @tracked recordCount = 0;
  @tracked pageNumber = 1;
  @tracked filteredWorkInstructions = new TrackedArray();
  @tracked filteredFolders = new TrackedArray();
  @tracked selectedFolder = this.args.currentWorkInstruction?.folder;
  @tracked smallThumb = true;
  @tracked showConfirmModal = false;
  @tracked showEditFolderModal = false;

  supportedFileTypes = wieSettings.supportedFileTypes;
  previousLibraryView;

  @taskGroup({ enqueue: true }) loadTasks;

  get oneOrMoreSelected() {
    return this.selectedCards.length > 0;
  }

  get isThumbView() {
    return this.args.currentLibraryView === wieSettings.libraryViews.THUMB;
  }

  get isListView() {
    return this.args.currentLibraryView === wieSettings.libraryViews.LIST;
  }

  get isApprovalView() {
    return this.args.currentLibraryView === wieSettings.libraryViews.APPROVAL;
  }

  get isLibraryFullScreen() {
    return this.isListView || this.isApprovalView;
  }

  get showSpinner() {
    return this.search.isRunning ||
      this.loadFolder.isRunning ||
      this.loadTasks.isRunning ||
      this.importWorkInstructions.isRunning ||
      this.moveToFolder.isRunning ||
      this.deleteConfirm.isRunning ||
      this._loadMore.isRunning ||
      this.saveFolder.isRunning;
  }

  get confirmModalText() {
    const selectedFolderCount = this.selectedCards.filter(card => card.isFolder).length;
    const selectedInstructionCount = this.selectedCards.length - selectedFolderCount;

    if (selectedFolderCount && selectedInstructionCount) {
      return this.intl.t('workInstructions.delete.allWarning', {
        instructionCount: selectedInstructionCount,
        folderCount: selectedFolderCount,
      });
    } else if (selectedFolderCount > 0) {
      return this.intl.t('workInstructions.delete.folderWarning', { count: selectedFolderCount });
    } else if (selectedInstructionCount > 0) {
      return this.intl.t('workInstructions.delete.warning', { count: selectedInstructionCount });
    } else {
      return null;
    }
  }

  get singleCardSelected() {
    return this.selectedCards.length === 1;
  }

  get singleCardSelectedAndNotFolder() {
    return this.singleCardSelected && !this.selectedCards.at(-1)?.isFolder;
  }

  get singleCardSelectedAndIsFolder() {
    return this.singleCardSelected && this.selectedCards.at(-1)?.isFolder;
  }

  get selectableFolders() {
    if (this.selectedFolder != null) {
      return [];
    }

    const folders = sortByProp('name', this.filteredFolders.filter(folder => !folder.isNew));
    const newFolder = this.filteredFolders.find(folder => folder.isNew);

    if (newFolder != null) {
      folders.unshift(newFolder);
    }

    return folders;
  }

  get selectableWorkInstructions() {
    let instructions = this.filteredWorkInstructions.filter(workInstruction => !workInstruction.isNew);

    if (this.isApprovalView) {
      instructions = instructions.filter(workInstruction => workInstruction.approvalRequested);
    } else if (this.selectedFolder) {
      instructions = instructions.filter(workInstruction => isPresent(workInstruction.folder));
    } else if (isEmpty(this.searchTerm)) {
      instructions = instructions.filter(workInstruction => workInstruction.folder == null);
    }

    return sortByProp('name', instructions);
  }

  get showLoadButton() {
    return this.workInstructionCount > 0 && this.workInstructionCount < this.recordCount;
  }

  get workInstructionCount() {
    return this.selectableWorkInstructions.length + this.selectableFolders.length;
  }

  get instructionsAreViewOnly() {
    return !this.workInstructionRepo.userCanEditSelected(this.currentUser, this.selectedCards);
  }

  get deleteAndDownloadDisabled() {
    return !this.oneOrMoreSelected || this.instructionsAreViewOnly;
  }

  @task({ restartable: true })
  @waitFor
  *search() {
    this.pageNumber = 1;

    if (isEmpty(this.searchTerm)) {
      return;
    }

    const { folders, instructions, count } = yield this._queryFoldersAndInstructions.perform();

    Object.assign(this, {
      recordCount: count,
      filteredFolders: new TrackedArray(folders),
      filteredWorkInstructions: new TrackedArray(instructions),
      selectedCards: new TrackedArray(),
    });

    this.args.onChange(this.selectedCards);
  }

  // jscpd:ignore-start
  @task
  @waitFor
  *loadFolder(folder) {
    if (folder == null && this.selectedFolder.isInvalid) {
      // Reset the folder to have its previous (i.e. non-blank) name.
      this.selectedFolder.rollbackAttributes();
    }

    Object.assign(this, {
      searchTerm: null,
      pageNumber: 1,
      selectedCards: new TrackedArray(),
      selectedFolder: folder,
    });

    this.args.setSelectedFolder?.(folder);

    if (folder == null) {
      yield this.resetList.perform();
      return;
    }

    const { instructions, instructionCount } = yield this._queryInstructions.perform();

    Object.assign(this, {
      recordCount: instructionCount,
      filteredWorkInstructions: new TrackedArray(instructions),
      filteredFolders: new TrackedArray(),
    });

    if (this.args.currentWorkInstruction?.isNew) {
      this._addRecordToFilteredInstructions(this.args.currentWorkInstruction);
    }

    this.args.onChange(this.selectedCards);
  }

  @task
  @waitFor
  *_loadMore() {
    let count, folders, instructions;
    if (this.selectedFolder == null) {
      ({ folders, instructions, count } = yield this._queryFoldersAndInstructions.perform());
    } else {
      folders = [];
      ({ instructions, instructionCount: count } = yield this._queryInstructions.perform());
    }

    addObjectsIfNotPresent(this.filteredWorkInstructions, instructions);
    addObjectsIfNotPresent(this.filteredFolders, folders);
    this.recordCount = count;
  }

  @task({ group: 'loadTasks' })
  @waitFor
  *resetList() {
    let count, folders, instructions;
    Object.assign(this, {
      searchTerm: null,
      pageNumber: 1,
      selectedCards: new TrackedArray(),
    });

    if (this.selectedFolder == null) {
      ({ folders, instructions, count } = yield this._queryFoldersAndInstructions.perform());
    } else {
      folders = [];
      ({ instructions, instructionCount: count } = yield this._queryInstructions.perform());
    }

    Object.assign(this, {
      recordCount: count,
      filteredFolders: new TrackedArray(folders),
      filteredWorkInstructions: new TrackedArray(instructions),
    });

    if (!this._isCurrentInstructionDisplayed(this.args.currentWorkInstruction)) {
      this._addRecordToFilteredInstructions(this.args.currentWorkInstruction);
    }

    this.clearSelections();
    this.args.onChange(this.selectedCards);
  }
  // jscpd:ignore-end

  @task
  @waitFor
  *importWorkInstructions(files, resetInput) {
    const childTasks = [...files]
      .filter(file => wieSettings.supportedFileTypes.includes(file.type))
      .map(file => this._importWorkInstruction.perform(file));

    yield all(childTasks);

    resetInput();
    yield this.resetList.perform();
  }

  @task({ enqueue: true, maxConcurrency: 3 })
  @waitFor
  *_importWorkInstruction(file) {
    const image = new Image();

    const { blobToDataURL } = yield import('blob-util');
    image.src = yield blobToDataURL(file);
    yield image.decode();

    const workInstruction = this.workInstructionRepo.createWorkInstruction({
      name: file.name,
      folder: this.selectedFolder,
      width: image.naturalWidth,
      height: image.naturalHeight,
      canvas: yield this.imageEditor.getImageCanvasJson.perform(image),
      displayImageData: image.src,
    });

    yield this.workInstructionRepo.saveWithImageData.perform(workInstruction);
  }

  @task
  @waitFor
  *deleteConfirm() {
    const toDelete = this.selectedCards;
    removeObjects(this.filteredFolders, toDelete);
    removeObjects(this.filteredWorkInstructions, toDelete);

    if (toDelete.includes(this.args.currentWorkInstruction?.folder)) {
      removeObject(this.filteredWorkInstructions, this.args.currentWorkInstruction);
    }

    Object.assign(this, {
      selectedCards: new TrackedArray(),
      showConfirmModal: false,
    });

    const currentWorkInstructionIsDeleted = yield this.workInstructionRepo.deleteSelectedWorkInstructions.perform(
      this.args.currentWorkInstruction,
      toDelete,
    );
    yield this.resetList.perform();

    this.args.onDelete?.(currentWorkInstructionIsDeleted);
  }

  @task
  @waitFor
  *saveFolder(folder) {
    if (!folder.isDirty || folder.isInvalid) {
      return;
    }

    yield folder.save();
  }

  @task
  @waitFor
  *dropOnFolder(folder, workInstruction) {
    let selectedInstructions = addObjectIfNotPresent(
      this.selectedCards.filter(card => !card.isFolder),
      workInstruction,
    );

    selectedInstructions.forEach(instruction => { instruction.folder = folder; });

    yield this.workInstructionRepo.saveInstructions.perform(selectedInstructions);
    yield this._loadMore.perform();

    this.selectedCards = new TrackedArray();
  }

  @task
  @waitFor
  *delistInstructions(instructions) {
    removeObjects(this.filteredWorkInstructions, instructions);
    this.recordCount = this.recordCount - instructions.length;

    if (
      this.filteredWorkInstructions.length > this.workInstructionRepo.perPage &&
      this.filteredWorkInstructions.length > this.recordCount
    ) {
      yield this._loadMore.perform();
    }
  }

  @task({ drop: true })
  @waitFor
  *moveToFolder(folder) {
    const wies = this.selectedCards.filter(card => !card.isFolder);
    wies.forEach(wi => { wi.folder = folder; });
    this.clearSelections();
    removeObjects(this.filteredWorkInstructions, wies);

    yield all(wies.map(wi => wi.save()));
  }

  @task
  @waitFor
  *setView(libraryView) {
    this.previousLibraryView = this.args.currentLibraryView;
    this.args.onSetLibraryView(libraryView);
    this.args.onChange(this.selectedCards);
    this.clearSelections();

    if (
      (LIBRARY_VIEWS_NEEDING_RELOAD.has(this.previousLibraryView) && libraryView === 'approval') ||
      (this.previousLibraryView === 'approval' && LIBRARY_VIEWS_NEEDING_RELOAD.has(libraryView))
    ) {
      yield this._resetLibrary.perform();
    }
  }

  @task
  @waitFor
  *addFolder() {
    const newFolder = this.workInstructionRepo.createFolder();
    this.filteredFolders.push(newFolder);
    this.recordCount += 1;
    yield this.saveFolder.perform(newFolder);
    yield this.loadFolder.perform(newFolder);
  }

  @task
  @waitFor
  *returnToLibrary() {
    if (this.selectedFolder.isInvalid) {
      // Reset the folder to have its previous (i.e. non-blank) name.
      this.selectedFolder.rollbackAttributes();
    }

    yield this._resetLibrary.perform();
  }

  @task
  @waitFor
  *_queryInstructions() {
    const query = {
      searchTerm: this.searchTerm,
      pageNumber: this.pageNumber,
      folder: this.selectedFolder?.id,
    };

    if (this.isApprovalView) {
      query.approvable = true;
    }

    return yield this.workInstructionRepo.queryInstructions.perform(query);
  }

  @task
  @waitFor
  *_queryFoldersAndInstructions() {
    const [{ instructions, instructionCount }, { folders, folderCount }] = yield all([
      this._queryInstructions.perform(),
      this.workInstructionRepo.queryFolders.perform({
        searchTerm: this.searchTerm,
        pageNumber: this.pageNumber,
      }),
    ]);

    return {
      folders,
      instructions,
      count: folderCount + instructionCount,
    };
  }

  @task
  @waitFor
  *_resetLibrary() {
    Object.assign(this, {
      selectedFolder: null,
      selectedCards: new TrackedArray(),
    });

    this.args.setSelectedFolder?.(null);
    yield this.resetList.perform();
  }

  @task({ restartable: true })
  @waitFor
  *_searchDebounced() {
    yield timeout(getDelayTime(300));
    yield this.search.perform();
  }

  @task
  @waitFor
  *onSearchKeyUp({ target }) {
    this.searchTerm = target.value;
    if (!isEmpty(this.searchTerm)) {
      yield this._searchDebounced.perform();
    } else {
      yield this.resetList.perform();
    }
  }

  @task
  @waitFor
  *onLoadMoreClick() {
    this.pageNumber += 1;
    yield this._loadMore.perform();
  }

  constructor() {
    super(...arguments);
    this.resetList.perform();
  }

  @task({ group: 'loadTasks' })
  @waitFor
  *onDidUpdate(element, [currentWorkInstruction]) {
    yield timeout(0);
    if (!this._isCurrentInstructionDisplayed(currentWorkInstruction)) {
      this._addRecordToFilteredInstructions(currentWorkInstruction);
    }
  }

  @task
  *editWorkInstruction(libraryCard) {
    if (libraryCard.isFolder) {
      yield this.loadFolder.perform(libraryCard);
      this.showEditFolderModal = true;
      return;
    }

    this.selectedCards = new TrackedArray();
    this.args.onSetLibraryView(wieSettings.libraryViews.THUMB);
    this.args.onChange(this.selectedCards);
    this.args.loadWorkInstruction?.(libraryCard);
  }

  @task
  *loadWorkInstruction(workInstruction) {
    if (workInstruction === this.args.currentWorkInstruction) {
      return;
    }

    yield this.editWorkInstruction.perform(workInstruction);
  }

  @task
  *confirmEditFolder() {
    yield this.saveFolder.perform(this.selectedFolder);
    this.showEditFolderModal = false;
  }

  @action
  clearSelections() {
    this.selectableWorkInstructions.forEach(instruction => { instruction.isSelected = false; });
    this.selectableFolders.forEach(folder => { folder.isSelected = false; });
    Object.assign(this, {
      selectedCards: new TrackedArray(),
      selectAllItems: false,
    });
  }

  @action
  selectAllLibraryItems(value) {
    this.selectAllItems = value;

    if (!this.selectAllItems) {
      this.clearSelections();
      return;
    }

    this.selectableWorkInstructions
      .concat(this.selectableFolders)
      .filter(instruction => !this.selectedCards.includes(instruction))
      .forEach(instruction => {
        instruction.isSelected = true;
        this.selectedCards.push(instruction);
      });
  }

  @action cancelEditFolder() {
    this.selectedFolder.rollbackAttributes();
    this.showEditFolderModal = false;
  }

  @action
  cardSelected(workInstruction) {
    if (this.lastSelected !== workInstruction) {
      this.lastSelected = workInstruction;
    }

    if (this.selectedCards.includes(this.lastSelected)) {
      this.lastSelected.isSelected = false;
      removeObject(this.selectedCards, this.lastSelected);
    } else {
      this.lastSelected.isSelected = true;
      addObjectIfNotPresent(this.selectedCards, this.lastSelected);
    }

    this.args.onChange(this.selectedCards);
  }

  _addRecordToFilteredInstructions(instructionToAdd) {
    if (!instructionToAdd) {
      return;
    }

    addObjectIfNotPresent(this.filteredWorkInstructions, instructionToAdd);

    if (instructionToAdd.isNew && this.showLoadButton) {
      this.recordCount += 1;
    }
  }

  _isCurrentInstructionDisplayed(currentWorkInstruction) {
    if (currentWorkInstruction == null || currentWorkInstruction.isDestroyed) {
      return false;
    }

    return this.filteredWorkInstructions.includes(currentWorkInstruction);
  }
}
