import CameraConfigValidations from 'eflex/validations/camera-configuration';
import Model, { attr } from '@ember-data/model';
import { buildValidations } from '@eflexsystems/ember-tracked-validations';
import { inject as service } from '@ember/service';
import { copyable } from 'eflex/decorators';

@buildValidations(CameraConfigValidations)
@copyable
class CameraConfiguration extends Model {
  @attr('number', { defaultValue: 365 }) daysToStorePassedCompressed;
  @attr('number', { defaultValue: 365 }) daysToStoreFailedCompressed;
  @attr('number', { defaultValue: 365 }) daysToStorePassedRaw;
  @attr('number', { defaultValue: 365 }) daysToStoreFailedRaw;
  @attr('number', { defaultValue: 365 }) daysToStoreThumbnails;
  @attr('boolean', { defaultValue: true }) evisionEnabled;

  @service store;

  copy() {
    return this.store.createRecord('cameraConfiguration', {
      daysToStorePassedCompressed: this.daysToStorePassedCompressed,
      daysToStoreFailedCompressed: this.daysToStoreFailedCompressed,
      daysToStorePassedRaw: this.daysToStorePassedRaw,
      daysToStoreFailedRaw: this.daysToStoreFailedRaw,
      daysToStoreThumbnails: this.daysToStoreThumbnails,
      evisionEnabled: this.evisionEnabled,
    });
  }
}

export default CameraConfiguration;
