import Model, { belongsTo, attr } from '@ember-data/model';
import { copyable } from 'eflex/decorators';
import AnimationEvents from 'eflex/constants/jem/instruction-animation-events';

@copyable
class WorkInstructionAnimation extends Model {
  @attr('string') object;
  @attr('string') event;
  @attr('string') animation;
  @attr('string') fillColor;
  @attr('string') strokeColor;
  @attr('string') bolt;

  @belongsTo('workInstructionHardwareTriggerConfig', {
    async: false,
    inverse: 'animations',
  }) workInstructionHardwareTriggerConfig;

  get copyableOptions() {
    return {
      ignoreAttributes: new Set(['workInstructionHardwareTriggerConfig']),
    };
  }

  get isPassOrReject() {
    return this.event === AnimationEvents.PASS || this.event === AnimationEvents.REJECT;
  }
}

export default WorkInstructionAnimation;
