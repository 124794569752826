import { decOpacityToHex } from 'eflex/util/opacity-helper';
import { action } from '@ember/object';
import { isBlank } from '@ember/utils';
import Component from '@glimmer/component';

const DEFAULT_OPACITY = '100';
const DEFAULT_COLOR = '#000000';

export default class InputsColorOpacity extends Component {
  get color() {
    if (isBlank(this.args.selectedColor)) {
      return DEFAULT_COLOR;
    } else {
      return this.args.selectedColor;
    }
  }

  get opacity() {
    if (isBlank(this.args.selectedOpacity)) {
      return DEFAULT_OPACITY;
    } else {
      return this.args.selectedOpacity;
    }
  }

  @action
  updateColor(color) {
    color ??= DEFAULT_COLOR;
    const opacityHex = decOpacityToHex(this.opacity);
    this.args.updateColor(color, this.opacity, color.concat(opacityHex));
  }

  @action
  updateOpacity(opacity) {
    opacity ??= DEFAULT_OPACITY;
    const opacityHex = decOpacityToHex(opacity);
    this.args.updateColor(this.color, opacity, this.color.concat(opacityHex));
  }
}
