import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { isBlank } from '@ember/utils';

const STD_VISOR_HEIGHT_OffSET = 160;

export default class CriteriaVisor extends Component {
  @tracked params = {};
  @tracked visorMaxHeight;

  @action
  updateParams(param) {
    this.params[param.key] = {
      value: param.value,
      breadcrumb: param.breadcrumb,
    };
    //eslint-disable-next-line no-self-assign
    this.params = this.params; // force rebind
  }

  @action
  removeParam(key) {
    delete this.params[key];
    //eslint-disable-next-line no-self-assign
    this.params = this.params; // force rebind
  }

  @action
  search() {
    if (!this.args.search) {
      return;
    }

    const params = {};
    for (const key in this.params) {
      const newKey = key.replace('_', '');
      params[newKey] = this.params[key].value;
    }

    if (!isBlank(this.args.searchTerm)) {
      params.searchTerm = this.args.searchTerm?.trim();
    }

    return this.args.search(params);
  }

  @action
  setVisorHeight() {
    const { height: workspaceHeight } = document.querySelector('.workspace').getBoundingClientRect();

    if (workspaceHeight > STD_VISOR_HEIGHT_OffSET) {
      const maxHeight = workspaceHeight - STD_VISOR_HEIGHT_OffSET;
      this.visorMaxHeight = `${maxHeight}px`;
    }
  }
}
