import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { all, task, timeout } from 'ember-concurrency';
import { taskTypes } from 'eflex/constants/tasks/task-types';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import { sum } from 'ramda';
import { TrackedArray } from 'tracked-built-ins';

export default class DataGeneratorController extends Controller {
  @service locationRepo;
  @service store;
  @service areaRepo;
  @service groupRepo;
  @service stationRepo;
  @service taskRepo;

  @tracked areaCount = 1;
  @tracked modelCount = 10;
  @tracked groupCount = 1;
  @tracked stationCount = 5;
  @tracked taskCount = 10;
  @tracked persist = true;
  @tracked profile = false;
  @tracked results = new TrackedArray();
  @tracked totalTimeToCreate;
  @tracked totalTimeToSave;
  @tracked current;

  get numRecords() {
    let numRecords = this.areaCount;
    numRecords += this.areaCount * this.modelCount;
    numRecords += this.areaCount * this.groupCount;
    numRecords += this.areaCount * this.groupCount * this.stationCount;
    numRecords += this.areaCount * this.groupCount * this.stationCount * this.taskCount;
    return numRecords;
  }

  @task
  @waitFor
  *generate() {
    yield timeout(1); // let animation start

    if (this.profile) {
      // eslint-disable-next-line no-console
      console.profile('create');
    }

    const times = {
      areaTimes: [],
      groupTimes: [],
      stationTimes: [],
      taskTimes: [],
      modelTimes: [],
    };

    this.current = 1;

    const areas = [];
    const start = performance.now();

    for (let i = 0; i < this.areaCount; i++) {
      areas.push(this._createArea(i, times));
    }

    if (this.profile) {
      // eslint-disable-next-line no-console
      console.profileEnd('create');
    }

    Object.assign(this, {
      results: new TrackedArray(),
      totalTimeToCreate: Math.round(performance.now() - start),
    });

    for (const type of ['area', 'model', 'group', 'station', 'task']) {
      const typeTimes = times[`${type}Times`];
      const total = Math.round(sum(typeTimes));
      const average = Math.round(total / typeTimes.length);

      this.results.push({
        type,
        times: typeTimes,
        average,
        total,
      });
    }

    if (this.persist) {
      const allModels = areas.flatMap(area => area.models);
      const groups = areas.flatMap(area => area.groups);
      const stations = groups.flatMap(group => group.stations);
      const tasks = stations.flatMap(station => station.tasks);
      const taskConfigs = tasks.flatMap(_task => _task.taskConfigs);

      const saveStart = performance.now();

      if (this.profile) {
        // eslint-disable-next-line no-console
        console.profile('save');
      }

      yield all(areas.map(area => area.save()));
      yield all(allModels.map(allModel => allModel.save()));
      yield all(groups.map(group => group.save()));

      yield this.locationRepo.bulkSave.perform({
        stations,
        tasks,
        taskConfigs,
      });

      if (this.profile) {
        // eslint-disable-next-line no-console
        console.profileEnd('save');
      }

      this.totalTimeToSave = Math.round(performance.now() - saveStart);
    }
  }

  _createArea(areaId, times) {
    const areaStart = performance.now();
    const area = this.areaRepo.create({ name: `Area ${areaId}` });

    times.areaTimes.push(performance.now() - areaStart);
    this._incrementTotal();

    for (let i = 0; i < this.modelCount; i++) {
      const modelStart = performance.now();
      this.store.createRecord('model', { code: i, area });
      times.modelTimes.push(performance.now() - modelStart);
      this._incrementTotal();
    }

    for (let g = 0, end1 = this.groupCount; g < end1; g++) {
      this._createGroup(g, area, times);
    }

    return area;
  }

  _createGroup(locationNumber, parent, times) {
    const start = performance.now();
    const group = this.groupRepo.create({ parent, name: `Group ${locationNumber}` });
    times.groupTimes.push(performance.now() - start);
    this._incrementTotal();

    for (let i = 0; i < this.stationCount; i++) {
      this._createStation(i, group, times);
    }
  }

  _createStation(locationNumber, parent, times) {
    const start = performance.now();
    const station = this.stationRepo.create({ parent, name: `Station ${locationNumber}`, jem: true });
    times.stationTimes.push(performance.now() - start);
    this._incrementTotal();

    for (let i = 0; i < this.taskCount; i++) {
      this._createTask(i, station, times);
    }
  }

  _createTask(locationNumber, parent, times) {
    const start = performance.now();

    this.taskRepo.create({
      parent,
      name: `Task ${locationNumber}`,
      taskType: taskTypes.timer,
    });

    times.taskTimes.push(performance.now() - start);
    this._incrementTotal();
  }

  _incrementTotal() {
    this.current += 1;
    if (this.current % 500 === 0 || this.current === this.numRecords) {
      // eslint-disable-next-line no-console
      console.log('Created', this.current, 'of', this.numRecords, 'total records.');
    }
  }
}
