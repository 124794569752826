import Model, { belongsTo, attr } from '@ember-data/model';
import { isEmpty } from '@ember/utils';
import { objectAttr } from 'eflex/decorators';

export default class OeeState extends Model {
  @attr('date') startDate;
  @attr('date') endDate;
  @attr('string') state;
  @attr('string') userName;
  @attr('string', { defaultValue: null }) customButtonColor;
  @objectAttr station;
  @objectAttr faultCodes;

  @belongsTo('oeeButton', { async: false, inverse: 'oeeStates' }) button;
  @belongsTo('scheduleHistory', { async: false, inverse: null }) scheduleHistory;

  get stationId() {
    return this.station?.id ?? this.station?._id;
  }

  get active() {
    return isEmpty(this.endDate);
  }

  get color() {
    return this.button?.color;
  }

  get triggerConfigs() {
    return this.button?.triggerConfigs;
  }

  get isCustom() {
    return this.button?.isCustom ?? false;
  }

  get stateString() {
    return this.button?.name ?? this.state;
  }
}
