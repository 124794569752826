import Model, { attr } from '@ember-data/model';
import { arrayAttr, caption } from 'eflex/decorators';

export default class ServerStatusLink extends Model {
  @attr('string') url;
  @arrayAttr captions;
  @caption name;

  get href() {
    if (this.url?.includes('http')) {
      return this.url;
    } else {
      return `http://${this.url}`;
    }
  }
}
