import Model, { attr, belongsTo } from '@ember-data/model';
import { arrayAttr, copyable } from 'eflex/decorators';

@copyable
class DecisionDefConfig extends Model {
  @attr('boolean', { defaultValue: true }) enabled;

  @arrayAttr decisionTags;

  @belongsTo('taskConfig', { async: false, inverse: 'decisionDefConfigs' }) taskConfig;
  @belongsTo('decisionDef', { async: false, inverse: 'decisionDefConfigs' }) decisionDef;

  get copyableOptions() {
    return {
      ignoreAttributes: new Set(['decisionDef', 'taskConfig']),
    };
  }
}

export default DecisionDefConfig;
