var _class;
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
import PowerSelectWithCreate from './power-select-with-create';
import { action } from '@ember/object';
import { ensureSafeComponent } from '@embroider/util';
import PowerSelectMultipleComponent from 'ember-power-select/components/power-select-multiple';
let PowerSelectMultipleWithCreate = (_class = class PowerSelectMultipleWithCreate extends PowerSelectWithCreate {
  get powerSelectComponent() {
    return ensureSafeComponent(this.args.powerSelectComponent || PowerSelectMultipleComponent, this);
  }
  selectOrCreate(selection, select) {
    let suggestion = selection.filter(option => {
      return option.__isSuggestion__;
    })[0];
    if (suggestion) {
      this.args.onCreate(suggestion.__value__, select);
    } else {
      this.args.onChange(selection, select);
    }
  }
}, (_applyDecoratedDescriptor(_class.prototype, "selectOrCreate", [action], Object.getOwnPropertyDescriptor(_class.prototype, "selectOrCreate"), _class.prototype)), _class);
export { PowerSelectMultipleWithCreate as default };