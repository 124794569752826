import moment from 'moment-timezone';

export default [
  {
    translationKey: 'datePickerToken.today',
    startDate() {
      return moment().startOf('day').toDate();
    },
    endDate() {
      return moment().endOf('day').toDate();
    },
  },
  {
    translationKey: 'datePickerToken.yesterday',
    startDate() {
      return moment().startOf('day').subtract(1, 'days').toDate();
    },
    endDate() {
      return moment().endOf('day').subtract(1, 'days').toDate();
    },
  },
  {
    translationKey: 'datePickerToken.currentWeek',
    startDate() {
      return moment().startOf('week').toDate();
    },
    endDate() {
      return moment().endOf('week').toDate();
    },
  },
  {
    translationKey: 'datePickerToken.currentMonth',
    startDate() {
      return moment().startOf('month').toDate();
    },
    endDate() {
      return moment().endOf('month').toDate();
    },
  },
  {
    translationKey: 'datePickerToken.currentQuarter',
    startDate() {
      return moment().startOf('quarter').toDate();
    },
    endDate() {
      return moment().endOf('quarter').toDate();
    },
  },
  {
    translationKey: 'datePickerToken.currentYear',
    startDate() {
      return moment().startOf('year').toDate();
    },
    endDate() {
      return moment().endOf('year').toDate();
    },
  },
  {
    translationKey: 'datePickerToken.previousWeek',
    startDate() {
      return moment()
        .startOf('week')
        .startOf('day')
        .subtract(1, 'week')
        .toDate();
    },
    endDate() {
      return moment()
        .startOf('week')
        .endOf('day')
        .subtract(1, 'day')
        .toDate();
    },
  },
  {
    translationKey: 'datePickerToken.previousMonth',
    startDate() {
      return moment()
        .startOf('month')
        .startOf('day')
        .subtract(1, 'month')
        .toDate();
    },
    endDate() {
      return moment()
        .startOf('month')
        .endOf('day')
        .subtract(1, 'day')
        .toDate();
    },
  },
  {
    translationKey: 'datePickerToken.previousQuarter',
    startDate() {
      return moment().startOf('quarter').subtract(1, 'quarter').toDate();
    },
    endDate() {
      return moment()
        .startOf('quarter')
        .subtract(1, 'day')
        .endOf('day')
        .toDate();
    },
  },
  {
    translationKey: 'datePickerToken.previousYear',
    startDate() {
      return moment().startOf('year').subtract(1, 'year').toDate();
    },
    endDate() {
      return moment()
        .startOf('year')
        .subtract(1, 'day')
        .endOf('day')
        .toDate();
    },
  },
  {
    translationKey: 'datePickerToken.lastHour',
    startDate() {
      return moment().subtract(1, 'hour').toDate();
    },
    endDate() {
      return new Date();
    },
  },
  {
    translationKey: 'datePickerToken.last30Days',
    startDate() {
      return moment().subtract(30, 'day').toDate();
    },
    endDate() {
      return new Date();
    },
  },
  {
    translationKey: 'datePickerToken.last60Days',
    startDate() {
      return moment().subtract(60, 'day').toDate();
    },
    endDate() {
      return new Date();
    },
  },
  {
    translationKey: 'datePickerToken.last90Days',
    startDate() {
      return moment().subtract(90, 'day').toDate();
    },
    endDate() {
      return new Date();
    },
  },
];
