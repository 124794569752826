import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { hardwareTypes, unavailableTypes } from 'eflex/constants/hardware-types';
import { task, all } from 'ember-concurrency';
import { camelize } from '@ember/string';
import { anyInvalid } from 'eflex/util/getter-helpers';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import { reject } from 'ramda';

export default class HardwareController extends Controller {
  @service validationErrorNotifier;
  @service hardwareRepo;
  @service router;
  @service licensing;

  hardwareTypes = hardwareTypes;

  @tracked hardwareToDelete;
  @tracked filterBy;
  @tracked selectedHardware;

  @tracked showHardwarePanel = false;

  get nonDeletedHardwares() {
    return this.hardwares.filter(item => !item.isDeleted);
  }

  get hardwareIos() {
    return this.hardwares.flatMap(hardware => hardware.hardwareIos);
  }

  get nonDeletedHardwareIos() {
    return this.hardwareIos.filter(item => !item.isDeleted);
  }

  get isDirty() {
    return this.hasDirtyHardware || this.hasDirtyHardwareIo;
  }

  get isInvalid() {
    return this.hardwaresInvalid || this.hardwareIosInvalid;
  }

  get hasDirtyHardware() {
    return this.hardwares.some(item => item.isDirty);
  }

  get hasDirtyHardwareIo() {
    return this.hardwareIos.some(item => item.isDirty);
  }

  get hardwaresInvalid() {
    return anyInvalid(this.hardwares);
  }

  get hardwareIosInvalid() {
    return anyInvalid(this.hardwareIos);
  }

  get hardwares() {
    return reject(hardware =>
      unavailableTypes.includes(camelize(hardware.constructor.modelName)),
    )(this.hardwareRepo.allHardware);
  }

  get displayedHardware() {
    return this.nonDeletedHardwares.filter(hardware => {
      const substring = this.filterBy ?? '';
      return hardware.displayName.toLowerCase().includes(substring.toLowerCase());
    });
  }

  get hardwareOptions() {
    return hardwareTypes.filter(hardware =>
      !unavailableTypes.includes(hardware) &&
      !this.licensing.license.disabledHardware.includes(hardware),
    );
  }

  @task({ drop: true })
  @waitFor
  *save() {
    if (this.isInvalid) {
      this.validationErrorNotifier.sendErrors(this.nonDeletedHardwares);
      this.validationErrorNotifier.sendErrors(this.nonDeletedHardwareIos);
      return;
    }

    yield all(this.hardwareIos.filter(item => item.isDirty).map(hardwareIo => hardwareIo.save()));
    yield all(this.hardwares.filter(item => item.isDirty).map(hardware => hardware.save()));
  }

  @action
  rollback() {
    if (this.selectedHardware?.isDeleted || this.selectedHardware?.isNew) {
      this.closeHardwarePanel();
    }

    this.hardwares.forEach(hardware => { hardware.rollbackAttributes(); });
    this.hardwareIos.forEach(hardwareIo => { hardwareIo.rollbackAttributes(); });
  }

  @action
  addHardware(hardwareType) {
    const hardware = this.hardwareRepo.createHardware(hardwareType);
    this.openHardwarePanel(hardware);
  }

  @action
  deleteHardware(hardware) {
    hardware.hardwareIos.forEach(hardwareIo => { hardwareIo.deleteRecord(); });
    hardware.deleteRecord();
    this.hardwareToDelete = null;
  }

  @action
  toggleDeleteWarning(hardware, event) {
    if (hardware.isInUse) {
      event.preventDefault();
      event.stopPropagation();
      this.hardwareToDelete = hardware;
    } else {
      this.deleteHardware(hardware);
    }
  }

  @action
  visitStation(id) {
    if (id != null) {
      this.router.transitionTo('plant.stations', id);
    }
  }

  @action
  openHardwarePanel(hardware) {
    this.selectedHardware = hardware;
    this.showHardwarePanel = true;
  }

  @action
  closeHardwarePanel() {
    this.showHardwarePanel = false;
    this.selectedHardware = null;
  }
}
