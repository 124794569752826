import Service, { inject as service } from '@ember/service';
import { all, task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import { recursiveSave } from 'eflex/util/tree-helpers';

export default class BomSourceRepoService extends Service {
  @service store;

  restBomSources = this.store.peekAll('restBomSource');
  restPullBomSources = this.store.peekAll('restPullBomSource');
  barcodeBomSources = this.store.peekAll('barcodeBomSource');
  oracleDellBoomiBomSources = this.store.peekAll('oracleDellBoomiBomSource');
  gepicsBomSources = this.store.peekAll('gepicsBomSource');

  get allBomSources() {
    return this.restBomSources
      .concat(this.restPullBomSources)
      .concat(this.barcodeBomSources)
      .concat(this.oracleDellBoomiBomSources)
      .concat(this.gepicsBomSources);
  }

  @task
  @waitFor
  *delete(bomSource) {
    yield bomSource.destroyRecord();
  }

  @task
  @waitFor
  *save(bomSource) {
    yield bomSource.save();
    yield all(bomSource.areas.map(area => recursiveSave(area)));
  }

  create(type, properties = {}) {
    const bomSource = this.store.createRecord(type, properties);

    const alwaysRun = this.store.createRecord('component', {
      isAlwaysRun: true,
      bomSource,
    });

    this.store.createRecord('simpleComponentOption', { component: alwaysRun });

    return bomSource;
  }

  peekRecord(id) {
    return this.allBomSources.find(item => item.id === id);
  }
}
