import Barcode from 'eflex/components/jem/task-specific-display/barcode';
import Button from 'eflex/components/jem/task-specific-display/button';
import Decision from 'eflex/components/jem/task-specific-display/decision';
import Edhr from 'eflex/components/jem/task-specific-display/edhr';
import ErrorProofing from 'eflex/components/jem/task-specific-display/error-proofing';
import ImageCapture from 'eflex/components/jem/task-specific-display/image-capture';
import Multispindle from 'eflex/components/jem/task-specific-display/multispindle';
import NodeRed from 'eflex/components/jem/task-specific-display/node-red';
import Pick from 'eflex/components/jem/task-specific-display/pick';
import Plc from 'eflex/components/jem/task-specific-display/plc';
import Print from 'eflex/components/jem/task-specific-display/print';
import PushToSchedule from 'eflex/components/jem/task-specific-display/push-to-schedule';
import SerialNumberTransfer from 'eflex/components/jem/task-specific-display/serial-number-transfer';
import Timer from 'eflex/components/jem/task-specific-display/timer';
import Torque from 'eflex/components/jem/task-specific-display/torque';
import Vision from 'eflex/components/jem/task-specific-display/vision';
import NoDisplay from 'eflex/components/jem/task-specific-display/no-display';

// eslint-disable-next-line complexity
export default (taskType) => {
  switch (taskType) {
    case 'barcode': {
      return Barcode;
    }
    case 'button': {
      return Button;
    }
    case 'decision': {
      return Decision;
    }
    case 'edhr': {
      return Edhr;
    }
    case 'errorProofing': {
      return ErrorProofing;
    }
    case 'imageCapture': {
      return ImageCapture;
    }
    case 'multispindle': {
      return Multispindle;
    }
    case 'nodeRed': {
      return NodeRed;
    }
    case 'pick': {
      return Pick;
    }
    case 'plc': {
      return Plc;
    }
    case 'print': {
      return Print;
    }
    case 'pushToSchedule': {
      return PushToSchedule;
    }
    case 'serialNumberTransfer': {
      return SerialNumberTransfer;
    }
    case 'timer': {
      return Timer;
    }
    case 'torque': {
      return Torque;
    }
    case 'vision': {
      return Vision;
    }
    default: {
      return NoDisplay;
    }
  }
};
