import { isBlank } from '@ember/utils';
import TaskStatuses from 'eflex/constants/task-statuses';
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class JemTaskSpecificDisplayButtonDetails extends Component {
  _elem;

  get processData() {
    return this.args.childStatus?.processData ?? this.args.task.jemProcessDataDefs.map(({ name }) => ({ name }));
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this._elem = null;
  }

  selectNextFocusable(previousIndex) {
    this._elem
      .querySelector(`.process-data-input[tabindex='${previousIndex + 1}']`)
      ?.focus();
  }

  tryCompleteTask() {
    if (!this.args.childStatus?.task?.isAuto || this.processData.some(item => item.isInvalid)) {
      return;
    }

    this.args.onTaskComplete(TaskStatuses.GOOD, this.args.childStatus);
  }

  @action
  onDidInsert(element) {
    this._elem = element;
    this.focusOnStart(element, [this.args.childStatus?.isFinished]);
  }

  @action
  focusOnStart(element, [isFinished]) {
    if (isFinished == null || isFinished) {
      return;
    }

    this.selectNextFocusable(-1);
  }

  @action
  updateProcessDatum(datum, { target }) {
    let value = target?.value;

    if (datum.type === 'float' && !isBlank(value) && !isNaN(value)) {
      value = Number(value);
    }

    Object.assign(datum, {
      wasFocused: true,
      value,
    });
  }

  @action
  didSelectProcessDatumButton(datum, previousIndex, value) {
    datum.value = value;
    this.selectNextFocusable(previousIndex);
    this.tryCompleteTask();
  }

  @action
  onEnter(previousIndex) {
    this.selectNextFocusable(previousIndex);
    this.tryCompleteTask();
  }
}
