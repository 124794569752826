import { action } from '@ember/object';
import { isBlank, isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { sortByProp } from 'ramda-adjunct';

export default class BaseCriteriaTreeItem extends Component {
  @tracked _expanded = false;

  // jscpd:ignore-start
  get expanded() {
    return this._expanded || !isBlank(this.args.searchTerm);
  }

  get filteredChildren() {
    let sortedChildren;

    if (this.args.treeItem.type === 'station') {
      sortedChildren = this.args.treeItem.sortedChildren;
    } else {
      sortedChildren = sortByProp('order', this.args.treeItem.children ?? []);
    }

    if (isBlank(this.args.searchTerm)) {
      return sortedChildren;
    }

    const searchRegex = new RegExp(this.args.searchTerm, 'i');

    return sortedChildren.filter(treeItem =>
      searchRegex.test(treeItem.name) ||
      treeItem.children?.some(_treeItem => searchRegex.test(_treeItem.name)) ||
      treeItem.tasks?.some(_treeItem => searchRegex.test(_treeItem.name)),
    );
  }

  get hasChildren() {
    return !isEmpty(this.args.treeItem.children);
  }

  get iconClass() {
    if (!this.hasChildren && !this.args.treeItem.type === 'task') {
      return null;
    }

    switch (this.args.treeItem.type) {
      case 'area': {
        return 'tree-area';
      }
      case 'group': {
        return 'tree-group';
      }
      case 'station': {
        return 'tree-station';
      }
      default: {
        return null;
      }
    }
  }

  get expandedClass() {
    if (!this.expanded) {
      return null;
    }

    if (this.args.treeItem.type === 'area' || this.args.treeItem.type === 'group') {
      return 'tree-toggle-down';
    } else {
      return 'tree-station-opened';
    }
  }
  // jscpd:ignore-end

  @action
  expand() {
    this._expanded = !this._expanded;
  }
}
