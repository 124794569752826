function initializeStore(application) {
  application.registerOptionsForType('serializer', {
    singleton: false
  });
  application.registerOptionsForType('adapter', {
    singleton: false
  });
}
export default function setupContainer(application) {
  initializeStore(application);
}