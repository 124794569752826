import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { waitFor } from '@ember/test-waiters';
import Component from '@glimmer/component';

export default class SaveWrapper extends Component {
  @service notifier;
  @service currentUser;
  @service intl;
  @service router;

  #transition;
  @tracked showTransitionModal = false;

  get exitmsg() {
    return this.intl.t('application.unsavedChanges');
  }

  get showDiscard() {
    return this.saveTask.isIdle && this.args.isDirty;
  }

  @task({ drop: true })
  @waitFor
  *saveTask() {
    try {
      yield this.args.save();
    } catch (err) {
      console.error(err);
      this.notifier.sendError('errors.saving');
    }
  }

  @task({ drop: true })
  @waitFor
  *transitionModalSave() {
    this.showTransitionModal = false;

    yield this.saveTask.perform();

    if (this.#transition == null) {
      return;
    }

    if (this.args.isInvalid) {
      try {
        yield this.#transition.abort();
      } catch (e) {
        if (e.name !== 'TransitionAborted') {
          throw e;
        }
      }
    } else {
      this.#transition.retry();
    }
  }

  constructor() {
    super(...arguments);
    this.router.on('routeWillChange', this.#willChangeRoute);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.#transition = null;
    this.router.off('routeWillChange', this.#willChangeRoute);
  }

  @action
  transitionModalDiscard() {
    this.showTransitionModal = false;

    this.args.rollback(true);

    this.#transition?.retry();
  }

  @action
  onBeforeUnload(event) {
    event.preventDefault();
    event.returnValue = this.exitmsg;
  }

  #willChangeRoute = (transition) => {
    if (transition.isAborted || transition.queryParamsOnly || transition.from == null) {
      return;
    }

    if (this.args?.isDirty) {
      this.#transition = transition;
      this.showTransitionModal = true;
      transition.abort();
    }
  };
}
