export default function (intl, key, status) {
  if (intl.exists(key) || window.isTesting) {
    return intl.t(key);
  }

  if (status != null) {
    return `${intl.t('unknown')}(${status})`;
  }

  return intl.t('unknown');
}
