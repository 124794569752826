import Model, { attr, belongsTo } from '@ember-data/model';
import JemContainmentValidations from 'eflex/validations/jem-containment';
import moment from 'moment-timezone';
import { isBlank } from '@ember/utils';
import { buildValidations } from '@eflexsystems/ember-tracked-validations';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

@buildValidations(JemContainmentValidations)
class JemContainment extends Model {
  @attr('string') serialNumber;
  @attr('string') campaign;
  @attr('string') userName;
  @attr('date', { defaultValue() { return new Date(); } }) createdAt;
  @attr('boolean', { defaultValue: false }) scrap;

  @belongsTo('buildStatus', { inverse: null, async: false, embedded: true }) buildStatus;

  @tracked isSelected = false;

  @service intl;

  get hasError() {
    return isBlank(this.serialNumber);
  }

  get lastStation() {
    if (this.buildStatus?.stationName != null) {
      return this.buildStatus.stationName;
    } else {
      return this.intl.t('none');
    }
  }

  get lastStatus() {
    if (this.endDate != null) {
      return this.endDate;
    } else {
      return this.intl.t('none');
    }
  }

  get endDate() {
    if (this.buildStatus == null) {
      return null;
    }
    return moment(this.buildStatus.timestamp).format('MM-DD-YY, h:mma');
  }

  get statusText() {
    if (this.scrap) {
      return this.intl.t('scrapped');
    } else {
      return this.intl.t('contained');
    }
  }
}

export default JemContainment;
