import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { getOwner } from '@ember/application';

const NO_CHROME_ROUTES = ['jem', 'styleguide', 'workInstructions', 'oee'];

export default class ApplicationController extends Controller {
  @tracked isLoading = false;
  @tracked showLogoutWarningModal = false;
  #authToken;

  @service session;
  @service router;
  @service resizeListener;
  @service licensing;
  @service currentUser;
  @service notifier;
  @service webSocket;

  get isNotTesting() {
    return !window.isTesting;
  }

  get isNotIntegrationTesting() {
    return !window.isIntegrationTest;
  }

  get onNoChromePage() {
    return NO_CHROME_ROUTES.some((route) => this.router.currentRouteName.startsWith(route));
  }

  get onBiPage() {
    return this.router.currentRouteName.startsWith('bi');
  }

  @action
  toggleNav() {
    if (this.resizeListener.navBarCollapsed) {
      this.resizeListener.openNavBar();
      this.resizeListener.triggerResize();
    } else {
      this.resizeListener.collapseNavBar();
      this.resizeListener.triggerResize();
    }
  }

  @action
  confirmLogoutWarning() {
    this.showLogoutWarningModal = false;
    getOwner(this).lookup('authenticator:jwt').refreshAccessToken(this.#authToken);
    this.#authToken = null;
    this.notifier.sendWarning('logoutModal.extendNotification');
  }

  @action
  logout() {
    return this.session.invalidate();
  }

  onCloseLogoutWarningModal() {
    if (!this.showLogoutWarningModal) {
      return;
    }

    this.showLogoutWarningModal = false;
    this.#authToken = null;
  }

  onShowLogoutWarningModal(token) {
    if (this.showLogoutWarningModal) {
      return;
    }

    this.#authToken = token;
    this.showLogoutWarningModal = true;
  }
}
