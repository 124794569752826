var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
import { isNone } from '@ember/utils';
import { makeArray } from '@ember/array';
import ValidationError from '../validations/error';
import { tracked } from '@glimmer/tracking';
let InternalResultObject = (_class = class InternalResultObject {
  static create(props) {
    return new InternalResultObject(props);
  }
  constructor(props) {
    _initializerDefineProperty(this, "model", _descriptor, this);
    _initializerDefineProperty(this, "isValid", _descriptor2, this);
    _initializerDefineProperty(this, "message", _descriptor3, this);
    _initializerDefineProperty(this, "warningMessage", _descriptor4, this);
    _initializerDefineProperty(this, "attribute", _descriptor5, this);
    _initializerDefineProperty(this, "_validator", _descriptor6, this);
    Object.assign(this, props);
  }
  get _type() {
    return this._validator._type;
  }
  get isWarning() {
    return this._validator.isWarning;
  }
  get isInvalid() {
    return !this.isValid;
  }
  get messages() {
    return makeArray(this.message);
  }
  get error() {
    if (this.isInvalid) {
      return ValidationError.create({
        type: this._type,
        message: this.message,
        attribute: this.attribute
      });
    }
    return null;
  }
  get errors() {
    return makeArray(this.error);
  }
  get warningMessages() {
    return makeArray(this.warningMessage);
  }
  get warning() {
    if (this.isWarning && !isNone(this.warningMessage)) {
      return ValidationError.create({
        type: this._type,
        message: this.warningMessage,
        attribute: this.attribute
      });
    }
    return null;
  }
  get warnings() {
    return makeArray(this.warning);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "model", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isValid", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return true;
  }
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "message", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "warningMessage", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "attribute", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return '';
  }
}), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "_validator", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { InternalResultObject as default };