import Model, { attr, belongsTo } from '@ember-data/model';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';

@buildValidations({
  opacity: [validator('number', { gte: 0, lte: 100, allowNone: false })],
})
class WieConfigImage extends Model {
  @belongsTo('wieConfig/stroke', { inverse: null, async: false, embedded: true }) stroke;

  @attr('number') opacity;

  get isDirty() {
    return super.isDirty || this.stroke.isDirty;
  }
}

export default WieConfigImage;
