import Model, { belongsTo } from '@ember-data/model';
import { arrayAttr } from 'eflex/decorators';

export default class WieConfig extends Model {
  @belongsTo('wieConfig/editorDefaults', { inverse: null, async: false, embedded: true }) editorDefaults;

  @arrayAttr wieDeployerTags;
  @arrayAttr wieEditorTags;
  @arrayAttr wieApproverTags;

  get isDirty() {
    return super.isDirty || this.editorDefaults.isDirty;
  }
}
