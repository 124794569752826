var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;
function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
import { tagName } from '@ember-decorators/component';
import { action, computed } from '@ember/object';
import Component from '@ember/component';
import { next } from '@ember/runloop';
import { getDestinationElement } from 'ember-bootstrap/utils/dom';
import defaultValue from 'ember-bootstrap/utils/default-decorator';
import deprecateSubclassing from 'ember-bootstrap/utils/deprecate-subclassing';
import { ref } from 'ember-ref-bucket';

/**
 Component for the dropdown menu.

 See [Components.Dropdown](Components.Dropdown.html) for examples.

 @class DropdownMenu
 @namespace Components
 @extends Ember.Component
 @public
 */
let DropdownMenu = (_dec = tagName(''), _dec2 = ref('menuElement'), _dec3 = computed('destinationElement', 'renderInPlace'), _dec4 = computed('align'), _dec5 = computed('direction', 'align'), _dec6 = computed('flip', 'popperPlacement'), _dec(_class = deprecateSubclassing(_class = (_class2 = class DropdownMenu extends Component {
  constructor(...args) {
    super(...args);
    /**
     * @property _element
     * @type null | HTMLElement
     * @private
     */
    _initializerDefineProperty(this, "menuElement", _descriptor, this);
    /**
     * @property ariaRole
     * @default menu
     * @type string
     * @protected
     */
    this.ariaRole = 'menu';
    /**
     * Alignment of the menu, either "left" or "right"
     *
     * @property align
     * @type string
     * @default left
     * @public
     */
    _initializerDefineProperty(this, "align", _descriptor2, this);
    /**
     * @property direction
     * @default 'down'
     * @type string
     * @private
     */
    _initializerDefineProperty(this, "direction", _descriptor3, this);
    /**
     * By default the menu is rendered in the same place as the dropdown. If you experience clipping
     * issues, you can set this to false to render the menu in a wormhole at the top of the DOM.
     *
     * @property renderInPlace
     * @type boolean
     * @default true
     * @public
     */
    _initializerDefineProperty(this, "renderInPlace", _descriptor4, this);
    this._isOpen = false;
    this.flip = true;
    this._popperApi = null;
  }
  /**
   * @property _renderInPlace
   * @type boolean
   * @private
   */
  get _renderInPlace() {
    return this.renderInPlace || !this.destinationElement;
  }

  /**
   * The wormhole destinationElement
   *
   * @property destinationElement
   * @type object
   * @readonly
   * @private
   */
  get destinationElement() {
    return getDestinationElement(this);
  }
  get alignClass() {
    return this.align !== 'left' ? `dropdown-menu-${this.align}` : undefined;
  }
  get isOpen() {
    return false;
  }
  set isOpen(value) {
    // delay removing the menu from DOM to allow (delegated Ember) event to fire for the menu's children
    // Fixes https://github.com/kaliber5/ember-bootstrap/issues/660
    next(() => {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      this.set('_isOpen', value);
    });
    return value;
  }
  get popperPlacement() {
    let placement = 'bottom-start';
    let {
      direction,
      align
    } = this;
    if (direction === 'up') {
      placement = 'top-start';
      if (align === 'right') {
        placement = 'top-end';
      }
    } else if (direction === 'left') {
      placement = 'left-start';
    } else if (direction === 'right') {
      placement = 'right-start';
    } else if (align === 'right') {
      placement = 'bottom-end';
    }
    return placement;
  }
  setFocus() {
    // when the dropdown menu is rendered in place, focus can stay on the toggle element
    if (this._renderInPlace) {
      return;
    }
    if (this.menuElement) {
      this.menuElement.focus();
    }
  }
  get popperOptions() {
    return {
      placement: this.popperPlacement,
      onFirstUpdate: () => this.setFocus(),
      modifiers: [{
        name: 'flip',
        enabled: this.flip
      }]
    };
  }

  /**
   * @property itemComponent
   * @type {String}
   * @private
   */

  /**
   * @property linkToComponent
   * @type {String}
   * @private
   */

  /**
   * @property dividerComponent
   * @type {String}
   * @private
   */
}, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "menuElement", [_dec2], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return null;
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "align", [defaultValue], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return 'left';
  }
}), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "direction", [defaultValue], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return 'down';
  }
}), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "renderInPlace", [defaultValue], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return true;
  }
}), _applyDecoratedDescriptor(_class2.prototype, "_renderInPlace", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "_renderInPlace"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "destinationElement", [computed], Object.getOwnPropertyDescriptor(_class2.prototype, "destinationElement"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "alignClass", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "alignClass"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isOpen", [computed], Object.getOwnPropertyDescriptor(_class2.prototype, "isOpen"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "popperPlacement", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "popperPlacement"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setFocus", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "setFocus"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "popperOptions", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "popperOptions"), _class2.prototype)), _class2)) || _class) || _class);
export { DropdownMenu as default };