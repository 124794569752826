import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { anyInvalid } from 'eflex/util/getter-helpers';
import { buildValidations, validator } from '@eflexsystems/ember-tracked-validations';
import OEE_STATES from 'eflex/constants/oee-states';

@buildValidations({
  name: [validator('presence', true)],
})
class OeeButton extends Model {
  @attr('string') name;
  @attr('string') color;
  @attr('boolean', { defaultValue: false }) isCustom;
  @attr('string', { defaultValue: 'oeeButton' }) type;

  @belongsTo('area', { async: false, inverse: 'oeeButtons' }) area;

  @hasMany('oeeState', { async: false, inverse: 'button' }) oeeStates;
  @hasMany('trigger', { async: false, polymorphic: true, inverse: 'oeeButton', embedded: true }) triggers;
  @hasMany('triggerConfig', { async: false, polymorphic: true, inverse: 'oeeButton', embedded: true }) triggerConfigs;

  get isDirty() {
    return super.isDirty ||
      this.triggers.some(item => item.isDirty) ||
      this.triggerConfigs.some(item => item.isDirty);
  }

  get captionKey() {
    return `oee.${this.name}`;
  }

  get isButton() {
    return true;
  }

  get triggerConfigsInvalid() {
    return anyInvalid(this.triggerConfigs);
  }

  get triggersInvalid() {
    return anyInvalid(this.triggers);
  }

  get activeState() {
    return this.oeeStates?.find((oeeState) => oeeState.endDate == null);
  }

  get background() {
    if (this.name === OEE_STATES.STATES.RUNNING) {
      return 'running';
    }

    if (this.name === OEE_STATES.STATES.FAULTED) {
      return 'faulted';
    }

    return 'default';
  }

  rollbackAttributes() {
    // prevents a double unload from cascadeDelete and embedded
    this.triggers.forEach(trigger => { trigger.rollbackAttributes(); });
    super.rollbackAttributes();
  }
}

export default OeeButton;
