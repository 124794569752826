import { inject as service } from '@ember/service';
import { TriggerEvents, StationEvents, OeeEvents } from 'eflex/constants/tasks/trigger-events';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { pipe, reduce } from 'ramda';
import { sortByProp } from 'ramda-adjunct';
import AdamIo from 'eflex/components/trigger-configs/adam-io';
import Audio from 'eflex/components/trigger-configs/audio';
import Edhr from 'eflex/components/trigger-configs/edhr';
import LightGuide from 'eflex/components/trigger-configs/light-guide';
import NodeRed from 'eflex/components/trigger-configs/node-red';
import PlcHardware from 'eflex/components/trigger-configs/plc-hardware';
import TurckIo from 'eflex/components/trigger-configs/turck-io';

const ALLOWED_TRIGGER_TYPES = [
  'light-guide',
  'adam-io',
  'edhr',
  'audio',
  'plc-hardware',
  'turck-io',
  'node-red',
];

const OEE_ALLOWED_TRIGGER_TYPES = [
  'light-guide',
  'adam-io',
  'audio',
  'plc-hardware',
  'turck-io',
  'node-red',
];

const HIDDEN_TRIGGER_CONFIG_TYPES = new Set(['work-instruction-hardware-trigger-config']);

export default class TriggerConfigs extends Component {
  @service hardwareRepo;
  @service triggerRepo;

  get useModelSpecific() {
    return this.args.useModelSpecific ?? true;
  }

  get nonDeletedTriggerConfigs() {
    return this.args.triggerConfigs?.filter(item => !item.isDeleted) ?? [];
  }

  get triggerEvents() {
    if (this.args.isOeeEvent) {
      return OeeEvents;
    } else if (this.args.isStationEvent) {
      return StationEvents;
    } else {
      return TriggerEvents;
    }
  }

  get visibleTriggerConfigs() {
    return this.nonDeletedTriggerConfigs.filter(triggerConfig =>
      !HIDDEN_TRIGGER_CONFIG_TYPES.has(triggerConfig.constructor.modelName),
    );
  }

  get hardwares() {
    const types = this.args.isOeeEvent ? OEE_ALLOWED_TRIGGER_TYPES : ALLOWED_TRIGGER_TYPES;
    const hardware = this.hardwareRepo.hardwareByType(types);

    return pipe(
      reduce((acc, item) => {
        const existing = acc.find(_item => _item.groupName === item.hardwareGroup);

        if (existing != null) {
          existing.options.push(item);
        } else {
          acc.push({
            groupName: item.hardwareGroup,
            options: [item],
          });
        }

        return acc;
      }, []),
      sortByProp('groupName'),
    )(hardware ?? []);
  }

  getTriggerComponent = (triggerConfig) => {
    switch (triggerConfig.hardware?.constructor.modelName) {
      case 'adam-io': {
        return AdamIo;
      }
      case 'audio': {
        return Audio;
      }
      case 'edhr': {
        return Edhr;
      }
      case 'light-guide': {
        return LightGuide;
      }
      case 'node-red': {
        return NodeRed;
      }
      case 'plc-hardware': {
        return PlcHardware;
      }
      case 'turck-io': {
        return TurckIo;
      }
      default: {
        return null;
      }
    }
  };

  @action
  addTrigger(hardware) {
    this.triggerRepo.createTrigger(hardware, this.args.location);
  }

  @action
  deleteTrigger(trigger) {
    trigger.triggerConfigs.forEach(triggerConfig => { triggerConfig.deleteRecord(); });
    trigger.deleteRecord();
  }
}
