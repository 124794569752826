import { validator } from '@eflexsystems/ember-tracked-validations';
import { getOwner } from '@ember/application';

export default {
  reasonCode: [validator('presence', true)],

  operation: [validator('presence', true)],

  event: [
    validator('inline', {
      validate(event, options, edhrTrigger) {
        if (event === 'start' && edhrTrigger.edhrMappings.some(item => item.isSendImage)) {
          return getOwner(edhrTrigger).lookup('service:intl').t('validations.edhrMapping.sendFile');
        }

        return true;
      },
    }),
  ],
};
