import Service, { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';

export default class FileUploaderService extends Service {
  @service eflexAjax;

  @task
  @waitFor
  *post(url, file, extra = {}) {
    return yield this.eflexAjax.post.perform(url, this._getFileFormData(file, extra), '*/*');
  }

  @task
  @waitFor
  *put(url, file, extra = {}) {
    return yield this.eflexAjax.put.perform(url, this._getFileFormData(file, extra), '*/*');
  }

  _getFileFormData(file, extra = {}) {
    const fd = new FormData();
    for (const prop in extra) {
      fd.append(prop, extra[prop]);
    }
    fd.append('file', file);
    return fd;
  }
}
