import AuthenticatedRoute from 'eflex/util/authenticated-route';
import QueryParams from 'eflex/constants/bi-query-params';
import { tracked } from '@glimmer/tracking';

export default class BiOeeFaultsRoute extends AuthenticatedRoute {
  @tracked queryParams = {
    ...QueryParams,
    states: {
      refreshModel: true,
    },
  };
}
