const TextOptions = {};

TextOptions.fontFamilies = [
  'Arial',
  'Arial Black',
  'Bookman',
  'Courier',
  'Courier New',
  'Garamond',
  'Georgia',
  'Helvetica',
  'Impact',
  'Open Sans',
  'Palatino',
  'Times',
  'Times New Roman',
  'Trebuchet MS',
  'Verdana',
];

TextOptions.defaultFont = 'Helvetica';
TextOptions.defaultText = 'Lorem ipsum dolor';

TextOptions.fontWeights = [
  { label: 'imageEditor.text.regular', value: 'normal' },
  { label: 'imageEditor.text.thin', value: '100' },
  { label: 'imageEditor.text.bold', value: 'bold' },
];

TextOptions.fontWeightOptions = ['normal', '100', 'bold'];

let StrokeOptions = {};

StrokeOptions = {
  SOLID: 'solid',
  DASHED: 'dashed',
  DOTTED: 'dotted',
};

StrokeOptions.default = StrokeOptions.SOLID;

StrokeOptions.options = [StrokeOptions.SOLID, StrokeOptions.DASHED, StrokeOptions.DOTTED];

const EditorModes = {
  CROP: 'CROP',
  LINES: 'LINES',
  NORMAL: 'NORMAL',
  SHAPE: 'SHAPE',
  TEXT: 'TEXT',
  IMAGE: 'IMAGE',
  SELECTION: 'SELECTION',
  ICONS: 'ICONS',
};

const TextTypes = {
  TEXT: 'text',
  ITEXT: 'i-text',
  TEXTBOX: 'textbox',
};

const ShapeTypes = {
  RECT: 'rect',
  ELLIPSE: 'ellipse',
  TRIANGLE: 'triangle',
};

ShapeTypes.options = [ShapeTypes.RECT, ShapeTypes.ELLIPSE, ShapeTypes.TRIANGLE];

const LineTypes = {
  STRAIGHT_LINE: 'straight-line',
  FREE_LINE: 'free-line',
};

LineTypes.options = [LineTypes.STRAIGHT_LINE, LineTypes.FREE_LINE];

const AlignmentTypes = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
  JUSTIFY: 'justify',
};

AlignmentTypes.options = [AlignmentTypes.LEFT, AlignmentTypes.CENTER, AlignmentTypes.RIGHT, AlignmentTypes.JUSTIFY];

const EflexObjTypes = {
  ICON: 'icon',
  STRAIGHT_LINE: 'straight-line',
  FREE_LINE: 'free-line',
  ARROWHEAD: 'arrowhead',
  HANDLE: 'handle',
  CROPBOX: 'cropbox',
  LINE_GROUP: 'line-group',
};

EflexObjTypes.options = new Set([
  EflexObjTypes.ICON,
  EflexObjTypes.STRAIGHT_LINE,
  EflexObjTypes.FREE_LINE,
  EflexObjTypes.ARROWHEAD,
  EflexObjTypes.HANDLE,
  EflexObjTypes.CROPBOX,
  EflexObjTypes.LINE_GROUP,
]);

const ObjectTypesToEditorModes = {
  [EditorModes.ICONS]: new Set([EflexObjTypes.ICON]),
  [EditorModes.TEXT]: new Set([TextTypes.TEXT, TextTypes.ITEXT, TextTypes.TEXTBOX]),
  [EditorModes.LINES]: new Set([EflexObjTypes.STRAIGHT_LINE, EflexObjTypes.FREE_LINE]),
  [EditorModes.SHAPE]: new Set([ShapeTypes.RECT, ShapeTypes.ELLIPSE, ShapeTypes.TRIANGLE]),
  [EditorModes.IMAGE]: new Set(['image']),
  [EditorModes.SELECTION]: new Set(['group', 'activeSelection']),
};

const IconFileNames = [
  'wie-icons-arrow1',
  'wie-icons-arrow2',
  'wie-icons-arrow3',
  'wie-icons-arrow4',
  'wie-icons-arrow5',
  'wie-icons-arrow6',
  'wie-icons-arrow7',
  'wie-icons-arrow8',
  'wie-icons-arrow9',
  'wie-icons-star1',
  'wie-icons-star2',
  'wie-icons-star3',
  'wie-icons-checkmark1',
  'wie-icons-checkmark2',
  'wie-icons-checkmark3',
  'wie-icons-x1',
  'wie-icons-x2',
  'wie-icons-x3',
  'wie-icons-alert',
  'wie-icons-donot',
  'wie-icons-smileyface',
];

const FABRIC_FILL_HEX_LENGTH = 7;

const ControlStyles = {
  borderColor: '#B5B5B5',
  cornerColor: 'rgba(255, 255, 255, 0.9)',
  cornerStrokeColor: '#B5B5B5',
  cornerSize: 15,
};

export {
  TextOptions,
  StrokeOptions,
  IconFileNames,
  ObjectTypesToEditorModes,
  EditorModes,
  TextTypes,
  ShapeTypes,
  LineTypes,
  AlignmentTypes,
  EflexObjTypes,
  FABRIC_FILL_HEX_LENGTH,
  ControlStyles,
};
