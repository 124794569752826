const NoteTypes = {
  general: 'general',
  improvement: 'improvement',
  maintenance: 'maintenance',
};

const SelectNoteTypes = [
  { value: NoteTypes.general, label: 'plant.jem.notes.types.general' },
  { value: NoteTypes.improvement, label: 'plant.jem.notes.types.improvement' },
  { value: NoteTypes.maintenance, label: 'plant.jem.notes.types.maintenance' },
];

export { NoteTypes, SelectNoteTypes };
