import AuthenticatedRoute from 'eflex/util/authenticated-route';
import QueryParams from 'eflex/constants/bi-query-params';
import { tracked } from '@glimmer/tracking';

export default class BiProcessDataRoute extends AuthenticatedRoute {
  @tracked queryParams = {
    ...QueryParams,
    processDataDefIds: {
      refreshModel: true,
    },
  };
}
