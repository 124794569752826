export { e as EmbeddedRecordsMixin } from "./embedded-records-mixin-d75385ff";
export { default as Transform } from '@ember/object';

/**
  @module @ember-data/serializer
*/

/**
  The `BooleanTransform` class is used to serialize and deserialize
  boolean attributes on Ember Data record objects. This transform is
  used when `boolean` is passed as the type parameter to the
  [attr](/ember-data/release/functions/@ember-data%2Fmodel/attr) function.

  Usage

  ```app/models/user.js
  import Model, { attr } from '@ember-data/model';

  export default class UserModel extends Model {
    @attr('boolean') isAdmin;
    @attr('string') name;
    @attr('string') email;
  }
  ```

  By default, the boolean transform only allows for values of `true` or
  `false`. You can opt into allowing `null` values for
  boolean attributes via `attr('boolean', { allowNull: true })`

  ```app/models/user.js
  import Model, { attr } from '@ember-data/model';

  export default class UserModel extends Model {
    @attr('string') email;
    @attr('string') username;
    @attr('boolean', { allowNull: true }) wantsWeeklyEmail;
  }
  ```

  @class BooleanTransform
  @public
 */
class BooleanTransform {
  deserialize(serialized, options) {
    if ((serialized === null || serialized === undefined) && options.allowNull === true) {
      return null;
    }
    let type = typeof serialized;
    if (type === 'boolean') {
      return serialized;
    } else if (type === 'string') {
      return /^(true|t|1)$/i.test(serialized);
    } else if (type === 'number') {
      return serialized === 1;
    } else {
      return false;
    }
  }
  serialize(deserialized, options) {
    if ((deserialized === null || deserialized === undefined) && options.allowNull === true) {
      return null;
    }
    return Boolean(deserialized);
  }
  static create() {
    return new this();
  }
}

/**
  @module @ember-data/serializer
*/

/**
 The `DateTransform` class is used to serialize and deserialize
 date attributes on Ember Data record objects. This transform is used
 when `date` is passed as the type parameter to the
 [attr](/ember-data/release/functions/@ember-data%2Fmodel/attr) function. It uses the [`ISO 8601`](https://en.wikipedia.org/wiki/ISO_8601)
 standard.

 ```app/models/score.js
 import Model, { attr, belongsTo } from '@ember-data/model';

 export default class ScoreModel extends Model {
    @attr('number') value;
    @belongsTo('player') player;
    @attr('date') date;
  }
 ```

 @class DateTransform
  @public
 */

class DateTransform {
  deserialize(serialized) {
    let type = typeof serialized;
    if (type === 'string') {
      let offset = serialized.indexOf('+');
      if (offset !== -1 && serialized.length - 5 === offset) {
        offset += 3;
        return new Date(serialized.slice(0, offset) + ':' + serialized.slice(offset));
      }
      return new Date(serialized);
    } else if (type === 'number') {
      return new Date(serialized);
    } else if (serialized === null || serialized === undefined) {
      // if the value is null return null
      // if the value is not present in the data return undefined
      return serialized;
    } else {
      return null;
    }
  }
  serialize(date) {
    if (date instanceof Date && !isNaN(date)) {
      return date.toISOString();
    } else {
      return null;
    }
  }
  static create() {
    return new this();
  }
}

/**
  @module @ember-data/serializer
*/

function isNumber(value) {
  return value === value && value !== Infinity && value !== -Infinity;
}

/**
  The `NumberTransform` class is used to serialize and deserialize
  numeric attributes on Ember Data record objects. This transform is
  used when `number` is passed as the type parameter to the
  [attr](/ember-data/release/functions/@ember-data%2Fmodel/attr) function.

  Usage

  ```app/models/score.js
  import Model, { attr, belongsTo } from '@ember-data/model';

  export default class ScoreModel extends Model {
    @attr('number') value;
    @belongsTo('player') player;
    @attr('date') date;
  }
  ```

  @class NumberTransform
  @public
 */
class NumberTransform {
  deserialize(serialized) {
    let transformed;
    if (serialized === '' || serialized === null || serialized === undefined) {
      return null;
    } else {
      transformed = Number(serialized);
      return isNumber(transformed) ? transformed : null;
    }
  }
  serialize(deserialized) {
    let transformed;
    if (deserialized === '' || deserialized === null || deserialized === undefined) {
      return null;
    } else {
      transformed = Number(deserialized);
      return isNumber(transformed) ? transformed : null;
    }
  }
  static create() {
    return new this();
  }
}

/**
  @module @ember-data/serializer
*/

/**
  The `StringTransform` class is used to serialize and deserialize
  string attributes on Ember Data record objects. This transform is
  used when `string` is passed as the type parameter to the
  [attr](/ember-data/release/functions/@ember-data%2Fmodel/attr) function.

  Usage

  ```app/models/user.js
  import Model, { attr, belongsTo } from '@ember-data/model';

  export default class UserModel extends Model {
    @attr('boolean') isAdmin;
    @attr('string') name;
    @attr('string') email;
  }
  ```

  @class StringTransform
  @public
 */
class StringTransform {
  deserialize(serialized) {
    return !serialized && serialized !== '' ? null : String(serialized);
  }
  serialize(deserialized) {
    return !deserialized && deserialized !== '' ? null : String(deserialized);
  }
  static create() {
    return new this();
  }
}
export { BooleanTransform, DateTransform, NumberTransform, StringTransform };