import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';
import { waitFor } from '@ember/test-waiters';

export default class FileButton extends Component {
  @service fileUploader;
  @service notifier;

  get fileSelector() {
    return this.args.fileSelector ?? `${guidFor(this)}-file-upload`;
  }

  get allowTypes() {
    return this.args.allowTypes ?? ['*/*'];
  }

  @task({ drop: true })
  @waitFor
  *upload(files) {
    const file = files.target.files[0];

    if (this.args.skipUpload) {
      this.args.onSuccess(file);
      return;
    }

    try {
      const response = yield this.fileUploader.post.perform(this.args.path, file);
      this.args.onSuccess(response);
    } catch (error) {
      console.error(error);
      this.notifier.sendError(error);
    }
  }
}
