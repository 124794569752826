import { checkUniqueField } from 'eflex/util/validators';
import { getOwner } from '@ember/application';

export default function (model) {
  const owner = getOwner(model);

  const tasks = owner.lookup('service:taskRepo').tasks;
  const checkedTasks = tasks
    .filter(item => item.isNodeRed)
    .filter(item => !item.isDeleted);

  const nodeRedTriggers = owner.lookup('service:triggerRepo').nodeRedTriggers;
  const checkedTriggers = nodeRedTriggers.filter(item => !item.isDeleted);

  const checked = checkedTasks.concat(checkedTriggers);

  return checkUniqueField(model, checked, 'mqttTopic').result;
}
