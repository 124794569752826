import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class WorkInstructionEditorLibraryListView extends Component {
  @service workInstructionRepo;
  @service currentUser;

  @action
  libraryCardDraggable(workInstruction) {
    return this.workInstructionRepo.userCanEdit(this.currentUser, workInstruction);
  }
}
