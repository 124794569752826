import { validator } from '@eflexsystems/ember-tracked-validations';
import { getOwner } from '@ember/application';

export default {
  name: [
    validator('presence', {
      presence: true,
      ignoreBlank: true,
    }),
    validator('inline', {
      validate(name, options, workInstructionFolder) {
        const intl = getOwner(workInstructionFolder).lookup('service:intl');
        if (name === intl.t('none')) {
          return intl.t('workInstructions.folderNameInvalid');
        }

        return true;
      },
    }),
  ],
};
