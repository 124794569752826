import Hardware from 'eflex/models/hardware';
import { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';

@buildValidations({
  name: [validator('presence', true)],
  cameraName: [validator('presence', true)],
  cameraId: [validator('presence', true)],
})
class WebCam extends Hardware {
  @attr('string') cameraId;
  @attr('string') cameraName;

  @service mediaDevices;

  get isWebCam() {
    return true;
  }

  get isConfigured() {
    return isPresent(this.cameraId);
  }

  get isConnected() {
    return this.mediaDevices.devices.some(item => item.deviceId === this.cameraId);
  }
}

export default WebCam;
