import Model, { attr, belongsTo } from '@ember-data/model';
import moment from 'moment-timezone';
import ScheduleDayValidations from 'eflex/validations/schedule-day';
import { buildValidations } from '@eflexsystems/ember-tracked-validations';
import { inject as service } from '@ember/service';
import weekdays from 'eflex/constants/weekdays';

@buildValidations(ScheduleDayValidations)
class ScheduleDayModel extends Model {
  @attr('number') day;
  @attr('number') startHours;
  @attr('number') startMinutes;
  @attr('number') endHours;
  @attr('number') endMinutes;

  @belongsTo('schedule', { async: false, inverse: 'days' }) schedule;

  @service intl;

  get startTime() {
    return moment(`${this.startHours}:${this.startMinutes}`, 'HH:mm').toISOString();
  }

  get endTime() {
    return moment(`${this.endHours}:${this.endMinutes}`, 'HH:mm').toISOString();
  }

  get startTimeWithTimezone() {
    if (!this.isTimeSet) {
      return null;
    }

    let startTime = moment.tz(
      {
        hour: this.startHours,
        minute: this.startMinutes,
      },
      this.schedule.timezone,
    );

    startTime.day(this.day);
    return startTime;
  }

  get endTimeWithTimezone() {
    if (!this.isTimeSet) {
      return null;
    }

    let endTime = moment.tz(
      {
        hour: this.endHours,
        minute: this.endMinutes,
      },
      this.schedule.timezone,
    );

    let day = this.day;

    if (this.passesMidnight) {
      day += 1;
    }

    endTime.day(day);
    return endTime;
  }

  get passesMidnight() {
    return moment(this.endTime).isSameOrBefore(this.startTime);
  }

  get isTimeSet() {
    return this.startTime && this.endTime;
  }

  get isCurrentDay() {
    return moment.tz(this.schedule.timezone).day() === this.day;
  }

  get isRunning() {
    if (!this.isCurrentDay) {
      return false;
    }

    return moment().isBetween(this.startTimeWithTimezone, this.endTimeWithTimezone);
  }

  get nextDay() {
    const nextDay = (this.day === 6) ? 0 : this.day + 1;
    return this.schedule.days.find(item => item.day === nextDay);
  }

  get prevDay() {
    const previousDay = (this.day === 0) ? 6 : this.day - 1;
    return this.schedule.days.find(item => item.day === previousDay);
  }

  get prevStart() {
    const dayAdd = (this.day === 0) ? -7 : 0;
    return this.prevDay.startTimeWithTimezone?.clone().add({ days: dayAdd });
  }

  get prevEnd() {
    const dayAdd = (this.day === 0) ? -7 : 0;
    return this.prevDay.endTimeWithTimezone?.clone().add({ days: dayAdd });
  }

  get nextStart() {
    const dayAdd = (this.day === 6) ? 7 : 0;
    return this.nextDay.startTimeWithTimezone?.clone().add({ days: dayAdd });
  }

  get nextEnd() {
    const dayAdd = (this.day === 6) ? 7 : 0;
    return this.nextDay.endTimeWithTimezone?.clone().add({ days: dayAdd });
  }

  get dayName() {
    return this.intl.t(weekdays.find(item => item.value === this.day).label);
  }

  get duration() {
    if (!this.isTimeSet) {
      return '---';
    }

    const diff = this.endTimeWithTimezone.diff(this.startTimeWithTimezone, 'minutes');
    return `${parseInt(diff / 60)}h ${diff % 60}m`;
  }
}

export default ScheduleDayModel;
