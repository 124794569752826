import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class AudioTriggerConfig extends Component {
  @service intl;

  get audioTypes() {
    return [
      { label: this.intl.t('sound'), value: 'sound' },
      { label: this.intl.t('text'), value: 'text' },
    ];
  }
}
