import Model, { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { getCaptionText } from 'eflex/util/translation-helper';

export default class LiveBuildStatusLog extends Model {
  @attr('string') action;
  @attr('string') ipAddress;
  @attr('date') createdAt;
  @attr('date') updatedAt;
  @attr liveBuildStatus;

  @service currentUser;

  get stationName() {
    return getCaptionText(this.liveBuildStatus?.location?.captions, this.currentUser);
  }

  get serialNumber() {
    return this.liveBuildStatus?.serialNumber;
  }

  get userName() {
    return this.liveBuildStatus?.userName;
  }
}
