import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class HardwareOpenProtocolTool extends Component {
  calibrationDatePicker;
  expirationDatePicker;

  willDestroy() {
    super.willDestroy(...arguments);
    Object.assign(this, {
      calibrationDatePicker: null,
      expirationDatePicker: null,
    });
  }

  @action
  calibrationDatePickerReady(_selectedDates, _dateStr, instance) {
    this.calibrationDatePicker = instance;
  }

  @action
  expirationDatePickerReady(_selectedDates, _dateStr, instance) {
    this.expirationDatePicker = instance;
  }

  @action
  toggleCalibrationDatePicker(event) {
    this.calibrationDatePicker.toggle();
    event.stopPropagation();
  }

  @action
  toggleExpirationDatePicker(event) {
    this.expirationDatePicker.toggle();
    event.stopPropagation();
  }

  @action
  onExpirationDateChange([date]) {
    this.args.hardware.calibrationExpirationDate = date;
  }

  @action
  onCalibrationDateChange([date]) {
    this.args.hardware.calibrationDate = date;
  }
}
