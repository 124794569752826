import { validator } from '@eflexsystems/ember-tracked-validations';

export default {
  name: [
    validator('presence', {
      presence: true,
      ignoreBlank: true,
    }),
  ],
};
