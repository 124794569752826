import ApplicationSerializer from 'eflex/serializers/application';

export default class AreaSerializer extends ApplicationSerializer {
  attrs = {
    groups: {
      deserialize: false,
      serialize: false,
    },
    models: {
      deserialize: false,
      serialize: false,
    },
    oeeButtons: {
      deserialize: false,
      serialize: false,
    },
    bomSource: {
      deserialize: false,
      serialize: false,
    },
  };
}
