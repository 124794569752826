import Model, { attr } from '@ember-data/model';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { TextOptions } from 'eflex/constants/work-instructions/tool-props';

@buildValidations({
  family: [validator('inclusion', { in: TextOptions.fontFamilies })],
  weight: [validator('inclusion', { in: TextOptions.fontWeightOptions })],
  size: [validator('number', { gte: 6, lte: 96, allowNone: false })],
  color: [validator('format', { regex: '#[A-Fa-fd]{6}' })],
})
class WieConfigFont extends Model {
  @attr('string') family;
  @attr('string') weight;
  @attr('string') size;
  @attr('string') style;
  @attr('string') color;
  @attr('boolean') strikethrough;
  @attr('boolean') underline;
}

export default WieConfigFont;
