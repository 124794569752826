import Tokens from 'eflex/constants/date-picker-tokens';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class DatePickerWithTokens extends Component {
  @service intl;

  tokens = Tokens;

  get locale() {
    return this.intl.locale[0].replace(/-.*/, '');
  }

  @action
  selectToken(token) {
    this.args.onChangeStartDate(token.startDate());
    this.args.onChangeEndDate(token.endDate());
    this.args.onChangeSelectedToken(token);
  }

  @action
  setStartDate(date) {
    this.args.onChangeStartDate(date);
    this.args.onChangeSelectedToken(null);
  }

  @action
  setEndDate(date) {
    this.args.onChangeEndDate(date);
    this.args.onChangeSelectedToken(null);
  }
}
