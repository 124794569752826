import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { all, task, timeout } from 'ember-concurrency';
import { task as trackedTask } from 'ember-resources/util/ember-concurrency';
import OEE_STATES from 'eflex/constants/oee-states';
import moment from 'moment-timezone';
import { formatStateColumnData } from 'eflex/util/bi-format-oee-state-cell';
import { tracked } from '@glimmer/tracking';
import TaskStatuses from 'eflex/constants/task-statuses';
import echartsTheme from 'eflex/echarts-theme';
import { waitFor } from '@ember/test-waiters';

const REFRESH_INTERVAL = 15_000;

// jscpd:ignore-start
export default class AndonStationSummary extends Component {
  @service queryRunner;
  @service intl;
  @service store;
  @service stationRepo;
  @service licensing;

  @tracked timerToken;

  @task({ drop: true })
  *startAutoRefresh() {
    if (window.isTesting || window.isIntegrationTest) {
      return;
    }

    while (true) {
      yield timeout(REFRESH_INTERVAL);
      this.timerToken = true;
    }
  }

  get params() {
    return {
      stationIds: this.args.stationIds,
    };
  }
  // jscpd:ignore-end

  get tableConfiguration() {
    return {
      columns: [
        {
          title: this.intl.t('station'),
          field: 'station',
          tooltip: false,
          headerSort: false,
          formatter: 'textarea',
        },

        {
          title: this.intl.t('bi.chartLabel.operator'),
          field: 'operator',
          tooltip: false,
          headerSort: false,
          formatter: 'textarea',
        },
        {
          title: this.intl.t('model'),
          field: 'model',
          tooltip: false,
          headerSort: false,
          formatter: 'textarea',
        },
        {
          title: this.intl.t('bi.chartLabel.oeeState'),
          field: 'state',
          tooltip: false,
          headerSort: false,
          formatter: (cell) => formatStateColumnData(cell),
          width: 100,
        },
        {
          title: this.intl.t('bi.chartLabel.oeeStateDuration'),
          field: 'oeeStateDuration',
          tooltip: false,
          headerSort: false,
          formatter: (cell) => this.formatStateDuration(cell),
          maxWidth: 185,
        },
        {
          title: this.intl.t('bi.chartLabel.faults'),
          field: 'faults',
          tooltip: false,
          headerSort: false,
          formatter: 'textarea',
        },
        {
          title: this.intl.t('bi.chartLabel.currentStationState'),
          field: 'stationState',
          tooltip: false,
          headerSort: false,
          minWidth: 100,
        },
        {
          title: this.intl.t('bi.chartLabel.stationCycleTime'),
          field: 'stationCycleTime',
          tooltip: false,
          headerSort: false,
          formatter: (cell) => this.formatCycleTime(cell),
          minWidth: 125,
        },
      ],
    };
  }

  oeeStateAndLiveBuildStatusData = trackedTask(
    this,
    this.getOeeStateData,
    () => [this.params, this.timerToken],
  );

  @task({ restartable: true })
  @waitFor
  *getOeeStateData() {
    const oeeStatesQuery = [
      {
        $match: {
          endDate: null,
          state: {
            $in: Object.values(OEE_STATES.STATES),
          },
        },
      },
      {
        $group: {
          _id: '$station._id',
          station: {
            $first: '$station',
          },
          state: {
            $first: '$state',
          },
          customButtonColor: {
            $first: '$customButtonColor',
          },
          faultCodes: {
            $first: '$faultCodes',
          },
          totalTimeInState: {
            $sum: {
              $dateDiff: {
                startDate: '$startDate',
                endDate: '$$NOW',
                unit: 'millisecond',
              },
            },
          },
        },
      },
    ];

    const [oeeStates, liveBuildStatuses] = yield all([
      this.queryRunner.runQuery.perform('OeeStates', oeeStatesQuery),
      this.queryRunner.runQuery.perform('LiveBuildStatuses', []),
    ]);

    const stations = this.params.stationIds.length > 0
      ? this.params.stationIds.map((stationId) =>
        this.store.peekRecord('station', stationId),
      )
      : this.stationRepo.stations;

    return stations
      .map((station) => {
        const liveBuildStatus = liveBuildStatuses
          .filter(item => item.status !== TaskStatuses.STOPPED)
          .find(item => item.location?._id === station.id);
        const oeeState = this.licensing.license.oeeEnabled
          ? oeeStates?.find(item => item.station?._id === station.id)
          : null;
        const stationCycleTime = moment.duration(
          moment().diff(moment(liveBuildStatus?.timestamp), 'milliseconds'),
        );

        return {
          station: station.name,
          operator: liveBuildStatus?.userName,
          model: liveBuildStatus?.model?.code,
          state: oeeState,
          oeeStateDuration: moment.duration(oeeState?.totalTimeInState),
          faults: oeeState?.faultCodes?.codes
            ?.map((faultCode) => `${faultCode.code} - ${faultCode.description}`)
            .join(', '),
          stationState: this.setStationState(liveBuildStatus),
          stationCycleTime,
          targetCycleTime: liveBuildStatus?.location?.targetCycleTime * 1000,
        };
      })
      .toSorted((a, b) => a.station.localeCompare(b.station));
  }

  formatStateDuration(cell) {
    const { state, oeeStateDuration } = cell.getData();

    Object.assign(cell.getElement().style, {
      whiteSpace: 'pre-wrap',
    });

    // render nothing instead of 0.0s
    return state
      ? oeeStateDuration.format('d[d] h[h] m[m] s[s]', {
        precision: 1,
        trim: 'both mid',
      })
      : null;
  }

  formatCycleTime(cell) {
    const cellData = cell.getData();
    let { stationCycleTime, targetCycleTime } = cellData;

    // render nothing instead of 0.0s
    if (!stationCycleTime._milliseconds) {
      return null;
    }

    if (targetCycleTime && stationCycleTime._milliseconds > targetCycleTime) {
      Object.assign(cell.getElement().style, {
        color: echartsTheme.colorPalette.danger,
        fontWeight: 'bolder',
        whiteSpace: 'pre-wrap',
      });
    }

    return stationCycleTime.format('d[d] h[h] m[m] s[s]', {
      precision: 1,
      trim: 'both mid',
    });
  }

  setStationState(liveBuildStatus) {
    if (liveBuildStatus && liveBuildStatus.status !== TaskStatuses.STOPPED) {
      return this.intl.t(`status.${liveBuildStatus.status}`);
    } else {
      return this.intl.t('idle');
    }
  }
}
