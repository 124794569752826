import Controller from '@ember/controller';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

export default class MissingConfigsController extends Controller {
  @service taskConfigRepo;
  @service taskRepo;
  @service locationRepo;

  @task({ drop: true })
  @waitFor
  *repair() {
    for (const taskRecord of this.taskRepo.tasks) {
      yield this.taskConfigRepo.loadTaskConfigs.perform(taskRecord);

      const { area } = taskRecord;

      if (taskRecord.usesModels) {
        area.models.forEach(model => {
          if (!this.taskRepo.getConfig(taskRecord, model)) {
            this.taskRepo.createConfig(taskRecord, model);
          }
        });
      } else if (area?.usesComponents && area.bomSource != null) {
        const component = taskRecord.component;
        component.options.forEach(option => {
          if (!this.taskRepo.getConfig(taskRecord, option)) {
            this.taskRepo.createConfig(taskRecord, option);
          }
        });
      }
    }

    yield this.locationRepo.bulkSave.perform({
      tasks: this.taskRepo.tasks,
      taskConfigs: this.taskRepo.tasks.flatMap(_task => _task.taskConfigs),
    });
  }
}
