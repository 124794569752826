import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { cached, tracked } from '@glimmer/tracking';
import getJemTaskSpecificDisplayComponent from 'eflex/util/get-jem-task-specific-display-component';

export default class JemTaskRow extends Component {
  @tracked showDetails = true;

  @service taskRepo;
  @service currentUser;

  get taskComponent() {
    return getJemTaskSpecificDisplayComponent(this.args.task.taskType);
  }

  get station() {
    return this.args.task?.station;
  }

  get taskNotDisplayed() {
    if (this.taskConfig?.disabled) {
      return true;
    }

    const task = this.args.task;
    const taskDecisionTags = task.decisionTags;
    const buildStatus = this.args.buildStatus;

    if (buildStatus && !isEmpty(taskDecisionTags)) {
      const allowedDecisions = new Set(buildStatus.getAllowedDecisions(task));
      if (!taskDecisionTags.some(tag => allowedDecisions.has(tag))) {
        return true;
      }
    }

    const jemVisibilityTags = task.jemVisibilityTags;

    if (isEmpty(jemVisibilityTags)) {
      return false;
    }

    const userVisibilityTags = new Set(this.currentUser.user.visibilityTags);
    return !jemVisibilityTags.some(tag => userVisibilityTags.has(tag));
  }

  get statusTranslation() {
    return this.childStatus?.statusTranslation;
  }

  @cached
  get taskConfig() {
    return this.taskRepo.getConfigForModelOrBuildDatum(
      this.args.task,
      this.args.model,
      this.args.buildStatus?.buildDatum,
    );
  }

  get isSelected() {
    return !this.taskNotDisplayed &&
      this.taskConfig != null &&
      this.args.currentTask === this.args.task;
  }

  get activeSelection() {
    return this.isSelected && this.childStatus?.taskStatusClass === 'info';
  }

  get showBigOkButton() {
    return this.args.buildStatus?.isStarted && this.childStatus?.isStarted;
  }

  @cached
  get childStatus() {
    return this.args.buildStatus?.getChildStatusForTask(this.args.task);
  }

  get buttonColSpan() {
    if (this.childStatus?.isStarted || !this.station.jemShowTaskCycle) {
      return '2';
    } else {
      return '1';
    }
  }

  @action
  onClick() {
    if (this.isSelected) {
      this.showDetails = !this.showDetails;
    } else {
      this.showDetails = true;
      this.args.onSelectTask(this.args.task);
    }
  }
}
