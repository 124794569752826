import Model, { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';

export default class Packet extends Model {
  @attr('date') requestDateTime;
  @attr('date') replyDateTime;
  @attr('number') responseTimeMs;
  @attr requestDecoded;
  @attr replyDecoded;

  @service stationRepo;

  get serialNumber() {
    return this.requestDecoded?.payload?.serialNumber;
  }

  get requestDatablockId() {
    return this.requestDecoded?.header?.datablockId;
  }

  get replyDatablockId() {
    return this.replyDecoded?.specId ?? this.replyDecoded?.header?.datablockId;
  }

  get station() {
    const stationFacsId = this.requestDecoded?.header?.stationId;

    if (stationFacsId == null || stationFacsId === 0) {
      return null;
    }

    return this.stationRepo.stations.find(item => item.facsId === stationFacsId);
  }

  get task() {
    const taskFacsId = this.requestDecoded?.payload?.taskNumber ?? this.replyDecoded?.taskNumber;

    if (taskFacsId == null || taskFacsId === 0) {
      return null;
    }

    return this.station?.tasks.find(item => item.facsId === taskFacsId);
  }
}
