import getDelayTime from 'eflex/util/get-delay-time';
import Component from '@glimmer/component';
import { task, timeout } from 'ember-concurrency';
import $ from 'jquery';
import { sum } from 'ramda';
import { waitFor } from '@ember/test-waiters';
import { registerDestructor } from '@ember/destroyable';

export default class JemTaskGrid extends Component {
  @task({ restartable: true })
  @waitFor
  *onResize(element) {
    // eslint-disable-next-line ember/no-jquery
    $(window).trigger('resize.JColResizer');
    yield timeout(getDelayTime(100));
    this._saveWidthsToStorage(element);
  }

  @task
  @waitFor
  *onDidInsert(element) {
    yield import('@eflexsystems/colresizable');

    // eslint-disable-next-line ember/no-jquery
    this.colResizable = $(element).colResizable({
      postbackSafe: true,
      removePadding: false,
      headerOnly: true,
      minWidth: 60,
      onResize: ({ currentTarget }) => {
        this._saveWidthsToStorage(currentTarget);
      },
    });

    registerDestructor(this, () => {
      this.colResizable?.destroy();
      this.colResizable = null;
    });

    yield this.onResize.perform(element);
  }

  _saveWidthsToStorage(element) {
    this.colResizable?.forceSyncGrips();

    if (element == null) {
      return;
    }
    const widths = [...element.querySelectorAll('th')].map(elem =>
      // eslint-disable-next-line ember/no-jquery
      $(elem).outerWidth(),
    );

    // colResizable needs a string in this format for localstorage
    localStorage['jem-task-table'] = `${widths.join(';')};${sum(widths)}`;
  }
}
