import Hardware from 'eflex/models/hardware';
import { attr } from '@ember-data/model';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { validMacAddress } from 'eflex/constants/regex';
import { checkUniqueField } from 'eflex/util/validators';
import { getOwner } from '@ember/application';

@buildValidations({
  name: [validator('presence', true)],

  macAddress: [
    validator('format', { regex: validMacAddress }),

    validator('inline', {
      validate(macAddress, options, adamIo, attribute) {
        const checked = getOwner(adamIo).lookup('service:hardwareRepo').allHardwareNonDeleted;
        return checkUniqueField(adamIo, checked, attribute).result;
      },
    }),
  ],
})
class AdamIo extends Hardware {
  @service intl;

  @attr('string') macAddress;

  get isConfigured() {
    return isPresent(this.macAddress);
  }

  get hardwareGroup() {
    return this.intl.t('ioBlock');
  }
}

export default AdamIo;
