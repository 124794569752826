import { task, timeout } from 'ember-concurrency';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

export default class AppReloader extends Component {
  @service eflexAjax;

  constructor(owner, args) {
    super(owner, args);
    this.checkWebservice.perform();
  }

  @task
  @waitFor
  *checkWebservice() {
    const interval = (this.args.checkInterval ?? 1) * 1000;
    let isSuccessful = false;

    while (!isSuccessful) {
      try {
        yield timeout(interval);
        yield this.eflexAjax.get.perform('health');
        isSuccessful = true;
      } catch {
        console.error('webservice not available yet');
      }
    }

    this.args.reload();
  }
}
