import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { registerDestructor } from '@ember/destroyable';
import { waitFor, waitForPromise } from '@ember/test-waiters';

export default class JemWebCamSerialNumberScan extends Component {
  @service webCamScanner;

  @task
  @waitFor
  *start(element) {
    registerDestructor(this, async () => {
      await waitForPromise(this.webCamScanner.destroyBarcodeScanner());
    });

    yield this.webCamScanner.startBarcodeScanner
      .linked()
      .perform(this.args.cameraId, element, this.args.barcodeSymbology);
  }
}
