import DecisionTypes from 'eflex/constants/tasks/decision-types';
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class JemTaskSpecificDisplayDecisionDetails extends Component {
  decisionTypes = DecisionTypes;

  get selectedOptions() {
    if (this.args.childStatus == null) {
      return [];
    }

    if (this.args.task.decisionType === DecisionTypes.singleChoice) {
      return this.args.childStatus.decisionProcessData.find(item => item.isSelected);
    } else {
      return this.args.childStatus.decisionProcessData.filter(item => item.isSelected);
    }
  }

  @action
  selectDecision(decisions) {
    if (!this.args.childStatus?.isStarted) {
      return;
    }

    this.args.childStatus.decisionProcessData.forEach(decisionProcessDatum => {
      if (decisions.includes?.(decisionProcessDatum) || decisions === decisionProcessDatum) {
        decisionProcessDatum.isSelected = true;
      } else {
        decisionProcessDatum.isSelected = false;
      }
    });
  }
}
