import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class ModalsPrintLabel extends Component {
  @tracked numberCopies = 1;
  @tracked selectedPrintTask = this.args.printTasks[0];

  get isDisabled() {
    return this.numberCopies < 1 || this.numberCopies > 999;
  }

  @action
  onConfirm() {
    this.args.onConfirm({
      task: this.selectedPrintTask.id,
      numberCopies: this.numberCopies,
      serialNumber: this.args.serialNumber,
    });
  }
}
