import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { task, all } from 'ember-concurrency';
import { action } from '@ember/object';
import { waitFor } from '@ember/test-waiters';

export default class CodesRejectController extends Controller {
  @service validationErrorNotifier;
  @service codeRepo;

  get codes() {
    return this.codeRepo.rejectCodes;
  }

  get isDirty() {
    return this.codes.some(item => item.isDirty);
  }

  get isInvalid() {
    return this.codes.filter(item => !item.isDeleted).some(item => item.isInvalid);
  }

  @task
  @waitFor
  *save() {
    const toSave = this.codes.filter(item => item.isDirty);

    if (toSave.some(item => item.isInvalid)) {
      this.validationErrorNotifier.sendErrors(toSave);
      return;
    }

    yield all(toSave.map(code => code.save()));
  }

  @action
  rollback() {
    this.codes
      .filter(item => item.isDirty)
      .forEach(code => { code.rollbackAttributes(); });
  }
}
