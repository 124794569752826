import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { isArray } from '@ember/array';
import { task, taskGroup, all } from 'ember-concurrency';
import { forEachObjIndexed } from 'ramda';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import { TrackedArray } from 'tracked-built-ins';

export default class PartsBuildHistoryController extends Controller {
  queryParams = [
    { searchTerm: { as: 'search' } },
    'station',
  ];

  @taskGroup({ drop: true }) loadingTasks;

  @tracked buildStatusSkip = 0;
  @tracked buildStatusTake = 30;
  @tracked buildHistories = new TrackedArray();
  @tracked buildStatuses = new TrackedArray();
  @tracked images = [];
  @tracked selectedBuildHistory;
  @tracked selectedBuildStatus;
  @tracked jemContainment;
  @tracked selectAll = false;
  @tracked searchTerm;
  @tracked station;
  @tracked params = {};

  @service fileDownloader;
  @service store;

  get _baseDimmed() {
    return this.fetchBuildHistory.isRunning || !this.selectedBuildHistory;
  }

  get dimProcessData() {
    return this.baseDimmed || isEmpty(this.buildStatuses.flatMap(buildStatus => buildStatus.formattedProcessData));
  }

  get dimMarriages() {
    return this.baseDimmed || !this.buildStatuses.some(buildStatus => buildStatus.hasMarriages);
  }

  get searchParams() {
    const params = this.params ?? {};

    if (!params.stationIds && this.station) {
      params.stationIds = [this.station];
    }

    return {
      ...params,
      take: this.buildStatusTake,
      skip: this.buildStatusSkip,
      searchTerm: this.selectedBuildHistory?.serialNumber,
    };
  }

  get jemContainmentStatus() {
    return this.jemContainment?.statusText;
  }

  @task({ group: 'loadingTasks' })
  @waitFor
  *fetchBuildHistory(buildHistory) {
    Object.assign(this, {
      selectedBuildHistory: buildHistory,
      selectedBuildStatus: null,
      buildStatuses: new TrackedArray(),
      buildStatusSkip: 0,
    });

    [this.images] = yield all([
      this.store.query('evisionImage', this.searchParams),
      this.fetchBuildStatuses.perform(),
    ]);
  }

  @task
  @waitFor
  *fetchBuildStatuses() {
    const buildStatuses = yield this.store.query('buildStatus', this.searchParams);

    if (isEmpty(buildStatuses) || !this.selectedBuildHistory) {
      return;
    }

    this.buildStatuses.push(...buildStatuses);

    if (this.selectedBuildStatus != null) {
      return;
    }

    this.selectedBuildStatus = this.buildStatuses[0];
    const jemContainments = yield this.store.query('jemContainment', {
      serialNumber: this.selectedBuildStatus.serialNumber,
    });
    this.jemContainment = jemContainments?.[0];
  }

  @task({ group: 'loadingTasks' })
  @waitFor
  *export() {
    const params = this.params ?? {};
    let queryString = '';

    forEachObjIndexed(function (val, key) {
      if (isArray(val)) {
        val.forEach(x => {
          queryString += `${key}[]=${x}&`;
        });
      } else {
        queryString += `${key}=${val}&`;
      }
    }, params);

    if (!this.selectAll) {
      this.buildHistories
        .filter(buildHistory => buildHistory.isSelected)
        .forEach(buildHistory => {
          queryString += `serialNumbers[]=${buildHistory.serialNumber}&`;
        });
    }

    yield this.fileDownloader.getFile.perform(
      'buildHistories',
      'text/csv',
      'build-histories.csv',
      queryString,
    );
  }

  @task({ group: 'loadingTasks' })
  @waitFor
  *getNextBuildStatusesPage() {
    this.buildStatusSkip = this.buildStatusSkip + this.buildStatusTake;

    yield this.fetchBuildStatuses.perform();
  }

  @action
  onSelectAll(val) {
    this.selectAll = val;
    this.buildHistories.forEach(buildHistory => {
      buildHistory.isSelected = val;
    });
  }

  @action
  setSelected(buildStatus, isSelected) {
    buildStatus.isSelected = isSelected;

    if (!isSelected) {
      this.selectAll = false;
    }
  }
}
