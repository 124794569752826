import { registerWarnHandler } from '@ember/debug';

registerWarnHandler(function (message, { id }, next) {
  if (
    id === 'ds.model.embedded-option-in-belongs-to' ||
    id === 'ds.serializer.embedded-relationship-undefined'
  ) {
    return;
  }
  next(...arguments);
});
