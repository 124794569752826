import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { sortByProp } from 'ramda-adjunct';

export default class NodeRedTriggerConfig extends Component {
  @service taskRepo;
  @service store;

  get parentTrigger() {
    return this.args.triggerConfig.parentTrigger;
  }

  get variableMappings() {
    return this.parentTrigger.variableMappings.filter(item => !item.isDeleted);
  }

  get previousTasks() {
    let task;
    if (this.parentTrigger.task != null) {
      task = this.parentTrigger.task;
    } else {
      task = sortByProp('row', this.parentTrigger.station.tasks ?? []).at(-1);
    }

    return this.taskRepo.getAllPreviousTasks(task, this.args.isStationEvent);
  }

  get previousJemProcessDataDefs() {
    return this.previousTasks
      .flatMap(_task => _task.jemProcessDataDefs)
      .filter(item => !item.isDeleted);
  }

  get filteredVariableDefs() {
    return this.previousTasks
      .flatMap(_task => _task.variableDefs)
      .filter((def) => !def.isDeleted && def.isFromDevice);
  }

  @action
  setVariableMappingValue(variableMapping, value) {
    switch (value.constructor?.modelName) {
      case 'variable-def': {
        Object.assign(variableMapping, {
          variableDefForValue: value,
          jemProcessDataDefForValue: null,
          value: null,
        });
        break;
      }
      case 'jem-process-data-def': {
        Object.assign(variableMapping, {
          variableDefForValue: null,
          jemProcessDataDefForValue: value,
          value: null,
        });
        break;
      }
      default: {
        Object.assign(variableMapping, {
          variableDefForValue: null,
          jemProcessDataDefForValue: null,
          value,
        });
      }
    }
  }

  @action
  addMultipleVariableMappings(quantity) {
    for (let i = 1, end = quantity; i <= end; i++) {
      this.parentTrigger.variableMappings.push(this.store.createRecord('variableMapping'));
    }
  }

  @action
  deleteVariableMapping(variableMapping) {
    variableMapping.deleteRecord();
  }
}
