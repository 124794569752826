import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { formatDate } from 'eflex/helpers/format-date';
import { waitFor } from '@ember/test-waiters';

export default class PartGenealogyController extends Controller {
  queryParams = ['serialNumber', 'searchMode', 'beginDate', 'endDate'];

  @service eflexAjax;
  @service fileDownloader;
  @service router;

  @tracked searchResult;
  @tracked searchMode = 'descendants';
  @tracked beginDate;
  @tracked endDate;
  @tracked serialNumber = '';

  get currentParams() {
    const params = {
      searchTerm: this.serialNumber,
      searchMode: this.searchMode,
      beginDate: this.beginDate,
      endDate: this.endDate,
    };

    Object.keys(params).forEach(key => {
      if (params[key] === undefined) {
        delete params[key];
      }
    });

    return params;
  }

  get searchNotRan() {
    return isEmpty(this.searchResult);
  }

  get marriageData() {
    return this.searchResult?.marriageData;
  }

  get resultCount() {
    return this.searchResult?.count;
  }

  get limitReached() {
    return this.searchResult?.limitReached;
  }

  get limit() {
    return this.searchResult?.limit;
  }

  get hasSearchResults() {
    return this.resultCount > 0;
  }

  get noSearchMatch() {
    return !isEmpty(this.searchResult) && !this.hasSearchResults;
  }

  get hasDateCritera() {
    return !isEmpty(this.beginDate) || !isEmpty(this.endDate);
  }

  get beginDateObject() {
    if (this.beginDate) {
      return new Date(this.beginDate);
    } else {
      return null;
    }
  }

  get endDateObject() {
    if (this.endDate) {
      return new Date(this.endDate);
    } else {
      return null;
    }
  }

  @task({ drop: true })
  @waitFor
  *searchSerials(params = {}) {
    if (!params.searchTerm) {
      return;
    }

    this.beginDate = params.beginDate;
    this.endDate = params.endDate;

    params.searchMode = this.searchMode;

    const result = yield this.eflexAjax.get.perform('marriages', params);
    this.searchResult = result;
  }

  @task({ drop: true })
  @waitFor
  *downloadExport(type) {
    const params = this.currentParams;

    if (!params.searchTerm) {
      return;
    }

    params.type = type;

    yield this.fileDownloader.getFile.perform('marriages', 'text/csv', this._getCsvFilename(type), params);
  }

  _getCsvFilename(type) {
    const params = this.currentParams;

    const name = [params.searchTerm.replace(/[^\s\w-]/gi, ''), params.searchMode, type];

    if (params.beginDate && params.endDate) {
      name.push(
        formatDate([params.beginDate, 'YYYY-MM-DD', 'hh-mma']),
        formatDate([params.endDate, 'YYYY-MM-DD', 'hh-mma']),
      );
    }

    name.push('marriage-history.csv');
    return name.join('_');
  }

  @action
  nodeClicked(node) {
    this.router.transitionTo('parts.buildHistory', { queryParams: { search: node.data('id') } });
  }
}
