import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { clone } from 'ramda';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import { TrackedArray } from 'tracked-built-ins';

export default class EvisionImageRetrievalController extends Controller {
  queryParams = ['searchTerm'];

  @service eflexAjax;
  @service currentUser;
  @service zipImages;

  @tracked selectAll = false;
  @tracked params = {};
  @tracked model = new TrackedArray();
  @tracked currentImage;
  @tracked showImageModal = false;
  @tracked showDeleteModal = false;
  @tracked searchTerm;

  get selectedImages() {
    return this.model.filter(item => item.isSelected);
  }

  get selectedImageIds() {
    return this.selectedImages.map(item => item.id);
  }

  get someSelected() {
    return this.model
      ?.filter(item => item.isDeleted === false)
      .some(item => item.isSelected);
  }

  @task
  @waitFor
  *deleteImages() {
    if (!this.someSelected) {
      this.showDeleteModal = false;
      return;
    }

    const params = clone(this.params) ?? {};
    params.selectedImages = this.selectedImageIds;

    yield this.eflexAjax.post.perform('evision/images/delete', params);

    this.selectedImages.filter(item => !item.isPdf).forEach(item => { item.hasImage = false; });
    this.selectedImages.filter(item => item.isPdf).forEach(selectedImage => {
      selectedImage.unloadRecord();
    });

    this.showDeleteModal = false;
    this.model.forEach(image => { image.isSelected = false; });
  }

  @action
  zipImagesAction() {
    this.zipImages.zip({
      selectAll: this.selectAll,
      selectedImageIds: this.selectedImageIds,
      totalImageCount: this.model.meta?.count ?? 0,
      params: this.params,
    });
  }

  @action
  onSelectAll(selectAll) {
    this.selectAll = selectAll;

    this.model
      ?.filter(item => item.isDeleted === false)
      .forEach(item => { item.isSelected = selectAll; });
  }

  @action
  setSelected(image, isSelected) {
    image.isSelected = isSelected;

    if (!isSelected) {
      this.selectAll = false;
    }
  }

  @action
  showImage(image) {
    this.currentImage = image;
    this.showImageModal = true;
  }
}
