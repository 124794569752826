import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { task as trackedTask } from 'ember-resources/util/ember-concurrency';
import { action } from '@ember/object';
import moment from 'moment-timezone';
import TaskAndFastenerMatcher from 'eflex/util/bi-task-fastener-matcher';
import BiFormatTaskAndBoltAxis from 'eflex/util/bi-task-anb-bolt-axis-formatter';
import EchartsTheme from 'eflex/echarts-theme';
import { clone } from 'ramda';

export default class BoltDataChart extends Component {
  // jscpd:ignore-start
  @service queryRunner;
  @service intl;
  @service router;

  get params() {
    return {
      stationIds: this.args.stationIds,
      fasteners: this.args.fasteners,
      modelIds: this.args.modelIds,
      userIds: this.args.userIds,
      tags: this.args.tags,
      startDate: this.args.startDate,
      endDate: this.args.endDate,
      shiftNames: this.args.shiftNames,
    };
  }

  individualTorqueData = trackedTask(this, this.getTorqueData, () => [this.params]);

  @task({ restartable: true })
  *getTorqueData() {
    const query = yield this.queryRunner.queryWithParams.perform(this.params, [
      {
        $limit: 100_000,
      },
      {
        $match: {
          'children.boltProcessData.0': {
            $exists: true,
          },
        },
      },
      {
        $unwind: {
          path: '$children',
        },
      },
      {
        $unwind: {
          path: '$children.boltProcessData',
        },
      },
      {
        $addFields: {
          idAndFastener: {
            $concat: [
              {
                $toString: '$children.location._id',
              },
              {
                $toString: '$children.boltProcessData.boltNumber',
              },
            ],
          },
        },
      },
      TaskAndFastenerMatcher(this.params.fasteners),
      {
        $project: {
          serialNumber: 1,
          taskAndBolt: {
            $concat: [
              { $first: '$children.location.captions.text' },
              ' ',
              this.intl.t('fastener'),
              ' ',
              {
                $toString: '$children.boltProcessData.boltNumber',
              },
            ],
          },
          idAndFastener: {
            $concat: [
              {
                $toString: '$children.location._id',
              },
              {
                $toString: '$children.boltProcessData.boltNumber',
              },
            ],
          },
          boltFinishTime: '$children.boltProcessData.timestamp',
          torque: '$children.boltProcessData.torque',
          angle: '$children.boltProcessData.angle',
          boltTimeStamp: '$children.boltProcessData.timestamp',
          taskId: '$children.location._id',
          timestamp: 1,
        },
      },
      {
        $sort: {
          boltTimeStamp: -1,
        },
      },
    ]);

    const individualTorqueData = yield this.queryRunner.runQuery.perform('BuildStatuses', query);

    return {
      chartData: this.getEchartOptions(individualTorqueData),
      exportData: () => this.formatExportData(clone(individualTorqueData)),
    };

    // jscpd:ignore-end
  }

  getEchartOptions(torqueData) {
    return {
      title: {
        text: this.intl.t('bi.chartLabel.individualAngleTorque'),
      },

      tooltip: {
        trigger: 'axis',
      },

      legend: {
        show: true,
        bottom: 5,
      },

      yAxis: BiFormatTaskAndBoltAxis(torqueData),

      xAxis: [
        {
          type: 'value',
          data: torqueData.map(item => item.torque),
          name: this.intl.t('torque'),
          nameTextStyle: {
            padding: 10,
          },
          nameLocation: 'center',
        },
        {
          type: 'value',
          data: torqueData.map(item => item.angle),
          name: this.intl.t('angle'),
          nameTextStyle: {
            padding: 10,
          },
          nameLocation: 'center',
          axisLabel: {
            formatter: '{value}°',
          },
        },
      ],

      series: [
        {
          name: this.intl.t('torque'),
          type: 'bar',
          xAxisIndex: 0,
          data: torqueData.map((m) => {
            return m.torque?.toFixed(2).replace(/\.00/, '') ?? 0;
          }),
          color: EchartsTheme.colorPalette.warning,
        },
        {
          name: this.intl.t('angle'),
          type: 'line',
          xAxisIndex: 1,
          data: torqueData.map((m) => {
            return m.angle?.toFixed(2).replace(/\.00/, '') ?? 0;
          }),
          color: EchartsTheme.colorPalette.success,
        },
      ],
      taskIds: torqueData.map(item => item.taskId),
      names: torqueData.map(item => item.taskAndBolt),
      idAndFasteners: torqueData.map(item => item.idAndFastener),
      timestamps: torqueData.map(item => item.timestamp),
    };
  }

  formatExportData(data) {
    data.forEach((boltData) => {
      boltData.torque = boltData?.torque?.toFixed(2).replace(/\.00/, '');
      boltData.angle = boltData?.angle?.toFixed(2).replace(/\.00/, '');
      boltData.boltTimeStamp = moment(boltData.boltTimeStamp).format('YYYY-MM-DD HH:mm:ss');

      delete boltData._id;
      delete boltData.timestamp;
      delete boltData.idAndFastener;
      delete boltData.boltFinishTime;
    });

    return data;
  }

  @action
  singleFastenerDrillDown(individualTorqueData, dataIndex) {
    const timestamp = new Date(individualTorqueData.timestamps[dataIndex]);

    this.router.transitionTo('bi.torqueData.bolts', {
      queryParams: {
        stationIds: this.args.stationIds,
        fasteners: [{
          _id: individualTorqueData.taskIds[dataIndex],
          name: individualTorqueData.names[dataIndex],
          idAndFastener: individualTorqueData.idAndFasteners[dataIndex],
        }],
        modelIds: this.args.modelIds,
        userIds: this.args.userIds,
        tags: this.args.tags,
        beginDate: moment(timestamp),
        endDate: moment(timestamp).add(1, 'seconds'),
        shiftNames: this.args.shiftNames,
      },
    });
  }
}
