import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class WorkInstructionEditorLibraryCard extends Component {
  @tracked isHovered = false;

  get isDraggable() {
    if (this.args.isDraggable !== undefined) {
      return this.args.isDraggable();
    }

    return true;
  }

  get isSelectable() {
    if (this.args.isSelectable !== undefined) {
      return this.args.isSelectable;
    }

    return true;
  }

  get isFolder() {
    return this.args.workInstruction == null || this.args.workInstruction.isFolder;
  }

  get instructionThumbnail() {
    if (this.isFolder) {
      return null;
    }

    if (this.args.isReadOnly) {
      return this.args.workInstruction.deployedImageThumbUrl;
    } else {
      return this.args.workInstruction.displayImageThumbUrl;
    }
  }

  get instructionName() {
    if (this.isFolder) {
      return null;
    }

    if (this.args.isReadOnly) {
      return this.args.workInstruction.deployedName;
    } else {
      return this.args.workInstruction.name;
    }
  }

  @action
  onDragOver() {
    if (this.args.isReadOnly) {
      return;
    }

    this.isHovered = true;
  }

  @action
  onDragOut() {
    if (this.args.isReadOnly) {
      return;
    }

    this.isHovered = false;
  }

  @action
  dropOnFolder(folder) {
    if (this.args.isReadOnly) {
      return;
    }

    this.args.dropOnFolder?.(folder);
  }
}
