import Model, { attr } from '@ember-data/model';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';

@buildValidations({
  color: [validator('format', { regex: '#[A-Fa-fd]{6}' })],
  opacity: [validator('number', { gte: 0, lte: 100, allowNone: false })],
})
class WieConfigFill extends Model {
  @attr('string') color;
  @attr('string') opacity;
}

export default WieConfigFill;
