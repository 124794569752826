import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import TaskStatuses from 'eflex/constants/task-statuses';

class JemActions extends Component {
  @tracked showScrapModal = false;
  @tracked showStopModal = false;
  @tracked showRejectModal = false;

  @action
  onConfirmScrap() {
    this.showScrapModal = false;
    return this.args.setStatusAndSave(TaskStatuses.SCRAPPED);
  }

  @action
  onConfirmStop() {
    this.showStopModal = false;
    return this.args.setStatusAndSave(TaskStatuses.STOPPED);
  }

  @action
  onRejectConfirm(codes) {
    this.showRejectModal = false;
    return this.args.setStatusAndSave(TaskStatuses.REJECTED, codes);
  }

  @action
  onReject() {
    if (this.args.station.confirmRejects) {
      this.showRejectModal = true;
      return;
    }

    return this.args.setStatusAndSave(TaskStatuses.REJECTED);
  }
}

export default JemActions;
