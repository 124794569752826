var _class, _class2, _descriptor, _descriptor2;
function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
import Component from '@glimmer/component';
import deprecateSubclassing from 'ember-bootstrap/utils/deprecate-subclassing';
import arg from 'ember-bootstrap/utils/decorators/arg';

/**

 @class FormElementLabel
 @namespace Components
 @extends Ember.Component
 @private
 */
let FormElementLabel = deprecateSubclassing(_class = (_class2 = class FormElementLabel extends Component {
  constructor(...args) {
    super(...args);
    /**
     * [BS4 only] Property for size styling, set to 'lg', 'sm'
     *
     * @property size
     * @type String
     * @public
     */
    /**
     * @property formElementId
     * @type {String}
     * @public
     */
    /**
     * @property labelClass
     * @type {String}
     * @public
     */
    /**
     * The form layout used for the markup generation (see http://getbootstrap.com/css/#forms):
     *
     * * 'horizontal'
     * * 'vertical'
     * * 'inline'
     *
     * Defaults to the parent `form`'s `formLayout` property.
     *
     * @property formLayout
     * @type string
     * @default 'vertical'
     * @public
     */
    _initializerDefineProperty(this, "formLayout", _descriptor, this);
    /**
     * The type of the control widget.
     * Supported types:
     *
     * * 'text'
     * * 'checkbox'
     * * 'textarea'
     * * any other type will use an input tag with the `controlType` value as the type attribute (for e.g. HTML5 input
     * types like 'email'), and the same layout as the 'text' type
     *
     * @property controlType
     * @type string
     * @default 'text'
     * @public
     */
    _initializerDefineProperty(this, "controlType", _descriptor2, this);
  }
  /**
   * @property label
   * @type string
   * @public
   */

  /**
   * @property invisibleLabel
   * @type boolean
   * @public
   */

  get isHorizontalAndNotCheckbox() {
    return this.isHorizontal && !this.isCheckbox;
  }
  /**
   * Indicates whether the type of the control widget equals `checkbox`
   *
   * @property isCheckbox
   * @type boolean
   * @private
   */
  get isCheckbox() {
    return this.args.controlType === 'checkbox';
  }

  /**
   * Indicates whether the form type equals `horizontal`
   *
   * @property isHorizontal
   * @type boolean
   * @private
   */
  get isHorizontal() {
    return this.args.formLayout === 'horizontal';
  }
}, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "formLayout", [arg], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return 'vertical';
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "controlType", [arg], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return 'text';
  }
})), _class2)) || _class;
export { FormElementLabel as default };