import { task, timeout, rawTimeout } from 'ember-concurrency';
import { keyResponder, onKey } from 'ember-keyboard';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';

const SKIPPED_SOURCES = new Set(['INPUT', 'SELECT', 'TEXTAREA']);

@keyResponder
class KeyboardWedgeEvent extends Component {
  _accumulator = '';

  @tracked keyboardPriority = 0;

  @task({ restartable: true })
  *_clearTask() {
    yield rawTimeout(this.args.eraseTimeout ?? 100);

    Object.assign(this, {
      _accumulator: '',
      keyboardPriority: 0,
    });

    yield this._actionTask.cancelAll({ resetState: true });
  }

  @task({ restartable: true })
  @waitFor
  *_actionTask(enterPressed) {
    yield timeout(this.args.actionTimeout ?? 50);

    const trimmedAccumulator = this._accumulator.trim().replace(/[\t\n\r]/g, '');
    const minActionLength = this.args.minActionLength ?? 5;

    if (trimmedAccumulator.length >= minActionLength || (trimmedAccumulator.length > 0 && enterPressed)) {
      this.args.onScan(trimmedAccumulator);

      if (enterPressed) {
        Object.assign(this, {
          _accumulator: '',
          keyboardPriority: 0,
        });
      }
    }
  }

  @onKey()
  _accumulateKeys(event) {
    if (SKIPPED_SOURCES.has(event.target.tagName)) {
      return;
    }

    const key = event.key;

    if (this._accumulator.length > 0) {
      this.keyboardPriority = 1; // prevents scan event from being captured by other wedge events
    }

    if (key.length === 1) {
      this._accumulator = this._accumulator + key;
    }

    this._actionTask.perform(key === 'Enter');
    this._clearTask.perform();
  }
}

export default KeyboardWedgeEvent;
