import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { reject, prop, pipe, map } from 'ramda';
import { sortByProps } from 'ramda-adjunct';

export default class TaskDeviceConfigPick extends Component {
  @service hardwareRepo;
  @service intl;

  get hardwareIos() {
    return this.hardwareRepo.allHardware.flatMap(hardware => hardware.hardwareIos);
  }

  get inputOptions() {
    const options = [{
      label: this.intl.t('none'),
      input: null,
    }];

    const inputs = pipe(
      reject(prop('outputOnly')),
      sortByProps(['hardwareName', 'hardwareId', 'index']),
      map(input => ({
        label: `${input.hardwareName} - ${input.inputName}`,
        input,
      })),
    )(this.hardwareIos);

    return options.concat(inputs);
  }

  get outputOptions() {
    return pipe(
      reject(prop('inputOnly')),
      sortByProps(['hardwareName', 'hardwareId', 'index']),
      map(output => ({
        label: `${output.hardwareName} - ${output.outputName}`,
        output,
      })),
    )(this.hardwareIos);
  }

  @action
  setInput({ input }) {
    this.args.task.hardwareInput = input;

    if (input == null) {
      this.args.task.taskConfigs.forEach(config => {
        config.pickSensorEnabled = false;
        if (isEmpty(config.interlockedTime)) {
          config.interlockedTime = 1;
        }
      });
    }
  }

  @action
  setOutput({ output }) {
    this.args.task.hardwareOutputs = [output];
  }
}
