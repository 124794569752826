import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

class DefaultContext {
  allSelected = true;

  @tracked treeItem;

  get displayName() {
    if (this.treeItem.usesComponents) {
      return this.intl.t('allOptions');
    } else {
      return this.intl.t('allModels');
    }
  }

  constructor(treeItem, intl) {
    this.intl = intl;
    this.treeItem = treeItem;
  }

  equals(obj) {
    return obj?.allSelected === true;
  }
}

export default class DefaultTaskConfigContext extends Service {
  @service intl;

  getContext(treeItem) {
    return new DefaultContext(treeItem, this.intl);
  }
}
