var _dec, _class;
import Component from '@ember/component';
import { tagName } from '@ember-decorators/component';
import deprecateSubclassing from 'ember-bootstrap/utils/deprecate-subclassing';

/**
 Component for a dropdown menu item.

 See [Components.Dropdown](Components.Dropdown.html) for examples.

 @class DropdownMenuItem
 @namespace Components
 @extends Ember.Component
 @public
 */
let DropdownMenuItem = (_dec = tagName(''), _dec(_class = deprecateSubclassing(_class = class DropdownMenuItem extends Component {}) || _class) || _class);
export { DropdownMenuItem as default };