import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class LogOut extends Component {
  @service session;

  @tracked showLogoutModal = false;

  @action
  logOut() {
    this.session.invalidate();
  }
}
