import { validator } from '@eflexsystems/ember-tracked-validations';
import nonUnique from 'eflex/util/non-unique';
import { getOwner } from '@ember/application';

export default {
  code: [
    validator('presence', { presence: true }),

    validator('inline', {
      validate(code, options, model) {
        const modelCodeLength = model.area?.modelCodeLength;
        if (modelCodeLength !== code?.length && !model.area?.advancedModelCodeEnabled) {
          return getOwner(model).lookup('service:intl').t('plant.models.code.exactLength', { count: modelCodeLength });
        }
        return true;
      },
    }),

    validator('inline', {
      validate(code, options, model, attribute) {
        const nonUniqueModelCodes = nonUnique(model.area?.models.filter(item => !item.isDeleted), attribute);

        if (nonUniqueModelCodes?.includes(code)) {
          return getOwner(model).lookup('service:intl').t('logs.nonUniqueFields');
        } else {
          return true;
        }
      },
    }),
  ],
};
