import Service, { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';

export default class LocationRepoService extends Service {
  @service store;
  @service areaRepo;
  @service groupRepo;
  @service stationRepo;
  @service taskRepo;
  @service jobRepo;
  @service('taskConfigRepo') taskconfigurationCommands;

  @task
  @waitFor
  *createAndSave(properties = {}) {
    return yield this
      .#getRepoForType(properties.parent.childType)
      .createAndSave.perform(properties);
  }

  @task
  @waitFor
  *deleteAndSave(deleted) {
    yield this.#getRepoForType(deleted.type).deleteAndSave.perform(deleted);
  }

  @task
  @waitFor
  *bulkSave(treeItems) {
    const bulkSave = this.store.createRecord('treeItemBulkSave', treeItems);
    yield bulkSave.save();
    bulkSave.unloadRecord();
  }

  #getRepoForType(type) {
    return this[`${type.toLowerCase()}Repo`];
  }
}
