import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import moment from 'moment-timezone';

export default class JemTaskSpecificDisplayTaskFault extends Component {
  @service intl;

  get taskErrorMessage() {
    if (this.args.task.isMissingHardware) {
      return this.intl.t('jem.hardwareMissing');
    } else if (this.args.task.hasHardwareFault) {
      return this.intl.t('jem.hardwareUnreachable');
    } else if (this.shouldFaultCalibrationExpiration) {
      return this.intl.t('jem.calibrationExpired');
    } else {
      return null;
    }
  }

  get shouldWarnCalibrationExpiration() {
    const hardware = this.args.task?.hardware;

    return (
      hardware &&
      hardware.calibrationExpirationDate &&
      hardware.calibrationDate &&
      hardware.warningThresholdDays &&
      moment(hardware.calibrationExpirationDate)
        .startOf('day')
        .subtract(hardware.warningThresholdDays, 'days') <= moment().startOf('day')
    );
  }

  get daysUntilCalibrationExpiration() {
    return moment(this.args.task?.hardware?.calibrationExpirationDate)
      .startOf('day')
      .diff(moment().startOf('day'), 'days');
  }

  get shouldFaultCalibrationExpiration() {
    const hardware = this.args.task?.hardware;

    return (
      hardware &&
      hardware.calibrationExpirationDate &&
      hardware.calibrationDate &&
      moment(hardware.calibrationExpirationDate).startOf('day') <= moment().startOf('day')
    );
  }
}
