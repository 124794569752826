import Model, { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';

export default class HardwareMessage extends Model {
  @service triggerRepo;
  @service hardwareRepo;
  @service store;

  @attr('string') type;
  @attr('string') serialNumber;
  @attr('number') responseTimeMs;
  @attr('date') createdAt;
  @attr('date') updatedAt;
  @attr message;

  // to prevent errors if the original station was deleted
  @attr('string') station;
  @attr('string') task;
  @attr('string') oeeButton;

  @service intl;

  get command() {
    return this.message?.command ?? this.message?.event;
  }

  get taskRecord() {
    if (this.task != null) {
      return this.store.peekRecord('task', this.task);
    } else {
      return null;
    }
  }

  get stationRecord() {
    if (this.station != null) {
      return this.store.peekRecord('station', this.station);
    } else {
      return null;
    }
  }

  get hardware() {
    if (this.message?.triggerId != null) {
      return this.triggerRepo.allTriggers.find(item => item.id === this.message.triggerId)?.hardware;
    } else if (this.taskRecord?.hardware != null) {
      return this.taskRecord.hardware;
    } else if (this.message?.hardwareId != null) {
      return this.hardwareRepo.allHardware.find(item => item.id === this.message.hardwareId);
    } else if (this.taskRecord?.hardwareInputDefs?.[0]?.input?.hardware != null) {
      return this.taskRecord.hardwareInputDefs[0].input.hardware;
    } else if (this.message?.header?.taskType === 'timer') {
      return { name: this.intl.t('plant.task.taskTypes.timer') };
    } else {
      return null;
    }
  }
}
