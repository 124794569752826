import TaskStatuses from 'eflex/constants/task-statuses';
import { range } from 'ramda';

export default [
  {
    statuses: null,
    label: 'repair.showAll',
  },
  {
    statuses: range(TaskStatuses.GOOD_RANGE_START, TaskStatuses.GOOD_RANGE_END),
    label: 'repair.showGood',
  },
  {
    statuses: range(TaskStatuses.REJECT_RANGE_START, TaskStatuses.REJECT_RANGE_END),
    label: 'repair.showRejected',
  },
  {
    statuses: [TaskStatuses.REPAIRED],
    label: 'repair.showRepaired',
  },
];
