import { validator } from '@eflexsystems/ember-tracked-validations';
import checkMqttTopicUnique from 'eflex/validations/check-mqtt-topic-unique';

export default {
  mqttTopic: [
    validator('inline', {
      validate(mqttTopic, options, nodeRedTriggerj) {
        return checkMqttTopicUnique(nodeRedTriggerj);
      },
    }),
    validator('presence', true),
  ],
};
