import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { FLOAT_BYTES } from 'eflex/constants/plc-variable-data-types';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { copyable, arrayAttr, caption } from 'eflex/decorators';
import { rollbackBelongsTo, isBelongsToRefDirty } from 'eflex/util/relationship-helpers';

@copyable
@buildValidations({
  name: [
    validator('presence', {
      presence: true,
      ignoreBlank: true,
    }),
  ],

  type: [
    validator('presence', {
      presence: true,
      ignoreBlank: true,
      get disabled() {
        return !this.model.task?.isPlc;
      },
    }),
  ],

  length: [
    validator('presence', {
      presence: true,
      ignoreBlank: true,
      get disabled() {
        return this.model.type !== 'ascii';
      },
    }),
  ],

  selectedValue: [
    validator('presence', {
      presence: true,
      ignoreBlank: true,
      get disabled() {
        return this.model.type !== 'variable';
      },
    }),
  ],
})
class VariableDef extends Model {
  @attr('string') type;
  @attr('number') length;
  @attr('boolean', { defaultValue: false }) isFromDevice;
  @arrayAttr captions;
  @caption name;

  @belongsTo('task', { async: false, inverse: 'variableDefs' }) task;
  @hasMany('variableDefConfig', { async: false, inverse: 'variableDef', cascadeDelete: true }) variableDefConfigs;
  @hasMany('variableMapping', {
    async: false,
    inverse: 'variableDefForValue',
    cascadeDelete: true,
  }) variableMappings;

  @hasMany('edhrMapping', { async: false, inverse: 'variableDef' }) edhrMappings;

  // for PLC tasks
  @hasMany('variableDef', {
    async: false,
    inverse: 'variableDefForValue',
    cascadeDelete: true,
  }) variableDefsWithReference;

  @belongsTo('variableDef', { async: false, inverse: 'variableDefsWithReference' }) variableDefForValue;
  @belongsTo('jemProcessDataDef', { async: false, inverse: 'variableDefsWithReference' }) jemProcessDataDefForValue;

  get isDirty() {
    return super.isDirty ||
      isBelongsToRefDirty(this, 'variableDefForValue') ||
      isBelongsToRefDirty(this, 'jemProcessDataDefForValue');
  }

  get isForPlc() {
    return this.task?.isPlc;
  }

  get errorIdentifier() {
    return this.task?.name;
  }

  // for plc tasks
  get selectedValue() {
    return this.variableDefForValue ?? this.jemProcessDataDefForValue;
  }

  get bytesConsumed() {
    switch (this.type) {
      case 'ascii': {
        return this.length ?? 0;
      }
      case 'float': {
        return FLOAT_BYTES;
      }
      default: {
        if (this.variableDefForValue != null) {
          return this.variableDefForValue.bytesConsumed;
        } else {
          return FLOAT_BYTES;
        }
      }
    }
  }

  get copyableOptions() {
    return {
      ignoreAttributes: new Set(['variableMappings', 'task', 'variableDefConfigs', 'edhrMappings']),
      copyByReference: new Set(['variableDefForValue', 'jemProcessDataDefForValue']),
    };
  }

  rollbackAttributes() {
    rollbackBelongsTo(this, 'variableDefForValue');
    rollbackBelongsTo(this, 'jemProcessDataDefForValue');
    super.rollbackAttributes();
  }
}

export default VariableDef;
