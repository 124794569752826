import Component from '@glimmer/component';

export default class JemTaskSpecificDisplayComponentOption extends Component {
  get displayedComponentOptionValue() {
    const taskConfig = this.args.taskConfig;

    if (!taskConfig || this.args.task.component?.isAlwaysRun) {
      return null;
    }

    if (!taskConfig.configOption.isSimple) {
      return this.args.childStatus?.getRawBuildDataValue(taskConfig);
    }

    return taskConfig.configOption.value;
  }
}
