import AuthenticatedRoute from 'eflex/util/authenticated-route';
import { inject as service } from '@ember/service';

export default class JobsRoute extends AuthenticatedRoute {
  @service router;

  async beforeModel(transition) {
    await super.beforeModel(transition);
    const selectedJob = this.controllerFor('jobs/job').job;
    if (selectedJob) {
      this.router.transitionTo('jobs.job', selectedJob.id);
    }
  }
}
