import AuthenticatedRoute from 'eflex/util/authenticated-route';
import { inject as service } from '@ember/service';

export default class ContainPartsScrappedRoute extends AuthenticatedRoute {
  @service store;

  queryParams = {
    serialNumber: {
      refreshModel: false,
    },
  };

  async model(params) {
    this.store.unloadAll('jemContainment');
    params.scrap = true;
    await this.store.query('jemContainment', params);
  }
}
