import Model, { attr } from '@ember-data/model';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { StrokeOptions } from 'eflex/constants/work-instructions/tool-props';

@buildValidations({
  color: [validator('format', { regex: '#[A-Fa-fd]{6}' })],
  opacity: [validator('number', { gte: 0, lte: 100, allowNone: false })],
  style: [validator('inclusion', { in: StrokeOptions.options })],
  width: [validator('number', { gte: 0, allowNone: false })],
})
class WieConfigStroke extends Model {
  @attr('string') color;
  @attr('number') opacity;
  @attr('string') style;
  @attr('number') width;
}

export default WieConfigStroke;
