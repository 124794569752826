import Component from '@glimmer/component';

export default class OptionsWithDefault extends Component {
  get optionsWithDefault() {
    let options = this.args.options;

    let builtOptions = [];

    if (this.args.defaultOption) {
      const newDefaultOption = {
        isDefaultOption: true,
        [this.args.displayedField]: this.args.defaultOption,
      };

      builtOptions.push(newDefaultOption);
    }

    return builtOptions.concat(options);
  }

  get selectedOption() {
    if (this.args.selected == null) {
      return this.optionsWithDefault[0];
    } else {
      return this.args.selected;
    }
  }
}
