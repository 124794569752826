import Service, { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';

const SOUND_FILE_PATH = '/audio/';
const SOUND_FILE_fORMAT = '.mp3';

export default class AudioPlayerService extends Service {
  @service systemConfig;

  @tracked voices = speechSynthesis.getVoices();

  @task
  @waitFor
  *play(audioType, toPlay) {
    if (window.isTesting) {
      return;
    }

    switch (audioType) {
      case 'sound': {
        yield new Audio(`${SOUND_FILE_PATH}${toPlay}${SOUND_FILE_fORMAT}`).play();
        break;
      }
      case 'text': {
        this.textToSpeech(toPlay);
        break;
      }
    }
  }

  constructor() {
    super(...arguments);

    // NOTE: This only applies to chrome
    speechSynthesis.onvoiceschanged = () => {
      this.voices = speechSynthesis.getVoices();
    };
  }

  willDestroy() {
    super.willDestroy(...arguments);
    speechSynthesis.onvoiceschanged = null;
  }

  textToSpeech(text) {
    if (window.isTesting) {
      return;
    }

    const speaker = new SpeechSynthesisUtterance(text);
    const config = this.systemConfig?.jem;
    const voice = this.voices.find(item => item.name === config?.textToSpeechVoice);

    if (voice != null) {
      Object.assign(speaker, {
        voice,
        rate: config?.textToSpeechRate,
        pitch: config?.textToSpeechPitch,
        volume: config?.textToSpeechVolume,
      });
    }

    speechSynthesis.speak(speaker);
  }
}
