import { action } from '@ember/object';
import createWorker from 'eflex/util/create-worker';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import numbro from 'numbro';

export default class JemTimer extends Component {
  @tracked currentRunningTimeInSeconds = this.args.currentRunningTimeInSeconds ?? 0;

  previousBuildStatusId = this.args.buildStatusId;
  previousIsTimerRunning = this.isTimerRunning;
  previousCurrentRunningTimeInSeconds = this.args.currentRunningTimeInSeconds;
  #worker;

  get timerProgress() {
    if (!this.targetTime) { return 0; }

    return Math.min(
      Math.ceil((this.currentRunningTimeInSeconds / this.targetTime) * 100),
      100,
    );
  }

  get timerType() {
    if (!this.targetTime || this.currentRunningTimeInSeconds <= (this.targetTime * this.alarmMargin)) {
      return 'success';
    } else if (this.currentRunningTimeInSeconds < this.targetTime) {
      return 'warning';
    } else {
      return 'danger';
    }
  }

  get isTimerRunning() {
    return this.args.stationIsRunning && !this.args.isHolding;
  }

  get targetTime() {
    return this.args.targetCycleTime ?? 0;
  }

  get alarmMargin() {
    return this.args.warningPercent / 100;
  }

  get runningTimeHour() {
    return Math.floor(this.currentRunningTimeInSeconds / 3600);
  }

  get runningTimeMinute() {
    return Math.floor(this.currentRunningTimeInSeconds / 60) % 60;
  }

  get runningTimeSecond() {
    return Math.floor(this.currentRunningTimeInSeconds % 60);
  }

  get runningTimeDeciSecond() {
    const fractionalSeconds = 10 * (this.currentRunningTimeInSeconds - Math.floor(this.currentRunningTimeInSeconds));
    return Math.floor(fractionalSeconds) % 10;
  }

  get targetTimeHours() {
    return Math.floor(this.targetTime / 3600);
  }

  get targetTimeMinutes() {
    return Math.floor(this.targetTime / 60) % 60;
  }

  get targetTimeSeconds() {
    return numbro(this.targetTime % 60).format({ optionalMantissa: true, mantissa: 1 });
  }

  get timerTextColor() {
    if (!this.targetTime) {
      return 'text-white';
    } else if (this.currentRunningTimeInSeconds <= (this.targetTime * this.alarmMargin)) {
      return 'text-success';
    } else if (this.currentRunningTimeInSeconds < this.targetTime) {
      return 'text-warning';
    } else {
      return 'text-danger';
    }
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.#worker?.removeEventListener('message', this.#onWorkerMessage);
    this.#worker?.terminate();
    this.#worker = null;
  }

  @action
  onDidUpdate(element, [buildStatusId, currentRunningTimeInSeconds, isTimerRunning]) {
    const buildStatusIdChanged = this.previousBuildStatusId !== buildStatusId;
    const isTimerRunningChanged = this.previousIsTimerRunning !== isTimerRunning;
    const currentRunningTimeInSecondsChanged = this.previousCurrentRunningTimeInSeconds !== currentRunningTimeInSeconds;

    this.previousBuildStatusId = buildStatusId;
    this.previousIsTimerRunning = isTimerRunning;
    this.previousCurrentRunningTimeInSeconds = currentRunningTimeInSeconds;

    if (currentRunningTimeInSecondsChanged) {
      this.currentRunningTimeInSeconds = currentRunningTimeInSeconds;
    }

    if (isTimerRunningChanged || buildStatusIdChanged || currentRunningTimeInSecondsChanged) {
      if (isTimerRunning) {
        this.#startTimer();
      } else {
        this.#worker?.postMessage('stop');
      }
    }
  }

  @action
  onDidInsert() {
    if (this.isTimerRunning) {
      this.#startTimer();
    }
  }

  #startTimer() {
    if (window.isTesting) {
      return;
    }

    this.#worker ??= this.#createWorker();
    this.#worker.postMessage('start');
  }

  #createWorker() {
    const worker = createWorker(({ data: command }) => {
      if (self.timerRef) {
        clearInterval(self.timerRef);
        self.timerRef = null;
      }

      if (command !== 'start') {
        return;
      }

      const now = performance.now();
      self.timerRef = setInterval(
        () => {
          self.postMessage((performance.now() - now) / 1000);
        },
        100,
      );
    });

    worker.addEventListener('message', this.#onWorkerMessage, { passive: true });

    return worker;
  }

  #onWorkerMessage = ({ data: seconds }) => {
    this.currentRunningTimeInSeconds = seconds + this.args.currentRunningTimeInSeconds;
  };
}
