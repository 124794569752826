import Model, { attr } from '@ember-data/model';
import TaskStatuses from 'eflex/constants/task-statuses';
import { arrayAttr, caption } from 'eflex/decorators';

export default class VisionProcessDatum extends Model {
  @attr('string') string;
  @attr('number') status;
  @attr('string') visionUrl;
  @attr('string') barcode;
  @attr('string') expectedBarcode;
  @arrayAttr captions;
  @caption name;

  get isNotRequired() {
    return this.status === TaskStatuses.NOT_REQUIRED;
  }

  get isGood() {
    return TaskStatuses.isGood(this.status);
  }

  get isRejected() {
    return TaskStatuses.isRejected(this.status);
  }
}
