import { inject as service } from '@ember/service';
import { isBlank } from '@ember/utils';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { trackedFunction } from 'ember-resources/util/function';
import getUrlType from 'eflex/util/get-url-type';

export default class HostedFilePicker extends Component {
  @service store;
  @service hostedFileRepo;

  fileType = trackedFunction(this, async () => {
    const computedImageUrl = this.computedImageUrl;

    if (!computedImageUrl) {
      return null;
    }

    if (computedImageUrl.mime != null) {
      return computedImageUrl.mime;
    }

    if (computedImageUrl.name == null) {
      return null;
    }

    return await getUrlType(computedImageUrl.name);
  });

  get allowPageSelection() {
    return this.args.allowPageSelection ?? true;
  }

  get computedImageUrl() {
    if (this.args.externalImageUrl == null) {
      return null;
    }

    const recordId = this.args
      .externalImageUrl
      .split('/')
      .at(-1)
      .match(/^([\da-z]*)/)?.[1];

    let record;
    if (!isBlank(recordId)) {
      record = this.store.peekRecord('hostedFile', recordId);
    }

    if (record != null) {
      return {
        id: record.id,
        name: record.name,
        mime: record.mime,
        pdfPage: this.args.pdfPage,
      };
    } else {
      return {
        id: null,
        name: this.args.externalImageUrl,
        pdfPage: this.args.pdfPage,
      };
    }
  }

  get fileOptions() {
    let options = [];
    options = this.hostedFileRepo.hostedFiles.map(({ id, name, mime }) => ({
      id,
      name,
      mime,
    }));

    if (this.computedImageUrl != null && this.computedImageUrl.id == null) {
      options.push(this.computedImageUrl);
    }

    if (this.args.mimeWhitelist?.length > 0) {
      options = options.filter(option => {
        return this.args.mimeWhitelist.includes(option.mime);
      });
    }

    return options;
  }

  @action
  setExternalImageUrl(url) {
    this.args.onChange?.(url, null, url);
  }

  @action
  updateLink(link) {
    if (link != null) {
      const record = this.store.peekRecord('hostedFile', link.id);
      this.args.onChange?.(record.url, record.id, record.name);
    } else {
      this.args.onChange?.(null);
    }
  }
}
