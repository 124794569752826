import { DEBUG } from '@glimmer/env';
import { ARGS_SET } from './component';
/**
 * This factory function returns a component manager class with common behavior
 * that can be extend to add Glimmer.js- or Ember.js-specific functionality. As
 * these environments converge, the need for two component manager
 * implementations (and thus this factory) should go away.
 */
export default function BaseComponentManager(setOwner, getOwner, capabilities) {
  return class {
    static create(attrs) {
      let owner = getOwner(attrs);
      return new this(owner);
    }
    constructor(owner) {
      this.capabilities = capabilities;
      setOwner(this, owner);
    }
    createComponent(ComponentClass, args) {
      if (DEBUG) {
        ARGS_SET.set(args.named, true);
      }
      return new ComponentClass(getOwner(this), args.named);
    }
    getContext(component) {
      return component;
    }
  };
}