import Component from '@glimmer/component';
import { anyInvalid } from 'eflex/util/getter-helpers';

class JemCustomIdentifierData extends Component {
  get disabled() {
    return anyInvalid(this.args.customIdentifierData);
  }
}

export default JemCustomIdentifierData;
