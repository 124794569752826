import { taskTypes } from 'eflex/constants/tasks/task-types';
import { action } from '@ember/object';
import TaskStatuses from 'eflex/constants/task-statuses';
import Component from '@glimmer/component';
import { keyResponder, onKey } from 'ember-keyboard';

@keyResponder
class JemTaskButtonRejectButton extends Component {
  get keyboardActivated() {
    return this.showRejectButton && !this.buttonDisabled;
  }

  get task() {
    return this.args.childStatus?.task;
  }

  get buildStatus() {
    return this.args.childStatus?.parent;
  }

  get showRejectButton() {
    if (!this.showButton) {
      return false;
    }

    return this.args.childStatus?.taskType !== taskTypes.plc &&
      (this.args.childStatus?.isStarted || this.args.childStatus?.isNotReceived);
  }

  // jscpd:ignore-start
  get showButton() {
    return this.task && this.args.childStatus && !this.buildStatus?.isFinished;
  }

  get buttonDisabled() {
    if (!this.showButton || this.args.disabled) {
      return true;
    }

    return this.args.notAuthorized || !this.buildStatus?.isRunning;
  }
  // jscpd:ignore-end

  @onKey('ctrl+alt+KeyR')
  keyboardReject() {
    if (this.buttonDisabled) {
      return;
    }

    this.sendStatus();
  }

  @action
  sendStatus() {
    return this.args.sendStatus?.(TaskStatuses.REJECTED, this.args.childStatus);
  }
}

export default JemTaskButtonRejectButton;
