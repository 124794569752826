import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { copyable } from 'eflex/decorators';
import { isHasManyRefDirty, rollbackHasMany } from 'eflex/util/relationship-helpers';

@copyable
class JemPartMarriage extends Model {
  @attr('number') order;

  @hasMany('station', { async: false, inverse: 'jemPartMarriages' }) stations;
  @belongsTo('task', { async: false, inverse: 'jemPartMarriages' }) task;

  get isDirty() {
    return super.isDirty || isHasManyRefDirty(this, 'stations');
  }

  get errorIdentifier() {
    return this.task?.name ?? this.station?.name;
  }

  get copyableOptions() {
    return {
      ignoreAttributes: new Set(['task']),
      copyByReference: new Set(['stations']),
    };
  }

  rollbackAttributes() {
    rollbackHasMany(this, 'stations');
    super.rollbackAttributes();
  }
}

export default JemPartMarriage;
