import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import BarcodeComponentOptions from 'eflex/constants/tasks/jem-barcode-component-options';
import Component from '@glimmer/component';

export default class TaskConfigBarcode extends Component {
  @service store;

  componentOptions = BarcodeComponentOptions;

  get currentPassThrough() {
    return BarcodeComponentOptions?.find(item => item.value === String(this.args.task.passThrough));
  }

  @action
  addMultipleStrings(stringQuantity) {
    for (let i = 0; i < stringQuantity; i++) {
      this.args.task.strings.push(this.store.createRecord('barcodeString'));

      this.args.task.taskConfigs.forEach(config => {
        config.strings.push(this.store.createRecord('barcodeString'));
      });
    }
  }

  @action
  removeString(index) {
    this.args.task.taskConfigs.forEach(config => {
      config.strings.splice(index, 1);
    });

    this.args.task.strings.splice(index, 1);
  }

  @action
  setPassThrough(value) {
    this.args.task.passThrough = JSON.parse(value);
  }
}
