import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import TaskStatuses from 'eflex/constants/task-statuses';
import EchartsTheme from 'eflex/echarts-theme';
import Component from '@glimmer/component';
import { task as trackedTask } from 'ember-resources/util/ember-concurrency';
import { action } from '@ember/object';
import { waitFor } from '@ember/test-waiters';
import { clone, omit } from 'ramda';

export default class BiStationQualityChart extends Component {
  @service queryRunner;
  @service intl;
  @service router;

  // jscpd:ignore-start
  stationQualityData = trackedTask(this, this.getData, () => [
    {
      stationIds: this.args.stationIds,
      modelIds: this.args.modelIds,
      userIds: this.args.userIds,
      tags: this.args.tags,
      startDate: this.args.startDate,
      endDate: this.args.endDate,
      shiftNames: this.args.shiftNames,
    },
  ]);

  @task({ restartable: true })
  @waitFor
  *getData(params) {
    const query = yield this.queryRunner.queryWithParams.perform(params, [
      {
        $group: {
          _id: '$location._id',
          station: {
            $first: {
              $first: '$location.captions.text',
            },
          },
          totalParts: {
            $sum: 1,
          },
          rejectCount: {
            $sum: {
              $cond: {
                if: {
                  $and: [
                    { $gte: ['$status', TaskStatuses.REJECT_RANGE_START] },
                    { $lte: ['$status', TaskStatuses.REJECT_RANGE_END] },
                  ],
                },
                then: 1,
                else: 0,
              },
            },
          },
          passCount: {
            $sum: {
              $cond: {
                if: {
                  $and: [
                    { $gte: ['$status', TaskStatuses.GOOD_RANGE_START] },
                    { $lte: ['$status', TaskStatuses.GOOD_RANGE_END] },
                  ],
                },
                then: 1,
                else: 0,
              },
            },
          },
        },
      },
      {
        $addFields: {
          quality: {
            $round: [
              {
                $multiply: [{ $divide: ['$passCount', '$totalParts'] }, 100],
              },
              1,
            ],
          },
        },
      },
      {
        $sort: {
          quality: -1,
        },
      },
    ]);

    const qualityData = yield this.queryRunner.runQuery.perform('BuildStatuses', query);

    return {
      chartData: this.getStationQualityChartOptions(qualityData),
      exportData: () => clone(qualityData).map(omit(['_id']), qualityData),
    };
    // jscpd:ignore-end
  }

  getStationQualityChartOptions(qualityData) {
    return {
      title: {
        text: this.intl.t('bi.chartName.qualitySummaryStation'),
      },
      legend: {
        show: true,
        bottom: 5,
      },
      tooltip: {
        trigger: 'axis',
      },
      yAxis: {
        type: 'category',
        boundaryGap: true,
        data: qualityData.map(item => item.station),
      },
      xAxis: [
        {
          type: 'value',
          max: 100,
          interval: 10,
          position: 'bottom',
          axisLabel: {
            formatter: '{value}%',
          },
        },
        {
          type: 'value',
          show: false,
        },
      ],
      series: [
        {
          name: this.intl.t('bi.chartLegend.timePeriodTotalParts'),
          data: qualityData.map(item => item.totalParts),
          type: 'line',
          xAxisIndex: 1,
          color: EchartsTheme.colorPalette.biBlue,
        },
        {
          name: this.intl.t('bi.chartLegend.timePeriodRejects'),
          data: qualityData.map(item => item.rejectCount),
          type: 'line',
          xAxisIndex: 1,
          color: EchartsTheme.colorPalette.danger,
        },
        {
          name: this.intl.t('bi.chartLegend.timePeriodQuality'),
          data: qualityData.map(item => item.quality),
          type: 'bar',
          xAxisIndex: 0,
          color: EchartsTheme.colorPalette.success,
        },
      ],
      stationIds: qualityData.map(item => item._id),
    };
  }

  @action
  stationDrillDown(stationQualityData, dataIndex) {
    this.router.transitionTo('bi.quality.stations', {
      queryParams: {
        stationIds: [stationQualityData.stationIds[dataIndex]],
        modelIds: this.args.modelIds,
        userIds: this.args.userIds,
        tags: this.args.tags,
        beginDate: this.args.startDate,
        endDate: this.args.endDate,
        shiftNames: this.args.shiftNames,
      },
    });
  }
}
