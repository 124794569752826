import Component from '@glimmer/component';
import { action } from '@ember/object';
import TaskStatuses from 'eflex/constants/task-statuses';

export default class JemTaskSpecificDisplayVisionDetails extends Component {
  get someChildrenRejected() {
    return this.args.childStatus?.visionProcessData?.some(item => item.isRejected) ?? false;
  }

  get showCaptureImageButton() {
    const childStatus = this.args.childStatus;

    if (!childStatus) {
      return false;
    }

    const rejectedOrStarted = childStatus.isStarted || childStatus.isRejected || this.someChildrenRejected;
    return rejectedOrStarted && this.args.task.triggerType === 'captureButton' && this.args.buildStatus?.isStarted;
  }

  get barcodeStrings() {
    return this.args.taskConfig?.strings?.map(item => item.barcodeString) ?? [];
  }

  get children() {
    const processData = this.args.childStatus?.visionProcessData ?? [];

    return processData.map(kid => {
      let taskStatusClass;

      if (kid.isNotRequired) {
        taskStatusClass = 'not-required';
      } else if (kid.isRejected) {
        taskStatusClass = 'danger';
      } else if (kid.isGood) {
        taskStatusClass = 'success';
      }

      return {
        name: kid.name,
        barcode: kid.barcode,
        taskStatusClass,
      };
    });
  }

  @action
  captureImage() {
    return this.args.sendTaskComplete?.(TaskStatuses.NOT_RECEIVED, this.args.childStatus);
  }
}
