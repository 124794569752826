import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import moment from 'moment-timezone';
import ScheduleValidations from 'eflex/validations/schedule';
import { buildValidations } from '@eflexsystems/ember-tracked-validations';
import { isHasManyRefDirty, rollbackHasMany } from 'eflex/util/relationship-helpers';

@buildValidations(ScheduleValidations)
class Schedule extends Model {
  @attr('string') text;
  @attr('date', { defaultValue() { return moment().startOf('day').toDate(); } }) startDate;
  @attr('date', { defaultValue() { return moment().endOf('day').toDate(); } }) endDate;
  @attr('string', { defaultValue: Intl.DateTimeFormat().resolvedOptions().timeZone }) timezone;
  @hasMany('scheduleDay', { async: false, inverse: 'schedule', embedded: true }) days;
  @hasMany('station', { async: false, inverse: null }) stations;
  @belongsTo('oee', { async: false, inverse: 'schedule' }) oee;

  get hasInvalidDays() {
    //no anyInvalid because we need to check the days even if they're not dirty
    return this.isDirty && this.days.some(item => item.isInvalid);
  }

  get isDirty() {
    return super.isDirty ||
      isHasManyRefDirty(this, 'stations') ||
      this.days.some(item => item.isDirty);
  }

  get isSelfOrChildInvalid() {
    return this.isInvalid || this.hasInvalidDays;
  }

  get stationIds() {
    return this.stations?.map(item => item.id) ?? [];
  }

  get stationsDisplay() {
    if (this.stations.length === 0) {
      return '---';
    }

    return this.stations
      .filter(item => !item.isDeleted)
      .map(item => item.name)
      .join(', ');
  }

  get isPast() {
    return moment().isAfter(this.endDate);
  }

  get isFuture() {
    return moment().isBefore(this.startDate);
  }

  get isArchived() {
    if (this.changedAttributes()?.endDate) {
      return false; //prevents invalid archiving of schedules
    }

    return this.isPast;
  }

  get isActive() {
    return !this.isArchived;
  }

  get canEditStart() {
    if (this.isActive && (this.isNew || this.isFuture || this.changedAttributes()?.startDate)) {
      return true;
    }

    return false;
  }

  rollbackAttributes() {
    rollbackHasMany(this, 'stations');
    super.rollbackAttributes();
  }
}

export default Schedule;
