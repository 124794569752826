var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2;
function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
import { tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';
import ComponentChild from 'ember-bootstrap/mixins/component-child';
import { next } from '@ember/runloop';
import overrideableCP from 'ember-bootstrap/utils/cp/overrideable';
import { addObserver } from '@ember/object/observers';
import deprecateSubclassing from 'ember-bootstrap/utils/deprecate-subclassing';
import { ref } from 'ember-ref-bucket';

/**
  A visible user-defined slide.

  See [Components.Carousel](Components.Carousel.html) for examples.

  @class CarouselSlide
  @namespace Components
  @extends Ember.Component
  @public
 */
let CarouselSlide = (_dec = tagName(''), _dec2 = ref('mainNode'), _dec3 = overrideableCP('isCurrentSlide', 'presentationState', function () {
  return this.isCurrentSlide && this.presentationState === null;
}), _dec4 = computed('currentSlide').readOnly(), _dec5 = computed('followingSlide').readOnly(), _dec(_class = deprecateSubclassing(_class = (_class2 = class CarouselSlide extends Component.extend(ComponentChild) {
  constructor(...args) {
    super(...args);
    /**
     * @property _element
     * @type null | HTMLElement
     * @private
     */
    _initializerDefineProperty(this, "_element", _descriptor, this);
    /**
     * Defines slide visibility.
     *
     * @property active
     * @type boolean
     * @private
     */
    _initializerDefineProperty(this, "active", _descriptor2, this);
    /**
     * Slide is moving to the left.
     *
     * @property left
     * @type boolean
     * @private
     */
    this.left = false;
    /**
     * Next to appear in a left sliding.
     *
     * @property next
     * @type boolean
     * @private
     */
    this.next = false;
    /**
     * Next to appear in a right sliding.
     *
     * @property prev
     * @type boolean
     * @private
     */
    this.prev = false;
    /**
     * Slide is moving to the right.
     *
     * @property right
     * @type boolean
     * @private
     */
    this.right = false;
  }
  /**
   * @private
   * @property isCurrentSlide
   * @type boolean
   */
  get isCurrentSlide() {
    return this.currentSlide === this;
  }

  /**
   * @private
   * @property isFollowingSlide
   * @type boolean
   */
  get isFollowingSlide() {
    return this.followingSlide === this;
  }
  /**
   * Coordinates the execution of a presentation.
   *
   * @method presentationStateObserver
   * @private
   */
  presentationStateObserver() {
    let presentationState = this.presentationState;
    if (this.isCurrentSlide) {
      switch (presentationState) {
        case 'didTransition':
          this.currentSlideDidTransition();
          break;
        case 'willTransit':
          this.currentSlideWillTransit();
          break;
      }
    }
    if (this.isFollowingSlide) {
      switch (presentationState) {
        case 'didTransition':
          this.followingSlideDidTransition();
          break;
        case 'willTransit':
          this.followingSlideWillTransit();
          break;
      }
    }
  }
  init() {
    super.init(...arguments);
    addObserver(this, 'presentationState', null, this.presentationStateObserver, true);
  }

  /**
   * @method currentSlideDidTransition
   * @private
   */
  currentSlideDidTransition() {
    this.set(this.directionalClassName, false);
    this.set('active', false);
  }

  /**
   * @method currentSlideWillTransit
   * @private
   */
  currentSlideWillTransit() {
    this.set('active', true);
    next(this, function () {
      this.set(this.directionalClassName, true);
    });
  }

  /**
   * @method followingSlideDidTransition
   * @private
   */
  followingSlideDidTransition() {
    this.set('active', true);
    this.set(this.directionalClassName, false);
    this.set(this.orderClassName, false);
  }

  /**
   * @method followingSlideWillTransit
   * @private
   */
  followingSlideWillTransit() {
    this.set(this.orderClassName, true);
    next(this, function () {
      this.reflow();
      this.set(this.directionalClassName, true);
    });
  }

  /**
   * Makes things more stable, especially when fast changing.
   */
  reflow() {
    this._element.offsetHeight;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "_element", [_dec2], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return null;
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "active", [_dec3], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class2.prototype, "isCurrentSlide", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "isCurrentSlide"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isFollowingSlide", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "isFollowingSlide"), _class2.prototype)), _class2)) || _class) || _class);
export { CarouselSlide as default };