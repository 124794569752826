export const anyInvalid = function (val) {
  return val?.some((item) =>
    (item.isSelfOrChildDirty || item.isDirty) && (item.isInvalid || item.isSelfOrChildInvalid),
  ) ?? false;
};

export const anyTreeItemDirty = function (val) {
  return val?.some((item) =>
    item.isSelfOrChildDirty || item.isDirty,
  ) ?? false;
};
