import Model, { attr, belongsTo } from '@ember-data/model';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { AlignmentTypes } from 'eflex/constants/work-instructions/tool-props';

@buildValidations({
  alignment: [validator('inclusion', { in: AlignmentTypes.options })],
})
class WieConfigText extends Model {
  @belongsTo('wieConfig/font', { inverse: null, async: false, embedded: true }) font;
  @belongsTo('wieConfig/stroke', { inverse: null, async: false, embedded: true }) stroke;
  @belongsTo('wieConfig/fill', { inverse: null, async: false, embedded: true }) background;
  @belongsTo('wieConfig/fill', { inverse: null, async: false, embedded: true }) textBackground;

  @attr('string') alignment;

  get isDirty() {
    return super.isDirty ||
      this.font.isDirty ||
      this.stroke.isDirty ||
      this.background.isDirty ||
      this.textBackground.isDirty;
  }
}

export default WieConfigText;
