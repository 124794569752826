import Model, { attr, belongsTo } from '@ember-data/model';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { ShapeTypes } from 'eflex/constants/work-instructions/tool-props';

@buildValidations({
  shape: [validator('inclusion', { in: ShapeTypes.option })],
})
class WieConfigShape extends Model {
  @belongsTo('wieConfig/stroke', { inverse: null, async: false, embedded: true }) stroke;
  @belongsTo('wieConfig/fill', { inverse: null, async: false, embedded: true }) fill;

  @attr('string') shape;

  get isDirty() {
    return super.isDirty ||
      this.stroke.isDirty ||
      this.fill.isDirty;
  }
}

export default WieConfigShape;
