import Model, { attr, belongsTo } from '@ember-data/model';
import JemConfigurationValidations from 'eflex/validations/jem-configuration';
import { inject as service } from '@ember/service';
import { buildValidations } from '@eflexsystems/ember-tracked-validations';
import { isPresent } from '@ember/utils';
import { rollbackBelongsTo, isBelongsToRefDirty } from 'eflex/util/relationship-helpers';
import { copyable, arrayAttr, caption } from 'eflex/decorators';

@copyable
@buildValidations(JemConfigurationValidations)
class JemConfiguration extends Model {
  @attr('string') externalImageUrl;
  @attr('number') page;
  @arrayAttr titleCaptions;
  @caption('titleCaptions') title;

  @belongsTo('model', {
    async: false,
    inverse: 'jemConfigurations',
    as: 'jem-configuration',
  }) model;

  @belongsTo('station', {
    async: false,
    inverse: 'jemConfigurations',
    as: 'jem-configuration',
  }) station;

  @belongsTo('workInstruction', {
    async: false,
    inverse: 'jemConfigurations',
    as: 'jem-configuration',
  }) workInstruction;

  @service workInstructionRepo;

  get polymorphic() {
    return true;
  }

  get isDirty() {
    return super.isDirty || isBelongsToRefDirty(this, 'workInstruction');
  }

  get hasExternalUrl() {
    return isPresent(this.externalImageUrl);
  }

  get modelId() {
    return this.model?.id;
  }

  get modelCode() {
    return this.model?.code;
  }

  get parent() {
    return this.station;
  }

  set parent(val) {
    this.station = val;
  }

  get configModel() {
    return this.model;
  }

  set configModel(val) {
    this.model = val;
  }

  get fullExternalUrl() {
    if (this.externalImageUrl == null) {
      return null;
    } else if (this.page == null) {
      return this.externalImageUrl;
    } else {
      return `${this.externalImageUrl}#page=${this.page}`;
    }
  }

  get copyableOptions() {
    return {
      ignoreAttributes: new Set(['station', 'taskConfig']),
      copyByReference: new Set(['workInstruction', 'model']),
    };
  }

  // jscpd:ignore-start
  setExternalImageUrl(url, page) {
    this.workInstructionRepo.setExternalImageUrl(this, url, page);
  }

  setWorkInstruction(workInstruction) {
    this.workInstructionRepo.setWorkInstruction(this, workInstruction);
  }

  deleteJemImage() {
    this.workInstructionRepo.deleteJemImage(this);
  }

  rollbackAttributes() {
    rollbackBelongsTo(this, 'workInstruction');
    super.rollbackAttributes();
  }
  // jscpd:ignore-end
}

export default JemConfiguration;
