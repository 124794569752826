import Model, { attr } from '@ember-data/model';

const BYPASS_COLORS = new Set([
  'none',
  '#FFF',
  '#FFFFFF',
  '#fff',
  '#ffffff',
  'white',
  'transparent',
  'rgb(255, 255, 255)',
]);

export default class WorkInstructionIcon extends Model {
  @attr('string') code;
  @attr('date') createdAt;
  @attr('date') updatedAt;

  setStyle(style, value) {
    let svg = this.code;
    const matches = [];
    const prop = `${style}:`;

    for (let i = 0; i < svg.length; i++) {
      if (svg.slice(i, i + prop.length) === prop) {
        matches.push(i);
      }
    }

    matches.forEach(match => {
      let sub = svg.slice(match);
      sub = sub.slice(0, sub.indexOf(';'));
      if (!BYPASS_COLORS.has(sub.slice(sub.indexOf(':') + 1))) {
        svg = svg.replace(sub, `${prop}${value}`);
      }
    });

    this.code = svg;
  }
}
