import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class BaseCriteriaPassFail extends Component {
  @service intl;
  @tracked isDisplayed = Boolean(this.args.passed);

  get options() {
    return [
      {
        label: this.intl.t('passed'),
        value: true,
      },
      {
        label: this.intl.t('failed'),
        value: false,
      },
    ];
  }

  @action
  onDisplayedChanged(isDisplayed) {
    this.isDisplayed = isDisplayed;
    this.args.removeParam('passed');
  }

  @action
  onSelected(selected) {
    this.args.updateParams({
      key: 'passed',
      value: selected.value,
      breadcrumb: selected.label,
    });
  }
}
