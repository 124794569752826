import Model, { attr, belongsTo } from '@ember-data/model';
import ProductionScheduleValidations from 'eflex/validations/production-schedule';
import { buildValidations } from '@eflexsystems/ember-tracked-validations';
import { isBelongsToRefDirty, rollbackBelongsTo } from 'eflex/util/relationship-helpers';

@buildValidations(ProductionScheduleValidations)
class ProductionSchedule extends Model {
  @attr('date') startTime;
  @attr('number', { defaultValue: 1 }) target;
  @attr('number', { defaultValue: 0 }) order;
  @attr('boolean', { defaultValue: false }) isCompleted;
  @attr('number', { defaultValue: 0 }) passed;
  @attr('number', { defaultValue: 0 }) failed;

  @belongsTo('station', { async: false, inverse: 'productionSchedules' }) station;
  @belongsTo('model', { async: false, inverse: 'productionSchedules' }) model;

  get isDirty() {
    return super.isDirty || isBelongsToRefDirty(this, 'model');
  }

  get notCompleted() {
    return !this.isCompleted;
  }

  get notStarted() {
    return this.startTime == null;
  }

  get isRunning() {
    return this.startTime != null && this.notCompleted;
  }

  rollbackAttributes() {
    rollbackBelongsTo(this, 'model');
    super.rollbackAttributes();
  }
}

export default ProductionSchedule;
