export default [
  {
    label: 'dataType.text',
    value: 'ascii',
  },
  {
    label: 'dataType.number',
    value: 'float',
  },
  {
    label: 'dataType.bit',
    value: 'bit',
  },
];
