import Service, { inject as service } from '@ember/service';
import { pipe, filter } from 'ramda';
import { sortByProp } from 'ramda-adjunct';

const ADAM_IO_PIN_COUNT = 8;
const TURCK_IO_PIN_COUNT = 16;

export default class HardwareRepoService extends Service {
  @service store;

  adamIos = this.store.peekAll('adamIo');
  audios = this.store.peekAll('audio');
  cognexCameras = this.store.peekAll('cognexCamera');
  datalogics = this.store.peekAll('datalogic');
  edhrs = this.store.peekAll('edhr');
  keyences = this.store.peekAll('keyence');
  lightGuides = this.store.peekAll('lightGuide');
  nodeReds = this.store.peekAll('nodeRed');
  openProtocolTools = this.store.peekAll('openProtocolTool');
  plcHardwares = this.store.peekAll('plcHardware');
  turckIos = this.store.peekAll('turckIo');
  universalRobots = this.store.peekAll('universalRobot');
  webCams = this.store.peekAll('webCam');
  workInstructionHardwares = this.store.peekAll('workInstructionHardware');
  zebraPrinters = this.store.peekAll('zebraPrinter');

  get allHardware() {
    return this.adamIos
      .concat(this.audios)
      .concat(this.cognexCameras)
      .concat(this.datalogics)
      .concat(this.edhrs)
      .concat(this.keyences)
      .concat(this.lightGuides)
      .concat(this.nodeReds)
      .concat(this.openProtocolTools)
      .concat(this.plcHardwares)
      .concat(this.turckIos)
      .concat(this.universalRobots)
      .concat(this.webCams)
      .concat(this.workInstructionHardwares)
      .concat(this.zebraPrinters);
  }

  get allHardwareNonDeleted() {
    return this.allHardware.filter(item => !item.isDeleted);
  }

  createHardware(hardwareType) {
    const hardware = this.store.createRecord(hardwareType);

    if (hardwareType === 'adamIo') {
      this._setupAdamIo(hardware);
    } else if (hardwareType === 'turckIo') {
      this._setupTurckIo(hardware);
    }

    return hardware;
  }

  hardwareByType(hardwareTypes) {
    return pipe(
      filter(hardware =>
        hardwareTypes.includes(hardware.constructor.modelName),
      ),
      sortByProp('triggerDisplayName'),
    )(this.allHardware);
  }

  _setupAdamIo(hardware) {
    for (let i = 0; i < ADAM_IO_PIN_COUNT; i++) {
      this.store.createRecord('hardwareIo', {
        index: i,
        hardware,
        inputName: `Input ${i + 1}`,
        outputName: `Output ${i + 1}`,
      });
    }
  }

  _setupTurckIo(hardware) {
    for (let i = 0; i < TURCK_IO_PIN_COUNT; i++) {
      this.store.createRecord('hardwareIo', {
        index: i,
        hardware,
        inputName: `Input ${i}`,
        outputName: `Output ${i}`,
      });
    }
  }
}
