import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import { routeTaskFromRouterService } from 'eflex/helpers/route-task';

export default class RoutePerform extends Helper {
  @service router;

  compute([taskName]) {
    return routeTaskFromRouterService(this.router, taskName).perform;
  }
}
