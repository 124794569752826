import numbro from 'numbro';
import Component from '@glimmer/component';

export default class JemBolt extends Component {
  get angleIconClass() {
    return this._getStatusIconClass(this.args.bolt?.angleStatus);
  }

  get angleClass() {
    return this._getStatusClass(this.args.bolt?.angleStatus);
  }

  get angleDisplay() {
    const angle = this.args.bolt?.angle;

    if (angle == null) {
      return '-';
    }

    return `${angle}°`;
  }

  get torqueIconClass() {
    return this._getStatusIconClass(this.args.bolt?.torqueStatus);
  }

  get torqueClass() {
    return this._getStatusClass(this.args.bolt?.torqueStatus);
  }

  get torqueDisplay() {
    const torque = this.args.bolt?.torque;

    if (torque == null) {
      return '-';
    }

    return `${numbro(torque).format({ mantissa: 3 })} ${this.args.torqueFormat}`;
  }

  get tighteningIconClass() {
    return this._getStatusIconClass(this.args.bolt?.tighteningStatus);
  }

  get tighteningClass() {
    return this._getStatusClass(this.args.bolt?.tighteningStatus);
  }

  _getStatusClass(status) {
    if (status == null) {
      return null;
    }

    if (status === 1 && this.args.bolt?.errorCode == null) {
      return 'jem-text-success';
    } else {
      return 'jem-text-danger';
    }
  }

  _getStatusIconClass(status) {
    if (status == null) {
      return null;
    }

    if (status === 1 && this.args.bolt?.errorCode == null) {
      return 'icon-checkmark';
    } else {
      return 'icon-x';
    }
  }
}
