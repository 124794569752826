import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class SerialNumberMonitorController extends Controller {
  @service bomSourceRepo;
  @service router;

  @tracked selectedBomSource;

  @action
  bomSourceSelected(bomSource) {
    this.selectedBomSource = bomSource;
    this.router.transitionTo('serialNumberMonitor.bomSource', bomSource.id);
  }
}
