import Control from 'ember-bootstrap/components/bs-form/element/control';
import { action } from '@ember/object';
import sizeClass from 'ember-bootstrap/utils/size-class';
import { castInputValueToNumber } from 'eflex/helpers/input-num';

export default class BsNumberInput extends Control {
  @action
  handleChange(event) {
    this.args.onChange(castInputValueToNumber(event));
  }

  @action
  handleInput(event) {
    this.args.onChange(castInputValueToNumber(event));
  }

  get sizeClass() {
    return sizeClass('form-control', this.args.size);
  }
}
