import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { CameraTriggerValues } from 'eflex/constants/tasks/trigger-types';
import { waitFor } from '@ember/test-waiters';

const CAPTURE_TRIGGER_TYPES = new Set([
  CameraTriggerValues.captureAdvance,
  CameraTriggerValues.taskActive,
]);

export default class WebCam extends Component {
  @service mediaDevices;
  @service notifier;

  @task({ onState: null })
  @waitFor
  *setupCamera() {
    if (this.isDestroying || this.isDestroyed) {
      return;
    }

    yield this.mediaDevices.initStream.linked().perform(this.args.cameraId);
  }

  @task({ restartable: true })
  @waitFor
  *captureImage(element) {
    if (!this.mediaDevices.cameraStreamPlaying) {
      this.notifier.sendWarning('cameraNotLoaded');
      return;
    }

    const imageWidth = element.videoWidth;
    const imageHeight = element.videoHeight;
    let blob;

    if (
      this.args.triggerType !== CameraTriggerValues.captureButton &&
      window.ImageCapture &&
      this.mediaDevices.cameraStream
    ) {
      const imageCapture = new ImageCapture(this.mediaDevices.cameraStream.getVideoTracks()[0]);
      blob = yield imageCapture.takePhoto({
        imageWidth,
        imageHeight,
      });
    } else {
      blob = yield this.getCanvasBlob.perform(element, imageWidth, imageHeight);
    }

    this.args.onCaptureImage?.(blob);
  }

  @task({ onState: null })
  @waitFor
  *getCanvasBlob(videoElement, imageWidth, imageHeight) {
    if (window.isTesting) {
      return new Blob([''], { type: 'image/jpeg' });
    }

    const canvas = document.createElement('canvas');
    canvas.width = imageWidth;
    canvas.height = imageHeight;
    const context = canvas.getContext('2d');
    context.drawImage(videoElement, 0, 0, imageWidth, imageHeight);
    const { canvasToBlob } = yield import('blob-util');
    const blob = yield canvasToBlob(canvas, 'image/jpeg');
    canvas.remove();
    return blob;
  }

  @action
  onResize(element) {
    const { width, height } = element.parentElement.getBoundingClientRect();
    Object.assign(this, {
      width,
      height,
    });
  }

  @task({ restartable: true })
  @waitFor
  *onPlaying({ target }) {
    if (this.isDestroying || this.isDestroyed) {
      return;
    }

    this.mediaDevices.cameraStreamPlaying = true;

    if (CAPTURE_TRIGGER_TYPES.has(this.args.triggerType)) {
      yield this.captureImage.perform(target);
    }
  }
}
