import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { TrackedArray } from 'tracked-built-ins';

export default class DebugLiveBuildStatusLogsController extends Controller {
  @tracked showJsonModal = false;
  @tracked params = {};
  @tracked searchTerm;
  @tracked model = new TrackedArray();
  @tracked displayedLog;

  @action
  onShowJsonModal(obj) {
    if (obj == null) {
      return;
    }

    Object.assign(this, {
      showJsonModal: true,
      displayedLog: obj,
    });
  }
}
