import Model, { belongsTo, hasMany, attr } from '@ember-data/model';
import { copyable } from 'eflex/decorators';

@copyable
class Trigger extends Model {
  @attr('string', { defaultValue: 'start' }) event;

  @belongsTo('oeeButton', { async: false, inverse: 'triggers', as: 'trigger' }) oeeButton;
  @belongsTo('station', { async: false, inverse: 'triggers', as: 'trigger' }) station;
  @belongsTo('task', { async: false, inverse: 'triggers', as: 'trigger' }) task;

  @belongsTo('hardware', {
    async: false,
    polymorphic: true,
    as: 'trigger',
    inverse: 'triggers',
  }) hardware;

  @hasMany('triggerConfig', {
    async: false,
    polymorphic: true,
    as: 'trigger',
    cascadeDelete: true,
    inverse: 'parentTrigger',
  }) triggerConfigs;

  get polymorphic() {
    return true;
  }

  get errorIdentifier() {
    return this.location?.name;
  }

  get location() {
    return this.task ?? this.station ?? this.oeeButton;
  }

  get copyableOptions() {
    return {
      ignoreAttributes: new Set(['station', 'task', 'triggerConfigs']),
      copyByReference: new Set(['hardware']),
    };
  }
}

export default Trigger;
