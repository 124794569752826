import LocationRepoBase from 'eflex/services/location-repo-base';
import { task } from 'ember-concurrency';
import getNextTreeOrder from 'eflex/util/get-next-tree-order';
import { waitFor } from '@ember/test-waiters';

export default class JobRepoService extends LocationRepoBase {
  jobs = this.store.peekAll('job');

  create(properties = {}) {
    properties.order = getNextTreeOrder(this.jobs);
    return super.create('job', properties);
  }

  @task
  @waitFor
  *createAndSave(properties = {}) {
    const job = this.create(properties);
    yield job.save();
    return job;
  }
}
