import Component from '@glimmer/component';
import TaskStatuses from 'eflex/constants/task-statuses';
import EchartsTheme from 'eflex/echarts-theme';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { task as trackedTask } from 'ember-resources/util/ember-concurrency';
import Levels from 'eflex/constants/level-select-levels';
import generateDataForBiTimeChart from 'eflex/util/generate-data-for-bi-time-chart';
import FormatxAxisTimestamps from 'eflex/util/bi-format-xAxis-Timestamps';
import { waitFor } from '@ember/test-waiters';

export default class ProductionTimeBarChart extends Component {
  @service queryRunner;
  @service intl;

  @tracked selectedLevel = 'Day';

  get params() {
    return {
      stationIds: this.args.stationIds,
      modelIds: this.args.modelIds,
      userIds: this.args.userIds,
      tags: this.args.tags,
      startDate: this.args.startDate,
      endDate: this.args.endDate,
      shiftNames: this.args.shiftNames,
      useUniqueSerialNumbers: this.args.useUniqueSerialNumbers,
    };
  }

  productionData = trackedTask(this, this.getData, () => [
    this.params,
    this.selectedLevel,
  ]);

  // jscpd:ignore-start
  @task({ drop: true })
  @waitFor
  *getData(params) {
    const totalCyclesQuery = yield this.queryRunner.queryWithParams.perform(
      params,
      [
        {
          $group: {
            _id: {
              $dateToString: {
                format: Levels[this.selectedLevel],
                date: '$timestamp',
              },
            },
            rejectCount: {
              $sum: {
                $cond: {
                  if: {
                    $and: [
                      { $gte: ['$status', TaskStatuses.REJECT_RANGE_START] },
                      { $lte: ['$status', TaskStatuses.REJECT_RANGE_END] },
                    ],
                  },
                  then: 1,
                  else: 0,
                },
              },
            },
            passCount: {
              $sum: {
                $cond: {
                  if: {
                    $and: [
                      { $gte: ['$status', TaskStatuses.GOOD_RANGE_START] },
                      { $lte: ['$status', TaskStatuses.GOOD_RANGE_END] },
                    ],
                  },
                  then: 1,
                  else: 0,
                },
              },
            },
          },
        },
        {
          $addFields: {
            date: '$_id',
          },
        },
        {
          $sort: {
            _id: 1,
          },
        },
        {
          $project: {
            _id: 0,
          },
        },
      ],
    );

    const totalPartsQuery = yield this.queryRunner.queryWithParams.perform(
      params,
      [
        {
          $sort: {
            timestamp: -1,
          },
        },
        {
          $group: {
            _id: {
              serialNumber: '$serialNumber',
            },
            buildStatus: {
              $first: {
                timestamp: '$timestamp',
                status: '$status',
              },
            },
          },
        },
        {
          $group: {
            _id: {
              $dateToString: {
                format: Levels[this.selectedLevel],
                date: '$buildStatus.timestamp',
              },
            },
            rejectCount: {
              $sum: {
                $cond: {
                  if: {
                    $and: [
                      {
                        $gte: [
                          '$buildStatus.status',
                          TaskStatuses.REJECT_RANGE_START,
                        ],
                      },
                      {
                        $lte: [
                          '$buildStatus.status',
                          TaskStatuses.REJECT_RANGE_END,
                        ],
                      },
                    ],
                  },
                  then: 1,
                  else: 0,
                },
              },
            },
            passCount: {
              $sum: {
                $cond: {
                  if: {
                    $and: [
                      {
                        $gte: [
                          '$buildStatus.status',
                          TaskStatuses.GOOD_RANGE_START,
                        ],
                      },
                      {
                        $lte: [
                          '$buildStatus.status',
                          TaskStatuses.GOOD_RANGE_END,
                        ],
                      },
                    ],
                  },
                  then: 1,
                  else: 0,
                },
              },
            },
          },
        },
        {
          $addFields: {
            date: '$_id',
          },
        },
        {
          $sort: {
            _id: 1,
          },
        },
        {
          $project: {
            _id: 0,
          },
        },
      ],
    );

    const productionChartQuery = params.useUniqueSerialNumbers
      ? totalPartsQuery
      : totalCyclesQuery;

    const productionChartData = yield this.queryRunner.runQuery.perform(
      'BuildStatuses',
      productionChartQuery,
    );

    const chartData = generateDataForBiTimeChart(productionChartData, this.selectedLevel);

    return {
      chartData: this.getEchartOptions(chartData),
      exportData: () => chartData,
    };
    // jscpd:ignore-end
  }

  getEchartOptions(productionChartData) {
    return {
      title: {
        text: this.intl.t('bi.chartLabel.productionTime'),
      },

      legend: {
        show: true,
        bottom: 5,
      },

      tooltip: {
        trigger: 'axis',
      },

      xAxis: FormatxAxisTimestamps(productionChartData, this.selectedLevel),

      yAxis: [
        {
          type: 'value',
          position: 'left',
          minInterval: 1,
        },
        {
          type: 'value',
          position: 'left',
          minInterval: 1,
        },
      ],

      series: [
        {
          name: this.intl.t('bi.chartLegend.timePeriodPassedParts'),
          data: productionChartData.map((m) => m.passCount),
          type: 'bar',
          stack: 'parts',
          yAxisIndex: 1,
          color: EchartsTheme.colorPalette.success,
        },
        {
          name: this.intl.t('bi.chartLegend.timePeriodRejects'),
          data: productionChartData.map((m) => m.rejectCount),
          type: 'bar',
          stack: 'parts',
          yAxisIndex: 1,
          color: EchartsTheme.colorPalette.danger,
        },
      ],
    };
  }
}
