import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class BaseCriteriaLogLevel extends Component {
  @tracked isDisplayed = Boolean(this.args.logLevel);
  @tracked includeInactive = false;

  @action
  onDisplayedChanged(isDisplayed) {
    this.isDisplayed = isDisplayed;
    this.args.removeParam('logLevel');
    this.args.removeParam('includeInactive');
  }

  @action
  onSelectedLevelChanged(logLevel) {
    if (logLevel === 'All') {
      this.args.removeParam('logLevel');
      return;
    }

    this.args.updateParams({
      key: 'logLevel',
      value: logLevel,
      breadcrumb: `level: ${logLevel}`,
    });
  }

  @action
  onIncludeInactiveChanged(includeInactive) {
    if (includeInactive) {
      this.args.updateParams({
        key: 'includeInactive',
        value: includeInactive,
        breadcrumb: 'Include Inactive',
      });
    } else {
      this.args.removeParam('includeInactive');
    }
  }
}
