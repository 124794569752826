import Service, { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import { getDescendants, recursiveSave } from 'eflex/util/tree-helpers';

export default class LocationRepoBaseService extends Service {
  @service store;
  @service validationErrorNotifier;

  @task
  @waitFor
  *deleteAndSave(deleted) {
    const { parent } = deleted;

    deleted.deleteRecord();

    if (parent != null && parent.isSelfOrChildInvalid) {
      deleted.rollbackAttributes();
      this.validationErrorNotifier.sendErrors([parent]);
      return;
    }

    yield deleted.save();

    if (!parent) {
      return;
    }

    this._reorder(parent);
    yield recursiveSave(parent);
  }

  _reorder(treeItem) {
    if (treeItem == null) {
      return;
    }

    let a = -1;
    let g = -1;
    let s = -1;

    getDescendants(treeItem, 'station')
      .forEach(loc => {
        switch (loc.type) {
          case 'area': {
            this.#setPropIfChanged(loc, 'order', a += 1);
            break;
          }
          case 'group': {
            this.#setPropIfChanged(loc, 'order', g += 1);
            break;
          }
          case 'station': {
            this.#setPropIfChanged(loc, 'order', s += 1);
            break;
          }
        }
      });
  }

  create(type, properties = {}) {
    const newLocation = this.store.createRecord(type, properties);
    if (!properties.path) {
      this.updateLocationPaths(newLocation);
    }
    return newLocation;
  }

  updateLocationPaths(treeItem) {
    let newPath = treeItem.id;

    if (treeItem.parent) {
      newPath = `${treeItem.parent.path}#${newPath}`;
    }

    treeItem.path = newPath;
    getDescendants(treeItem).forEach(this.updateLocationPaths, this);
  }

  #setPropIfChanged(treeItem, propName, value) {
    if (treeItem[propName] !== value) {
      treeItem[propName] = value;
    }
  }
}
