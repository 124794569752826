import ApplicationSerializer from 'eflex/serializers/application';
import { isBlank } from '@ember/utils';

export default class SimpleComponentOptionSerializer extends ApplicationSerializer {
  attrs = {
    component: {
      serialize: false,
      deserialize: false,
    },
    jemPrerequisites: {
      serialize: false,
      deserialize: false,
    },
    taskConfigs: {
      serialize: false,
      deserialize: false,
    },
  };

  serialize(snapshot) {
    const serialized = super.serialize(...arguments);

    if (isBlank(snapshot.attr('value'))) {
      const length = snapshot.belongsTo('component')?.attr('length');

      if (length != null) {
        serialized.value = ' '.repeat(length);
      }
    }

    return serialized;
  }
}
