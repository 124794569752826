import Service, { inject as service } from '@ember/service';
import { isArray } from '@ember/array';
import { isEmpty, isPresent } from '@ember/utils';
import { decamelize, capitalize } from '@ember/string';
import { pipe, replace, always, concat, when } from 'ramda';
import { concatRight } from 'ramda-adjunct';

export default class ValidationErrorNotifierService extends Service {
  @service notifier;

  _notifyAllErrors(model) {
    if (!model) {
      return;
    }

    const allErrors = model.validations?.errors ?? [];
    const label = model.errorIdentifier ?? model.name;

    allErrors.forEach(error => {
      if (isEmpty(error.message)) {
        return;
      }

      const message = pipe(
        decamelize,
        replace(/_/g, ' '),
        capitalize,
        concatRight(': '),
        when(always(isPresent(label)), concat(`${label} - `)),
        concatRight(error.message),
      )(error.attribute);

      this.notifier.sendError(message);
    });

    model.validatedRelationships?.forEach(key => {
      const children = model[key];
      const childArray = isArray(children) ? children : [children];

      childArray.forEach(child => {
        this._notifyAllErrors(child);
      });
    });
  }

  sendErrors(models) {
    if (models && !isArray(models)) {
      models = [models];
    }

    models.forEach((model) => {
      this._notifyAllErrors(model);
    });
  }
}
