import Service, { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';

export default class FileDownloaderService extends Service {
  @service eflexAjax;
  @service notifier;

  @task
  @waitFor
  *getFile(url, accept, filename, params = {}) {
    const fullUrl = this.eflexAjax.getUrl(url, params);
    const response = yield this.eflexAjax.sendRawRequest.perform(fullUrl, 'GET', null, accept);
    if (!response.ok) {
      const { error: { message } } = yield response.json();
      const error = new Error(message);
      this.notifier.sendError(error);
      return;
    }
    const responseBlob = yield response.blob();

    yield this._saveAs.perform(responseBlob, filename);
  }

  @task
  *_saveAs(blob, filename) {
    const { default: saveAs } = yield import('file-saver');
    saveAs(blob, filename);
  }
}
