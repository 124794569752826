import RESTSerializer from '@ember-data/serializer/rest';
import toFastProperties from 'to-fast-properties';
import { warn } from '@ember/debug';
import { camelize } from '@ember/string';

export default class ApplicationSerializer extends RESTSerializer {
  serialize(snapshot, options = {}) {
    options.includeId = true;

    const json = super.serialize(snapshot, options);
    const { record } = snapshot;

    if (record.polymorphic) {
      json.type = camelize(snapshot.modelName);
    }

    return json;
  }

  normalize(typeClass, hash, prop) {
    let didDeleteProperty = false;

    if (hash._id != null) {
      hash.id ??= hash._id;
      delete hash._id;
      didDeleteProperty = true;
    }

    if (hash.__v != null) {
      delete hash.__v;
      didDeleteProperty = true;
    }

    if (didDeleteProperty) {
      toFastProperties(hash);
    }

    const normalizedHash = super.normalize(typeClass, hash, prop);
    return this.#extractEmbeddedRecords(this.store, typeClass, normalizedHash);
  }

  serializeBelongsTo(snapshot, json, relationship) {
    const attr = relationship.key;
    const relationshipsDefinition =
      this.store.getSchemaDefinitionService().relationshipsDefinitionFor({ type: snapshot.modelName });

    if (this.#noSerializeOptionSpecified(relationshipsDefinition, attr)) {
      super.serializeBelongsTo(snapshot, json, relationship);
      return;
    }
    const includeIds = this.#hasSerializeIdsOption(attr);
    const includeRecords = this.defIsEmbedded(relationshipsDefinition, attr);
    const embeddedSnapshot = snapshot.belongsTo(attr);
    if (includeIds) {
      const schema = this.store.modelFor(snapshot.modelName);
      let serializedKey = this._getMappedKey(relationship.key, schema);
      if (serializedKey === relationship.key && this.keyForRelationship) {
        serializedKey = this.keyForRelationship(relationship.key, relationship.kind, 'serialize');
      }

      if (!embeddedSnapshot) {
        json[serializedKey] = null;
      } else {
        json[serializedKey] = embeddedSnapshot.id;

        if (relationship.options.polymorphic) {
          this.serializePolymorphicType(snapshot, json, relationship);
        }
      }
    } else if (includeRecords) {
      this.#serializeEmbeddedBelongsTo(snapshot, json, relationship);
    }
  }

  serializeHasMany(snapshot, json, relationship) {
    const attr = relationship.key;

    const relationshipsDefinition =
      this.store.getSchemaDefinitionService().relationshipsDefinitionFor({ type: snapshot.modelName });

    if (this.#noSerializeOptionSpecified(relationshipsDefinition, attr)) {
      super.serializeHasMany(snapshot, json, relationship);
      return;
    }

    if (this.#hasSerializeIdsOption(attr)) {
      const schema = this.store.modelFor(snapshot.modelName);
      let serializedKey = this._getMappedKey(relationship.key, schema);
      if (serializedKey === relationship.key && this.keyForRelationship) {
        serializedKey = this.keyForRelationship(relationship.key, relationship.kind, 'serialize');
      }

      json[serializedKey] = snapshot.hasMany(attr, { ids: true });
    } else if (this.defIsEmbedded(relationshipsDefinition, attr)) {
      this.#serializeEmbeddedHasMany(snapshot, json, relationship);
    } else if (this.#hasSerializeIdsAndTypesOption(attr)) {
      this.#serializeHasManyAsIdsAndTypes(snapshot, json, relationship);
    }
  }

  defIsEmbedded(relationshipsDefinition, attr) {
    return (relationshipsDefinition[camelize(attr)] ?? relationshipsDefinition[attr])?.options.embedded ?? false;
  }

  #serializeEmbeddedBelongsTo(snapshot, json, relationship) {
    const embeddedSnapshot = snapshot.belongsTo(relationship.key);
    const schema = this.store.modelFor(snapshot.modelName);
    let serializedKey = this._getMappedKey(relationship.key, schema);
    if (serializedKey === relationship.key && this.keyForRelationship) {
      serializedKey = this.keyForRelationship(relationship.key, relationship.kind, 'serialize');
    }

    if (!embeddedSnapshot) {
      json[serializedKey] = null;
    } else {
      json[serializedKey] = embeddedSnapshot.serialize({ includeId: true });
      this.#removeEmbeddedForeignKey(snapshot, embeddedSnapshot, relationship, json[serializedKey]);

      if (relationship.options.polymorphic) {
        this.serializePolymorphicType(snapshot, json, relationship);
      }
    }
  }

  #serializeHasManyAsIdsAndTypes(snapshot, json, relationship) {
    const serializedKey = this.keyForAttribute(relationship.key, 'serialize');
    const hasMany = snapshot.hasMany(relationship.key) ?? [];

    json[serializedKey] = hasMany.map(function (recordSnapshot) {
      return { id: recordSnapshot.id, type: camelize(recordSnapshot.modelName) };
    });
  }

  #serializeEmbeddedHasMany(snapshot, json, relationship) {
    const schema = this.store.modelFor(snapshot.modelName);
    let serializedKey = this._getMappedKey(relationship.key, schema);
    if (serializedKey === relationship.key && this.keyForRelationship) {
      serializedKey = this.keyForRelationship(relationship.key, relationship.kind, 'serialize');
    }

    warn(
      // eslint-disable-next-line max-len
      `The embedded relationship '${serializedKey}' is undefined for '${snapshot.modelName}' with id '${snapshot.id}'. Please include it in your original payload.`,
      // eslint-disable-next-line unicorn/no-typeof-undefined
      typeof snapshot.hasMany(relationship.key) !== 'undefined',
      { id: 'ds.serializer.embedded-relationship-undefined' },
    );

    json[serializedKey] = this.#generateSerializedHasMany(snapshot, relationship);
  }

  #generateSerializedHasMany(snapshot, relationship) {
    const hasMany = snapshot.hasMany(relationship.key) ?? [];
    // eslint-disable-next-line unicorn/no-new-array
    const ret = new Array(hasMany.length);

    for (const [i, embeddedSnapshot] of hasMany.entries()) {
      const embeddedJson = embeddedSnapshot.serialize({ includeId: true });
      if (relationship.options.polymorphic) {
        embeddedJson.type = camelize(embeddedSnapshot.modelName);
      }
      this.#removeEmbeddedForeignKey(snapshot, embeddedSnapshot, relationship, embeddedJson);
      ret[i] = embeddedJson;
    }

    return ret;
  }

  #removeEmbeddedForeignKey(snapshot, embeddedSnapshot, relationship, json) {
    if (relationship.kind === 'belongsTo') {
      const schema = this.store.modelFor(snapshot.modelName);
      const parentRecord = schema.inverseFor(relationship.key, this.store);
      if (parentRecord) {
        const name = parentRecord.name;
        const embeddedSerializer = this.store.serializerFor(embeddedSnapshot.modelName);
        const parentKey = embeddedSerializer.keyForRelationship(name, parentRecord.kind, 'deserialize');
        if (parentKey) {
          delete json[parentKey];
        }
      }
    }
  }

  #hasSerializeIdsOption(attr) {
    const option = this.#attrsOption(attr);
    return option && (option.serialize === 'ids' || option.serialize === 'id');
  }

  #hasSerializeIdsAndTypesOption(attr) {
    const option = this.#attrsOption(attr);
    return option && (option.serialize === 'ids-and-types' || option.serialize === 'id-and-type');
  }

  #attrsOption(attr) {
    const attrs = this.attrs;
    return attrs && (attrs[camelize(attr)] ?? attrs[attr]);
  }

  #extractEmbeddedRecords(store, typeClass, partial) {
    const relationshipsDefinition =
      this.store.getSchemaDefinitionService().relationshipsDefinitionFor({ type: typeClass.modelName });

    typeClass.eachRelationship((key, relationship) => {
      if (this.defIsEmbedded(relationshipsDefinition, key)) {
        if (relationship.kind === 'hasMany') {
          this.#extractEmbeddedHasMany(store, key, partial, relationship);
        }
        if (relationship.kind === 'belongsTo') {
          this.#extractEmbeddedBelongsTo(store, key, partial, relationship);
        }
      }
    });
    return partial;
  }

  #extractEmbeddedHasMany(store, key, hash, relationshipMeta) {
    const relationshipHash = hash.data?.relationships?.[key]?.data;

    if (!relationshipHash) {
      return;
    }

    // eslint-disable-next-line unicorn/no-new-array
    const hasMany = new Array(relationshipHash.length);

    for (const [i, item] of relationshipHash.entries()) {
      const { data, included } = this.#normalizeEmbeddedRelationship(store, relationshipMeta, item);
      hash.included = hash.included ?? [];
      hash.included.push(data);
      if (included) {
        hash.included = hash.included.concat(included);
      }

      hasMany[i] = { id: data.id, type: data.type };
    }

    const relationship = { data: hasMany };
    hash.data.relationships[key] = relationship;
  }

  #extractEmbeddedBelongsTo(store, key, hash, relationshipMeta) {
    const relationshipHash = hash.data?.relationships?.[key]?.data;
    if (!relationshipHash) {
      return;
    }

    const { data, included } = this.#normalizeEmbeddedRelationship(store, relationshipMeta, relationshipHash);
    hash.included = hash.included ?? [];
    hash.included.push(data);
    if (included) {
      hash.included = hash.included.concat(included);
    }

    const belongsTo = { id: data.id, type: data.type };
    const relationship = { data: belongsTo };

    hash.data.relationships[key] = relationship;
  }

  #normalizeEmbeddedRelationship(store, relationshipMeta, relationshipHash) {
    let modelName = relationshipMeta.type;
    if (relationshipMeta.options.polymorphic) {
      modelName = relationshipHash.type;
    }
    const modelClass = store.modelFor(modelName);
    const serializer = store.serializerFor(modelName);

    return serializer.normalize(modelClass, relationshipHash, null);
  }

  #noSerializeOptionSpecified(relationshipsDefinition, attr) {
    return !this.#hasSerializeIdsOption(attr) && !this.defIsEmbedded(relationshipsDefinition, attr);
  }
}
