import { validator } from '@eflexsystems/ember-tracked-validations';
import { getOwner } from '@ember/application';

export default {
  deleteToHardDrivePercentage: [
    validator('number', {
      gte: 0,
      lt: 100,
      allowString: true,
      allowNone: false,
    }),

    validator('inline', {
      validate(deleteToHardDrivePercentage, options, fileRetention) {
        if (deleteToHardDrivePercentage >= fileRetention.maxHardDriveUsage) {
          return getOwner(fileRetention).lookup('service:intl').t('settings.evision.fileRetentionError');
        }
        return true;
      },
    }),
  ],

  maxHardDriveUsage: [
    validator('number', {
      gte: 1,
      lte: 100,
      allowString: true,
      allowNone: false,
    }),
  ],
};
