import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { copyable } from 'eflex/decorators';
import { rollbackHasMany, isHasManyRefDirty } from 'eflex/util/relationship-helpers';

@copyable
class JemPrerequisite extends Model {
  @attr('boolean', { defaultValue: false }) enabled;
  @hasMany('station', { inverse: null, async: false }) prereqs1;
  @hasMany('station', { inverse: null, async: false }) prereqs2;
  @belongsTo('station', { inverse: 'jemPrerequisites', async: false }) station;
  @belongsTo('model', { inverse: 'jemPrerequisites', async: false }) configModel;
  @belongsTo('componentOption', { inverse: 'jemPrerequisites', async: false, polymorphic: true }) configOption;

  get isDirty() {
    return super.isDirty ||
      isHasManyRefDirty(this, 'prereqs1') ||
      isHasManyRefDirty(this, 'prereqs2');
  }

  get copyableOptions() {
    return {
      ignoreAttributes: new Set(['station']),
      copyByReference: new Set(['prereqs1', 'prereqs2', 'configModel', 'configOption']),
    };
  }

  rollbackAttributes() {
    rollbackHasMany(this, 'prereqs1');
    rollbackHasMany(this, 'prereqs2');
    super.rollbackAttributes();
  }
}

export default JemPrerequisite;
