import Component from '@glimmer/component';

export default class JemTaskSpecificDisplayNodeRedDetails extends Component {
  get vars() {
    return this.args.task.variableDefs
      .filter(item => item.isFromDevice)
      .map(def => ({
        name: def.name,
        value: this.args.childStatus?.processData?.find(item => item.name === def.name)?.value,
      }));
  }
}
