import config from 'eflex/config/environment';
import Model, { attr } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';

export default class HostedFile extends Model {
  @attr('string') name;
  @attr('string') mime;

  @tracked isSelected = false;

  get url() {
    const extension = this.name.split('.').pop();

    return `${config.servicePrefix}/hostedFiles/${this.id}.${extension}`;
  }
}
