import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import eflexFeatures from 'eflex/constants/eflex-features';
import { isEmpty, isPresent } from '@ember/utils';
import { camelize } from '@ember/string';
import Model, { attr } from '@ember-data/model';
import { objectAttr, arrayAttr } from 'eflex/decorators';

export default class License extends Model {
  @attr('date') expirationDate;
  @attr('string') jobNumber;
  @attr('string') label;
  @attr('number') licenseId;
  @attr('number') stationLimit;
  @arrayAttr features;
  @objectAttr organization;

  @service stationRepo;
  @service areaRepo;

  get expirationThreshold() {
    return this.organization?.expirationThreshold;
  }

  get visionOrImageCaptureEnabled() {
    return this.evisionEnabled || this.imageCaptureEnabled;
  }

  get serialNumberMonitorEnabled() {
    return this.useComponentAreas;
  }

  get jobsEnabled() {
    return this.areaRepo.areas.some(area => area.usesJobs);
  }

  get visionEnabled() {
    return this.evisionEnabled;
  }

  get visionDisabled() {
    return this.evisionDisabled;
  }

  get daysTillExpired() {
    return moment(this.expirationDate).diff(moment(), 'days') + 1;
  }

  get licenseWarningEnabled() {
    return moment(this.expirationDate)
      .subtract(this.expirationThreshold, 'days')
      .isBefore(moment());
  }

  get activeStations() {
    return this.stationRepo.stations
      .filter(item => !item.isDeleted)
      .filter(item => !item.hideFromOperator)
      .length;
  }

  get atStationLimit() {
    return this.activeStations >= this.stationLimit;
  }

  get aboveStationLimit() {
    return this.activeStations > this.stationLimit;
  }

  get unlicensed() {
    return isEmpty(this.licenseId) ||
      isEmpty(this.stationLimit) ||
      isEmpty(this.features) ||
      isEmpty(this.expirationDate);
  }

  get useComponentAreas() {
    return this.areaRepo.areas.some(item => item.usesComponents) ?? false;
  }

  get disabledHardware() {
    return eflexFeatures
      .filter(feature =>
        isPresent(feature.hardware) && !this.features?.find(item => item.key === feature.key)?.enabled,
      )
      .flatMap(feature => feature.hardware);
  }

  constructor() {
    super(...arguments);

    eflexFeatures.forEach(feature => {
      const featureName = camelize(feature.key);

      Object.defineProperty(this, `${featureName}Licensed`, {
        get() {
          return this.features?.find(item => item.key === feature.key)?.enabled ?? false;
        },
      });

      Object.defineProperty(this, `${featureName}Disabled`, {
        get() {
          return !this[`${featureName}Enabled`];
        },
      });

      Object.defineProperty(this, `${featureName}Enabled`, {
        get() {
          const expirationDate = moment(this.expirationDate);
          return this[`${featureName}Licensed`] && !expirationDate.isBefore(moment());
        },
      });

      Object.defineProperty(this, `${featureName}LicenseWarningEnabled`, {
        get() {
          return this[`${featureName}Licensed`] && this.licenseWarningEnabled;
        },
      });
    });

    Object.defineProperty(this, 'noProductsLicensed', {
      get() {
        return eflexFeatures.every(feature => this[`${camelize(feature.key)}Disabled`]);
      },
    });
  }
}
