import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { reject } from 'ramda';
import getJemTaskSpecificDisplayComponent from 'eflex/util/get-jem-task-specific-display-component';

export default class JemStv extends Component {
  @service taskRepo;

  get taskComponent() {
    return getJemTaskSpecificDisplayComponent(this.task.taskType);
  }

  get task() {
    return this.args.currentTask ?? this.args.tasks[0];
  }

  get viewingIndex() {
    let index = this.displayedTasks.indexOf(this.task);

    if (index < 0) {
      index = 0;
    } else if (index >= this.displayedTasks.length) {
      index = this.displayedTasks.length - 1;
    }

    return index;
  }

  @cached
  get displayedTasks() {
    return reject(task => {
      const taskConfig = this._getTaskConfig(task);
      return !taskConfig || taskConfig.disabled;
    }, this.args.tasks);
  }

  @cached
  get childStatus() {
    return this.args.buildStatus?.getChildStatusForTask(this.task);
  }

  get nextTaskDisabled() {
    return !this.args.currentTask || this.viewingIndex + 1 >= this.displayedTasks.length;
  }

  _getTaskConfig(task) {
    return this.taskRepo.getConfigForModelOrBuildDatum(
      task,
      this.args.model,
      this.args.buildStatus?.buildDatum,
    );
  }

  @action
  nextTask() {
    const nextIndex = this.viewingIndex + 1;

    if (nextIndex >= this.displayedTasks.length) {
      return;
    }

    const task = this.displayedTasks[nextIndex];

    if (!task) {
      return;
    }

    this.args.onSelectTask?.(task);
  }

  @action
  prevTask() {
    if (this.viewingIndex < 0) {
      return;
    }

    const previousIndex = this.viewingIndex - 1;
    const task = this.displayedTasks[previousIndex];

    if (!task) {
      return;
    }

    this.args.onSelectTask?.(task);
  }
}
