import ApplicationSerializer from 'eflex/serializers/application';

export default class BuildStatusChildSerializer extends ApplicationSerializer {
  attrs = {
    liveBuildStatus: {
      serialize: false,
      deserialize: false,
    },
    buildStatus: {
      serialize: false,
      deserialize: false,
    },
  };
}
