import Model, { attr, hasMany } from '@ember-data/model';
import { arrayAttr } from 'eflex/decorators';

export default class ServerStatus extends Model {
  @attr('string') hostname;
  @attr('string') ipAddress;
  @arrayAttr networkStatuses;

  @hasMany('serverStatusLink', { inverse: null, async: false, embedded: true }) links;
}
