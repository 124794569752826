import { inject as service } from '@ember/service';
import Helper from '@ember/component/helper';
import { registerDestructor } from '@ember/destroyable';

export default class EventBusHelper extends Helper {
  @service eventBus;

  #isInitialized = false;

  compute(positional, actions) {
    if (this.#isInitialized) {
      return;
    }

    this.#isInitialized = true;
    const events = Object.keys(actions);

    registerDestructor(this, () => {
      for (const event of events) {
        this.eventBus.off(event, actions[event]);
      }
    });

    for (const event of events) {
      this.eventBus.on(event, actions[event]);
    }
  }
}
