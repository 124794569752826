import Hardware from 'eflex/models/hardware';
import { attr } from '@ember-data/model';
import { isPresent } from '@ember/utils';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { validIpAddress } from 'eflex/constants/regex';
import verifyAddress from 'eflex/validations/ip-validator';
import portValidation from 'eflex/validations/port';
import { getOwner } from '@ember/application';

@buildValidations({
  name: [validator('presence', true)],

  port: [
    portValidation,
    validator('presence', true),
    validator('inline', {
      validate(port, options, keyence) {
        return verifyAddress(keyence, getOwner(keyence).lookup('service:hardwareRepo').keyences);
      },
    }),
  ],

  ipAddress: [
    validator('presence', true),
    validator('format', {
      regex: validIpAddress,
      allowBlank: false,
    }),
    validator('inline', {
      validate(ipAddress, options, keyence) {
        return verifyAddress(keyence, getOwner(keyence).lookup('service:hardwareRepo').keyences);
      },
    }),
  ],
})
class Keyence extends Hardware {
  @attr('string') ipAddress;
  @attr('number', { defaultValue: 9004 }) port;

  get isBarcodeHardware() {
    return true;
  }

  get isConfigured() {
    return isPresent(this.ipAddress) && isPresent(this.port);
  }
}

export default Keyence;
