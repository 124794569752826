import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

class JemBomVariables extends Component {
  @service intl;

  noneOption = { name: this.intl.t('none') };

  get displayVariableValue() {
    const selectedId = this.args.jemDisplayVariable?.id;
    const variable = this.args.buildDatumVariables?.find(item => item.id === selectedId);
    return variable?.rawValue;
  }

  @action
  onChange(variable) {
    if (variable === this.noneOption) {
      variable = null;
      return;
    }

    this.args.onSave(variable);
  }
}

export default JemBomVariables;
