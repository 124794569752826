const AnimationEvents = {
  START: 'start',
  PASS: 'pass',
  REJECT: 'reject',
};

const AnimationEventValues = [AnimationEvents.START, AnimationEvents.PASS, AnimationEvents.REJECT];

export { AnimationEvents, AnimationEventValues };
export default AnimationEvents;
