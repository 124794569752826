import Model, { attr } from '@ember-data/model';
import { caption, arrayAttr } from 'eflex/decorators';

export default class DecisionProcessDatum extends Model {
  @attr('string') decisionDef;
  @attr('boolean', { defaultValue: false }) isSelected;
  @arrayAttr decisionTags;
  @arrayAttr captions;
  @caption name;
}
