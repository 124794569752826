import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import EdhrMethodTypes from 'eflex/constants/tasks/edhr-method-types';
import EdhrProcessDataTypes from 'eflex/constants/edhr-process-data-types';
import { taskTypes } from 'eflex/constants/tasks/task-types';
import { concatRight, sortByProps, sortByProp } from 'ramda-adjunct';
import { range, pipe } from 'ramda';

const METHODS_WITH_REASON_CODES = new Set([EdhrMethodTypes.sendData, EdhrMethodTypes.completeOperation]);
const IMAGE_TASK_TYPES = new Set([taskTypes.imageCapture, taskTypes.vision]);

export default class EdhrDeviceConfig extends Component {
  @service store;
  @service intl;

  edhrMethodTypes = EdhrMethodTypes;
  edhrProcessDataTypes = EdhrProcessDataTypes;

  get previousTasks() {
    const allTasks = sortByProps(['row', 'column'], this.args.task.parent?.children ?? []);
    const index = allTasks.indexOf(this.args.task);

    if (index === -1) {
      return [];
    }

    return allTasks.slice(0, index);
  }

  get showReasonCode() {
    return METHODS_WITH_REASON_CODES.has(this.args.task.edhrMethod);
  }

  get processDataOptions() {
    return pipe(
      concatRight(this.jemProcessDataDefs ?? []),
      concatRight(this.variableDefs ?? []),
      concatRight(this.images ?? []),
      concatRight(this.barcodes ?? []),
      concatRight(this.decisions ?? []),
      sortByProp('groupName'),
      concatRight([
        this._getMappingOption(EdhrProcessDataTypes.currentDateTime),
        this._getMappingOption(EdhrProcessDataTypes.userDefinedString),
      ]),
    )(this.bolts ?? []);
  }

  get decimalOptions() {
    let keys = Array.from({ length: 11 }).keys();
    return [...keys].map(String);
  }

  get bolts() {
    const torqueTasks = this.previousTasks.filter(item => item.taskType === taskTypes.torque);
    return torqueTasks.map((task) => {
      const options = range(1, task.maxBoltCount + 1).flatMap((i) => {
        return [
          this._boltToProcessDatum(i, EdhrProcessDataTypes.boltTorque, task),
          this._boltToProcessDatum(i, EdhrProcessDataTypes.boltAngle, task),
        ];
      });
      options.unshift(this._getMappingOption(EdhrProcessDataTypes.hardwareName, task));
      return { groupName: task.name, options };
    });
  }

  get jemProcessDataDefs() {
    return this.previousTasks
      .filter(item => item.taskType === taskTypes.button)
      .filter(item => item.jemProcessDataDefs?.length)
      .map((task) => ({
        groupName: task.name,
        options: task.jemProcessDataDefs.map((jemProcessDataDef) => ({
          id: jemProcessDataDef.id,
          processDataType: EdhrProcessDataTypes.jemProcessDataDef,
          name: jemProcessDataDef.name,
          jemProcessDataDef,
          dataTask: task,
        })),
      }));
  }

  get variableDefs() {
    return this.previousTasks
      .filter(item => item.taskType === taskTypes.nodeRed)
      .filter(item => item.variableDefs?.length)
      .map((task) => {
        const options = task.variableDefs.filter(item => item.isFromDevice).map((variableDef) => ({
          id: variableDef.id,
          processDataType: EdhrProcessDataTypes.variableDef,
          name: variableDef.name,
          variableDef,
          dataTask: task,
        }));
        options.unshift(this._getMappingOption(EdhrProcessDataTypes.hardwareName, task));
        return { groupName: task.name, options };
      });
  }

  get images() {
    const imageTasks = this.previousTasks.filter(task =>
      IMAGE_TASK_TYPES.has(task.taskType),
    );

    return imageTasks.map((task) => {
      return {
        groupName: task.name,
        options: [
          this._getMappingOption(EdhrProcessDataTypes.hardwareName, task),
          this._getMappingOption(EdhrProcessDataTypes.sendImage, task),
        ],
      };
    });
  }

  get barcodes() {
    const barcodeTasks = this.previousTasks.filter(item => item.taskType === taskTypes.barcode);

    return barcodeTasks.map((task) => {
      return {
        groupName: task.name,
        options: [
          this._getMappingOption(EdhrProcessDataTypes.hardwareName, task),
          this._getMappingOption(EdhrProcessDataTypes.scannedBarcode, task),
        ],
      };
    });
  }

  get decisions() {
    const decisionTasks = this.previousTasks.filter(item => item.taskType === taskTypes.decision);

    return decisionTasks.map((task) => {
      return {
        groupName: task.name,
        options: [this._getMappingOption(EdhrProcessDataTypes.selectedDecision, task)],
      };
    });
  }

  _getMappingOption(processDataType, dataTask) {
    return {
      id: processDataType,
      processDataType,
      name: this.intl.t(processDataType),
      dataTask,
    };
  }

  _boltToProcessDatum(boltNumber, processDataType, task) {
    return {
      id: this._getBoltId(boltNumber, processDataType),
      processDataType,
      name: `${this.intl.t('bolt')} ${boltNumber} ${this.intl.t(processDataType)}`,
      boltIndex: boltNumber,
      dataTask: task,
    };
  }

  _getBoltId(boltNumber, boltFieldType) {
    return String(boltNumber) + boltFieldType;
  }

  getDataMappingReference = (mapping) => {
    const group = this.processDataOptions.find((option) => {
      return option.options?.find(item => item.dataTask === mapping.dataTask);
    });

    switch (mapping.processDataType) {
      case EdhrProcessDataTypes.jemProcessDataDef: {
        return group?.options.find(item => item.id === mapping.jemProcessDataDef?.id);
      }
      case EdhrProcessDataTypes.variableDef: {
        return group?.options.find(item => item.id === mapping.variableDef?.id);
      }
      case EdhrProcessDataTypes.boltTorque:
      case EdhrProcessDataTypes.boltAngle: {
        return group?.options.find(item => item.id === this._getBoltId(mapping.boltIndex, mapping.processDataType));
      }
      default: {
        if (group) {
          return group.options.find(item => item.id === mapping.processDataType);
        }
        return this.processDataOptions.find(item => item.id === mapping.processDataType);
      }
    }
  };

  // jscpd:ignore-start
  @action
  addMapping(count) {
    for (let i = 1; i <= count; i++) {
      this.store.createRecord('edhrMapping', { task: this.args.task });
    }
  }

  @action
  removeMapping(mapping) {
    mapping.deleteRecord();
  }

  @action
  setProcessDatum(mapping, processDatum) {
    Object.assign(mapping, {
      boltIndex: processDatum.boltIndex ?? null,
      processDataType: processDatum.processDataType,
      jemProcessDataDef: processDatum.jemProcessDataDef,
      variableDef: processDatum.variableDef,
      dataTask: processDatum.dataTask,
    });
  }
  // jscpd:ignore-end
}
