/**
 * Copyright 2015, Yahoo! Inc.
 * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
 */

/**
 * @private
 * @hide
 */ // eslint-disable-next-line @typescript-eslint/ban-types
export default class FormatterBase {
  get options() {
    return [];
  }
}
FormatterBase.type = void 0;