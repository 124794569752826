import Model, { attr, hasMany } from '@ember-data/model';

export default class Audit extends Model {
  @attr('string') message;
  @attr('string') username;
  @attr('string') ipAddress;
  @attr('date') timestamp;
  @attr('string') type;
  @attr('string') verbAction;
  @attr dto;

  @hasMany('auditMetadatum', { async: false, inverse: null, embedded: true }) metadata;
}
