import ApplicationSerializer from 'eflex/serializers/application';

export default class ScheduleSerializer extends ApplicationSerializer {
  attrs = {
    stations: {
      serialize: 'ids',
      deserialize: 'ids',
    },
    oee: {
      deserialize: false,
      serialize: false,
    },
  };
}
