import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task, waitForProperty } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { task as trackedTask } from 'ember-resources/util/ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import { forEach, filter, pipe, without } from 'ramda';
import { isAncestorOf, recursiveSave } from 'eflex/util/tree-helpers';
import { intoArray } from '@eflexsystems/ramda-helpers';

export default class JobController extends Controller {
  @tracked showChangeNotificationModal = false;
  @tracked showTaskPanel = false;
  @tracked activeTaskPanelTab = 'task-device';
  @tracked job;
  @tracked selectedTask;

  gridTaskConfigLoadTracker = new Map();

  @service taskRepo;
  @service validationErrorNotifier;
  @service notifier;
  @service systemConfig;
  @service store;
  @service currentUser;
  @service eventBus;
  @service taskConfigRepo;

  gridTaskConfigs = trackedTask(this, this._loadTaskGridConfigs, () => [
    this.job,
  ]);

  get isInvalid() {
    return this.job?.isSelfOrChildInvalid;
  }

  get disabled() {
    return this.currentUser.isNotAdmin;
  }

  get isDirty() {
    if (this.job?.isDeleted) {
      return false;
    }

    return this.job?.isSelfOrChildDirty ?? false;
  }

  @task
  @waitFor
  *_loadTaskGridConfigs(job, modelId) {
    const cacheKey = modelId ?? true;
    const jobCacheSet = this.gridTaskConfigLoadTracker.get(job.id);

    if (jobCacheSet?.has(cacheKey)) {
      return;
    }

    if (jobCacheSet) {
      jobCacheSet.add(cacheKey);
    } else {
      this.gridTaskConfigLoadTracker.set(job.id, new Set([cacheKey]));
    }

    yield this.store.query('taskConfig', {
      ancestorPath: job.path,
      model: modelId,
    });
  }

  @task
  @waitFor
  *save() {
    if (!this.job) {
      return;
    }

    if (this.isInvalid) {
      this.validationErrorNotifier.sendErrors([this.job]);
      return;
    }

    if (this.systemConfig.jem?.changeNotifications) {
      this.showChangeNotificationModal = true;
      yield waitForProperty(this, 'showChangeNotificationModal', false);
    }

    yield recursiveSave(this.job);
  }

  @task({ drop: true })
  @waitFor
  *copyTask(treeTask) {
    if (!this.job || this.disabled) {
      return;
    }

    yield this.taskConfigRepo.loadTaskConfigs.perform(treeTask);

    const copy = this.taskRepo.copyTask(treeTask, this.job);

    this.closeAllPanels();
    this.activeTaskPanelTab = 'task-device';
    this.showTaskPanel = true;
    this.selectedTask = copy;
  }

  @action
  updateTaskCardRows(treeItem) {
    if (treeItem.type !== 'task' || this.job == null || !isAncestorOf(this.job, treeItem)) {
      return;
    }

    const newTaskStart = treeItem.row;
    const newTaskEnd = newTaskStart + treeItem.sizeY;

    pipe(
      intoArray(
        without([treeItem]),
        filter(taskRecord => {
          const changed = taskRecord.changedAttributes();
          return changed.row != null && newTaskStart <= changed.row[1] && changed.row[1] < newTaskEnd;
        }),
      ),
      forEach(taskRecord => {
        this.taskRepo.addToEndOfSequence(this.job, taskRecord);
      }),
    )(this.job.tasks);
  }

  closeAllPanels() {
    this.showTaskPanel = false;
  }

  @task
  @waitFor
  *openTaskPanel(taskRecord) {
    Object.assign(this, {
      showStationPanel: false,
      showStationLoadPanel: false,
      showOptionsPanel: false,
      showTaskPanel: true,
    });

    yield this.taskConfigRepo.loadTaskConfigs.perform(taskRecord);

    this.selectedTask = taskRecord;
  }

  @action
  deleteTask(taskRecord) {
    if (this.selectedTask === taskRecord) {
      Object.assign(this, {
        selectedTask: null,
        showTaskPanel: false,
      });
    }

    this.taskRepo.delete(taskRecord);
  }

  @action
  rollback() {
    if (this.selectedTask?.isNew) {
      this.closeAllPanelsAndClearPanelModel();
    }

    this.job.rollbackAttributes();
    this.eventBus.trigger('taskCardsRolledBack');
  }

  @action
  addTask(taskType) {
    this.closeAllPanels();
    Object.assign(this, {
      showTaskPanel: true,
      activeTaskPanelTab: 'task-device',
      selectedTask: this.taskRepo.create({
        parent: this.job,
        taskType,
        row: (this.selectedTask?.row ? this.selectedTask.rowEnd + 1 : null),
        column: 1,
      }),
    });
  }

  @action
  openOptionsPanel() {
    this.closeAllPanels();
    this.showOptionsPanel = true;
  }

  @action
  closeAllPanelsAndClearPanelModel() {
    this.closeAllPanels();
    this.selectedTask = null;
  }
}
