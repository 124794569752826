import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { toTree } from 'eflex/util/tree-helpers';

export default class CodesConfig extends Component {
  @service store;
  @service currentUser;
  @service areaRepo;

  get treeData() {
    return this.areaRepo.areas.map(area =>
      toTree(area, {
        lastModel: 'station',
      }),
    );
  }

  get disableCode() {
    return !this.currentUser.isAdmin;
  }

  get displayedCodes() {
    return this.args.codes.filter(item => !item.isDeleted);
  }

  @action
  addCode() {
    this.store.createRecord(this.args.codeModel);
  }

  @action
  removeCode(code) {
    code.deleteRecord();
  }

  @action
  updateCode(code, stations) {
    const records = stations.map(station =>
      this.store.peekRecord(station.modelName, station.id),
    );

    code.stations = records;
  }
}
