import ApplicationSerializer from 'eflex/serializers/application';

export default class WorkInstructionAnimationSerializer extends ApplicationSerializer {
  attrs = {
    workInstructionHardwareTriggerConfig: {
      serialize: false,
      deserialize: false,
    },
  };
}
