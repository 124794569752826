import GridRoute from 'eflex/util/grid-route';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { NotificationLogTypes } from 'eflex/constants/notification-log';
import { waitFor } from '@ember/test-waiters';
import { TrackedArray } from 'tracked-built-ins';

export default class LogsRoute extends GridRoute {
  @service notifier;
  @service logRepo;
  @service store;

  @task
  @waitFor
  *getData(searchField = {}) {
    const params = Object.assign(searchField, { logType: NotificationLogTypes.GLOBAL }, this.controller?.params);
    return new TrackedArray(yield this.store.query('log', params));
  }

  @task
  @waitFor
  *confirmAcknowledgeLogs() {
    const logs = this.controller.selectedLogs;

    if (this.controller.selectAll) {
      yield this.logRepo.acknowledgeAll.perform(NotificationLogTypes.GLOBAL);
    } else {
      yield this.logRepo.acknowledgeLogs.perform(logs);
    }

    logs.forEach(log => { log.unloadRecord(); });

    this.notifier.sendSuccess('logs.logsAcknowledged');
    yield this.search.perform();
    this.controller.showAcknowledgeModal = false;
  }
}
