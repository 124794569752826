import { inject as service } from '@ember/service';
import AuthenticatedRoute from 'eflex/util/authenticated-route';

export default class JobRoute extends AuthenticatedRoute {
  @service store;

  model(params) {
    return this.store.peekRecord('job', params.id);
  }

  setupController(controller, job) {
    Object.assign(controller, {
      job,
      activeTaskPanelTab: 'task-device',
    });
  }

  resetController(controller) {
    controller.closeAllPanels();

    Object.assign(controller, {
      taskPanelModel: null,
      selectedTask: null,
    });
  }
}
