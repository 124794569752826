import Model, { attr } from '@ember-data/model';

export default class JemCampaign extends Model {
  @attr('string') name;

  get truncatedName() {
    if (this.name.length < 10) {
      return this.name;
    }

    return `${this.name.slice(0, 10)}...`;
  }
}
