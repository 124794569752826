import moment from 'moment-timezone';

const FormatDurationAxisBi = (value) => {
  return moment.duration(value, 'milliseconds').format('d[d] h[h] m[m] s[s]', {
    precision: 1,
    trim: 'both mid',
  });
};

export default FormatDurationAxisBi;
