import { isBlank } from '@ember/utils';

export default function modelDisplayName(model) {
  if (!model) {
    return null;
  }

  let displayed = model.code;

  if (!isBlank(model.name)) {
    displayed = `${displayed} - ${model.name}`;
  }

  return displayed;
}
