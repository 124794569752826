import Model, { attr } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import config from 'eflex/config/environment';

export default class NonConformingImage extends Model {
  @attr('string') fileName;
  @attr('number') fileSize;
  @attr('string') fileType;
  @attr('date') dateCreated;
  @attr('string') url;
  @attr('string') thumbnailUrl;

  @tracked isActive;
  @tracked isSelected = false;

  get downloadUrl() {
    return this.fullUrl;
  }

  get isPdf() {
    return this.fileType === 'pdf';
  }

  get fullUrl() {
    return `${config.photoUrlPrefix}/${this.url}`;
  }

  get fullThumbnailUrl() {
    return `${config.photoUrlPrefix}/${this.thumbnailUrl}`;
  }
}
