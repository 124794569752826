import Model, { attr } from '@ember-data/model';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';

@buildValidations({
  background: [validator('format', { regex: '#[A-Fa-fd]{6}' })],
})
class WieConfigNormal extends Model {
  @attr('string') background;
}

export default WieConfigNormal;
